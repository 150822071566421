import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../VibeIcon/VibeIcon';
import { transparent } from '../../utils/ColorUtil';
import viAddCircle from '../../icons/viAddCircle';
import viCloseCircle from '../../icons/viCloseCircle';
import color from '../../sass/color.scss';
import './AddRemoveField.scss';

function AddRemoveField({
  className,
  id,
  allowAdd,
  allowRemove,
  children,
  onAdd,
  onRemove,
}) {
  const onClickRemove = () => {
    onRemove(id);
  };

  const onClickAdd = () => {
    onAdd();
  };

  return (
    <div className={classNames('AddRemoveField', className)}>
      <div className="field-container">
        {children}
      </div>

      <div className="actions">
        {allowAdd && (
          <VibeIcon
            icon={viAddCircle}
            color={color.success}
            hoverColor={transparent(color.success, 50)}
            size={24}
            tooltip="Add Field"
            tooltipProps={{
              placement: 'top',
            }}
            style={{
              marginLeft: 4,
            }}
            onClick={onClickAdd}
          />
        )}

        {allowRemove && (
          <VibeIcon
            icon={viCloseCircle}
            color={color.error}
            hoverColor={transparent(color.error, 50)}
            size={24}
            tooltip="Remove Field"
            tooltipProps={{
              placement: 'right',
            }}
            onClick={onClickRemove}
          />
        )}
      </div>
    </div>
  );
}

AddRemoveField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  allowAdd: PropTypes.bool,
  allowRemove: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

AddRemoveField.defaultProps = {
  className: '',
  allowAdd: false,
  allowRemove: false,
  onAdd: () => {},
  onRemove: () => {},
};

export default AddRemoveField;
