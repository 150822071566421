import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  TableVisualLoopTemplates,
  VibeButtonNew,
  VibeIcon,
  viClose,
  viAdd,
  color,
} from 'vibeguide';
import CompanyLoopTemplateEdit from './CompanyLoopTemplateEdit';
import './CompanyLoopTemplates.scss';

function CompanyLoopTemplates({
  user,
  companyId,
  setPanel,
  onClose,
}) {
  /**
   * When the row is clicked
   */
  const onClickRow = (row) => {
    setPanel({
      thirdPanel: {
        width: 600,
        show: true,
        title: row._id
          ? row.name
          : 'New Loop Template',
        children: (
          <CompanyLoopTemplateEdit
            templateId={row._id}
            companyId={row.companyId}
          />
        ),
      },
    });
  };

  return (
    <div className="CompanyLoopTemplates">
      <div className="title-container">
        <div className="title">
          Loop Templates
        </div>

        <VibeButtonNew
          style={{
            marginRight: 16,
          }}
          text="New Loop Template"
          icon={viAdd}
          color={color.primary}
          disabled={!user.can('visual_loop_template.create')}
          onClick={() => {
            onClickRow({
              companyId,
            });
          }}
        />

        <VibeIcon
          className="close"
          icon={viClose}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
          onClick={onClose}
        />
      </div>

      <TableVisualLoopTemplates
        columns={[
          'Name',
          'Planned Duration',
          'Actual Duration',
          'Max Hourly Ad Spots',
          '...',
        ]}
        fetch={API.Company.LoopTemplate.list}
        fetchProps={{
          filters: {
            companyId,
          },
        }}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Loop Templates',
          urlPaging: false,
          urlFilters: false,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Loop Templates',
          tooltip: true,
        }}
        menuItems={[
          { name: 'Archive', userCan: 'visual_loop_template.delete' },
        ]}
        onClickRow={onClickRow}
      />
    </div>
  );
}

CompanyLoopTemplates.propTypes = {
  companyId: PropTypes.string,
  onClose: PropTypes.func,
};

CompanyLoopTemplates.defaultProps = {
  companyId: '',
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLoopTemplates);
