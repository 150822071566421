import {
  each,
  get,
  filter,
  last,
  isUndefined,
  isEmpty,
} from 'lodash';

class MessageRequest {
  constructor(data = {}) {
    this._id = data._id || '';
    this.active = data.active || '';
    this.activity = data.activity || [];
    this.advertiserId = data.advertiserId || '';
    this.changeRequested = data.changeRequested || false;
    this.companyId = data.companyId || '';
    this.companyName = data.companyName || '';
    this.createdBy = data.createdBy || '';
    this.createdDate = data.createdDate || '';
    this.dueDate = data.dueDate || '';
    this.endDate = data.endDate || '';
    this.instructions = data.instructions || '';
    this.locations = data.locations || [];
    this.locationsData = data.locationsData || {
      companies: [],
      locations: [],
    };
    this.messageLists = data.messageLists || [];
    this.messageType = data.messageType || '';
    this.modifiedBy = data.modifiedBy || '';
    this.modifiedDate = data.modifiedDate || '';
    this.name = data.name || '';
    this.originalRequestId = data.originalRequestId || '';
    this.mediaSourceRequestId = data.mediaSourceRequestId || '';
    this.publishMode = data.publishMode || 'review';
    this.daysOfWeek = data.daysOfWeek || ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    this.readType = data.readType || 'interpret';
    this.script = data.script || '';
    this.startDate = data.startDate || '';
    this.status = data.status || '';
    this.tags = data.tags || [];
    // default to 1 empty tracking url
    this.trackingUrls = isEmpty(data.trackingUrls)
      ? ['']
      : data.trackingUrls || [''];
    this.voiceTalentId = data.voiceTalentId || '';
    this.voiceTalentFname = data.voiceTalentFname || '';
    this.voiceTalentLname = data.voiceTalentLname || '';
    this.voiceTalentImageUrl = data.voiceTalentImageUrl || '';
    this.voiceTalent = data.voiceTalent || {};
    this.lastArchivedBy = data.lastArchivedBy || null;
    this.lastArchivedDate = data.lastArchivedDate || null;
    this.lastUnarchivedBy = data.lastUnarchivedBy || null;
    this.lastUnarchivedDate = data.lastUnarchivedDate || null;

    /**
     * Does the company require message approval?
     * This is tracked through the API.Company.getMessageConfig() endpoint
     */
    this.requireApproval = data.requireApproval || false;

    /**
     * Message Approvers
     */
    this.clientApproval = data.clientApproval || {
      approvers: [],
      style: 'unanimous',
      approvalsRequired: 0,
    };

    // Is message an external request
    this.isExternal = this.messageType === 'ext';

    // Is the script to be read exactly as written
    this.isScriptExact = this.readType === 'exact';

    const roughCuts = this.activity.length > 0
      ? filter(this.activity, {
        activityType: 'upload',
        detail: {
          type: 'rough',
        },
      })
      : [];

    const roughCut = last(roughCuts);

    const finalCuts = this.activity.length > 0
      ? filter(this.activity, {
        activityType: 'upload',
        detail: {
          type: 'final',
        },
      })
      : [];

    const finalCut = last(finalCuts);

    // Set the URLs for each type of cut
    this.roughCutUrl = roughCuts.length > 0
      ? get(roughCut, 'detail.url', null)
      : null;

    this.finalCutUrl = finalCuts.length > 0
      ? get(finalCut, 'detail.url', null)
      : null;

    this.url = this.finalCutUrl || this.roughCutUrl;

    each(data, (val, key) => {
      // Convert the key to camelcase
      // const camelKey = camelCase(key);

      if (isUndefined(this[key])) {
        // Add the property to the model
        this[key] = val;

        console.warn(`${key} is not added to MessageRequest Model`);
      }
    });
  }
}

export default MessageRequest;
