import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  clone,
} from 'lodash';
import VibeIcon from '../VibeIcon/VibeIcon';
import viAdd from '../../icons/viAdd';
import viStar from '../../icons/viStar';
import viCloseCircle from '../../icons/viCloseCircle';
import color from '../../sass/color.scss';
import './Tag.scss';

class Tag extends PureComponent {
  constructor(props) {
    super(props);

    const {
      tag: {
        name,
      },
    } = props;

    this.state = {
      // Name of the tag
      name,
      // is the tag in edit mode
      isEdit: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      tag: {
        name,
      },
    } = this.props;

    const {
      tag: {
        name: prevName,
      },
    } = prevProps;

    if (name !== prevName) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        name,
      });
    }
  }

  /**
   * When a tag name is double clicked
   */
  onDoubleClickName = () => {
    const {
      tag: {
        status = 'unedited',
      },
      allowEdit,
    } = this.props;

    // Make tag editable if user can edit the tag and it's not being removed
    if (allowEdit && status !== 'remove') {
      this.setState({
        isEdit: true,
      });
    } else {
      console.warn('You cannot edit a tag or a tag queued for removal');
    }
  };

  /**
   * When the icon is clicked
   */
  onClickIcon = () => {
    const {
      tag: {
        status = 'unedited',
        active,
      },
      manage,
    } = this.props;

    const {
      isEdit,
    } = this.state;

    if (status === 'remove') {
      // Restore the tag
      this.onRestore();
      return;
    }

    if (isEdit) {
      // Cancel editing the tag
      this.onCancelEditName();
    } else if (!active
      && status !== 'add'
      && status !== 'unarchive'
      && (status === 'unedited' && manage)
    ) {
      // Add the tag (unarchive)
      this.onAdd();
    } else {
      // Remove the tag
      this.onRemove();
    }
  };

  /**
   * When a key is pressed on the edit tag input
   */
  onKeyDownEditName = (e) => {
    const {
      tag,
      onEdit,
    } = this.props;

    const {
      name,
    } = this.state;

    if (e.key === 'Enter') {
      if (name !== tag.name) {
        // Save changes
        const editTag = clone(tag);
        // Set previous tag name (for when it needs to revert)
        editTag.namePrev = tag.name;
        // Set new tag name
        editTag.name = name;

        onEdit(editTag);
      }

      // Take the tag out of edit mode
      this.setState({
        isEdit: false,
      });
    } else if (e.key === 'Escape') {
      // Cancel edit
      this.onCancelEditName();
    }
  };

  /**
   * When the edit name input is changed
   */
  onChangeEditName = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      name: value,
    });
  };

  /**
   * When the edit input loses focus
   */
  onBlurEditName = () => {
    const {
      tag,
      onEdit,
    } = this.props;

    const {
      name,
    } = this.state;

    if (name !== tag.name) {
      // Save changes
      const editTag = clone(tag);
      // Set previous tag name (for when it needs to revert)
      editTag.namePrev = tag.name;
      // Set new tag name
      editTag.name = name;

      onEdit(editTag);
    }

    // Take the tag out of edit mode
    this.setState({
      isEdit: false,
    });
  };

  /**
   * When the close icon is clicked while in edit mode
   * Cancel editing a tag
   */
  onCancelEditName = () => {
    const {
      tag: {
        name,
      },
    } = this.props;

    this.setState({
      name,
      isEdit: false,
    });
  };

  /**
   * When the remove icon is clicked, queue the tag to remove
   */
  onRemove = () => {
    const {
      tag,
      dataId,
      allowRemove,
      onRemove,
    } = this.props;

    if (!allowRemove) {
      console.error('You cannot remove this tag');
      return;
    }

    onRemove(tag, dataId);
  };

  /**
   * When the add icon is clicked, queue the tag to add (unarchive)
   */
  onAdd = () => {
    const {
      tag,
      onAdd,
    } = this.props;

    onAdd(tag);
  };

  /**
   * When the restore (add) icon is clicked, queue the tag to restore
   */
  onRestore = () => {
    const {
      tag,
      onRestore,
    } = this.props;

    onRestore(tag);
  };

  render() {
    const {
      className,
      tag: {
        type,
        status = 'unedited',
        active,
        icon: propIcon,
      },
      style,
      manage,
      allowRemove,
      textColor,
      activeType,
    } = this.props;

    const {
      name,
      isEdit,
    } = this.state;

    const isAdminTag = type === 'admin';
    let iconColor;
    let iconHtml;

    switch (status) {
      case 'add':
      case 'unarchive':
        iconColor = style.color || color.success;
        if (allowRemove) {
          iconHtml = (
            <VibeIcon
              className="tag-icon add-tag-icon"
              icon={viCloseCircle}
              color={iconColor}
              size={12}
              onClick={this.onClickIcon}
            />
          );
        }
        break;

      case 'remove':
        iconColor = style.color || color.error;
        iconHtml = (
          <VibeIcon
            className="tag-icon restore-tag-icon"
            icon={viAdd}
            color={iconColor}
            size={12}
            onClick={this.onClickIcon}
          />
        );
        break;

      case 'edit':
        iconColor = style.color || color.primary;
        iconHtml = (
          <VibeIcon
            className="tag-icon edit-tag-icon"
            icon={viCloseCircle}
            color={iconColor}
            size={12}
            onClick={this.onClickIcon}
          />
        );
        break;

      case 'restore':
      case 'unedited':
      default: {
        iconColor = style.color || color.manatee;
        if (allowRemove) {
          let icon = viCloseCircle;

          if (manage && !active) {
            icon = viAdd;
          }

          iconHtml = (
            <VibeIcon
              className="tag-icon remove-tag-icon"
              icon={icon}
              color={iconColor}
              hoverColor={color.obsidian}
              size={12}
              onClick={this.onClickIcon}
            />
          );
        }
        break;
      }
    }

    if (!allowRemove) {
      // Do not show the icon
      iconHtml = null;
    }

    if (textColor) {
      iconColor = textColor;
    }

    return (
      <div
        className={classNames('Tag', status, className, { [activeType]: activeType === type })}
        style={style}
      >
        {isAdminTag ? (
          <VibeIcon
            className="admin-tag-icon"
            icon={viStar}
            color={iconColor}
            size={12}
          />
        ) : null}

        <div className="tag-name-container">
          {isEdit ? (
            <input
              className="input-edit-tag"
              type="text"
              value={name}
              onKeyDown={this.onKeyDownEditName}
              onBlur={this.onBlurEditName}
              onChange={this.onChangeEditName}
              autoFocus
            />
          ) : (
            <>
              {propIcon && (
                <VibeIcon
                  icon={propIcon}
                  color={iconColor}
                  size={16}
                  style={{
                    marginRight: 4,
                  }}
                />
              )}
              <div
                className="tag-name"
                onDoubleClick={this.onDoubleClickName}
              >
                {name}
              </div>
            </>
          )}
        </div>

        {iconHtml}
      </div>
    );
  }
}

Tag.propTypes = {
  className: PropTypes.string,
  /** Field ID for data attribute on input */
  dataId: PropTypes.string,
  /** Tag data */
  tag: PropTypes.shape({
    _id: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    type: PropTypes.oneOf([
      'client',
      'admin',
    ]),
    activeType: PropTypes.oneOf([
      'client',
      'admin',
    ]),
    name: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.node,
    ]),
    active: PropTypes.bool,
    // Status is added to determine the state of the tag if changes are saved
    status: PropTypes.oneOf([
      'unedited',
      'add',
      'unarchive',
      'remove',
      'restore',
      'edit',
      'found',
    ]),
  }).isRequired,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  textColor: PropTypes.string,
  /** Is this tag in the manage tags list */
  manage: PropTypes.bool,
  /** Allow the tag to be edited */
  allowEdit: PropTypes.bool,
  /** Allow the tag to be removed */
  allowRemove: PropTypes.bool,
  /** When a tag is queued to remove */
  onRemove: PropTypes.func,
  /** When a tag is queued to add (unarchive) */
  onAdd: PropTypes.func,
  /** When a tag is queued to restore */
  onRestore: PropTypes.func,
  /** When a tag is queued to edit */
  onEdit: PropTypes.func,
};

Tag.defaultProps = {
  className: '',
  dataId: null,
  style: {},
  textColor: '',
  manage: false,
  allowEdit: false,
  allowRemove: false,
  onRemove: () => {},
  onAdd: () => {},
  onRestore: () => {},
  onEdit: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Tag);
