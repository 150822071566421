import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './EmptyState.scss';

function EmptyState({
  className,
  style,
  image,
  imageStyle,
  title,
  description,
  buttonHtml,
  tableGridRef,
}) {
  // align the button position with the bottom of the empty state content area
  const [buttonPosition, setButtonPosition] = useState({
    bottom: 0,
    left: 0,
    marginLeft: 0,
    top: 0,
  });
  const contentRef = useRef(null);

  /**
   * Set the button position
   */
  const setButton = () => {
    const el = contentRef.current.getBoundingClientRect();

    if (tableGridRef.current.getBoundingClientRect().height >= 330) {
      // place button underneath the empty state text
      setButtonPosition({
        bottom: el.bottom - el.y,
        left: 0,
      });
    } else if (tableGridRef.current.getBoundingClientRect().height <= 285) {
      setButtonPosition({
        top: 220,
        left: 120,
      });
    } else {
      setButtonPosition({
        top: 100 + style.height - 50,
        left: 120,
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setButton);

    return () => {
      window.removeEventListener('resize', setButton);
    };
  }, []);

  useEffect(() => {
    if (buttonHtml && contentRef && contentRef.current) {
      // set button position
      setButton();
    }

    window.addEventListener('resize', setButton);

    return () => {
      window.removeEventListener('resize', setButton);
    };
  }, [style.height]);

  return (
    <>
      <div
        className={classNames('EmptyState', className)}
        style={style}
      >
        <div className="empty-state-content">
          {image && (
            <div
              className={classNames('empty-state-image', {
                horizontal: style.height < 422,
              })}
            >
              <img
                src={image}
                style={{
                  maxHeight: 150,
                  ...imageStyle,
                }}
                alt="Empty"
              />
            </div>
          )}

          <div
            ref={contentRef}
            className={classNames('empty-state-text', {
              horizontal: style.height < 422,
            })}
          >
            <h2 className="title">
              {title}
            </h2>

            <div className="description">
              {description}
            </div>
          </div>
        </div>
      </div>

      {buttonHtml !== null && (
        <div
          className="button-wrapper"
          style={{
            bottom: buttonPosition.bottom,
            top: buttonPosition.top,
            left: buttonPosition.left,
            right: 0,
          }}
        >
          {buttonHtml}
        </div>
      )}
    </>
  );
}

EmptyState.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Image */
  image: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Image Style */
  imageStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Title */
  title: PropTypes.string.isRequired,
  /** Description */
  description: PropTypes.string.isRequired,
  /** Button HTML */
  buttonHtml: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  tableGridRef: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
  ]),
};

EmptyState.defaultProps = {
  className: '',
  image: '',
  style: {},
  imageStyle: {},
  buttonHtml: null,
  tableGridRef: null,
};

export default EmptyState;
