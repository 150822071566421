import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  sortBy,
} from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import VibeSplash from '../VibeSplash/VibeSplash';
import SwapButton from '../SwapButton/SwapButton';
import MixPlaylist from '../MixPlaylist/MixPlaylist';
import { addMixPlaylists, removeMixPlaylist } from '../../actions/Mix/NewMixActions';
import color from '../../sass/color.scss';
import './PlaylistsAssigned.scss';

class PlaylistsAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      newMix,
    } = this.props;

    this.state = {
      playlists: newMix.playlists,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      // selected,
      onUpdate,
      newMix,
    } = this.props;

    const {
      // selected: prevSelected,
      newMix: oldMix,
    } = prevProps;

    onUpdate({
      playlists: newMix.playlists,
    });

    // Update selected locations by grouping them by company
    if (JSON.stringify(oldMix.playlists) !== JSON.stringify(newMix.playlists)) {
      this.setState({
        playlists: sortBy(newMix.playlists, 'name'),
      });
    }
  }

  /**
   * When the unassign all button is clicked
   */
  onDeselectAll = () => {
    const {
      onDeselect,
      removeMixPlaylist,
    } = this.props;

    const {
      playlists,
    } = this.state;

    playlists.forEach((playlist) => {
      onDeselect(playlist._id);
      removeMixPlaylist(playlist._id);
    });
  };

  render() {
    const {
      customToolbar,
      allowChanges,
      onDeselect,
    } = this.props;

    const {
      playlists,
    } = this.state;

    const hasNoPlaylists = playlists.length <= 0;

    return (
      <div className="PlaylistsAssigned">
        <div className="title-container">
          <div className="title">
            Selected Playlists
          </div>

          {customToolbar}
        </div>

        <div className="playlists-content-root">
          {hasNoPlaylists ? (
            <VibeSplash
              title="No Playlists Selected"
              titleStyle={{
                color: color.primary,
                textTransform: 'uppercase',
              }}
              subtitle="Select playlists from the left panel."
            />
          ) : (
            <PerfectScrollbar>
              <div className="playlists-content">
                <div className="assigned-toolbar">
                  {allowChanges ? (
                    <SwapButton
                      className="unassign-btn"
                      text="Unassign All"
                      color={color.success}
                      arrowPlacement="left"
                      arrowPosition="left"
                      onClick={this.onDeselectAll}
                    />
                  ) : null}
                </div>

                <div className="MixPlaylists">
                  {playlists.map((playlist, index) => {
                    return (
                      <MixPlaylist
                        key={`${playlist._id}-${index}`}
                        playlist={playlist}
                        allowChanges={allowChanges}
                        onDeselect={onDeselect}
                      />
                    );
                  })}
                </div>
              </div>
            </PerfectScrollbar>
          )}
        </div>
      </div>
    );
  }
}

PlaylistsAssigned.propTypes = {
  /** Selected playlists */
  // selected: PropTypes.arrayOf(PropTypes.object),
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Allow changes to be made */
  allowChanges: PropTypes.bool,
  /** When a location is deselected */
  onDeselect: PropTypes.func,
  onUpdate: PropTypes.func,
};

PlaylistsAssigned.defaultProps = {
  // selected: [],
  customToolbar: null,
  allowChanges: false,
  onDeselect: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    newMix: state.newMix,
  };
}

const mapDispatchToProps = {
  addMixPlaylists,
  removeMixPlaylist,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistsAssigned);
