import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import {
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelTabs,
  VibeIcon,
  viArrowLeft,
  viChat,
  viTimeHistory,
  viClose,
  color,
} from 'vibeguide';
import HistoryScript from './History/HistoryScript';
import HistoryAudio from './History/HistoryAudio';
import './MessageHistory.scss';

class MessageHistory extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabs: [{
        name: 'Activity',
        selected: false,
        disabled: true,
      },
      {
        name: 'Script',
        selected: true,
      },
      {
        name: 'Audio',
        selected: false,
      }],
    };
  }

  /**
   * When a tab is selected
   */
  onSelectTab = (name) => {
    this.setState((state) => {
      return {
        tabs: state.tabs.map((tab) => {
          if (tab.name === name) {
            return {
              ...tab,
              selected: true,
            };
          }

          return {
            ...tab,
            selected: false,
          };
        }),
      };
    });
  };

  render() {
    const {
      user,
      message,
      onToggleDetails,
      onToggleChat,
      onClose,
    } = this.props;

    const {
      tabs,
    } = this.state;

    const selectedTab = find(tabs, { selected: true });
    const isContentCurator = user.can('message.content_curator');

    return (
      <SidePanelContainer className="MessageHistory">
        <SidePanelHeader
          icons={(
            <div>
              <VibeIcon
                id="chat"
                icon={viChat}
                color={message.changeRequested
                  ? color.orange
                  : color.manatee}
                hoverColor={color.obsidian}
                tooltip="Chat"
                size={24}
                onClick={onToggleChat}
              />

              {isContentCurator ? (
                <VibeIcon
                  id="history"
                  icon={viTimeHistory}
                  color={color.primary}
                  hoverColor={color.obsidian}
                  tooltip="History"
                  size={24}
                  onClick={onToggleDetails}
                />
              ) : null}

              <VibeIcon
                id="close-sidebar"
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
            </div>
          )}
        >
          <div className="flex-horizontal">
            <VibeIcon
              id="back"
              className="left-icon"
              icon={viArrowLeft}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onToggleDetails}
            />

            <div className="title">
              History
            </div>
          </div>
        </SidePanelHeader>

        <SidePanelTabs
          tabs={tabs}
          onSelectTab={this.onSelectTab}
        />

        <SidePanelContent>
          {selectedTab.name === 'Script' && message._id ? (
            <HistoryScript
              message={message}
            />
          ) : null}

          {selectedTab.name === 'Audio' && message._id ? (
            <HistoryAudio
              message={message}
            />
          ) : null}
        </SidePanelContent>
      </SidePanelContainer>
    );
  }
}

MessageHistory.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onToggleChat: PropTypes.func,
  onToggleDetails: PropTypes.func,
};

MessageHistory.defaultProps = {
  message: {},
  onClose: () => {},
  onToggleChat: () => {},
  onToggleDetails: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(MessageHistory);
