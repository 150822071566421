import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Menu,
  MenuItem,
} from '@mui/material';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viAdd from '../../../icons/viAdd';
import color from '../../../sass/color.scss';

class ManageColumns extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns,
    } = props;

    this.columnListRef = React.createRef();

    this.state = {
      menuOpen: false,
      // store the pending column changes to send when the menu closes
      pending: [],
      columns,
    };
  }

  onCloseMenu = () => {
    const {
      onToggleVisible,
    } = this.props;

    const {
      pending,
    } = this.state;

    if (pending.length > 0) {
      // tell the table to trigger these columns visibility
      onToggleVisible(pending);
    }

    this.setState({
      menuOpen: false,
      pending: [],
    });
  };

  toggleMenu = () => {
    this.setState((state) => {
      return {
        menuOpen: !state.menuOpen,
      };
    });
  };

  toggleColumnMenuItem = (e) => {
    const {
      target: {
        dataset: {
          columnname: columnName,
        },
      },
    } = e;

    // show or hide the column
    this.setState((state) => {
      return {
        pending: state.pending.includes(columnName)
          // the change was undone remove it from the pending changes
          ? state.pending.filter(name => name !== columnName)
          // add to the pending changes
          : [...state.pending, columnName],
        columns: state.columns.map((column) => {
          if (column.name === columnName) {
            return {
              ...column,
              hide: !column.hide,
            };
          }

          return column;
        }),
      };
    });
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      menuOpen,
      columns,
    } = this.state;

    const {
      columnListRef: {
        current: columnListRef,
      },
    } = this;

    // remove the select/more info columns from being in the list
    const showColumns = columns.filter(column => column.name !== '.' && column.name !== '...');

    return (
      <div
        ref={this.columnListRef}
        className={classNames('ManageColumns', className)}
      >
        <VibeIcon
          icon={viAdd}
          type="button"
          buttonProps={{
            size: 24,
            borderColor: color.primary,
          }}
          tooltip="Manage Columns"
          tooltipProps={{
            placement: 'left',
          }}
          color={color.primary}
          size={20}
          onClick={this.toggleMenu}
        />

        <Menu
          anchorEl={columnListRef}
          open={menuOpen}
          style={{
            zIndex: 1301,
          }}
          PaperProps={{
            style: {
              maxHeight: 295,
              marginTop: 8,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.onCloseMenu}
          disableAutoFocusItem
        >
          {showColumns.map((column) => {
            return (
              <MenuItem
                key={column.name}
                disabled={column.locked}
                data-columnname={column.name}
                onClick={this.toggleColumnMenuItem}
              >
                <VibeCheckbox
                  rootStyle={{
                    pointerEvents: 'none',
                  }}
                  checked={!column.hide}
                />

                {column.name}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
}

ManageColumns.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  onToggleVisible: PropTypes.func,
};

ManageColumns.defaultProps = {
  className: '',
  columns: [],
  onToggleVisible: () => {},
};

export default ManageColumns;
