import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  API,
  color,
  Field2 as Field,
  AdCategoryHelper,
  AdProviderHelper,
  ProgrammaticBuyerHelper,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function AdInformation({
  className,
  style,
  title,
  user,
  flightData,
  advertiserName,
  onChange,
  onChangeDropdown,
  onChangeAdvertiser,
  onChangeStartDate,
  onChangeEndDate,
}) {
  const canModifyFlight = user.can('flight.modify') && flightData.active;
  const unconfirmed = flightData.status === 'unconfirmed';

  return (
    <div
      className={classNames('AdInformation', className)}
      style={{
        position: 'relative',
        marginBottom: 16,
        padding: 16,
        backgroundColor: color.white,
        border: `1px solid ${color.whiteSmoke}`,
        fontSize: 12,
        userSelect: 'none',
        ...style,
      }}
    >
      {title && (
        <div
          className={classNames('ProfileContentTitle', className)}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 16,
            ...style,
          }}
        >
          <div
            style={{
              flexGrow: 1,
              fontSize: 14,
              fontWeight: 'bold',
              color: color.primary,
            }}
          >
            {title}
          </div>
        </div>
      )}
      <Grid spacing={2} container>
        <Grid xs={6} item>
          <Field
            type="dropdown"
            label="Delivery System"
            name="deliverySystem"
            value={flightData.deliverySystem}
            dropdownProps={{
              type: 'delivery system',
              attr: 'value',
              canCreate: user.can('flight_delivery_system.create'),
              successMessage: 'FLIGHTDELIVERYSYSTEM.CREATED',
              create: API.Flight.DeliverySystem.create,
              fetch: API.Flight.DeliverySystem.list,
            }}
            disabled={!unconfirmed || !canModifyFlight}
            onChange={onChangeDropdown}
            tabIndex={6}
            required
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="select"
            label="Ad Provider"
            name="adProvider"
            value={flightData.adProvider}
            options={[
              {
                label: '',
                value: '',
              },
              ...AdProviderHelper.list.map((adProvider) => {
                return {
                  label: adProvider.name,
                  value: adProvider.value,
                };
              }),
            ]}
            disabled={!unconfirmed || !canModifyFlight}
            onChange={onChange}
            tabIndex={7}
            required
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="dropdown"
            label="Advertiser"
            name="advertiser"
            value={advertiserName}
            tabIndex={8}
            dropdownProps={{
              type: 'advertiser',
              attr: 'name',
              fetch: API.Advertiser.list,
              create: API.Advertiser.create,
              canCreate: user.can('advertiser.create'),
              successMessage: 'ADVERTISER.CREATED',
            }}
            disabled={!unconfirmed || !canModifyFlight}
            onChange={onChangeAdvertiser}
            required
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="dropdown"
            label="Ad Category"
            name="category"
            value={flightData.category && flightData.category !== 'Not Selected'
              ? flightData.category
              : ''}
            dropdownProps={{
              type: 'category',
              attr: 'value',
              collection: AdCategoryHelper.list(),
            }}
            disabled={!unconfirmed || !canModifyFlight}
            onChange={onChangeDropdown}
            tabIndex={9}
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="text"
            label="Agency"
            name="agency"
            placeholder="Agency"
            value={flightData.agency}
            tabIndex={10}
            onChange={onChange}
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="select"
            label="Programmatic Buyer"
            name="programmaticBuyer"
            value={flightData.programmaticBuyer}
            options={ProgrammaticBuyerHelper.list({ includeEmpty: true })}
            tabIndex={11}
            onChange={onChange}
          />
        </Grid>
      </Grid>

      <Grid spacing={2} sx={{ pt: 2 }} container>
        <Grid xs={6} item>
          <Field
            type="datepicker"
            label="Start Date"
            placeholder="YYYY-MM-DD"
            value={flightData.startDate}
            style={{
              width: '100%',
            }}
            dateProps={{
              format: 'YYYY-MM-DD',
              tooltipDate: moment(flightData.startDate)
                .startOf('day')
                .format('MMMM DD, YYYY [at] hh:mm:ss A'),
            }}
            disabled={!unconfirmed || !canModifyFlight}
            onChange={onChangeStartDate}
            tabIndex={12}
            required
          />
        </Grid>
        <Grid xs={6} item>
          <Field
            type="datepicker"
            label="End Date"
            placeholder="YYYY-MM-DD"
            value={flightData.endDate}
            dateProps={{
              format: 'YYYY-MM-DD',
              tooltipDate: moment(flightData.endDate)
                .subtract(1, 'second')
                .format('MMMM DD, YYYY [at] hh:mm:ss A'),
            }}
            disabled={!unconfirmed || !canModifyFlight}
            onChange={onChangeEndDate}
            tabIndex={13}
            required
          />
        </Grid>
      </Grid>
    </div>
  );
}

AdInformation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
};

AdInformation.defaultProps = {
  className: '',
  style: {},
  title: '',
};

export default AdInformation;
