import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  find,
  sortBy,
} from 'lodash';
import classNames from 'classnames';
import {
  CircularProgress,
} from '@mui/material';
import API from '../../api';
import VibeCheckbox from '../VibeCheckbox/VibeCheckbox';
import VibeIcon from '../VibeIcon/VibeIcon';
import viChevronDown from '../../icons/viChevronDown';
import viChevronRight from '../../icons/viChevronRight';
import {
  setTargetTagActive,
  addTargetTags,
  updateTargetTag,
  selectTargetTag,
  deselectTargetTag,
} from '../../actions/TargetTag/TargetTagActions';
import color from '../../sass/color.scss';
import './TargetTagListItem.scss';

class TargetTagListItem extends PureComponent {
  constructor(props) {
    super(props);

    const {
      level,
    } = props;

    this.state = {
      isLoading: false,
      showChildren: false,
      paddingLeft: level === 1
        ? 30
        : (level * 25),
    };
  }

  onClick = () => {
    const {
      active,
      tag,
      allowSelect,
      setTargetTagActive,
    } = this.props;

    // tag is already active or the checkbox is enabled
    if (active || allowSelect) {
      return;
    }

    setTargetTagActive(tag);
  };

  /**
   * When the select box is changed
   */
  onChangeSelect = async () => {
    const {
      targetTag: {
        _id,
        hasChildren,
        selected,
        allChildrenSelected,
      },
      selectTargetTag,
      deselectTargetTag,
    } = this.props;

    if (!selected) {
      // Target tag is not selected
      selectTargetTag(_id);
    } else if (selected && hasChildren && !allChildrenSelected) {
      // Select the tag and all its children
      selectTargetTag(_id, true);
    } else {
      // Target tag is already selected
      deselectTargetTag(_id);
    }
  };

  getChildren = async (tagId, showLoading, overwriteData) => {
    const {
      addTargetTags,
      updateTargetTag,
    } = this.props;

    if (showLoading) {
      this.setState({
        isLoading: true,
      });
    }

    const children = await API.TargetTag.getChildren(tagId, { filters: { active: true } });

    const addChildren = overwriteData
      ? children.map((child) => {
        return {
          ...child,
          ...overwriteData,
        };
      })
      : children;

    addTargetTags(addChildren);
    updateTargetTag({
      _id: tagId,
      childrenLoaded: true,
    });

    if (showLoading) {
      this.setState({
        isLoading: false,
      });
    }

    return children;
  };

  toggleChildren = async (e) => {
    e.stopPropagation();

    const {
      targetTag: {
        _id,
        childrenLoaded,
      },
      hasChildren,
    } = this.props;

    const {
      showChildren: currentShowChildren,
    } = this.state;

    if (!hasChildren) {
      return;
    }

    const showChildren = !currentShowChildren;

    if (showChildren && !childrenLoaded) {
      await this.getChildren(_id, true);
    }

    this.setState({
      showChildren,
    });
  };

  render() {
    const {
      className,
      active,
      level,
      targetTag,
      allowSelect,
      tagName,
      hasChildren,
      children,
    } = this.props;

    const {
      isLoading,
      showChildren,
      paddingLeft,
    } = this.state;

    return (
      <div className={classNames('TargetTagListItem', className)}>
        <div
          className={classNames('tag-info', { active })}
          style={{
            paddingLeft,
          }}
          onClick={this.onClick}
          onDoubleClick={this.toggleChildren}
        >
          {allowSelect ? (
            <div>
              <VibeCheckbox
                size={16}
                color={color.success}
                indeterminate={
                  !targetTag.selected
                  && (!targetTag.allChildrenSelected || targetTag.someChildrenSelected)
                }
                checked={
                  targetTag.selected
                  || targetTag.someChildrenSelected
                }
                outline={
                  targetTag.hasChildren
                  && !targetTag.allChildrenSelected
                }
                onChange={this.onChangeSelect}
              />
            </div>
          ) : null}

          {hasChildren ? (
            <VibeIcon
              className="children-icon"
              icon={showChildren ? viChevronDown : viChevronRight}
              color={active ? color.primary : color.obsidian}
              size={24}
              onClick={this.toggleChildren}
            />
          ) : (
            <div className="no-children" />
          )}

          {isLoading ? (
            <div
              className="loading"
              style={{
                left: paddingLeft - 16,
              }}
            >
              <CircularProgress
                className="loading-icon"
                color="inherit"
                size={16}
              />
            </div>
          ) : null}

          <div className="tag-name">
            {tagName}
          </div>
        </div>

        {showChildren && children.map((tag) => {
          return (
            <ConnectedTargetTagListItem
              key={tag._id}
              level={level + 1}
              tag={tag}
              allowSelect={allowSelect}
            />
          );
        })}
      </div>
    );
  }
}

TargetTagListItem.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Tag Level */
  level: PropTypes.number.isRequired,
  /** Tag Data */
  tag: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    qualifiedName: PropTypes.string,
    parentId: PropTypes.string,
    active: PropTypes.bool,
    hasChildren: PropTypes.bool,
  }).isRequired,
  /** Allow selection of target tags */
  allowSelect: PropTypes.bool,
};

TargetTagListItem.defaultProps = {
  className: '',
  allowSelect: false,
};

function mapStateToProps(state, props) {
  const targetTag = find(state.targetTag.tags, { _id: props.tag._id }) || {};
  const hasChildTags = find(state.targetTag.tags, { parentId: props.tag._id }) !== undefined;
  // Child Tags
  const children = state.targetTag.tags.filter(childTag => childTag.parentId === props.tag._id);

  return {
    targetTag,
    active: state.targetTag.activeTag._id === props.tag._id && !props.allowSelect,
    tagName: targetTag.name || props.tag.name,
    hasChildren: props.tag.hasChildren || hasChildTags,
    children: sortBy(children, 'name'),
  };
}

const mapDispatchToProps = {
  setTargetTagActive,
  addTargetTags,
  updateTargetTag,
  selectTargetTag,
  deselectTargetTag,
};

const ConnectedTargetTagListItem = connect(mapStateToProps, mapDispatchToProps)(TargetTagListItem);
export default ConnectedTargetTagListItem;
