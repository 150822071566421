import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TimeUtil,
} from 'vibeguide';
import './EventItem.scss';

function EventItem({
  event,
  activeDisplay,
}) {
  const {
    color,
    title,
    startTime,
    stopTime,
  } = event;

  const [minHeight, setMinHeight] = useState('');

  const itemStart = TimeUtil.convertToTwelveHour({
    time: startTime,
    short: true,
  });

  const itemStop = TimeUtil.convertToTwelveHour({
    time: stopTime,
    short: true,
  });

  useEffect(() => {
    // show/hide and style content based on height of event in the baseline
    const timeSpan = parseFloat(stopTime.replace(':', ''), 10) - parseFloat(startTime.replace(':', ''), 10);
    if (activeDisplay === '24h') {
      if (timeSpan <= 300) {
        if (timeSpan >= 200) {
          setMinHeight('min-height');
        } else if (timeSpan >= 130) {
          setMinHeight('min-height-full');
        } else if (timeSpan < 130) {
          setMinHeight('min-height-tiny');
        }
      } else {
        setMinHeight('');
      }
    }

    if (activeDisplay === '1h') {
      if (timeSpan <= 100 && timeSpan > 30) {
        setMinHeight('min-height');
      } else if (timeSpan <= 30) {
        setMinHeight('min-height-tiny');
      } else {
        setMinHeight('');
      }
    }

    if (activeDisplay === '15m' || activeDisplay === '1m') {
      setMinHeight('');
    }
  }, [startTime, stopTime, activeDisplay]);

  /**
   * Switch the content based on the event height
   */
  const switchContent = () => {
    switch (minHeight) {
      case '':
        return (
          <>
            <div className="time">
              {itemStart} - {itemStop}
            </div>
            <div className="title">
              {title}
            </div>
          </>
        );
      case 'min-height':
        return (
          <>
            <div className="time">
              {itemStart} - {itemStop}
            </div>
            <div className="title">
              {title}
            </div>
          </>
        );
      case 'min-height-full':
        return (
          <div className="title">
            {title}
          </div>
        );
      case 'min-height-tiny':
        return (
          <div className="title-ellipsis" />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`EventItem ${minHeight}`}
      style={{
        border: `1px solid ${color}`,
        borderRadius: 4,
        boxSizing: 'border-box',
      }}
    >
      {switchContent()}
    </div>
  );
}

EventItem.propTypes = {
  event: PropTypes.oneOfType([
    PropTypes.object,
  ]),
};

EventItem.defaultProps = {
  event: {},
};

export default EventItem;
