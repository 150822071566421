import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  get,
  isArray,
} from 'lodash';
import {
  convertSeconds,
} from '../../utils/TimeUtil';
import TableSongs from '../VibeTable/Tables/TableSongs';
import API from '../../api';
import Tag from '../Tags/Tag';
import VibeIcon from '../VibeIcon/VibeIcon';
import viTime from '../../icons/viTime';
import companyImage from '../../assets/default_company.png';
import color from '../../sass/color.scss';
import './SongsAssigned.scss';

class SongsAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = this.props;

    this.state = {
      filteredSongs: selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    const {
      selected: prevSelected,
    } = prevProps;

    // Update selected locations by grouping them by company
    if (JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
      this.setState({
        filteredSongs: selected,
      });
    }
  }

  onExport = async () => {
    const options = {
      headers: {
        Accept: 'text/csv',
      },
    };

    const {
      playlistId,
    } = this.props;

    const response = await API.Playlist.getById(playlistId, options);

    const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `playlist-${timestamp}.csv`);
  };

  onAdd = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const songs = [
        ...state.filteredSongs,
        item,
      ];

      onChange(songs);

      return {
        filteredSongs: songs,
      };
    });
  };

  onRemove = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const itemIds = items.map(item => item._id);

    this.setState((state) => {
      const songs = state.filteredSongs.filter(song => !itemIds.includes(song._id));
      onChange(songs);

      return {
        filteredSongs: songs,
      };
    });
  };

  render() {
    const {
      user,
      averageDurationSeconds,
      allowChanges,
      customToolbar,
      onChange,
    } = this.props;

    const {
      filteredSongs,
    } = this.state;

    const ratingCounts = {
      g: 0,
      radioEdit: 0,
      explicit: 0,
      unknown: 0,
    };
    let totalDuration = 0;

    filteredSongs.forEach((song) => {
      // Increment total duration
      totalDuration += song.durationSeconds;
      // Song rating
      const rating = get(song, 'rating', '').toLowerCase();

      switch (rating) {
        case 'g':
          ratingCounts.g += 1;
          break;

        case 'radio-edit':
          ratingCounts.radioEdit += 1;
          break;

        case 'explicit':
          ratingCounts.explicit += 1;
          break;

        default:
          ratingCounts.unknown += 1;
          break;
      }
    });

    const formatDuration = convertSeconds(totalDuration);
    const averageDuration = convertSeconds(averageDurationSeconds);

    const columns = [
      'Song Title',
      'Artist',
      'Album',
      'Genre',
      'ISRC',
      'ISWC',
      'Record Label',
      'Source',
      'Release Year',
      'Duration',
      'Tempo',
      'Ranking',
      'Rating',
      'Reason',
      'Playlists',
      'PRO',
      'Date Added',
      'Tags',
      '...',
    ];

    if (allowChanges) {
      columns.unshift('Add/Remove');
    }

    return (
      <div className="SongsAssigned">
        <div className="title-container">
          <div className="title">
            Assigned Songs

            <div className="details-toolbar">
              <div className="playlist-duration">
                <VibeIcon
                  className="duration-icon"
                  icon={viTime}
                  color={color.manatee}
                  size={16}
                />

                <span>
                  {formatDuration}
                </span>

                {averageDurationSeconds > 0 ? (
                  <Tag
                    tag={{
                      name: `Avg Song: ${averageDuration}`,
                    }}
                    style={{
                      background: color.secondary16,
                      color: color.manatee,
                      marginLeft: 8,
                    }}
                  />
                ) : null}
              </div>

              <div className="rating-counts">
                <Tag
                  tag={{
                    name: `${ratingCounts.g} G`,
                  }}
                  style={{
                    background: color.success16,
                    color: color.success,
                    marginRight: 8,
                  }}
                />

                <Tag
                  tag={{
                    name: `${ratingCounts.radioEdit} Radio Edit`,
                  }}
                  style={{
                    background: color.primary16,
                    color: color.primary,
                    marginRight: 8,
                  }}
                />

                <Tag
                  tag={{
                    name: `${ratingCounts.explicit} Explicit`,
                  }}
                  style={{
                    background: color.error16,
                    color: color.error,
                  }}
                />

                {ratingCounts.unknown > 0 ? (
                  <Tag
                    tag={{
                      name: `${ratingCounts.unknown} Unknown`,
                    }}
                    style={{
                      background: color.secondary16,
                      color: color.secondary,
                      marginLeft: 8,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>

          {customToolbar}
        </div>

        <div className="songs-container">
          <TableSongs
            columns={columns}
            collection={filteredSongs}
            defaultSortBy={{
              label: 'Song Title',
              attr: 'name',
              direction: 'asc',
            }}
            paginator
            paginatorProps={{
              label: 'Songs',
              urlPaging: false,
              urlFilters: false,
            }}
            emptyProps={{
              image: companyImage,
              title: 'Add Songs to Your Playlist',
              description: 'Choose songs to add to this playlist. Any duplicates will be removed.',
            }}
            menuItems={[
              { name: 'Archive', userCan: 'music.delete' },
            ]}
            csv={user.sysAdmin}
            csvProps={{
              onExport: this.onExport,
            }}
            assigned
            highlight={filteredSongs}
            onAdd={this.onAdd}
            onRemove={this.onRemove}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}

SongsAssigned.propTypes = {
  /** Selected songs */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Playlist ID for exporting */
  playlistId: PropTypes.string,
  /** Average seconds for all songs in the table */
  averageDurationSeconds: PropTypes.number,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** When the songs are changed */
  onChange: PropTypes.func,
};

SongsAssigned.defaultProps = {
  selected: [],
  playlistId: '',
  averageDurationSeconds: 0,
  allowChanges: false,
  customToolbar: null,
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(SongsAssigned);
