/* eslint-disable radix */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RRule } from 'rrule';
import moment from 'moment';
import { get } from 'lodash';
import API from '../../api';
import TagList from '../Tags/TagList';
import VibeModal from '../VibeModal/VibeModal';
import VibeCard from '../VibeCard/VibeCard';
import color from '../../sass/color.scss';
import './EventCard.scss';

class EventCard extends Component {
  constructor(props) {
    super(props);

    const {
      event,
    } = props;

    const rule = RRule.fromString(event.schedule);

    const dtstart = get(rule, 'origOptions.dtstart', null);

    const stDt = new moment(dtstart).tz(event.scheduleOriginTimeZone);
    const startDate = stDt.toDate();

    //* Get most upcomming occurance from rule */
    const nextOccur = rule.after(new Date());
    let month = '';
    let date = '';
    let scheduled = '';

    if (nextOccur) {
      let nextOccurMoment;

      if (event.scheduleOriginTimeZone) {
        // convert the next occurance to the events timezone
        // Get the timezone offset of the event
        const offset = new moment().tz(event.scheduleOriginTimeZone).utcOffset();
        // Get the local timezone offset
        const localOffset = new moment().utcOffset();
        // Combine the offsets to get the hour to set the date to
        const combinedOffset = offset - localOffset;

        const dtstartUTC = new moment(startDate).utc().format();
        nextOccurMoment = new moment(dtstartUTC).add(combinedOffset, 'minutes');

        // nextOccurMoment.tz(event.scheduleOriginTimeZone);
      } else {
        // When the event runs next
        nextOccurMoment = new moment(nextOccur);
      }

      month = nextOccurMoment.format('MMM');
      date = nextOccurMoment.format('DD');
      const startTime = nextOccurMoment.format('h:mm a');
      const duration = new moment.duration(event.duration);
      const endTime = nextOccurMoment
        .clone()
        .add(duration)
        .format('h:mm a');
      // use a static date to prevent DST issues on events that start on a DST switch
      // const endTime = new moment('2021-01-01').add(duration).format('h:mm a');

      scheduled = `${startTime} - ${endTime}`;
    }

    this.state = {
      showConfirmArchive: false,
      scheduleInfo: {
        month,
        date,
        scheduled,
      },
    };
  }

  /**
   * When the card is clicked
   */
  onClick = () => {
    const {
      event,
      onClick,
    } = this.props;

    onClick(event);
  };

  /**
   * When a Menu Item is Selected
   */
  onSelectMenuItem = async (name) => {
    const {
      event: {
        _id,
      },
      onArchive,
    } = this.props;

    if (name === 'archive') {
      this.setState({
        showConfirmArchive: true,
      });
    } else if (name === 'unarchive') {
      const response = await API.Event.reactivate({
        _id,
      });
      const isSuccess = get(response, '[0].documentId', null);

      if (isSuccess) {
        onArchive(_id);
      }
    }
  };

  /**
   * Confirm Archive Message
   */
  onConfirmArchive = async (e) => {
    e.stopPropagation();

    const {
      event: {
        _id,
      },
      onArchive,
    } = this.props;

    const response = await API.Event.deactivate(_id);
    const isSuccess = get(response, '[0].documentId', null);

    if (isSuccess) {
      onArchive(_id);
    }

    this.setState({
      showConfirmArchive: false,
    });
  };

  /**
   * Cancel Archive Message
   */
  onCancelArchive = () => {
    this.setState({
      showConfirmArchive: false,
    });
  };

  render() {
    const {
      user,
      event,
      link,
      showMenu,
      allowClick,
    } = this.props;

    const {
      showConfirmArchive,
      scheduleInfo: {
        month,
        date,
        scheduled,
      },
    } = this.state;

    let eventColor = event.color;
    if (!eventColor) {
      eventColor = color.primary;
    }

    // 29 == 16% opacity
    const tagBGColor = `${eventColor}29`;

    const { tags } = event;

    const menuItems = [
      {
        name: 'Edit',
        userCan: 'event.modify',
        disabled: !user.hasAccessToCompany(event.companyId)
          || !user.exceedsLocations(event.locations)
          || !event.active,
        link: `/programs/events/${event._id}/edit`,
      },
      {
        name: 'Duplicate',
        userCan: 'event.create',
        disabled: !user.hasAccessToCompany(event.companyId)
          || !user.exceedsLocations(event.locations)
          || !event.active,
        link: `/programs/events/${event._id}/duplicate`,
      },
    ];

    const cardHtml = (
      <div className="EventCard">
        <VibeCard
          className="card-container"
          width={350}
          height={164}
          link={link}
          menuItems={showMenu
            ? menuItems
            : []}
          style={{
            borderBottom: `6px solid ${eventColor}`,
          }}
          allowClick={allowClick}
          onSelectMenuItem={this.onSelectMenuItem}
          onClick={this.onClick}
        >
          <div className="event-card-header">
            <div
              className="next-date"
              style={{
                color: eventColor,
              }}
            >
              {month} {date}
            </div>
            <div className="event-header">
              <div className="event-title-header">
                <div className="event-card-title">
                  {event.name}
                </div>
              </div>

              <div className="event-location-header">
                <div className="event-updated">
                  {scheduled}
                </div>
              </div>
            </div>
          </div>

          <div className="description">
            {event.description || ''}
          </div>

          <div className="tags-container">
            <TagList
              tags={tags}
              style={{
                margin: '0 16px',
              }}
              textColor={eventColor}
              tagColor={tagBGColor}
            />
          </div>
        </VibeCard>

        <VibeModal
          show={showConfirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${event.name}?`}
          confirmProps={{
            text: 'Archive',
            color: color.error,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );

    return cardHtml;
  }
}

EventCard.propTypes = {
  /** Object that describes the message that's being displayed */
  event: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    name: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    description: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      locationId: PropTypes.string,
    })),
    schedule: PropTypes.string,
    scheduleOriginTimeZone: PropTypes.string,
    color: PropTypes.string,
    duration: PropTypes.string,
    locationCount: PropTypes.number,
  }).isRequired,
  link: PropTypes.string,
  showMenu: PropTypes.bool,
  allowClick: PropTypes.bool,
  /** When a card is clicked (if allowed) */
  onClick: PropTypes.func,
  /** When a card is archived */
  onArchive: PropTypes.func,
};

EventCard.defaultProps = {
  link: '',
  showMenu: false,
  allowClick: false,
  onClick: () => {},
  onArchive: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(EventCard);
