import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';
import classNames from 'classnames';
import API from '../../../api';
import Locations from '../../Locations/Locations';
import LocationSelection from '../../Locations/LocationSelection';
import {
  handleLocationsData,
} from '../../../helpers/ExpandedLocations';
import TargetTag from '../TargetTag';
import VibeButton from '../../VibeButton/VibeButton';
import VibeDialog from '../../VibeDialog/VibeDialog';
import VibeModal from '../../VibeModal/VibeModal';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viStore from '../../../icons/viStore';
import viShow from '../../../icons/viShow';
import viAdd from '../../../icons/viAdd';
import viEdit from '../../../icons/viEdit';
import viTrash from '../../../icons/viTrash';
import viClose from '../../../icons/viClose';
import PanelDetailsEdit from './PanelDetailsEdit';
import {
  removeTargetTag,
} from '../../../actions/TargetTag/TargetTagActions';
import color from '../../../sass/color.scss';
import './PanelDetails.scss';

class PanelDetails extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      isEdit,
      isLocation,
    } = props;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const canView = user.can('targeting_tag.view');
    const canCreate = user.can('targeting_tag.create');
    const canModify = user.can('targeting_tag.modify');
    const canRemove = user.can('targeting_tag.delete');

    this.state = {
      companyId: adminCompanyId,
      locations: [],
      locationsData: {
        companies: [],
        locations: [],
      },
      showCopy: canModify,
      showCreate: canCreate && !isLocation,
      showEdit: canModify && !isLocation,
      showViewLocations: canView && !isLocation,
      showRemove: canRemove && !isLocation,
      showRemoveFromLocation: isLocation,
      showConfirmArchive: false,
      showLocations: false,
      showCopyToLocations: false,
      tempQualifiedName: null,
      isCreateChild: false,
      isEdit,
    };
  }

  /**
   * When the Qualified Name Changes
   */
  onQualifiedNameChange = (qualifiedName) => {
    this.setState({
      tempQualifiedName: qualifiedName,
    });
  };

  /**
   * Confirm Archive
   */
  onConfirmArchive = async (e) => {
    e.stopPropagation();

    const {
      targetTag: {
        _id,
      },
      removeTargetTag,
    } = this.props;

    const response = await API.TargetTag.deactivate(_id);
    const isSuccess = get(response, '[0].documentId', null);

    if (isSuccess) {
      removeTargetTag(_id);

      this.setState({
        showConfirmArchive: false,
      });
    }
  };

  /**
   * Cancel Archive
   */
  onCancelArchive = () => {
    this.setState({
      showConfirmArchive: false,
    });
  };

  onConfirmCopyToLocations = async () => {
    const {
      targetTag: {
        _id,
      },
    } = this.props;

    const {
      locations,
    } = this.state;

    const bulkUpdateResponse = await API.Location.bulkCopyTargetTags({
      mergeMode: 'merge',
      locations,
      targetingTags: [_id],
    });

    bulkUpdateResponse.forEach((response) => {
      if (response.type !== 'LOCATION.TARGETING_TAGS_UPDATED') {
        console.error('Error updating location targeting tags', response);
      }
    });

    this.onCloseLocations();
  };

  /**
   * Close View Locations
   */
  onCloseLocations = () => {
    this.setState({
      showLocations: false,
      showCopyToLocations: false,
      locations: [],
      locationsData: {
        companies: [],
        locations: [],
      },
    });
  };

  onSelect = (location) => {
    this.setState((state) => {
      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      let {
        locations: stateLocations,
      } = state;

      if (location.locationId === '*') {
        // Add an entire company
        // Remove all individual company locations
        stateLocations = stateLocations.filter(loc => loc.companyId !== location.companyId);
      }

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'add',
          data: location,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: [...stateLocations, location],
      };
    });
  };

  onDeselect = (locationData) => {
    this.setState((state) => {
      const locationItem = find(state.locations, {
        companyId: locationData.companyId,
        locationId: locationData.locationId,
      });

      const {
        locationsData: {
          companies: currCompanies,
          locations: currLocations,
        },
      } = state;

      return {
        ...state,
        locationsData: handleLocationsData({
          type: 'remove',
          data: locationData,
          companies: currCompanies,
          locations: currLocations,
        }),
        locations: locationData.locationId === '*'
          // Remove all locations for this company
          ? state.locations.filter(location => locationData.companyId !== location.companyId)
          // Remove single location
          : state.locations.filter(location => location !== locationItem),
      };
    });
  };

  onDeselectAll = () => {
    this.setState({
      locationsData: {
        companies: [],
        locations: [],
      },
      locations: [],
    });
  };

  /**
   * When the tag is removed from a location
   */
  onRemoveFromLocation = () => {
    const {
      targetTag,
      onRemoveFromLocation,
    } = this.props;

    onRemoveFromLocation(targetTag);
  };

  /**
   * Toggle Edit Mode
   */
  toggleEdit = () => {
    this.setState((state) => {
      return {
        isEdit: !state.isEdit,
        isCreateChild: false,
        tempQualifiedName: null,
      };
    });
  };

  /**
   * Toggle Create Child (Edit) Mode
   */
  toggleCreateChild = () => {
    this.setState((state) => {
      return {
        isEdit: !state.isEdit,
        isCreateChild: true,
        tempQualifiedName: null,
      };
    });
  };

  /**
   * View Tag Locations
   */
  viewLocations = async () => {
    this.setState({
      showLocations: true,
    });
  };

  /**
   * Copy Tag to Locations
   */
  copyToLocations = async () => {
    this.setState({
      showCopyToLocations: true,
    });
  };

  /**
   * Show Archive Tag Confirmation
   */
  archiveTag = () => {
    this.setState({
      showConfirmArchive: true,
    });
  };

  render() {
    const {
      className,
      targetTag,
      user,
      isLocation,
      isEdit: propsIsEdit,
    } = this.props;

    const {
      companyId,
      locationsData,
      showCopy,
      showCreate,
      showEdit,
      showViewLocations,
      showRemove,
      showRemoveFromLocation,
      showConfirmArchive,
      showLocations,
      showCopyToLocations,
      isCreateChild,
      isEdit,
      tempQualifiedName,
      locations,
    } = this.state;

    const qualifiedName = tempQualifiedName || targetTag.qualifiedName;
    const locationFunc = user.getLocationFunc(user.companyId) === 'user'
      ? API.User.getLocations
      : API.Company.getLocations;

    return (
      <div className={classNames('PanelDetails', className)}>
        <div className="tag-section">
          <TargetTag
            qualifiedName={qualifiedName}
            allowCopy
          />
        </div>

        {isEdit || propsIsEdit ? (
          <PanelDetailsEdit
            isCreateChild={isCreateChild}
            isLocation={isLocation}
            onQualifiedNameChange={this.onQualifiedNameChange}
            onClose={this.toggleEdit}
          />
        ) : (
          <div className="tag-section">
            {showCreate ? (
              <div
                className="target-tag-action action-create"
                onClick={this.toggleCreateChild}
              >
                <VibeIcon
                  icon={viAdd}
                  color={color.success}
                  size={16}
                />

                <div className="text">
                  Create Child Tag
                </div>
              </div>
            ) : null}

            {showEdit ? (
              <div
                className="target-tag-action action-edit"
                onClick={this.toggleEdit}
              >
                <VibeIcon
                  icon={viEdit}
                  color={color.primary}
                  size={16}
                />

                <div className="text">
                  Edit Name
                </div>
              </div>
            ) : null}

            {showCopy ? (
              <div
                className="target-tag-action action-copy"
                onClick={this.copyToLocations}
              >
                <VibeIcon
                  icon={viStore}
                  color={color.primary}
                  size={16}
                />

                <div className="text">
                  Copy to Locations
                </div>
              </div>
            ) : null}

            {showViewLocations ? (
              <div
                className="target-tag-action action-view"
                onClick={this.viewLocations}
              >
                <VibeIcon
                  icon={viShow}
                  color={color.primary}
                  size={16}
                />

                <div className="text">
                  View Locations
                </div>
              </div>
            ) : null}

            {showRemove ? (
              <div
                className="target-tag-action action-remove"
                onClick={this.archiveTag}
              >
                <VibeIcon
                  icon={viTrash}
                  color={color.error}
                  size={16}
                />

                <div className="text">
                  Archive Tag
                </div>
              </div>
            ) : null}

            {showRemoveFromLocation ? (
              <div
                className="target-tag-action action-remove"
                onClick={this.onRemoveFromLocation}
              >
                <VibeIcon
                  icon={viClose}
                  color={color.error}
                  size={16}
                />

                <div className="text">
                  Remove Tag
                </div>
              </div>
            ) : null}
          </div>
        )}

        <VibeDialog
          title={`View Locations: ${targetTag.name}`}
          open={showLocations}
          onClose={this.onCloseLocations}
          fullScreen
        >
          <Locations
            className="locations-container"
            filterParamFirst={targetTag._id}
            filterLocations={API.TargetTag.getLocations}
            showPaginator
          />
        </VibeDialog>

        <VibeDialog
          title="Copy to Locations"
          open={showCopyToLocations}
          onClose={this.onCloseLocations}
          fullScreen
        >
          <div className="copy-locations-header">
            <div className="copy-locations-header-content">
              <div className="header-text-container">
                <p className="header-title">Choose Locations</p>
                <p className="header-sub-title">
                  Choose locations to copy {targetTag.name || targetTag.qualifiedName}:
                </p>
              </div>

              <div className="btn-container">
                <VibeButton
                  text="Confirm Locations"
                  btnColor="green"
                  textColor="white"
                  disabled={locations.length <= 0}
                  onClick={this.onConfirmCopyToLocations}
                />
              </div>
            </div>
          </div>

          <LocationSelection
            companyId={companyId}
            selectedLocations={locations}
            locationsData={locationsData}
            filterLocations={locationFunc}
            filterCompanies={API.User.getCompanies}
            onSelect={this.onSelect}
            onDeselect={this.onDeselect}
            onDeselectAll={this.onDeselectAll}
            allowChanges
            allowPaginator
            allowCompanies
            allowAssignCompany
          />
        </VibeDialog>

        <VibeModal
          show={showConfirmArchive}
          type="confirm"
          title="Archive"
          text={(
            <div>
              <div>
                Are you sure you want to archive
                <br />
                <strong>{targetTag.name}</strong> and all its children?
                <br />
                <br />
                <div
                  style={{
                    fontSize: 12,
                  }}
                >
                  {qualifiedName}
                </div>
              </div>
            </div>
          )}
          confirmProps={{
            text: 'Archive',
            color: color.error,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );
  }
}

PanelDetails.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Target Tag */
  targetTag: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    qualifiedName: PropTypes.string,
  }),
  /** Show Edit Panel */
  isEdit: PropTypes.bool,
  /** Is Location Details */
  isLocation: PropTypes.bool,
  /** When tag is removed from a location */
  onRemoveFromLocation: PropTypes.func,
};

PanelDetails.defaultProps = {
  className: '',
  targetTag: {
    _id: 'new',
    name: 'Parent Name',
    qualifiedName: 'Parent-Name',
  },
  isEdit: false,
  isLocation: false,
  onRemoveFromLocation: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    // targetTag: state.targetTag.activeTag,
    targetTag: find(state.targetTag.tags, { _id: state.targetTag.activeTag._id }),
  };
}

const mapDispatchToProps = {
  removeTargetTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelDetails);
