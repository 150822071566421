import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import VibeToggle from '../VibeToggle/VibeToggle';
import EmptyState from '../EmptyState/EmptyState';
import TableCompanies from '../VibeTable/Tables/TableCompanies';
import defaultCompanyImage from '../../assets/default_company.png';
import color from '../../sass/color.scss';
import './CompaniesAssigned.scss';

class CompaniesAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = props;

    this.state = {
      filteredItems: selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    const {
      selected: prevSelected,
    } = prevProps;

    if (JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
      this.setState({
        filteredItems: selected,
      });
    }
  }

  onAdd = (item) => {
    const {
      onChange,
    } = this.props;

    this.setState((state) => {
      const items = [
        ...state.filteredItems,
        item,
      ];

      onChange(items);

      return {
        filteredItems: items,
      };
    });
  };

  onRemove = (items) => {
    const {
      onChange,
    } = this.props;

    if (!isArray(items)) {
      items = [items];
    }

    const itemIds = items.map(item => item._id);

    this.setState((state) => {
      const items = state.filteredItems.filter(item => !itemIds.includes(item._id));
      onChange(items);

      return {
        filteredItems: items,
      };
    });
  };

  /**
   * When ACAF is toggled
   */
  onChangeAssignAllCurrentAndFuture = () => {
    const {
      selected,
      onChange,
    } = this.props;

    const isACAF = selected.length === 1 && selected[0]._id === '*';

    if (isACAF) {
      // reset selected companies
      onChange([]);
    } else {
      // change to all current and future
      onChange([
        {
          _id: '*',
        },
      ]);
    }
  };

  render() {
    const {
      user,
      allowCustomOrder,
      allowChanges,
      allowACAF,
      selected,
      customToolbar,
      onChange,
    } = this.props;

    const {
      filteredItems,
    } = this.state;

    const columns = [
      'Company',
      'Category',
      'Address 1',
      'Address 2',
      'Country',
      'City',
      'State/Province',
      'Postal Code',
      'Tags',
      '...',
    ];

    if (allowChanges) {
      columns.unshift('Add/Remove');
    }

    const isACAF = selected.length === 1 && selected[0]._id === '*';

    return (
      <div className="CompaniesAssigned">
        <div className="title-container">
          <div className="title">
            Assigned Companies

            {allowChanges && allowACAF && find(user.locationsAllowed, { companyId: '*' }) !== undefined && (
              <div className="assign-all-container">
                <VibeToggle
                  label="Assign all current and future companies"
                  labelStyle={{
                    color: color.success,
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                  height={16}
                  checked={isACAF}
                  color={color.success}
                  onChange={this.onChangeAssignAllCurrentAndFuture}
                />
              </div>
            )}
          </div>

          {customToolbar}
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {isACAF ? (
            <EmptyState
              image={defaultCompanyImage}
              title="All Current and Future Companies Assigned"
              description="This object has been assigned to all current and future companies."
            />
          ) : (
            <TableCompanies
              columns={columns}
              collection={filteredItems}
              defaultSortBy={{
                label: 'Company',
                attr: 'name',
                direction: 'asc',
              }}
              paginator
              paginatorProps={{
                label: 'Companies',
                urlPaging: false,
                urlFilters: false,
              }}
              emptyProps={{
                image: defaultCompanyImage,
                title: 'Assign Companies to this Partner',
                description: 'Choose companies to add to this partner.',
              }}
              menuItems={[
                { name: 'Archive', userCan: 'company.delete' },
              ]}
              draggable={allowCustomOrder}
              droppable={allowCustomOrder}
              assigned
              highlight={filteredItems}
              onAdd={this.onAdd}
              onRemove={this.onRemove}
              onChange={onChange}
            />
          )}
        </div>
      </div>
    );
  }
}

CompaniesAssigned.propTypes = {
  /** Allow custom order of objects in the table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the selection */
  allowChanges: PropTypes.bool,
  /** Allow ACAF selection */
  allowACAF: PropTypes.bool,
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  selected: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

CompaniesAssigned.defaultProps = {
  allowCustomOrder: false,
  allowChanges: false,
  allowACAF: false,
  customToolbar: null,
  selected: [],
  onChange: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(CompaniesAssigned);
