import {
  get,
  find,
  clone,
  sortBy,
  uniqueId,
} from 'lodash';
import { RRule } from 'rrule';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import {
  getLocationsData,
} from '../helpers/ExpandedLocations';
import {
  rrule,
} from '../helpers/DateTime';
import {
  resolveLocalTimeZone,
  getDuration,
  getMinutesBetween,
  getOffset,
} from '../utils/TimeUtil';
import color from '../sass/color.scss';

class Event {
  constructor(data = {}) {
    const currDate = new moment();
    const currHour = currDate.hour();

    // Default date (based on start time)
    const defaultDate = currHour + 1 <= 21
      ? currDate.toDate()
      : currDate.clone().add(1, 'day').toDate();

    // const defaultStartHour = currHour + 1 <= 21
    //   ? currHour + 1
    //   : 9;

    // const defaultStopHour = currHour + 1 <= 21
    //   ? currHour + 3
    //   : 11;

    // // Default start date
    // const defaultStartDate = new moment(defaultDate)
    //   .hour(defaultStartHour)
    //   .minute(0)
    //   .toDate();

    // // Default start time if not set
    // const defaultStartTime = `${defaultStartHour}:00`;

    // // Default stop time if not set
    // const defaultStopTime = `${defaultStopHour}:00`;

    // // Default duration for an event
    // const defaultDuration = '02:00';

    this._id = data._id || 'new-event';
    this.active = data.active || false;
    this.timeSlotId = data.timeSlotId || null;
    this.locationsData = data.locationsData || {
      companies: [],
      locations: [],
    };
    this.locations = data.locations || [];
    this.allLocations = data.allLocations || false;
    this.color = data.color || color.primary;
    this.title = data.title || data.name || '';
    this.companyId = data.companyId || '';
    this.companyName = data.companyName || '';
    this.description = data.description || '';
    this.date = data.date || defaultDate;
    this.day = new moment(this.date).format('ddd');
    this.startDate = data.startDate || new moment()
      .startOf('day')
      .toDate();
    this.stopDate = data.stopDate || null;
    this.duration = data.duration || '24:00';
    this.startTime = data.startTime || null;
    this.stopTime = data.stopTime || null;
    this.repeat = data.repeat || false;
    this.repeatRule = data.repeatRule || null;
    this.repeatInterval = data.repeatInterval || 1;
    this.repeatFrequency = data.repeatFrequency || 'week';
    this.repeatDays = data.repeatDays || [];
    this.playlists = data.playlists || [];
    this.mixes = data.mixes || [];
    this.messageBlocks = data.messageBlocks || [];
    this.interruptions = data.interruptions || [];
    this.schedule = data.schedule || '';
    this.scheduleOriginTimeZone = data.scheduleOriginTimeZone || resolveLocalTimeZone();
    this.playBaselineMusic = data.playBaselineMusic || false;
    this.playBaselineMessageBlocks = data.playBaselineMessageBlocks || data.playBaselineMessageList || false;
    this.playBaselineInterrupts = data.playBaselineInterrupts || false;
    this.volume = data.volume || {
      eq: [0, 0, 0, 0, 0],
      master: 0,
      message: 0,
      music: 0,
    };
    this.hasError = data.hasError || false;

    this.tags = (data.tags || []).map((tag) => {
      return {
        ...tag,
        initialTag: true,
      };
    });

    // Items in the event from the API
    const musicParts = data.musicParts || [];
    const messageParts = data.messageParts || [];
    const interrupts = data.interrupts || [];

    // Get event repeat data
    this.getRepeatData();

    // start date based on the date the event begins
    const tzStartDate = new moment(this.startDate)
      .tz(this.scheduleOriginTimeZone);
    // start date based on this event instance
    // const startDate = new moment(this.date)
    //   .tz(this.scheduleOriginTimeZone);
    // static date to start/stop the event (to not cause DST issues)
    const startDate = new moment('2021-01-01')
      .hour(tzStartDate.hour())
      .minute(tzStartDate.minute());

    if (this.playlists.length <= 0 && this.mixes.length <= 0 && musicParts.length > 0) {
      musicParts.forEach((music) => {
        if (music.type === 'playlist') {
          const span = this.getItemTimes(music);

          const playlistStart = startDate
            .clone()
            .add(span.offsetHours, 'hours')
            .add(span.offsetMinutes, 'minutes');

          const playlistStop = playlistStart
            .clone()
            .add(span.durationHours, 'hours')
            .add(span.durationMinutes, 'minutes');

          // does the item start/stop time span different days?
          const multipleDays = playlistStart.day() !== playlistStop.day();
          const playlistStartTime = playlistStart.format('HH:mm');
          const playlistStopTime = multipleDays
            ? '24:00'
            : playlistStop.format('HH:mm');

          const existingPlaylist = find(this.playlists, {
            eventId: this._id,
            startTime: playlistStartTime,
            stopTime: playlistStopTime,
            playlist: {
              _id: music.musicId,
            },
          });

          if (!existingPlaylist) {
            this.addPlaylist({
              playlist: {
                _id: music.musicId,
                active: music.active,
                ...music.detail,
              },
              startTime: playlistStartTime,
              stopTime: playlistStopTime,
            });
          } else {
            existingPlaylist.playlist = {
              ...existingPlaylist.playlist,
              ...music.detail,
            };
          }
        } else if (music.type === 'mix') {
          const span = this.getItemTimes(music);

          const mixStart = startDate
            .clone()
            .add(span.offsetHours, 'hours')
            .add(span.offsetMinutes, 'minutes');

          const mixStop = mixStart
            .clone()
            .add(span.durationHours, 'hours')
            .add(span.durationMinutes, 'minutes');

          // does the item start/stop time span different days?
          const multipleDays = mixStart.day() !== mixStop.day();
          const mixStartTime = mixStart.format('HH:mm');
          const mixStopTime = multipleDays
            ? '24:00'
            : mixStop.format('HH:mm');

          const existingMix = find(this.mixes, {
            eventId: this._id,
            startTime: mixStartTime,
            stopTime: mixStopTime,
            mix: {
              _id: music.musicId,
            },
          });

          if (!existingMix) {
            this.addStation({
              station: {
                _id: music.musicId,
                active: music.active,
                ...music.detail,
              },
              startTime: mixStartTime,
              stopTime: mixStopTime,
            });
          } else {
            existingMix.mix = {
              ...existingMix.mix,
              ...music.detail,
            };
          }
        }
      });
    }

    if (this.messageBlocks.length <= 0 && messageParts.length > 0) {
      messageParts.forEach((messageBlock) => {
        const span = this.getItemTimes(messageBlock);

        if (get(messageBlock, 'detail.messages.length', 0) > 0) {
          messageBlock.detail.messages = sortBy(messageBlock.detail.messages, 'sequence');
        }

        const blockStart = startDate
          .clone()
          .add(span.offsetHours, 'hours')
          .add(span.offsetMinutes, 'minutes');

        const blockStop = blockStart
          .clone()
          .add(span.durationHours, 'hours')
          .add(span.durationMinutes, 'minutes');

        // does the item start/stop time span different days?
        const multipleDays = blockStart.day() !== blockStop.day();
        const blockStartTime = blockStart.format('HH:mm');
        const blockStopTime = multipleDays
          ? '24:00'
          : blockStop.format('HH:mm');

        const existingMessageBlock = find(this.messageBlocks, {
          eventId: this._id,
          startTime: blockStartTime,
          stopTime: blockStopTime,
          messageBlock: {
            _id: messageBlock.messageListId,
          },
        });

        if (!existingMessageBlock) {
          this.addMessageBlock({
            messageBlock: {
              _id: messageBlock.messageListId,
              active: messageBlock.active,
              ...messageBlock.detail,
            },
            startTime: blockStartTime,
            stopTime: blockStopTime,
          });
        } else {
          existingMessageBlock.messageBlock = {
            ...existingMessageBlock.messageBlock,
            ...messageBlock.detail,
          };
        }
      });
    }

    if (this.interruptions.length <= 0 && interrupts.length > 0) {
      interrupts.forEach((interrupt) => {
        const span = this.getItemTimes(interrupt);

        const interruptStart = startDate
          .clone()
          .add(span.offsetHours, 'hours')
          .add(span.offsetMinutes, 'minutes');

        const interruptStartTime = interruptStart.format('HH:mm');

        const existingInterrupt = find(this.interruptions, {
          eventId: this._id,
          startTime: interruptStartTime,
          message: {
            _id: interrupt.messageId,
          },
        });

        if (!existingInterrupt) {
          this.addInterrupt({
            interrupt: {
              _id: interrupt.messageId,
              active: interrupt.active,
              accessAllLocations: interrupt.accessAllLocations,
              accessCurrentLocation: interrupt.accessCurrentLocation,
              ...interrupt.detail,
            },
            startTime: interruptStartTime,
          });
        } else {
          existingInterrupt.message = {
            ...existingInterrupt.message,
            ...interrupt.detail,
          };
        }
      });
    }

    // sort items so they are in chronological order for drag/drop purposes
    this.playlists = sortBy(this.playlists, 'startTime');
    this.mixes = sortBy(this.mixes, 'startTime');
    this.messageBlocks = sortBy(this.messageBlocks, 'startTime');
    this.interruptions = sortBy(this.interruptions, 'startTime');

    // each(data, (val, key) => {
    //   // Convert the key to camelcase
    //   // const camelKey = camelCase(key);

    //   if (isUndefined(this[key])) {
    //     // Add the property to the model
    //     this[key] = val;

    //     console.warn(`${key} is not added to Event Model`);
    //   }
    // });
  }

  /**
   * Return the object with event attributes only
   */
  getData() {
    const data = clone(this);

    // Remove unnecessary data
    delete data.getData;
    delete data.getRepeatData;
    delete data.getLocationsData;
    delete data.getItemTimes;

    return data;
  }

  /**
   * Get Repeat Data
   */
  getRepeatData() {
    if (!this.schedule) {
      // No schedule, create one
      this.schedule = rrule({
        dtstart: this.startDate,
      }).toString();
    }

    this.repeatRule = RRule.fromString(this.schedule);
    this.repeatDays = [];

    const days = get(this.repeatRule, 'origOptions.byweekday', []) || [];
    const frequency = get(this.repeatRule, 'origOptions.freq', null);

    const dtstart = get(this.repeatRule, 'origOptions.dtstart', null);
    const dtstartDate = new moment(dtstart)
      .tz(this.scheduleOriginTimeZone);

    // start date based on the instance of this event
    // const dtstartDate = new moment(this.date)
    //   .tz(this.scheduleOriginTimeZone);

    // static date to start/stop the event (to not cause DST issues)
    const staticStart = new moment('2021-01-01')
      .hour(dtstartDate.hour())
      .minute(dtstartDate.minute());

    // stop time is determined by the event duration
    const durationHours = parseInt(this.duration.split(':')[0], 10);
    const durationMinutes = parseInt(this.duration.split(':')[1], 10);

    const stopTimeDate = staticStart
      .clone()
      .add(durationHours, 'hours')
      .add(durationMinutes, 'minutes');

    // does the event start/stop on a different day?
    const multipleDays = staticStart.day() !== stopTimeDate.day();

    this.startTime = staticStart.format('HH:mm');
    this.stopTime = multipleDays
      ? '24:00'
      : stopTimeDate.format('HH:mm');

    if (dtstart) {
      const startDate = dtstartDate.clone();
      this.startDate = startDate.toDate();
    }

    const dtstop = get(this.repeatRule, 'origOptions.until', null);

    if (dtstop) {
      // stop date is determined by the rrule
      const stopDate = new moment(dtstop)
        .tz(this.scheduleOriginTimeZone);

      this.stopDate = stopDate.toDate();
    }

    // Repeat if schedule has a frequency parameter
    this.repeat = this.schedule.indexOf('FREQ=') >= 0;
    this.repeatInterval = get(this.repeatRule, 'origOptions.interval', 1);

    if (frequency === RRule.WEEKLY) {
      this.repeatFrequency = 'week';
    } else if (frequency === RRule.MONTHLY) {
      this.repeatFrequency = 'month';
    } else if (frequency === RRule.YEARLY) {
      this.repeatFrequency = 'year';
    }

    const sortDays = sortBy(days, 'weekday');

    sortDays.forEach((day) => {
      if (day.weekday === RRule.SU.weekday) {
        // Ensure Sunday is the first day in the array
        this.repeatDays.unshift('Sunday');
      } else if (day.weekday === RRule.MO.weekday) {
        this.repeatDays.push('Monday');
      } else if (day.weekday === RRule.TU.weekday) {
        this.repeatDays.push('Tuesday');
      } else if (day.weekday === RRule.WE.weekday) {
        this.repeatDays.push('Wednesday');
      } else if (day.weekday === RRule.TH.weekday) {
        this.repeatDays.push('Thursday');
      } else if (day.weekday === RRule.FR.weekday) {
        this.repeatDays.push('Friday');
      } else if (day.weekday === RRule.SA.weekday) {
        this.repeatDays.push('Saturday');
      }
    });
  }

  /**
   * Get the locations data
   */
  getLocationsData(companies) {
    return getLocationsData(companies);
  }

  /**
   * Get offset and duration time spans
   */
  getItemTimes(item) {
    item.offset = item.offset || '00:00';
    item.duration = item.duration || '00:00';

    const offsetHours = parseInt(item.offset.split(':')[0], 10);
    const offsetMinutes = parseInt(item.offset.split(':')[1], 10);
    const durationHours = parseInt(item.duration.split(':')[0], 10);
    const durationMinutes = parseInt(item.duration.split(':')[1], 10);

    return {
      offsetHours,
      offsetMinutes,
      durationHours,
      durationMinutes,
    };
  }

  /**
   * Add a playlist to the event
   */
  addPlaylist({
    playlist,
    startTime,
    stopTime,
  }) {
    this.playlists.push({
      _uniqueId: uniqueId('playlist-'),
      eventId: this._id,
      duration: getDuration(startTime, stopTime),
      minutes: getMinutesBetween(startTime, stopTime),
      offset: getOffset(this.startTime, startTime),
      startTime,
      stopTime,
      playlist,
    });

    this.playlists = sortBy(this.playlists, 'startTime');
    return this.playlists;
  }

  /**
   * Add a station to the event
   */
  addStation({
    station,
    startTime,
    stopTime,
  }) {
    this.mixes.push({
      _uniqueId: uniqueId('station-'),
      eventId: this._id,
      duration: getDuration(startTime, stopTime),
      minutes: getMinutesBetween(startTime, stopTime),
      offset: getOffset(this.startTime, startTime),
      startTime,
      stopTime,
      mix: station,
    });

    this.mixes = sortBy(this.mixes, 'startTime');
    return this.mixes;
  }

  /**
   * Add a message block to the event
   */
  addMessageBlock({
    messageBlock,
    startTime,
    stopTime,
  }) {
    this.messageBlocks.push({
      _uniqueId: uniqueId('message-block-'),
      eventId: this._id,
      duration: getDuration(startTime, stopTime),
      minutes: getMinutesBetween(startTime, stopTime),
      offset: getOffset(this.startTime, startTime),
      startTime,
      stopTime,
      messageBlock,
    });

    this.messageBlocks = sortBy(this.messageBlocks, 'startTime');
    return this.messageBlocks;
  }

  /**
   * Add an interrupt to the event
   */
  addInterrupt({
    interrupt,
    startTime,
  }) {
    this.interruptions.push({
      _uniqueId: uniqueId('message-'),
      eventId: this._id,
      offset: getOffset(this.startTime, startTime),
      startTime,
      accessAllLocations: interrupt.accessAllLocations,
      accessCurrentLocation: interrupt.accessCurrentLocation,
      message: interrupt,
    });

    this.interruptions = sortBy(this.interruptions, 'startTime');
    return this.interruptions;
  }

  /**
   * Update a playlist in the event
   */
  updatePlaylist(data) {
    this.playlists = this.playlists.map((playlist) => {
      if (playlist._uniqueId === data._uniqueId) {
        return {
          ...playlist,
          ...data,
        };
      }

      return playlist;
    });

    this.playlists = sortBy(this.playlists, 'startTime');
    return this.playlists;
  }

  /**
   * Update a station in the event
   */
  updateStation(data) {
    this.mixes = this.mixes.map((mix) => {
      if (mix._uniqueId === data._uniqueId) {
        return {
          ...mix,
          ...data,
        };
      }

      return mix;
    });

    this.mixes = sortBy(this.mixes, 'startTime');
    return this.mixes;
  }

  /**
   * Update a message block in the event
   */
  updateMessageBlock(data) {
    this.messageBlocks = this.messageBlocks.map((messageBlock) => {
      if (messageBlock._uniqueId === data._uniqueId) {
        return {
          ...messageBlock,
          ...data,
        };
      }

      return messageBlock;
    });

    this.messageBlocks = sortBy(this.messageBlocks, 'startTime');
    return this.messageBlocks;
  }

  /**
   * Update an interrupt in the event
   */
  updateInterrupt(data) {
    this.interruptions = this.interruptions.map((interrupt) => {
      if (interrupt._uniqueId === data._uniqueId) {
        return {
          ...interrupt,
          ...data,
        };
      }

      return interrupt;
    });

    this.interruptions = sortBy(this.interruptions, 'startTime');
    return this.interruptions;
  }

  /**
   * Remove a playlist
   */
  removePlaylist(_uniqueId) {
    this.playlists = this.playlists.filter(playlist => playlist._uniqueId !== _uniqueId);
    return this.playlists;
  }

  /**
   * Remove a station
   */
  removeStation(_uniqueId) {
    this.mixes = this.mixes.filter(mix => mix._uniqueId !== _uniqueId);
    return this.mixes;
  }

  /**
   * Remove a message block
   */
  removeMessageBlock(_uniqueId) {
    this.messageBlocks = this.messageBlocks.filter(messageBlock => messageBlock._uniqueId !== _uniqueId);
    return this.messageBlocks;
  }

  /**
   * Remove an interrupt
   */
  removeInterrupt(_uniqueId, removeOption = 'single') {
    if (removeOption === 'single') {
      // remove a single interrupt
      this.interruptions = this.interruptions.filter(interrupt => interrupt._uniqueId !== _uniqueId);
    } else if (removeOption === 'all') {
      // remove all interrupts that have this ID
      const interruptItem = find(this.interruptions, { _uniqueId });
      this.interruptions = this.interruptions.filter(interrupt => interrupt.message._id !== interruptItem.message._id);
    }

    return this.interruptions;
  }
}

export default Event;
