import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  forEach,
} from 'lodash';
import {
  API,
  GlobalActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelFooter,
  SidePanelContent,
  Assignments,
  ToastActions,
  VibeModal,
  VibeTooltip,
  VibeButtonNew,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  withRouter,
  color,
} from 'vibeguide';
import Information from './Information';
import AdSettings from './AdSettings/AdSettings';
import CompanyLayoutTemplates from './CompanyLayoutTemplates/CompanyLayoutTemplates';
import CompanyLoopTemplates from './CompanyLoopTemplates/CompanyLoopTemplates';
import CompanyUsers from './CompanyUsers';
import CompanyLocations from './CompanyLocations';
import CompanyBanners from './CompanyBanners/CompanyBanners';
import AddPicture from '../../Shared/AddPicture';
import defaultCompanyImage from '../../../assets/default_company.png';
import './CompanyDetails.scss';

let companyImageFile = null;

function CompanyDetails({
  user,
  company,
  onCloseExtraPanel,
  onClose,
  onUpdate,
  isNew,
  history,
  setPanel,
  queueToast,
}) {
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [bannersOpen, setBannersOpen] = useState(false);

  /**
   * When the banners sidebar is closed
   */
  const onCloseBanners = () => {
    setBannersOpen(false);
  };

  /**
   * When the locations assigned is clicked
   */
  const onClickLocations = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <CompanyLocations
            companyId={company._id}
            onClose={onCloseExtraPanel}
          />
        ),
      },
    });
  };

  /**
   * When the Users assigned is clicked
   */
  const onClickUsers = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <CompanyUsers
            companyId={company._id}
            onClose={onCloseExtraPanel}
          />
        ),
      },
    });
  };

  /**
   * When the Banners assigned is clicked
   */
  const onClickBanners = () => {
    setPanel({
      extraPanel: {
        width: 800,
        show: true,
        children: (
          <CompanyBanners
            companyId={company._id}
            onClose={onCloseBanners}
          />
        ),
      },
    });

    // needed to update banners when the company ID changes
    setBannersOpen(true);
  };

  /**
   * When the Layout Templates is clicked
   */
  const onClickLayoutTemplates = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <CompanyLayoutTemplates
            companyId={company._id}
            onClose={onCloseExtraPanel}
          />
        ),
      },
    });
  };

  /**
   * When the Loop Templates is clicked
   */
  const onClickLoopTemplates = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <CompanyLoopTemplates
            companyId={company._id}
            onClose={onCloseExtraPanel}
          />
        ),
      },
    });
  };

  /**
   * When the archive button is clicked
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * When the unarchive button is clicked
   */
  const onClickUnarchive = async () => {
    await API.Company.reactivate({
      _id: company._id,
    });

    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompany'));
  };

  /**
   * When the archive modal is closed
   */
  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * When the archive modal is confirmed
   */
  const onConfirmArchive = async () => {
    await API.Company.deactivate(company._id);

    onCloseArchive();
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompany'));
  };

  const onUploadImage = (file) => {
    companyImageFile = file;
  };

  /**
   * Save the Company
   */
  const onSave = async () => {
    const {
      _id,
      name,
      category,
      address1,
      address2,
      country,
      city,
      state,
      postalCode,
      tags,
      salesforceId,
      requireMessageApproval,
      adNetworkEnabled,
      adProgramEnabled,
      playbackMode,
      adProviderConfig,
      allowBackToBackAdDelivery = false,
    } = company;

    document.dispatchEvent(new Event('onSaveCompanyStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    // Get tags to add/remove
    const modifyTags = tags ? tags.filter(tag => tag.status === 'add' || tag.status === 'remove') : [];
    const companyId = _id;

    // Data to create company
    const data = {
      name,
      category,
      address1,
      address2,
      country,
      city,
      state,
      postalCode,
      tags: modifyTags.map((tag) => {
        return {
          _id: tag._id,
          action: tag.status,
        };
      }),
      salesforceId,
      requireMessageApproval,
      adNetworkEnabled,
      adProgramEnabled,
      playbackMode,
      adProviderConfig,
      allowBackToBackAdDelivery,
    };

    let modify = false;

    if (companyId && !isNew) {
      // Editing or saving an item
      data._id = companyId;
      modify = true;
    }

    try {
      const companyResponse = modify
        ? await API.Company.update(companyId, data)
        : await API.Company.create(data);
      const newCompanyId = get(companyResponse, '[0].documentId', null);

      if (companyImageFile && companyImageFile.type) {
        // Upload company image
        const uploadCompanyImage = await API.Company.uploadImage(newCompanyId, companyImageFile);
        const uploadCompanyImageId = get(uploadCompanyImage, '[0].documentId', null);

        if (uploadCompanyImageId) {
          // Image was successful
          queueToast({
            type: 'success',
            title: 'Saved!',
            allowClose: true,
          });
          queueToast({
            type: 'success',
            title: 'Company Image Uploaded!',
            allowClose: true,
          });
        } else {
          queueToast({
            type: 'error',
            title: 'Error Saving Image',
            timeout: 10,
            allowClose: true,
          });
        }
      } else {
        // No image to upload
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });
      }

      if (isNew) {
        // Redirect user to the edit page for the new item
        history(`/companies/list?companyId=${newCompanyId}`);
      }

      // tell listening components the object was saved
      document.dispatchEvent(new Event('onSaveCompany'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveCompanyError'));
    }
  };

  const storeCompanyImage = image => {
    companyImageFile = image;
  };

  useEffect(() => {
    if (company._id && bannersOpen) {
      setPanel({
        extraPanel: {
          width: 600,
          show: true,
          children: (
            <CompanyBanners
              companyId={company._id}
              onClose={onCloseBanners}
            />
          ),
        },
      });
    }
  }, [company._id]);

  /**
   * When it's a new company, allow duplicate ads back 2 back
   */
  useEffect(() => {
    if (isNew) {
      onUpdate({
        allowBackToBackAdDelivery: true,
      });
    }
  }, []);

  const validateCompanyAdProviderConfig = () => {
    let valid = true;

    forEach(company.adProviderConfig, (provider, providerKey) => {
      forEach(provider, (item) => {
        if (!valid) {
          return false;
        }

        if (providerKey === 'vistar') {
          valid = item.networkId && item.apiKey;
        }

        return true;
      });
    });

    return valid;
  };

  const disableSave = !company.name
    || !company.category
    || !validateCompanyAdProviderConfig();

  const disableInput = !user.can('company.modify');
  const showArchive = user.can('company.delete');
  const isArchived = !company.active;
  const image = company.imageUrl || null;

  return (
    <SidePanelContainer className="CompanyDetails">
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="company-image">
          <AddPicture
            imageUrl={image}
            silhouette={(
              <img
                className="company-picture"
                src={defaultCompanyImage}
                width="auto"
                height="100%"
                alt="Company"
              />
            )}
            shape="circle"
            getImageURL={storeCompanyImage}
          />
        </div>

        {company._id ? (
          <div className="title">
            <VibeTooltip title={company.name}>
              <span>
                {company.name}
              </span>
            </VibeTooltip>
          </div>
        ) : (
          <div className="title">
            New Company
          </div>
        )}
      </SidePanelHeader>

      <SidePanelContent>
        <Information
          companyId={company._id}
          name={company.name}
          category={company.category}
          address1={company.address1}
          address2={company.address2}
          country={company.country}
          city={company.city}
          state={company.state}
          zip={company.postalCode}
          salesforceId={company.salesforceId}
          requireMessageApproval={company.requireMessageApproval}
          tags={company.tags}
          disableInput={disableInput}
          onUpdate={onUpdate}
          onUploadImage={onUploadImage}
        />

        <AdSettings
          adNetworkEnabled={company.adNetworkEnabled || false}
          adProgramEnabled={company.adProgramEnabled || false}
          playbackMode={company.playbackMode}
          adProviderConfig={company.adProviderConfig || {}}
          allowBackToBackAdDelivery={company.allowBackToBackAdDelivery || false}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />

        {(user.can('visual_layout_template.view') || user.can('visual_loop_template.view')) && (
          <Assignments
            title="Visual Templates"
            items={[{
              label: 'Layout Templates',
              count: user.can('visual_layout_template.view')
                // show the layout template count if they have access
                ? company.layoutTemplateCount || 0
                : null,
              disabled: isNew || !user.can('visual_layout_template.view'),
              onClick: !isNew
                ? onClickLayoutTemplates
                : null,
            },
            {
              label: 'Loop Templates',
              count: user.can('visual_loop_template.view')
                // show the loop template count if they have access
                ? company.loopTemplateCount || 0
                : null,
              disabled: isNew || !user.can('visual_loop_template.view'),
              onClick: !isNew
                ? onClickLoopTemplates
                : null,
            }]}
          />
        )}

        <Assignments
          items={[{
            label: 'Locations Assigned',
            count: company.locationCount || 0,
            disabled: isNew,
            onClick: !isNew
              ? onClickLocations
              : null,
          },
          {
            label: 'Users Assigned',
            count: company.userCount || 0,
            disabled: isNew,
            onClick: !isNew
              ? onClickUsers
              : null,
          },
          {
            label: 'Banners',
            count: user.can('company_banner.view')
              // show the banner count if they have access
              ? company.bannerCount || 0
              : null,
            disabled: isNew || !user.can('company_banner.view'),
            onClick: !isNew
              ? onClickBanners
              : null,
          }]}
        />
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButtonNew
          text="Save Changes"
          style={{
            marginRight: 12,
          }}
          color={color.primary}
          loadingEvent="onSaveCompany"
          disabled={disableSave || disableInput || (!company.adNetworkEnabled && company.adProgramEnabled)}
          onClick={onSave}
        />

        {!isNew ? (
          <div className="toolbar-buttons">
            {showArchive && !isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.error,
                  }}
                  tooltip="Archive"
                  color={color.error}
                  size={20}
                  onClick={onClickArchive}
                />
              </div>
            ) : null}

            {showArchive && isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.success,
                  }}
                  tooltip="Unarchive"
                  color={color.success}
                  size={20}
                  onClick={onClickUnarchive}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text="Would you like to archive this company?"
        confirmProps={{
          text: 'Archive',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

CompanyDetails.propTypes = {
  /** New Company */
  isNew: PropTypes.bool,
  /** Duplicate Company */
  company: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onCloseExtraPanel: PropTypes.func,
  onUpdate: PropTypes.func,
};

CompanyDetails.defaultProps = {
  isNew: false,
  company: {},
  onClose: () => {},
  onCloseExtraPanel: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyDetails));
