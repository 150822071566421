// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AudioCell {
  display: flex;
  align-items: center;
}
.AudioCell > .secondary-icon {
  margin-left: 4px;
}
.AudioCell .audio-name {
  margin-left: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/vibeguide/src/components/VibeTable/CellTypes/AudioCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAJ","sourcesContent":[".AudioCell {\n  display: flex;\n  align-items: center;\n\n  > .secondary-icon {\n    margin-left: 4px;\n  }\n\n  .audio-name {\n    margin-left: 4px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
