import React from 'react';
import PropTypes from 'prop-types';
import {
  VibeIcon,
  viNowPlaying,
  color,
} from 'vibeguide';
import './CurrentTime.scss';

function CurrentTime({
  time,
}) {
  return (
    <div className="CurrentTime">
      <div className="vertical-bar" />

      <div className="now-playing">
        <VibeIcon
          icon={viNowPlaying}
          color={color.primary}
          size={16}
          tooltip={`Current Time: ${time}`}
        />
      </div>
    </div>
  );
}

CurrentTime.propTypes = {
  time: PropTypes.string,
};

CurrentTime.defaultProps = {
  time: '',
};

export default CurrentTime;
