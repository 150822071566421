import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  find,
  isEqual,
} from 'lodash';
import {
  VibeIcon,
  viError,
  color,
} from 'vibeguide';
import ImportField from './ImportField';
import './ImportFields.scss';

/**
 * Create a field
 */
function createField(name, example) {
  return {
    name,
    example,
  };
}

class ImportFields extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      requiredFields: [
        createField('locationName', 'Location Name'),
        createField('address1', '1234 Street Ave'),
        createField('city', 'City Name'),
        createField('state', 'IN'),
        createField('postalCode', '00000'),
        createField('country', 'US'),
        createField('contentRating', 'radio-edit'),
        createField('salesforceId', '0012M00002EvDDZ'),
        createField('category', 'Category'),
        createField('bannerName', 'Location Banner'),
        createField('adNetworkEnabled', 'true | false'),
        createField('mediaFormat', 'audio | visual'),
        createField('contentNetworkOwner', 'Mood | Stingray | Vibenomics'),
        createField('contentDeliverySystem', 'Mood | Stingray | Vibenomics'),
      ],
      adNetwork: {
        if: [
          createField('adNetworkEnabled', 'true'),
        ],
        then: [
          createField('adNetworkOwner', 'Mood | Stingray | Vibenomics'),
          createField('adDeliverySystem', 'Mood | Stingray | Vibenomics'),
        ],
      },
      setOneFields: [
        createField('address2', 'STE 200'),
      ],
      setTwoFields: [
        createField('latitude', '00.000000'),
        createField('longitude', '00.000000'),
      ],
      setThreeFields: [
        createField('userEmail', 'user@gmail.com'),
        createField('userFname', 'First Name'),
        createField('userLname', 'Last Name'),
        createField('userTitle', 'Title'),
        createField('userPhone', '555-555-5555'),
        createField('smsNotifications', 'true'),
        createField('emailNotifications', 'false'),
      ],
      setFourFields: [
        createField('serviceEmail', 'vibe+xxxx0000@vibenomics.com'),
        createField('servicePassword', 'xxxxxxxx'),
      ],
      setFiveFields: [
        createField('deviceType', 'optra | mandroid'),
        createField('serialNumber', '0123456789'),
        createField('macAddress', '0F:0F:0F:0F:0F:0F'),
      ],
      setSixFields: [
        createField('externalSource', 'Mood | Stingray'),
        createField('externalId', 'id'),
      ],
      requiredFieldsMissing: false,
      requiredFieldsInvalid: false,
      setOneDetectedCount: {
        detected: 0,
        undetected: 0,
      },
      setTwoDetectedCount: {
        detected: 0,
        undetected: 0,
      },
      setThreeDetectedCount: {
        detected: 0,
        undetected: 0,
      },
      setFourDetectedCount: {
        detected: 0,
        undetected: 0,
      },
      setFiveDetectedCount: {
        detected: 0,
        undetected: 0,
      },
      setSixDetectedCount: {
        detected: 0,
        undetected: 0,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const {
      validationResult,
    } = this.props;

    const {
      validationResult: prevValidationResult,
    } = prevProps;

    // Only check fields when the validation result changes
    if (!isEqual(validationResult, prevValidationResult)) {
      this.checkFields();
    }
  }

  /**
   * Get detected and undetected count for a set
   */
  getDetectedCount = (columns) => {
    if (!columns) {
      return {
        detected: 0,
        undetected: 0,
      };
    }

    let detected = 0;
    let undetected = 0;

    columns.forEach((field) => {
      if (field.detected) {
        detected++;
      } else {
        undetected++;
      }
    });

    return {
      detected,
      undetected,
    };
  };

  /**
   * Check fields for validation issues
   */
  checkFields = () => {
    const {
      status,
      validationResult: {
        sets,
      },
    } = this.props;

    const setRequired = find(sets, { name: 'Default' }) || {};
    const setOne = find(sets, { name: 'Set 1' }) || {};
    const setTwo = find(sets, { name: 'Set 2' }) || {};
    const setThree = find(sets, { name: 'Set 3' }) || {};
    const setFour = find(sets, { name: 'Set 4' }) || {};
    const setFive = find(sets, { name: 'Set 5' }) || {};
    const setSix = find(sets, { name: 'Set 6' }) || {};

    const requiredDetectedCount = this.getDetectedCount(setRequired.columns);
    const requiredFieldsMissing = status === 'validated'
      ? false
      : requiredDetectedCount.undetected > 0;
    const requiredFieldsInvalid = status === 'validated'
      ? false
      : !requiredFieldsMissing && !setRequired.valid;

    const setOneDetectedCount = this.getDetectedCount(setOne.columns);
    const setTwoDetectedCount = this.getDetectedCount(setTwo.columns);
    const setThreeDetectedCount = this.getDetectedCount(setThree.columns);
    const setFourDetectedCount = this.getDetectedCount(setFour.columns);
    const setFiveDetectedCount = this.getDetectedCount(setFive.columns);
    const setSixDetectedCount = this.getDetectedCount(setSix.columns);

    this.setState((state) => {
      return {
        ...state,
        requiredFields: state.requiredFields.map((requiredField) => {
          const field = find(setRequired.columns, { name: requiredField.name }) || requiredField;

          return {
            ...field,
            example: requiredField.example,
          };
        }),
        setOneFields: state.setOneFields.map((setOneField) => {
          const field = find(setOne.columns, { name: setOneField.name }) || setOneField;

          return {
            ...field,
            example: setOneField.example,
          };
        }),
        setTwoFields: state.setTwoFields.map((setTwoField) => {
          const field = find(setTwo.columns, { name: setTwoField.name }) || setTwoField;

          return {
            ...field,
            example: setTwoField.example,
          };
        }),
        setThreeFields: state.setThreeFields.map((setThreeField) => {
          const field = find(setThree.columns, { name: setThreeField.name }) || setThreeField;

          return {
            ...field,
            example: setThreeField.example,
          };
        }),
        setFourFields: state.setFourFields.map((setFourField) => {
          const field = find(setFour.columns, { name: setFourField.name }) || setFourField;

          return {
            ...field,
            example: setFourField.example,
          };
        }),
        setFiveFields: state.setFiveFields.map((setFiveField) => {
          const field = find(setFive.columns, { name: setFiveField.name }) || setFiveField;

          return {
            ...field,
            example: setFiveField.example,
          };
        }),
        setSixFields: state.setSixFields.map((setSixField) => {
          const field = find(setSix.columns, { name: setSixField.name }) || setSixField;

          return {
            ...field,
            example: setSixField.example,
          };
        }),
        requiredFieldsMissing,
        requiredFieldsInvalid,
        setOneDetectedCount,
        setTwoDetectedCount,
        setThreeDetectedCount,
        setFourDetectedCount,
        setFiveDetectedCount,
        setSixDetectedCount,
      };
    });
  };

  render() {
    const {
      status,
    } = this.props;

    const {
      requiredFields,
      adNetwork,
      setOneFields,
      setTwoFields,
      setThreeFields,
      setFourFields,
      setFiveFields,
      setSixFields,
      requiredFieldsMissing,
      requiredFieldsInvalid,
      setOneDetectedCount,
      setTwoDetectedCount,
      setThreeDetectedCount,
      setFourDetectedCount,
      setFiveDetectedCount,
      setSixDetectedCount,
    } = this.state;

    return (
      <div className="ImportFields">
        <div className="section section-required">
          <div className="section-title">
            Required Fields
          </div>

          <div className="section-description">
            {!requiredFieldsMissing && !requiredFieldsInvalid ? (
              <span>
                The file must contain the following columns with precise formatting.
              </span>
            ) : null}

            {requiredFieldsMissing ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <span>
                  Missing columns. The file must contain the following columns with precise formatting.
                </span>
              </div>
            ) : null}

            {requiredFieldsInvalid ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <span>
                  Incorrect formatting. The file must contain the following columns with precise formatting.
                </span>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {requiredFields.map((field, index) => {
              return (
                <ImportField
                  key={`required-field-${index}`}
                  field={field}
                  importStatus={status}
                  required
                />
              );
            })}
          </div>
        </div>

        <div className="section section-optional">
          <div className="section-title">
            If, Then Fields
          </div>

          <div className="section-description">
            If a column is marked true, then other columns are required
          </div>

          <div className="if-then-wrapper">
            <div className="if-then-set">
              <div className="title">IF</div>
              <div className="fields">
                {adNetwork.if.map((field, index) => {
                  return (
                    <ImportField
                      key={`set-adnetwork-if-field-${index}`}
                      field={field}
                      importStatus={status}
                      required
                    />
                  );
                })}
              </div>
            </div>
            <p>=</p>
            <div className="if-then-set">
              <div className="title">THEN</div>
              <div className="fields">
                {adNetwork.then.map((field, index) => {
                  return (
                    <ImportField
                      key={`set-adnetwork-then-field-${index}`}
                      field={field}
                      importStatus={status}
                      required
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="section section-optional">
          <div className="section-title">
            Optional Fields
          </div>

          <div className="section-description">
            The following columns are optional, but if one is included, you must include all in its set.
          </div>

          <div className="section-set">
            <div className="set-title">
              Set 1
            </div>

            {setOneFields.length > 1 && setOneDetectedCount.undetected > 0 ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Including one column in a set requires to inclusion of all columns in the set.
                </div>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {setOneFields.map((field, index) => {
              return (
                <ImportField
                  key={`set-one-field-${index}`}
                  field={field}
                  importStatus={status}
                  required={setOneDetectedCount.detected > 0}
                />
              );
            })}
          </div>

          <div className="section-set">
            <div className="set-title">
              Set 2
            </div>

            {setTwoFields.length > 1 && setTwoDetectedCount.undetected > 0 ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Including one column in a set requires to inclusion of all columns in the set.
                </div>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {setTwoFields.map((field, index) => {
              return (
                <ImportField
                  key={`set-two-field-${index}`}
                  field={field}
                  importStatus={status}
                  required={setTwoDetectedCount.detected > 0}
                />
              );
            })}
          </div>

          <div className="section-set">
            <div className="set-title">
              Set 3
            </div>

            {setThreeFields.length > 1 && setThreeDetectedCount.undetected > 0 ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Including one column in a set requires to inclusion of all columns in the set.
                </div>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {setThreeFields.map((field, index) => {
              return (
                <ImportField
                  key={`set-three-field-${index}`}
                  field={field}
                  importStatus={status}
                  required={setThreeDetectedCount.detected > 0}
                />
              );
            })}
          </div>

          <div className="section-set">
            <div className="set-title">
              Set 4
            </div>

            {setFourFields.length > 1 && setFourDetectedCount.undetected > 0 ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Including one column in a set requires to inclusion of all columns in the set.
                </div>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {setFourFields.map((field, index) => {
              return (
                <ImportField
                  key={`set-four-field-${index}`}
                  field={field}
                  importStatus={status}
                  required={setFourDetectedCount.detected > 0}
                />
              );
            })}
          </div>

          <div className="section-set">
            <div className="set-title">
              Set 5
            </div>

            {setFiveFields.length > 1 && setFiveDetectedCount.undetected > 0 ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Including one column in a set requires to inclusion of all columns in the set.
                </div>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {setFiveFields.map((field, index) => {
              return (
                <ImportField
                  key={`set-five-field-${index}`}
                  field={field}
                  importStatus={status}
                  required={setFiveDetectedCount.detected > 0}
                />
              );
            })}
          </div>

          <div className="section-set">
            <div className="set-title">
              Set 6
            </div>

            {setSixFields.length > 1 && setSixDetectedCount.undetected > 0 ? (
              <div className="error-container">
                <VibeIcon
                  className="error-icon"
                  icon={viError}
                  color={color.error}
                  size={16}
                />

                <div>
                  Including one column in a set requires to inclusion of all columns in the set.
                </div>
              </div>
            ) : null}
          </div>

          <div className="fields">
            {setSixFields.map((field, index) => {
              return (
                <ImportField
                  key={`set-six-field-${index}`}
                  field={field}
                  importStatus={status}
                  required={setSixDetectedCount.detected > 0}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    status: state.import.locations.data.status,
    validationResult: state.import.locations.data.validationResult || {},
  };
}

export default connect(mapStateToProps)(ImportFields);
