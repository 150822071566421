import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  Navigate,
} from 'react-router-dom';
import {
  GlobalActions,
  NavigationHelper,
  VibeModal,
  VibeButtonNew,
  color,
  withRouter,
} from 'vibeguide';
import LocationSidebar from './Sidebar/LocationSidebar';
import LocationViewTable from './LocationView/LocationViewTable';
import './LocationsList.scss';

class LocationsList extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    // if the root URL is not the locations list, redirect the user
    this.redirect = user.getRootUrl() !== '/locations';

    this.state = {
      showAddLocationModal: false,
    };
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.locationId && qs.type !== 'new';

    if (isNew || isView) {
      setTimeout(() => {
        this.sidebar(qs.type, qs.locationId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.locationId || qs.type === 'new') {
        this.sidebar(qs.type, qs.locationId);
      }
    }
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      locationId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  /**
   * When new location is clicked
   */
  onClickNewLocation = () => {
    const {
      user,
      history,
    } = this.props;

    if (user.sysAdmin) {
      // Admins see the new location modal to choose if they want to import
      this.setState({
        showAddLocationModal: true,
      });
    } else {
      // Non-admins go straight to new location page
      history(NavigationHelper.updateParams({
        type: 'new',
      }));
    }
  };

  /**
   * Close the new location modal
   */
  onCancelAddLocation = () => {
    this.setState({
      showAddLocationModal: false,
    });
  };

  sidebar = (type, locationId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <LocationSidebar
          locationId={locationId}
          isNew={type === 'new'}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    const {
      showAddLocationModal,
    } = this.state;

    if (this.redirect) {
      // user doesn't have access to the locations list, redirect to their root url
      return (
        <Navigate
          to={user.getRootUrl()}
        />
      );
    }

    const sidebar = user.sysAdmin;
    const style = {
      height: '100%',
    };

    return (
      <div className={classNames('LocationsList', { sidebar })}>
        <div style={style}>
          <LocationViewTable
            onClickNewLocation={this.onClickNewLocation}
          />
        </div>

        <VibeModal
          show={showAddLocationModal}
          type="custom"
          title="New Location"
          text={(
            <div>
              <div className="button-container">
                <VibeButtonNew
                  style={{
                    width: '100%',
                    height: 40,
                    justifyContent: 'center',
                  }}
                  text="Single Location"
                  color={color.success}
                  link={NavigationHelper.updateParams({
                    type: 'new',
                  })}
                  onClick={this.onCancelAddLocation}
                />

                <VibeButtonNew
                  style={{
                    width: '100%',
                    height: 40,
                    marginTop: 16,
                    justifyContent: 'center',
                  }}
                  text="Import Spreadsheet"
                  color={color.primary}
                  link="/locations/import"
                />

                <VibeButtonNew
                  style={{
                    width: '100%',
                    height: 40,
                    marginTop: 16,
                    justifyContent: 'center',
                  }}
                  text="Cancel"
                  color={color.manatee}
                  onClick={this.onCancelAddLocation}
                />
              </div>
            </div>
          )}
          onClose={this.onCancelAddLocation}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationsList));
