import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  forEach,
  isEqual,
  isArray,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  convertDuration,
} from '../../../utils/TimeUtil';
import {
  setModal,
} from '../../../actions/Global/GlobalActions';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeButton from '../../VibeButton/VibeButton';
import VibeIcon from '../../VibeIcon/VibeIcon';
import VibeModal from '../../VibeModal/VibeModal';
import Field from '../../Field/Field2';
import CellTooltip from '../CellTypes/CellTooltip';
import ContentTags from '../CellTypes/ContentTags';
import ContentLabel from '../CellTypes/ContentLabel';
import TimestampCell from '../CellTypes/TimestampCell';
import AudioCell from '../CellTypes/AudioCell';
import viDownload from '../../../icons/viDownload';
import viTime from '../../../icons/viTime';
import viAddCircle from '../../../icons/viAddCircle';
import viCloseCircle from '../../../icons/viCloseCircle';
import viEvent from '../../../icons/viEvent';
import viPlaylist from '../../../icons/viPlaylist';
import viEdit from '../../../icons/viEdit';
import viClose from '../../../icons/viClose';
import viCheck from '../../../icons/viCheck';
import color from '../../../sass/color.scss';

const tableId = 'table:songs';
const permissionPrefix = 'music';

class TableSongs extends PureComponent {
  constructor(props) {
    super(props);

    let {
      columns: columnNames,
    } = props;

    const {
      user,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Add/Remove',
      defaultWidth: 77,
      icon: viAddCircle,
      locked: true,
    },
    {
      name: 'Song Title',
      searchAttr: 'name',
      defaultWidth: 275,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
      autoFocus: true,
    },
    {
      name: 'Artist',
      searchAttr: 'artist',
      defaultWidth: 230,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Album',
      searchAttr: 'album',
      defaultWidth: 230,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Genre',
      dataType: 'array',
      searchAttr: 'genre',
      defaultWidth: 190,
      editable: true,
      resizable: true,
      searchable: true,
    },
    {
      name: 'ISRC',
      searchAttr: 'isrc',
      defaultWidth: 190,
      editable: true,
      resizable: true,
      sortable: true,
    },
    {
      name: 'ISWC',
      dataType: 'array',
      searchAttr: 'iswc',
      defaultWidth: 190,
      editable: true,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Record Label',
      searchAttr: 'copyright',
      defaultWidth: 190,
      editable: true,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Source',
      searchAttr: 'source',
      defaultWidth: 190,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Release Year',
      searchAttr: 'releaseYear',
      defaultWidth: 100,
      icon: viEvent,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Duration',
      searchAttr: 'durationSeconds',
      defaultWidth: 85,
      icon: viTime,
      sortable: true,
    },
    {
      name: 'Tempo',
      searchAttr: 'tempo',
      defaultWidth: 100,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
      ],
    },
    {
      name: 'Ranking',
      searchAttr: 'ranking',
      defaultWidth: 100,
      editable: true,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'A',
          value: 'A',
        },
        {
          label: 'B',
          value: 'B',
        },
        {
          label: 'C',
          value: 'C',
        },
        {
          label: 'D',
          value: 'D',
        },
        {
          label: 'E',
          value: 'E',
        },
        {
          label: 'F',
          value: 'F',
        },
        {
          label: 'G',
          value: 'G',
        },
      ],
    },
    {
      name: 'Rating',
      searchAttr: 'rating',
      defaultWidth: 120,
      editable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'G',
          value: 'g',
        },
        {
          label: 'Radio Edit',
          value: 'radio-edit',
        },
        {
          label: 'Explicit',
          value: 'explicit',
        },
      ],
    },
    {
      name: 'Reason',
      searchAttr: 'ratingReason',
      defaultWidth: 275,
      editable: true,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Playlists',
      searchAttr: 'playlistCount',
      defaultWidth: 85,
      icon: viPlaylist,
      sortable: true,
    },
    {
      name: 'PRO',
      dataType: 'array-multiple',
      searchAttr: 'proSelection',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      dropdownItems: [],
    },
    {
      name: 'Modified',
      searchAttr: 'modifiedDate',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      datepicker: true,
      sortable: true,
    },
    {
      name: 'Date Added',
      searchAttr: 'dateAdded',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      datepicker: true,
    },
    {
      name: 'Tags',
      searchAttr: 'tags.name',
      valueAttr: 'tags',
      defaultWidth: 275,
      editable: true,
      resizable: true,
      searchable: true,
    }];

    if (!user.can('music.manage_pro') && columnNames.includes('PRO')) {
      // remove PRO column if they can not manage PRO data
      columnNames = columnNames.filter(name => name !== 'PRO');
    }

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      // row changes being edited in "Edit Mode"
      rowsEdit: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
      isEditMode: false,
      errorIds: [],
      // only fetch PRO definitions if the column exists and the user has access to view
      proFetched: !columnNames.includes('PRO'),
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveSong', this.onUpdateTableData);
    document.addEventListener('onUpdateTableAssignedSongs', this.onUpdateTableAssignedData);
  }

  componentDidMount() {
    const {
      columnNames,
    } = this.state;

    if (columnNames.includes('PRO')) {
      // fetch PRO definitions
      this.getProDefinitions();
    }

    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveSong', this.onUpdateTableData);
    document.removeEventListener('onUpdateTableAssignedSongs', this.onUpdateTableAssignedData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });

    // reset the selected rows
    // document.dispatchEvent(new Event('onResetSelectedRows'));
  };

  /**
   * When an event asks the table to update the assigned table
   */
  onUpdateTableAssignedData = () => {
    const {
      assigned,
    } = this.props;

    if (assigned) {
      this.onUpdate({
        refresh: true,
      });
    }
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      onSelectMenuItem,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.error,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.success,
        });

        break;
      }

      case 'unblock': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unblock ${row.name}?`,
          confirmApproveText: 'Yes, Unblock',
          confirmApproveColor: color.error,
        });

        break;
      }

      default:
        break;
    }

    onSelectMenuItem({
      name: itemName,
      row,
    });
  };

  onConfirmModal = async () => {
    const {
      currentLocation,
      collection,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = await API.Music.deactivate(_id);

        const successText = 'MUSIC.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = await API.Music.reactivate({
          _id,
        });

        const successText = 'MUSIC.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unblock': {
        const locationId = get(currentLocation, '_id', null);
        const response = await API.Location.unblockSong({
          _id: locationId,
          music: [
            _id,
          ],
        });

        const successText = 'LOCATION.MUSIC_UNBLOCKED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * Bulk Edit Selected Rows
   */
  onBulkEditApply = async ({
    selected,
    data,
  }) => {
    const {
      setModal,
    } = this.props;

    const bulkData = selected.map((item) => {
      return {
        _id: item._id,
        ...data,
      };
    });

    const response = await API.Music.updateBulk(bulkData);
    const errorItems = response.filter(result => result.type.indexOf('MUSIC.UPDATED') < 0);
    const success = errorItems.length <= 0;

    if (success) {
      // refresh table data
      this.onUpdate({
        refresh: true,
      });
    } else {
      // failed to update some rows
      const errorIds = errorItems.map(item => item.documentId);
      const errorMessages = errorItems.map(item => item.data.message);

      setModal({
        type: 'error',
        title: `${errorItems.length} Songs Failed to Update`,
        message: (
          <div>
            {errorMessages.map((message, index) => {
              return (
                <div
                  key={`update-error-${index}`}
                >
                  {message}
                </div>
              );
            })}
          </div>
        ),
      });

      this.setState({
        errorIds,
      });
    }
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Remove a Song from the table
   */
  onRemoveSong = (e, _rowId) => {
    const {
      onRemove,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onRemove(row);
  };

  /**
   * Add a Song to the table
   */
  onAddSong = (e, _rowId) => {
    const {
      onAdd,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onAdd(row);
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  /**
   * Toggle the table edit mode
   */
  onClickEditMode = () => {
    this.setState({
      isEditMode: true,
    });
  };

  /**
   * Cancel edit mode changes
   */
  onClickEditModeCancel = () => {
    this.setState({
      isEditMode: false,
      // reset any edits made to rows
      rowsEdit: [],
      // reset any errors
      errorIds: [],
    });
  };

  /**
   * Save edit mode changes
   */
  onClickEditModeSave = async () => {
    const {
      setModal,
    } = this.props;

    const {
      rowsEdit,
    } = this.state;

    if (rowsEdit.length <= 0) {
      console.warn('No rows have been edited');
      return;
    }

    const response = await API.Music.updateBulk(rowsEdit);
    const errorItems = response.filter(result => result.type.indexOf('MUSIC.UPDATED') < 0);
    const success = errorItems.length <= 0;

    if (success) {
      // refresh table data
      this.onUpdate({
        refresh: true,
        data: {
          isEditMode: false,
          // reset any edits made to rows
          rowsEdit: [],
          // reset any errors
          errorIds: [],
        },
      });
    } else {
      // One or more rows failed to update
      const errorIds = errorItems.map(item => item.documentId);
      const errorMessages = errorItems.map(item => item.data.message);

      setModal({
        type: 'error',
        title: `${errorItems.length} Songs Failed to Update`,
        message: (
          <div>
            {errorMessages.map((message, index) => {
              return (
                <div
                  key={`update-error-${index}`}
                >
                  {message}
                </div>
              );
            })}
          </div>
        ),
      });

      this.setState({
        errorIds,
      });
    }
  };

  /**
   * When a field is changed during edit mode
   */
  onChangeEditField = (e) => {
    const {
      target: {
        name,
        value,
        dataset: {
          id: _id,
        },
      },
    } = e;

    this.setState((state) => {
      // current row data
      const currRow = find(state.rows, { _id }) || {};
      // is the row already being edited
      const rowEditExists = find(state.rowsEdit, { _id }) !== undefined;

      return {
        rowsEdit: rowEditExists
          // the row is already being edited
          ? state.rowsEdit.map((row) => {
            if (row._id === _id) {
              return {
                // current row data (all)
                ...currRow,
                // current edited attributes for the row (some)
                ...row,
                // new value for the changed attribute
                [name]: value,
              };
            }

            return row;
          })
          // need to add the row to edit
          : [...state.rowsEdit, {
            // current row data (all)
            ...currRow,
            _id,
            // new value for the changed attribute
            [name]: value,
          }],
      };
    });
  };

  /**
   * When a field is blurred during edit mode
   */
  onBlurEditField = (e) => {
    const {
      target: {
        name,
        value,
        dataset: {
          id: _id,
        },
      },
    } = e;

    const {
      columnsDef,
    } = this.state;

    const column = find(columnsDef, { searchAttr: name });
    let fieldValue = value;

    if (column.dataType === 'array') {
      // keep value an array
      fieldValue = value.split(',').map(item => item.trim()).filter(item => item !== '');

      this.setState((state) => {
        // current row data
        const currRow = find(state.rows, { _id }) || {};
        // is the row already being edited
        const rowEditExists = find(state.rowsEdit, { _id }) !== undefined;

        return {
          rowsEdit: rowEditExists
            // the row is already being edited
            ? state.rowsEdit.map((row) => {
              if (row._id === _id) {
                return {
                  // current row data (all)
                  ...currRow,
                  // current edited attributes for the row (some)
                  ...row,
                  // new value for the changed attribute
                  [name]: fieldValue,
                };
              }

              return row;
            })
            // need to add the row to edit
            : [...state.rowsEdit, {
              // current row data (all)
              ...currRow,
              _id,
              // new value for the changed attribute
              [name]: fieldValue,
            }],
        };
      });
    }
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Fetch the PRO definitions for dropdown
   */
  getProDefinitions = async () => {
    const proDefinitions = await API.Music.getProList();
    const proDropdownItems = proDefinitions.map((pro) => {
      return {
        key: `pro-${pro.name}`,
        label: pro.name,
        value: pro.name,
      };
    });

    this.setState((state) => {
      const {
        columnsDef,
      } = state;

      const proColumn = find(columnsDef, { name: 'PRO' });
      proColumn.dropdownItems = proDropdownItems;

      return {
        columnsDef,
        proFetched: true,
      };
    });
  };

  /**
   * Get the Rating Style
   */
  getRatingStyle = (rating = '') => {
    switch (rating?.toLowerCase() ?? '') {
      case 'g':
        return {
          text: 'G',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'radio-edit':
        return {
          text: 'Radio Edit',
          textStyle: {
            background: color.primary16,
            color: color.primary,
          },
        };

      case 'explicit':
        return {
          text: 'Explicit',
          textStyle: {
            background: color.error16,
            color: color.error,
          },
        };

      default:
        return {
          text: rating,
          textStyle: {
            background: 'transparent',
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Fetch data for the table
   * Only request and return the response from the API or collection
   */
  fetchData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    const {
      rows,
      // totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    return rows;
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      highlight,
    } = this.props;

    const {
      isEditMode,
    } = this.state;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    let value = get(row, attr, '');

    switch (column.name) {
      case 'Add/Remove': {
        const assigned = find(highlight, { _id: row._id }) !== undefined;

        return (
          <VibeIcon
            name={row._rowId}
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.error
              : color.success}
            hoverColor={assigned
              ? color.error75
              : color.success75}
            size={24}
            tooltip={assigned
              ? 'Remove Song'
              : 'Add Song'}
            tooltipProps={{
              placement: 'right',
            }}
            onClick={assigned
              ? this.onRemoveSong
              : this.onAddSong}
          />
        );
      }

      case 'Song Title': {
        if (column.editable && isEditMode) {
          return (
            <Field
              type="text"
              dataId={row._id}
              name={column.searchAttr}
              placeholder="Edit..."
              value={value}
              tabIndex={200}
              onChange={this.onChangeEditField}
            />
          );
        }

        return (
          <AudioCell
            className="cell-content"
            id={row._id}
            name={row.name}
            src={row.contentUrl}
            disabled={!row.contentUrl}
            color={color.success}
            durationSeconds={row.durationSeconds}
            playText="Play Song"
            pauseText="Pause Song"
            edit={column.editable && isEditMode}
          />
        );
      }

      case 'Duration': {
        const duration = convertDuration(value, 'm:ss');

        return (
          <CellTooltip title={duration}>
            <div className="cell-content">
              {duration}
            </div>
          </CellTooltip>
        );
      }

      case 'Modified':
      case 'Date Added':
        return (
          <TimestampCell
            time={value}
          />
        );

      case 'Rating': {
        const {
          text,
          textStyle,
        } = this.getRatingStyle(value);

        if (column.editable && isEditMode) {
          return (
            <Field
              type="select"
              dataId={row._id}
              name={column.searchAttr}
              value={value}
              options={column.dropdownItems}
              tabIndex={200}
              onChange={this.onChangeEditField}
            />
          );
        }

        return (
          <ContentLabel
            className="cell-content"
            text={text}
            textStyle={{
              ...textStyle,
              margin: '0 auto',
            }}
          />
        );
      }

      case 'Tags': {
        return (
          <ContentTags
            className="cell-content"
            tags={row.tags}
            hideIcon
          />
        );
      }

      default: {
        if (column.searchAttr === 'proSelection') {
          const proItems = [];

          forEach(value, (v, key) => {
            if (v === true) {
              proItems.push(key);
            }
          });

          value = proItems;
        }

        // the array fields are strings when editing, converted to array once focus is lost
        const useValue = (column.dataType === 'array' || column.dataType === 'array-multiple') && isArray(value)
          ? value.join(', ')
          : value;

        if (column.editable && isEditMode) {
          return (
            <Field
              type={get(column, 'dropdownItems.length', 0) > 0
                ? 'select'
                : 'text'}
              dataId={row._id}
              name={column.searchAttr}
              placeholder="Edit..."
              value={useValue}
              options={column.dropdownItems}
              tabIndex={200}
              onBlur={this.onBlurEditField}
              onChange={this.onChangeEditField}
            />
          );
        }

        return (
          <CellTooltip title={useValue}>
            <div className="cell-content">
              {useValue}
            </div>
          </CellTooltip>
        );
      }
    }
  };

  render() {
    const {
      className,
      toolbarProps,
      csv,
      rowLink,
      editMode,
      errorIds: propErrorIds,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      rowsEdit,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
      isEditMode,
      errorIds,
      proFetched,
    } = this.state;

    if (!proFetched) {
      return null;
    }

    const totalItemsStr = totalItems.toLocaleString();
    const customButtons = [];

    // merge row data with edit row data
    const newRows = isEditMode
      // only merge when in edit mode
      ? rows.map((row) => {
        const rowEdit = find(rowsEdit, { _id: row._id }) || {};

        return {
          ...row,
          ...rowEdit,
        };
      })
      // not in edit mode, use existing row data
      : rows;

    if (editMode && !isEditMode) {
      customButtons.push(
        <VibeButton
          key="btn-edit-mode"
          variant="outlined"
          text="Edit Mode"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viEdit}
              color={color.primary}
              size={16}
            />
          )}
          tooltip="Edit all cells in the table"
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onClickEditMode}
        />,
      );
    }

    if (csv && !isEditMode) {
      customButtons.push(
        <VibeButton
          key="btn-export"
          variant="outlined"
          text="Export"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viDownload}
              color={color.primary}
              size={16}
            />
          )}
          tooltip={`Export ${totalItemsStr} Songs to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />,
      );
    }

    if (isEditMode) {
      customButtons.push(
        <VibeButton
          key="btn-edit-mode-cancel"
          variant="outlined"
          text="Cancel"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viClose}
              color={color.primary}
              size={16}
            />
          )}
          onClick={this.onClickEditModeCancel}
        />,
      );

      customButtons.push(
        <VibeButton
          key="btn-edit-mode-save"
          variant="outlined"
          text="Save Changes"
          btnColor="green"
          icon={(
            <VibeIcon
              icon={viCheck}
              color={color.success}
              size={16}
            />
          )}
          onClick={this.onClickEditModeSave}
        />,
      );
    }

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TableSongs', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          errorIds={[
            ...propErrorIds,
            ...errorIds,
          ]}
          rowLink={!isEditMode
            ? rowLink
            : null}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={newRows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          bulkArchive={API.Music.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkEditApply={this.onBulkEditApply}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onFetch={this.fetchData}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableSongs.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableSongs.defaultProps = {
  ...tablePropsDefault,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  setModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableSongs));
