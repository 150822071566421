import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  map,
  uniq,
  forEach,
} from 'lodash';
import {
  API,
  TableSongs,
  VibeDialog,
  NavigationHelper,
  GlobalActions,
  viEdit,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import SongBlocker from './SongBlocker';
import PlaylistSelector from './PlaylistSelector';
import SongSidebar from './Sidebar/SongSidebar';
import './SongsContainer.scss';

class SongsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      showBlockMenu: false,
      showAddToPlaylist: false,
    };
  }

  componentDidMount() {
    const qs = NavigationHelper.getParams() || {};
    const isNew = qs.type === 'new';
    const isView = qs.songId && qs.type !== 'new';

    if (isNew || isView) {
      this.sidebarTimeout = setTimeout(() => {
        this.sidebar(qs.type, qs.songId);
      }, 1500);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        search,
      },
    } = this.props;

    const {
      location: {
        search: prevSearch,
      },
    } = prevProps;

    if (search !== prevSearch) {
      const qs = NavigationHelper.getParams();

      if (qs.songId || qs.type === 'new') {
        this.sidebar(qs.type, qs.songId);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.sidebarTimeout);
  }

  /**
   * When the sidebar is closed
   */
  onCloseSidebar = () => {
    const {
      history,
    } = this.props;

    const url = NavigationHelper.updateParams({
      songId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  onSelectMenuItem = ({
    name,
    row: song,
  }) => {
    const songs = [song];

    if (name === 'add to playlist') {
      this.openPlaylistSelection(songs);
    } else if (name === 'block') {
      this.openBlockMenu(songs);
    }
  };

  onBlockSongs = async (data) => {
    const {
      songs,
      locations,
    } = data;

    await forEach(songs, async (song) => {
      await API.Music.block({
        _id: song._id,
        locations: locations.map((location) => {
          return {
            companyId: location.companyId,
            locationId: location._id,
          };
        }),
      });
    });

    this.closeBlockMenu();
  };

  onAddToPlaylists = async (data) => {
    const {
      songs,
      playlists,
    } = data;

    const newSongs = map(songs, s => {
      return s._id;
    });

    forEach(playlists, async (playlist) => {
      const playlistDetails = await API.Playlist.getById(playlist._id);
      const musicItems = [...playlistDetails.musicItems];
      const previousMusicIds = [];

      forEach(musicItems, (m) => {
        if (m.active) {
          previousMusicIds.push(m._id);
        }
      });

      let songsToAdd = [...newSongs, ...previousMusicIds];
      songsToAdd = uniq(songsToAdd);

      const updatedPlaylist = {
        _id: playlist._id,
        category: playlist.category,
        musicItems: songsToAdd,
      };

      await API.Playlist.update(playlist._id, updatedPlaylist);
    });

    this.closePlaylistSelection();
  };

  onExport = (data) => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `songs-${timestamp}.csv`);
  };

  /**
   * When selecting a bulk action
   */
  onToggleBulkAction = (action, selected) => {
    if (action === 'add') {
      this.openPlaylistSelection(selected);
    } else if (action === 'block') {
      this.openBlockMenu(selected);
    }
  };

  sidebar = (type, songId = null) => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 600,
      children: (
        <SongSidebar
          songId={songId}
        />
      ),
      onClose: this.onCloseSidebar,
    });
  };

  openPlaylistSelection = (songs) => {
    this.setState({
      showAddToPlaylist: true,
      selected: songs,
    });
  };

  closePlaylistSelection = () => {
    this.setState({
      showAddToPlaylist: false,
    });
  };

  openBlockMenu = (songs) => {
    this.setState({
      showBlockMenu: true,
      selected: songs,
    });
  };

  closeBlockMenu = () => {
    this.setState({
      showBlockMenu: false,
    });
  };

  render() {
    const {
      user,
    } = this.props;

    const {
      selected,
      showAddToPlaylist,
      showBlockMenu,
    } = this.state;

    return (
      <div className="SongsContainer">
        <SubNavigation
          title="Songs"
          buttons={[
            {
              text: 'Curate Pending Songs',
              color: color.primary,
              icon: viEdit,
              url: '/music/songs/publish',
              userCan: [
                'music.modify',
              ],
            },
            {
              text: 'Upload Songs',
              icon: viAdd,
              url: '/music/songs/upload',
              userCan: [
                'music.create',
              ],
            },
          ]}
        />

        <TableSongs
          columns={[
            '.',
            'Song Title',
            'Artist',
            'Album',
            'Genre',
            'ISRC',
            'ISWC',
            'Record Label',
            'Source',
            'Release Year',
            'Duration',
            'Tempo',
            'Ranking',
            'Rating',
            'Reason',
            'Playlists',
            'PRO',
            'Modified',
            'Tags',
            '...',
          ]}
          fetch={API.Music.list}
          defaultSortBy={{
            label: 'Song Title',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Songs',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Songs',
            tooltip: true,
          }}
          bulk
          bulkProps={{
            edit: true,
            add: true,
            block: true,
            remove: false,
            archive: true,
            onToggle: this.onToggleBulkAction,
          }}
          editMode={user.can('music.modify')}
          rowLink={{
            songId: '_id',
          }}
          menuItems={[
            { name: 'Add to Playlist', userCan: 'playlist.modify' },
            { name: 'Block', userCan: 'music.block' },
            { name: 'Archive', userCan: 'music.delete' },
          ]}
          csv={user.sysAdmin}
          csvProps={{
            onExport: this.onExport,
          }}
          onSelectMenuItem={this.onSelectMenuItem}
        />

        <VibeDialog
          title="Block Songs"
          open={showBlockMenu}
          onClose={this.closeBlockMenu}
          fullScreen
        >
          <SongBlocker
            songs={selected}
            onBlockSongs={this.onBlockSongs}
          />
        </VibeDialog>

        <VibeDialog
          title="Add to Playlist"
          open={showAddToPlaylist}
          onClose={this.closePlaylistSelection}
          fullScreen
        >
          <PlaylistSelector
            songs={selected}
            onAddToPlaylists={this.onAddToPlaylists}
          />
        </VibeDialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SongsContainer));
