import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  LocationActions,
  EmptyState,
  VibeIcon,
  viRefresh,
  color,
  VibeModal,
  ToastActions,
} from 'vibeguide';
import TokenTableRow from './TokenTableRow';
import './TokenTable.scss';
import './TokenTableRow.scss';

function TokenTable({
  user,
  currentUser,
  queueToast,
  className,
}) {
  const [tokens, setTokens] = useState([]);
  const [showRegToken, setShowRegToken] = useState(false);

  useEffect(() => {
    setTokens(user.tokens || []);
  }, [user]);

  const cycleToken = () => {
    setShowRegToken(true);
  };

  const formatTokens = (data) => {
    const tokens = [];

    Object.keys(data.newTokens).forEach(token => {
      if (data.newTokens[token]) {
        tokens.push({
          token,
          expires: data.newTokens[token].expires,
          createdDate: data.newTokens[token].createdDate,
        });
      }
    });

    return tokens;
  };

  const onConfirmRegToken = async (e, userId) => {
    e.stopPropagation();

    const cycleData = await API.IntegrationUser.cycleToken(userId);

    setTokens(formatTokens(cycleData[0].data));
    setShowRegToken(false);
  };

  const onCancelRegToken = () => {
    setShowRegToken(false);
  };

  const filterToken = (token) => {
    setTokens(tokens.filter(t => t.token !== token));

    queueToast({
      type: 'success',
      title: 'Deactivation Successful',
      allowClose: true,
    });
  };

  return (
    <div className={classNames('TokenTable', className)}>
      <div className="header">
        {user._id
          && currentUser.can('integration_user.manage_tokens')
          && (tokens && tokens.length)
          && (
            <VibeIcon
              type="button"
              icon={viRefresh}
              color={color.primary}
              size={16}
              loading={false}
              tooltip="Generate Token"
              tooltipProps={{
                placement: 'left',
              }}
              buttonProps={{
                size: 32,
                borderColor: color.primary,
              }}
              onClick={cycleToken}
            />
          )}

      </div>

      {tokens && tokens.length > 0 ? (
        <div className="lookup-items">
          <div className={classNames('TokenTableRow header', className)}>
            <div className="header data-col token">
              Token
            </div>

            <div className="header data-col created-date">
              Created
            </div>

            <div className="header data-col expire-date">
              Expires
            </div>
          </div>
          {tokens.map((item, index) => {
            return (
              <TokenTableRow
                key={`external-row-${index}`}
                userId={user._id}
                token={item.token}
                created={item.createdDate}
                expires={item.expires}
                filterToken={filterToken}
              />
            );
          })}
        </div>
      ) : (
        <EmptyState
          title="No Token Generated"
          description="To view generated token, please save and refresh the new user"
          style={{
            flexGrow: 1,
            padding: 24,
            justifyContent: 'start',
            backgroundColor: color.white,
          }}
        />
      )}
      <VibeModal
        show={showRegToken}
        type="confirm"
        title="Changes to Tokens"
        text="If you regenerate a user token you will have 30 days before the previous token will deactivate."
        confirmProps={{
          text: 'Regenerate',
          color: color.primary,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={(e) => onConfirmRegToken(e, user._id)}
        onClose={onCancelRegToken}
      />
    </div>
  );
}

TokenTable.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

TokenTable.defaultProps = {
  className: '',
  user: {},
};

function mapStateToProps(state) {
  return {
    currentUser: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

const mapDispatchToProps = {
  updateCurrentLocation: LocationActions.updateCurrentLocation,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenTable);
