import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import toNumber from 'lodash/toNumber';
import { Grid } from '@mui/material';
import {
  Field2 as Field,
  VibeIcon,
  viTrash,
  viPlayCircleOutline,
  color,
  VibeButtonNew,
  GlobalActions,
} from 'vibeguide';
import CreativeMedia from '../../Orders/OrderSegment/CreativeMedia';
import './DayPart.scss';
import DayPartCreative from './DayPartCreative';

function DayPart({
  advertiserId,
  segmentLocations,
  partIndex,
  part,
  totalParts,
  hourOptions,
  canAddMedia,
  disableMedia,
  onTimeChange,
  onCreativeChange,
  onDelete,
  setPanel,
}) {
  const onSelectMedia = (dayPart, data) => {
    const creatives = data.map(message => {
      return {
        name: message.name,
        creativeId: message._id,
        url: message.url,
        durationSeconds: message.durationSeconds,
        fileSize: message.fileSizeBytes || '',
        fileType: message.contentType || '',
        graphicSize: message.graphicSize || '',
        weight: message.weight || 0,
      };
    });

    onCreativeChange({
      creatives: part.creatives
        ? [...part.creatives, ...creatives]
        : [...creatives],
      dayPart,
    });
  };

  const onCreativeUpdate = (creative) => {
    const updatedCreatives = part.creatives.map(c => {
      return c.creativeId === creative.creativeId ? creative : c;
    });

    onCreativeChange({
      creatives: updatedCreatives,
    });
  };

  const onCreativeDelete = (creative) => {
    const updatedCreatives = part.creatives.filter(c => c.creativeId !== creative.creativeId);

    onCreativeChange({
      creatives: updatedCreatives,
    });
  };

  const onAddCreative = () => {
    setPanel({
      extraPanel: {
        show: true,
        backdrop: false,
        width: window.innerWidth,
        children: (
          <CreativeMedia
            dayPart={`${part.startTime}-${part.endTime}`}
            locations={segmentLocations}
            advertiserId={!advertiserId ? null : advertiserId}
            onSelectMedia={onSelectMedia}
          />
        ),
      },
    });
  };

  const disableByLocation = !segmentLocations || Object.keys(segmentLocations).length === 0;

  const totalCreativeWeight = canAddMedia && part.creatives?.length > 0
    ? part.creatives?.reduce((accumulator, creative) => {
      return accumulator + toNumber(creative.weight);
    }, 0) || 0
    : 0;

  return (
    <div className="DayPart">
      <div className="part-number">
        <div>
          Day Part {partIndex + 1}
        </div>
        {totalParts > 1 && (
          <VibeIcon
            icon={viTrash}
            color={color.primary}
            hoverColor={color.primary60}
            size={16}
            tooltip="Delete Day Part"
            onClick={() => onDelete(partIndex)}
          />
        )}
      </div>

      <Grid
        spacing={2}
        container
      >

        <Grid
          xs={6}
          item
        >
          <Field
            type="select"
            label="Start Time"
            name="startTime"
            placeholder="Choose Time"
            value={!part.startTime ? '' : part.startTime}
            options={[
              {
                label: '',
                value: '',
              },
              ...hourOptions,
            ]}
            tabIndex={15}
            onChange={onTimeChange}
            required
          />
        </Grid>
        <Grid
          xs={6}
          item
        >
          <Field
            type="select"
            label="End Time"
            name="endTime"
            value={!part.endTime ? '' : part.endTime}
            options={[
              {
                label: '',
                value: '',
              },
              ...hourOptions,
            ]}
            tabIndex={15}
            onChange={onTimeChange}
            required
          />
        </Grid>
      </Grid>

      {canAddMedia && (
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <div className="creatives">
              {part.creatives?.map((creative, index) => (
                <DayPartCreative
                  key={`day-part-creative-${index}`}
                  index={index}
                  creative={creative}
                  totalCreativeWeight={totalCreativeWeight}
                  onUpdate={onCreativeUpdate}
                  onRemove={onCreativeDelete}
                />
              ))}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div
                  className="creative-btn"
                  style={{
                    flexGrow: 1,
                  }}
                >
                  <VibeButtonNew
                    className="custom-button"
                    style={{
                      marginRight: 8,
                      borderRadius: 16,
                      border: 'none',
                      height: '22px',
                    }}
                    text="Add Media"
                    iconProps={{
                      style: {
                        color: color.primary,
                      },
                    }}
                    color={color.whiteSmoke}
                    icon={viPlayCircleOutline}
                    onClick={onAddCreative}
                    tooltip={(disableMedia || disableByLocation)
                      ? 'Locations & Flight must be selected to add media'
                      : ''}
                    disabled={disableMedia || disableByLocation}
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

DayPart.propTypes = {
  advertiserId: PropTypes.string,
  segmentLocations: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  partIndex: PropTypes.number,
  part: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    creatives: PropTypes.arrayOf(PropTypes.shape({
      creativeId: PropTypes.string,
      weight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })),
  }),
  totalParts: PropTypes.number,
  hourOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  canAddMedia: PropTypes.bool,
  disableMedia: PropTypes.bool,
  onTimeChange: PropTypes.func,
  onCreativeChange: PropTypes.func,
  onDelete: PropTypes.func,
};

DayPart.defaultProps = {
  advertiserId: '',
  segmentLocations: [],
  partIndex: 0,
  part: {
    startTime: null,
    endTime: null,
    creatives: [],
  },
  totalParts: 0,
  hourOptions: [],
  canAddMedia: false,
  disableMedia: false,
  onTimeChange: () => {},
  onCreativeChange: () => {},
  onDelete: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(null, mapDispatchToProps)(DayPart);
