import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  NavigationHelper,
  GlobalActions,
  StorageUtil,
  VibeButton,
  VibeToggle,
  VibeTooltip,
  VibeIcon,
  viAdd,
  viPlayCircleFilled,
  viPauseCircleFilled,
  viArrowLeft,
  withRouter,
  color,
} from 'vibeguide';
import {
  CircularProgress,
} from '@mui/material';
import './DashboardHeader.scss';

class DashboardHeader extends PureComponent {
  constructor(props) {
    super(props);

    const {
      refreshEvery,
    } = props;

    this.refreshInterval = null;

    const currAutoRefresh = StorageUtil.getLocal('alerts:autoRefresh', true);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      totalTicks: refreshEvery,
      tick: 0,
      percentage: 100,
      autoRefresh: currAutoRefresh,
    };
  }

  componentDidMount() {
    const {
      pauseRefresh,
      refreshEvery,
    } = this.props;

    const {
      autoRefresh,
    } = this.state;

    if (autoRefresh && refreshEvery && !pauseRefresh) {
      // Set the timer to show when the next refresh will occur
      this.refreshInterval = setInterval(this.tickRefresh, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  /**
   * When include flagged locations is toggled
   */
  onToggleIncludeFlaggedLocations = () => {
    const {
      includeFlaggedLocations,
      onChangeIncludeFlaggedLocations,
    } = this.props;

    onChangeIncludeFlaggedLocations(!includeFlaggedLocations);
  };

  /**
   * When the automatic refresh is toggled
   */
  onToggleAutomaticRefresh = () => {
    this.setState((state) => {
      const autoRefresh = !state.autoRefresh;

      if (autoRefresh) {
        // Start the timer
        this.refreshInterval = setInterval(this.tickRefresh, 1000);
      } else {
        // Stop the timer
        clearInterval(this.refreshInterval);
      }

      StorageUtil.setLocal('alerts:autoRefresh', autoRefresh);

      return {
        autoRefresh,
        tick: 0,
        percentage: 100,
      };
    });
  };

  tickRefresh = () => {
    const {
      onRefresh,
    } = this.props;

    this.setState((state) => {
      const {
        totalTicks,
        tick,
      } = state;

      let nextTick = tick + 1;

      if (nextTick > totalTicks) {
        // Reset the next tick
        nextTick = 0;

        // Run the refresh method from the parent component
        onRefresh();
      }

      const percentage = 100 - ((nextTick / totalTicks) * 100);

      // console.log('TICK', nextTick, totalTicks, percentage);

      return {
        tick: nextTick,
        percentage,
      };
    });
  };

  render() {
    const {
      className,
      title,
      goBackLink,
      includeFlaggedLocations,
      showNewLocationButton,
      showIncludeFlaggedLocationsToggle,
      refreshEvery,
    } = this.props;

    const {
      tick,
      percentage,
      autoRefresh,
    } = this.state;

    return (
      <div className={classNames('DashboardHeader', className)}>
        <div className="content">
          {goBackLink ? (
            <Link to={goBackLink}>
              <VibeIcon
                id="back"
                className="go-back-icon"
                icon={viArrowLeft}
                color={color.obsidian}
                size={24}
              />
            </Link>
          ) : null}

          <div className="title">
            {title}
          </div>

          {showNewLocationButton ? (
            <VibeButton
              className="btn-new-location"
              text="New Location"
              btnColor="green"
              textColor="white"
              btnLink={NavigationHelper.updateParams({
                type: 'new',
              })}
              icon={(
                <VibeIcon
                  icon={viAdd}
                  color={color.white}
                  size={24}
                />
              )}
            />
          ) : null}

          {showIncludeFlaggedLocationsToggle ? (
            <VibeToggle
              label="Include Flagged Locations"
              labelStyle={{
                color: color.obsidian,
                fontSize: 12,
                fontWeight: 'bold',
              }}
              style={{
                width: 250,
              }}
              height={16}
              checked={includeFlaggedLocations}
              color={color.success}
              outlined
              onChange={this.onToggleIncludeFlaggedLocations}
            />
          ) : null}

          {refreshEvery ? (
            <div
              className="progress"
              style={{
                color:
                  autoRefresh
                    ? color.primary
                    : color.whiteSmoke,
              }}
            >
              <CircularProgress
                variant="determinate"
                color="inherit"
                size={32}
                value={percentage}
              />

              <VibeTooltip
                title="Automatically refresh the dashboard."
                placement="left"
              >
                <div
                  className="tick"
                  onClick={this.onToggleAutomaticRefresh}
                >
                  <div className="tick-text">
                    {refreshEvery - tick}
                  </div>

                  <div className="tick-toggle-icon">
                    <VibeIcon
                      icon={autoRefresh
                        ? viPauseCircleFilled
                        : viPlayCircleFilled}
                      color={color.secondary}
                      size={24}
                    />
                  </div>
                </div>
              </VibeTooltip>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

DashboardHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  goBackLink: PropTypes.string,
  includeFlaggedLocations: PropTypes.bool,
  showNewLocationButton: PropTypes.bool,
  showIncludeFlaggedLocationsToggle: PropTypes.bool,
  refreshEvery: PropTypes.number,
  pauseRefresh: PropTypes.bool,
  onRefresh: PropTypes.func,
  onChangeIncludeFlaggedLocations: PropTypes.func,
};

DashboardHeader.defaultProps = {
  className: '',
  goBackLink: null,
  includeFlaggedLocations: false,
  showNewLocationButton: false,
  showIncludeFlaggedLocationsToggle: false,
  refreshEvery: null,
  pauseRefresh: false,
  onRefresh: () => {},
  onChangeIncludeFlaggedLocations: () => {},
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(DashboardHeader));
