import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  get,
} from 'lodash';
import {
  color,
  API,
  GlobalActions,
  Tag,
  UploadImage,
  VibeIcon,
  viEvent,
  viNowPlaying,
  viSpeaker,
  viTv,
} from 'vibeguide';
import classNames from 'classnames';
import ErrorIcon from '@mui/icons-material/Error';
import defaultAlbumImage from '../../../assets/no_album_art_t.png';
import defaultMessageImage from '../../../assets/messages.png';
import './LocationHeader.scss';

class LocationHeader extends PureComponent {
  constructor(props) {
    super(props);

    const {
      currentLocation: {
        playerOnline,
      },
    } = props;

    this.socketEventsRegistered = false;

    this.state = {
      nowPlaying: {},
      isOnline: playerOnline,
    };
  }

  componentDidMount() {
    const {
      socket,
    } = this.props;

    const {
      isOnline,
    } = this.state;

    if (isOnline) {
      // Fetch current play
      this.getNowPlaying();
    }

    if (socket.connected) {
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketEventsRegistered = true;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      socket,
      currentLocation,
    } = this.props;

    const {
      currentLocation: prevCurrentLocation,
    } = prevProps;

    const {
      isOnline,
    } = this.state;

    if (isOnline && currentLocation._id !== prevCurrentLocation._id) {
      // Fetch Current Played
      this.getNowPlaying();

      socket.off('VAPI_EVENT', this.onApiEvent);
      socket.on('VAPI_EVENT', this.onApiEvent);
    }

    if (!this.socketEventsRegistered && socket.connected) {
      socket.on('VAPI_EVENT', this.onApiEvent);
      this.socketEventsRegistered = true;
    }
  }

  componentWillUnmount() {
    const {
      socket,
    } = this.props;

    socket.off('VAPI_EVENT', this.onApiEvent);
  }

  onApiEvent = async (e) => {
    switch (e.type) {
      // When a media item is being played
      case 'LOCATION.CAPTURED_MEDIA_PLAYED': {
        this.getNowPlaying();
        break;
      }

      // Player online status changed
      case 'LOCATION.PLAYER_ONLINE_UPDATED': {
        const isOnline = get(e, 'data.online', false);

        if (isOnline) {
          // Only fetch current item if the player is online
          this.getNowPlaying();
        }

        this.setState({
          isOnline,
        });
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
   * When an image is dropped on the location
   */
  onDrop = async (file) => {
    const {
      currentLocation: {
        _id,
      },
      setModal,
    } = this.props;

    const uploadResponse = await API.Location.uploadImage(_id, file);
    const success = get(uploadResponse, '[0].type') === 'LOCATION.IMAGE_UPLOADED';

    if (!success) {
      setModal({
        type: 'error',
        message: 'Image failed to upload',
      });
    }
  };

  getNowPlaying = async () => {
    const {
      currentLocation: {
        _id,
      },
    } = this.props;

    const nowPlaying = await API.Location.getNowPlaying(_id);

    this.setState({
      nowPlaying,
    });
  };

  render() {
    const {
      user,
      currentLocation,
    } = this.props;

    const {
      nowPlaying,
      isOnline,
    } = this.state;

    if (!currentLocation) {
      return null;
    }

    const currDefaultImage = get(nowPlaying, 'mediaType', null) === 'message'
      ? defaultMessageImage
      : defaultAlbumImage;

    const currArtwork = get(nowPlaying, 'artworkUrl', null) || currDefaultImage;
    const currItemName = get(nowPlaying, 'name', null);
    const currArtist = get(nowPlaying, 'artist', null);
    const currProgramName = get(nowPlaying, 'programName', null) || 'Unknown';
    const currProgramColor = get(nowPlaying, 'color', color.primary);
    const provisioning = currentLocation.onboardStatus !== 'complete';

    return (
      <div>
        <div className="LocationHeader">
          <div className="header">
            <div className="location-image">
              <UploadImage
                image={currentLocation.imageUrl}
                allowUpload={user.can('location.modify')}
                onDrop={this.onDrop}
              />
            </div>

            <div className="location-information">
              <div className="page-title">
                <VibeIcon
                  style={{
                    marginRight: 4,
                  }}
                  icon={currentLocation.mediaFormat === 'visual'
                    ? viTv
                    : viSpeaker}
                  color={color.obsidian}
                  tooltip={currentLocation.mediaFormat === 'visual'
                    ? 'Visual Device'
                    : 'Audio Device'}
                  tooltipProps={{
                    placement: 'left',
                  }}
                  size={24}
                />

                {currentLocation.name}
                {currentLocation.testLocation && (
                <Tag
                  className={classNames('test-location-status')}
                  tag={{
                    name: 'Test',
                  }}
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    fontSize: 10,
                    textTransform: 'uppercase',
                    backgroundColor: color.primary16,
                    color: color.primary,
                  }}
                />
                )}
              </div>
              <div className="company-name">
                {currentLocation.companyName}
              </div>
              <div className="address">
                {currentLocation.address1} {currentLocation.address2} <br />
                {currentLocation.city}, {currentLocation.state}, {currentLocation.postalCode}
              </div>
            </div>
          </div>

          <div className="queue">
            <div className="queue-items">
              <div className="item curr-item">
                <div className="item-card">
                  <div
                    className="card-info-container"
                    style={{
                      boxShadow:
                        provisioning
                          ? `inset 0 -4px 0 0 ${color.manatee}`
                          : `inset 0 -4px 0 0 ${currProgramColor}`,
                    }}
                  >
                    <div className="card-info">
                      {isOnline || provisioning ? (
                        <div>
                          <div className="now-playing">
                            {!provisioning ? (
                              <VibeIcon
                                className="now-playing-icon"
                                icon={viNowPlaying}
                                color={currProgramColor}
                                size={24}
                              />
                            ) : null}

                            {provisioning
                              ? 'Provisioning'
                              : 'Now Playing'}
                          </div>

                          <div className="song-info">
                            <div className="song-title">
                              {provisioning
                                ? 'Setup'
                                : currItemName}
                            </div>

                            <div className="song-artist">
                              {provisioning
                                ? 'Awaiting activation'
                                : currArtist}
                            </div>
                          </div>

                          <div className="program-container">
                            <VibeIcon
                              className="program-icon"
                              icon={viEvent}
                              color={provisioning
                                ? color.manatee
                                : currProgramColor}
                              size={16}
                            />

                            <div
                              className="program-name"
                              style={{
                                color:
                                  provisioning
                                    ? color.manatee
                                    : currProgramColor,
                              }}
                            >
                              {provisioning
                                ? 'None'
                                : currProgramName}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="player-offline">
                          <ErrorIcon className="offline-icon" />

                          <div className="offline-text">
                            Player Offline
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div
                    className="card-image"
                    style={{
                      boxShadow:
                        provisioning
                          ? `inset 0 -4px 0 0 ${color.manatee}`
                          : `inset 0 -4px 0 0 ${currProgramColor}`,
                    }}
                  >
                    <img
                      src={provisioning
                        ? defaultAlbumImage
                        : currArtwork}
                      alt="Current"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
    socket: state.socket.connection,
  };
}

const mapDispatchToProps = {
  setModal: GlobalActions.setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationHeader);
