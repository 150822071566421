import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  ToastActions,
  UserSelection,
  VibeButton,
  VibeIcon,
  viAdd,
  viClose,
  color,
} from 'vibeguide';
import './PermissionSetBulkUsers.scss';

class PermissionSetBulkUsers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      assignedUsers: [],
    };
  }

  /**
   * When the users are changed
   */
  onChange = (users) => {
    this.setState({
      changed: true,
      assignedUsers: users,
    });
  };

  /**
   * Show the available users
   */
  onClickAssign = () => {
    const {
      setPanel,
    } = this.props;

    const {
      assignedUsers,
    } = this.state;

    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <UserSelection
            selected={assignedUsers}
            customToolbar={(
              <VibeIcon
                className="btn-toolbar close"
                icon={viClose}
                color={color.white}
                hoverColor={color.white}
                size={24}
                onClick={this.onCloseAvailable}
              />
            )}
            assigning
            allowChanges
            onChange={this.onChange}
          />
        ),
      },
    });
  };

  /**
   * Close the third panel on the sidebar
   */
  onCloseAvailable = () => {
    const {
      setPanel,
    } = this.props;

    setPanel({
      thirdPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        thirdPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Assign the permission set to the selected bulk users
   */
  onSave = async () => {
    const {
      permissionSetId,
      queueToast,
    } = this.props;

    const {
      assignedUsers,
    } = this.state;

    const userIds = assignedUsers.map(user => user._id);

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const response = await API.User.bulkAssignPermissionSet({
      users: userIds,
      permissionSet: permissionSetId,
    });

    const success = response.filter(result => result.type !== 'USER.UPDATED').length <= 0;

    if (success) {
      queueToast({
        type: 'success',
        title: 'Saved!',
        allowClose: true,
      });

      this.setState({
        changed: false,
      });
    } else {
      queueToast({
        type: 'error',
        title: 'Error Assigning to Users',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  render() {
    const {
      onClose,
    } = this.props;

    const {
      assignedUsers,
      changed,
    } = this.state;

    return (
      <div className="PermissionSetBulkUsers">
        <UserSelection
          selected={assignedUsers}
          customToolbar={(
            <div className="assigned-toolbar">
              <VibeButton
                className="btn-toolbar"
                text="Assign Users"
                btnColor="green"
                variant="outlined"
                icon={(
                  <VibeIcon
                    icon={viAdd}
                    color={color.success}
                    size={16}
                  />
                )}
                textColor="green"
                onClick={this.onClickAssign}
              />

              <VibeButton
                className="btn-toolbar"
                text="Save Changes"
                btnColor="green"
                textColor="white"
                disabled={!changed || assignedUsers.length <= 0}
                onClick={this.onSave}
              />

              <VibeIcon
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
            </div>
          )}
          allowChanges
          onChange={this.onChange}
        />
      </div>
    );
  }
}

PermissionSetBulkUsers.propTypes = {
  permissionSetId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

PermissionSetBulkUsers.defaultProps = {
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionSetBulkUsers);
