import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  get,
} from 'lodash';
import {
  API,
  color,
  // Field2 as Field,
  NavigationHelper,
  // AdProviderHelper,
  VibeButtonNew,
  TableSegments,
  Tag,
  viAdd,
} from 'vibeguide';
// import Grid from '@mui/material/Grid';
import FlightRollupItem from './FlightRollupItem';
import './FlightSegmentOverview.scss';

function FlightSegmentOverview({
  className,
  style,
  user,
  flightData,
  flightSummary,
  flightSegments,
  onToggleArchive,
}) {
  // const canModifyFlight = user.can('flight.modify') && flightData.active;
  const unconfirmed = flightData.status === 'unconfirmed';

  // when the flight is not confirmed/unconfirmed show the loading spinner
  const loading = flightData.status !== 'unconfirmed'
    && flightData.status !== 'confirmed';

  /**
   * Export to CSV file
   */
  const onExport = async () => {
    // if user cannot view segments skip
    if (!user.can('flight_segment.view')) {
      return;
    }

    const params = NavigationHelper.getParams();
    const active = get(params, 'active') !== 'false';

    // get all segments so we can merge with flight summary data
    const response = await API.Flight.Segment.list({
      flightId: flightData._id,
      filters: {
        active,
      },
      pageSize: -1,
    }, {
      headers: {
        Accept: 'text/csv',
      },
    });

    const blob = new Blob([response], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `segments-${timestamp}.csv`);
  };

  const renderLocationRollup = (key) => {
    const value = get(flightSummary, 'locationSummary', {})[key]
      ? get(flightSummary, 'locationSummary', {})[key].toLocaleString()
      : '--';

    return (
      <div className="rollup-item">
        <div className="title">
          {key}
        </div>

        <Tag
          tag={{
            name: value,
          }}
          style={{
            background: color.primary16,
            color: color.primary,
          }}
        />
      </div>
    );
  };

  return (
    <div
      className={classNames('FlightSegmentOverview', className)}
      style={{
        position: 'relative',
        marginBottom: 16,
        fontSize: 12,
        userSelect: 'none',
        ...style,
      }}
    >
      <div className="overview-rollup">
        <div className="title">
          Summary
        </div>

        {flightSummary.createdDate
          ? new moment(flightSummary.createdDate).format('[Updated] MMMM DD, YYYY [at] hh:mm:ss A')
          : ''}
      </div>

      <div className="location-rollup">
        {renderLocationRollup('companies')}
        {renderLocationRollup('banners')}
        {renderLocationRollup('locations')}
      </div>

      <div className="flight-rollup">
        <FlightRollupItem
          title="Target Impressions"
          loading={loading}
          total={get(flightSummary, 'targetImpressions', 0) || 0}
          totalAudio={get(flightSummary, 'mediaFormatSummary.audio.targetImpressions', 0) || 0}
          totalVisual={get(flightSummary, 'mediaFormatSummary.visual.targetImpressions', 0) || 0}
        />

        <FlightRollupItem
          title="Total Impressions"
          loading={loading}
          total={get(flightSummary, 'totalImpressions', 0) || 0}
          totalAudio={get(flightSummary, 'mediaFormatSummary.audio.totalImpressions', 0) || 0}
          totalVisual={get(flightSummary, 'mediaFormatSummary.visual.totalImpressions', 0) || 0}
        />

        <FlightRollupItem
          title="AV Impressions"
          loading={loading}
          total={get(flightSummary, 'totalAddedValueImpressions', 0) || 0}
          totalAudio={get(flightSummary, 'mediaFormatSummary.audio.totalAddedValueImpressions', 0) || 0}
          totalVisual={get(flightSummary, 'mediaFormatSummary.visual.totalAddedValueImpressions', 0) || 0}
        />

        <FlightRollupItem
          title="Total Spots"
          loading={loading}
          total={get(flightSummary, 'totalSpots', 0) || 0}
          totalAudio={get(flightSummary, 'mediaFormatSummary.audio.totalSpots', 0) || 0}
          totalVisual={get(flightSummary, 'mediaFormatSummary.visual.totalSpots', 0) || 0}
        />

        <FlightRollupItem
          title="Weighted CPM"
          loading={loading}
          total={get(flightSummary, 'weightedCpm', 0) || 0}
          totalAudio={get(flightSummary, 'mediaFormatSummary.audio.weightedCpm', 0) || 0}
          totalVisual={get(flightSummary, 'mediaFormatSummary.visual.weightedCpm', 0) || 0}
          currency
        />

        <FlightRollupItem
          style={{
            marginRight: 0,
          }}
          title="Total Budget"
          loading={loading}
          total={get(flightSummary, 'budget', 0) || 0}
          totalAudio={get(flightSummary, 'mediaFormatSummary.audio.totalBudget', 0) || 0}
          totalVisual={get(flightSummary, 'mediaFormatSummary.visual.totalBudget', 0) || 0}
          currency
        />
      </div>

      {user.can('flight_segment.view') && (
        <TableSegments
          columns={[
            'Name',
            'Media Format',
            'Segment Type',
            'Creative',
            'Start Date',
            'End Date',
            'Start Time',
            'End Time',
            'Budget',
            'CPM',
            'Target Impressions',
            'Total Impressions',
            'Spots',
            'Frequency',
            'Frequency Cap',
            'Spot Type',
            'Spot Selection Strategy',
            'Allocate Remainder',
            'Allow Duplicate Ads Back to Back',
            'Days of Week',
            'Created By',
            'Created Date',
            '...',
          ]}
          collection={flightSegments}
          defaultSortBy={{
            label: 'Name',
            attr: 'name',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Segments',
            urlPaging: true,
            urlFilters: true,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Segments',
            tooltip: true,
            onToggle: onToggleArchive,
          }}
          toolbarProps={{
            customButtons: flightData.active && unconfirmed && user.can('flight_segment.create')
              ? [
                (
                  <VibeButtonNew
                    key="button-new-segment"
                    text="New Segment"
                    icon={viAdd}
                    color={color.success}
                    link={NavigationHelper.updateParams({
                      type: 'new',
                      flightId: flightData._id,
                    })}
                  />
                ),
              ]
              : [],
          }}
          rowLink={{
            segmentId: '_id',
          }}
          menuItems={[
            {
              name: 'Archive',
              // Allow archive if the user has permission and the flight is unconfirmed
              userCan: () => user.can('flight_segment.delete')
                && flightData.status === 'unconfirmed'
                && flightData.active,
            },
          ]}
          csv
          csvProps={{
            onExport,
          }}
          // include segment summary data to show warnings and rollup information
          segmentSummaries={flightSummary.segments}
          adProvider={flightData.adProvider}
        />
      )}
    </div>
  );
}

FlightSegmentOverview.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

FlightSegmentOverview.defaultProps = {
  className: '',
  style: {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    socket: state.socket.connection,
  };
}

export default connect(mapStateToProps, null)(FlightSegmentOverview);
