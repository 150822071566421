import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  transparent,
} from '../../utils/ColorUtil';
import Avatar from '../Avatar/Avatar';
import RelativeTime from '../RelativeTime/RelativeTime';
import defaultUserImage from '../../assets/users.png';
import color from '../../sass/color.scss';
import './NoteMessage.scss';

function NoteMessage({
  className,
  style,
  // _id,
  userId,
  username,
  date,
  avatarUrl,
  // type,
  note,
  user,
}) {
  // is the message sent by this user?
  const isMyMessage = user._id === userId;

  return (
    <div
      className={classNames('NoteMessage', className)}
      style={{
        display: 'flex',
        ...style,
      }}
    >
      <Avatar
        style={{
          alignItems: 'flex-start',
          marginRight: 8,
        }}
        imageUrl={avatarUrl}
        imageDefault={defaultUserImage}
        imageSize={48}
      />

      <div
        style={{
          flexGrow: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div
            className="username"
            style={{
              fontSize: 14,
            }}
          >
            {username}
          </div>

          <RelativeTime
            style={{
              whiteSpace: 'nowrap',
              marginLeft: 8,
              fontSize: 12,
              color: color.manatee,
            }}
            time={date}
          />
        </div>

        <div
          className="message"
          style={{
            display: 'inline-block',
            margin: '8px 0',
            padding: 8,
            backgroundColor: isMyMessage
              ? transparent(color.primary, 16)
              : color.lightGray,
            borderRadius: 4,
            lineHeight: '24px',
          }}
        >
          {note}
        </div>
      </div>
    </div>
  );
}

NoteMessage.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  // _id: PropTypes.string,
  userId: PropTypes.string,
  username: PropTypes.string,
  date: PropTypes.string,
  avatarUrl: PropTypes.string,
  // type: PropTypes.string,
  note: PropTypes.string,
};

NoteMessage.defaultProps = {
  className: '',
  style: {},
  // _id: '',
  userId: '',
  username: 'Unknown',
  date: '',
  avatarUrl: '',
  // type: '',
  note: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(NoteMessage);
