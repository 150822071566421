import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  VibeButton,
  VibeModal,
  color,
  API,
} from 'vibeguide';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as moment from 'moment';
import './TokenTableRow.scss';

function TokenTableRow({
  className,
  user,
  userId,
  token,
  created,
  expires,
  filterToken,
}) {
  const [showDeactivateToken, setShowDeativateToken] = useState(false);

  const onDeactivateToken = () => {
    setShowDeativateToken(true);
  };

  const onConfirmDeactivation = async (e, token) => {
    e.stopPropagation();

    const removedTokenData = await API.IntegrationUser.deactivateToken(userId, { token });
    const { data } = removedTokenData[0];

    filterToken(data.token);

    setShowDeativateToken(false);
  };

  const onCancelDeactivation = () => {
    setShowDeativateToken(false);
  };

  // format start/end date strings
  const createdStr = moment(created).isValid() ? moment(created).format('ddd, MMM DD, YYYY') : '';
  const expiresStr = moment(expires).isValid() ? moment(expires).format('ddd, MMM DD, YYYY') : '';

  return (
    <div className={classNames('TokenTableRow', className)}>
      <div className="data-col token">
        {token}
      </div>

      <div className="data-col created-date">
        {createdStr}
      </div>

      <div className="data-col expire-date">
        {expiresStr}
      </div>

      <div className="data-col actions">
        {user.can('integration_user.manage_tokens') && expires != null && (
          <VibeButton
            text="Deactivate"
            btnColor="purple"
            textColor="white"
            variant="outlined"
            onClick={() => onDeactivateToken(token)}
          />
        )}
      </div>

      <VibeModal
        show={showDeactivateToken}
        type="confirm"
        title="Deactivating Tokens"
        confirmProps={{
          text: 'Deactivate',
          color: color.primary,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={(e) => onConfirmDeactivation(e, token)}
        onClose={onCancelDeactivation}
      >
        Deactivating a token will make that token unusable.
        Any systems using that token will be unable to communicate with Vibenomics.
        Only deactivate a token once all systems using it have been updated to use a newer active token.<br /><br />
        Are you sure you want to deactivate this token?
      </VibeModal>
    </div>
  );
}

TokenTableRow.propTypes = {
  className: PropTypes.string,
  token: PropTypes.string,
  created: PropTypes.string,
  expires: PropTypes.string,
};

TokenTableRow.defaultProps = {
  className: '',
  token: '',
  created: '',
  expires: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(TokenTableRow);
