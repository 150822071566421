import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  isEmpty,
} from 'lodash';
import API from '../../../api';
import TargetTag from '../TargetTag';
import Field from '../../Field/Field2';
import VibeButton from '../../VibeButton/VibeButton';
import VibeModal from '../../VibeModal/VibeModal';
import color from '../../../sass/color.scss';
import './DeviceAttributeAddItem.scss';

class DeviceAttributeAddItem extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
      name,
      value,
    } = props;

    this.state = {
      name,
      value,
      showConfirmArchive: false,
      canArchive: user.can('device_attribute.delete'),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      name,
      value,
    } = this.props;

    const {
      id: prevId,
    } = prevProps;

    if (id !== prevId) {
      this.setState({
        name,
        value,
      });
    }
  }

  /**
   * When the name/value changes
   */
  onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  /**
   * Save the Device Attribute
   */
  onSave = async () => {
    const {
      id,
      onSave,
    } = this.props;

    const {
      name,
      value,
    } = this.state;

    const response = id
      ? await API.DeviceAttribute.update({
        _id: id,
        name,
        value,
      })
      : await API.DeviceAttribute.create({
        name,
        value,
      });

    const responseId = get(response, '[0].documentId');
    const responseType = get(response, '[0].type');

    if (responseType === 'DEVICEATTRIBUTE.CREATED' || responseType === 'DEVICEATTRIBUTE.UPDATED') {
      onSave({
        _id: responseId,
        name,
        value,
      });
    }
  };

  onArchive = () => {
    this.setState({
      showConfirmArchive: true,
    });
  };

  onConfirmArchive = async () => {
    const {
      id,
      onArchive,
    } = this.props;

    const response = await API.DeviceAttribute.deactivate(id);
    const isSuccess = get(response, '[0].documentId', null);

    if (isSuccess) {
      this.setState({
        showConfirmArchive: false,
      });

      onArchive(id);
    }
  };

  onCancelArchive = () => {
    this.setState({
      showConfirmArchive: false,
    });
  };

  render() {
    const {
      className,
      id,
      allowModify,
      onClose,
    } = this.props;

    const {
      name,
      value,
      showConfirmArchive,
      canArchive,
    } = this.state;

    const nameStr = name || 'Attribute Name';
    const valueStr = value || 'Value';
    const disabled = !isEmpty(id) && !allowModify;

    return (
      <div className={classNames('DeviceAttributeAddItem', className)}>
        <div className="attribute-section">
          <TargetTag
            qualifiedName={`${nameStr} : ${valueStr}`}
            allowCopy
          />
        </div>

        <div className="attribute-section section-content">
          <Field
            type="text"
            label="Attribute Name"
            placeholder="Name"
            name="name"
            marginBottom={16}
            tabIndex={1}
            value={name}
            disabled={disabled}
            autoFocus
            onChange={this.onChange}
          />

          <Field
            type="text"
            label="Value"
            placeholder="Value"
            name="value"
            tabIndex={2}
            value={value}
            disabled={disabled}
            onChange={this.onChange}
          />
        </div>

        <div className="attribute-section section-footer">
          <VibeButton
            className="btn-save"
            text="Save Attribute"
            btnColor="green"
            textColor="white"
            disabled={(!name || !value) || disabled}
            onClick={this.onSave}
          />

          {canArchive ? (
            <VibeButton
              className="btn-archive"
              text="Archive"
              btnColor="red"
              textColor="white"
              onClick={this.onArchive}
            />
          ) : null}

          <VibeButton
            text="Cancel"
            btnColor="transparent"
            textColor="manatee"
            onClick={onClose}
          />
        </div>

        <VibeModal
          show={showConfirmArchive}
          type="confirm"
          title="Archive"
          text={`Are you sure you want to archive ${name}:${value}?`}
          confirmProps={{
            text: 'Archive',
            color: color.error,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );
  }
}

DeviceAttributeAddItem.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Attribute ID */
  id: PropTypes.string,
  /** Attribute name */
  name: PropTypes.string,
  /** Attribute value */
  value: PropTypes.string,
  /** Allow modification of attributes */
  allowModify: PropTypes.bool,
  /** When the attribute is saved */
  onSave: PropTypes.func,
  /** When the attribute is archived */
  onArchive: PropTypes.func,
  /** When the new attribute is closed */
  onClose: PropTypes.func,
};

DeviceAttributeAddItem.defaultProps = {
  className: '',
  id: '',
  name: '',
  value: '',
  allowModify: false,
  onSave: () => {},
  onArchive: () => {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(DeviceAttributeAddItem);
