import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  find,
} from 'lodash';
import {
  API,
  Field2 as Field,
  VibeButtonNew,
  VibeModal,
  viAdd,
  color,
} from 'vibeguide';
import ThirdPartyDataRow from './ThirdPartyDataRow';
import './ThirdPartyData.scss';

class ThirdPartyData extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    this.state = {
      canViewThirdPartyData: user.can('location.view_third_party_data'),
      canManageThirdPartyData: user.can('location.manage_third_party_data'),
      thirdPartyProviders: [],
      showAddId: false,
      showCollisionModal: false,
    };
  }

  componentDidMount() {
    const {
      canViewThirdPartyData,
    } = this.state;

    if (canViewThirdPartyData) {
      this.getThirdPartyProviders();
    }
  }

  /**
   * When a data row is changed
   */
  onSaveThirdPartyDataRow = ({
    originalData,
    data,
  }) => {
    const {
      thirdPartyData,
      onUpdate,
    } = this.props;

    const {
      thirdPartyProviderName,
    } = this.state;

    // only get the data for the selected provider
    const useThirdPartyData = get(thirdPartyData, 'data', []).filter(item => item.provider === thirdPartyProviderName);

    // get the data item being edited
    const thirdPartyDataItem = find(useThirdPartyData, {
      externalId: originalData.externalId,
      startDate: originalData.startDate,
      endDate: originalData.endDate,
    });

    if (!thirdPartyDataItem) {
      console.error('Could not find third party data', originalData.externalId);
      return;
    }

    let newData = get(thirdPartyData, 'data', []).map((item) => {
      if (item === thirdPartyDataItem) {
        return {
          ...item,
          ...data,
        };
      }

      return item;
    });

    const valid = this.validateItems(newData);

    newData = get(thirdPartyData, 'data', []).map((item) => {
      if (item === thirdPartyDataItem) {
        return {
          ...item,
          ...data,
          hasError: !valid,
        };
      }

      return item;
    });

    onUpdate({
      thirdPartyData: {
        ...thirdPartyData,
        data: newData,
      },
      thirdPartyDataValid: valid,
    });

    this.setState({
      showCollisionModal: !valid,
    });
  };

  /**
   * When a data row is added
   */
  onAddThirdPartyDataRow = ({
    data,
  }) => {
    const {
      thirdPartyData,
      onUpdate,
    } = this.props;

    const {
      thirdPartyProviderName,
    } = this.state;

    const newData = [
      ...get(thirdPartyData, 'data', []),
      {
        provider: thirdPartyProviderName,
        ...data,
      },
    ];

    const valid = this.validateItems(newData);

    onUpdate({
      thirdPartyData: {
        ...thirdPartyData,
        data: newData,
      },
      thirdPartyDataValid: valid,
    });

    this.setState({
      showAddId: false,
      showCollisionModal: !valid,
    });
  };

  /**
   * When the data row changes are cancelled, revert to the original data
   */
  onCancelEditThirdPartyDataRow = () => {
    const {
      thirdPartyData,
      onUpdate,
    } = this.props;

    const newData = get(thirdPartyData, 'data', []).map((item) => {
      return {
        ...item,
        hasError: false,
      };
    });

    onUpdate({
      thirdPartyData: {
        ...thirdPartyData,
        data: newData,
      },
    });
  };

  /**
   * When a data row is removed
   */
  onRemoveThirdPartyDataRow = ({
    externalId,
    startDate,
    endDate,
  }) => {
    const {
      thirdPartyData,
      onUpdate,
    } = this.props;

    const {
      thirdPartyProviderName,
    } = this.state;

    // only get the data for the selected provider
    const useThirdPartyData = get(thirdPartyData, 'data', []).filter(item => item.provider === thirdPartyProviderName);

    // get the data item being edited
    const thirdPartyDataItem = find(useThirdPartyData, {
      externalId,
      startDate,
      endDate,
    });

    if (!thirdPartyDataItem) {
      console.error('Could not find third party data', externalId);
      return;
    }

    const newData = get(thirdPartyData, 'data', []).filter(item => item !== thirdPartyDataItem);
    const valid = this.validateItems(newData);

    onUpdate({
      thirdPartyData: {
        ...thirdPartyData,
        data: newData,
      },
      thirdPartyDataValid: valid,
    });
  };

  /**
   * When third party provider is changed
   */
  onChangeThirdPartyProviderName = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const {
      onChangeThirdPartyProvider,
    } = this.props;

    this.setState({
      thirdPartyProviderName: value,
    });

    onChangeThirdPartyProvider(value);
  };

  /**
   * When the Add ID button is clicked
   */
  onClickAddId = () => {
    this.setState({
      showAddId: true,
    });
  };

  /**
   * Remove the row before it's added
   */
  onCancelAddThirdPartyDataRow = () => {
    this.setState({
      showAddId: false,
    });
  };

  /**
   * Close the collision modal
   */
  onCloseCollisionModal = () => {
    this.setState({
      showCollisionModal: false,
    });
  };

  /**
   * Get Third Party Providers
   */
  getThirdPartyProviders = async () => {
    const {
      onChangeThirdPartyProvider,
    } = this.props;

    const thirdPartyProviders = await API.ThirdParty.listProviders();
    const thirdPartyProviderName = get(thirdPartyProviders, '[0].key');

    this.setState({
      thirdPartyProviders,
      thirdPartyProviderName,
    });

    onChangeThirdPartyProvider(thirdPartyProviderName);
  };

  /**
   * Validate third party data is not overlapping
   */
  validateItems = (data) => {
    const {
      thirdPartyProviderName,
    } = this.state;

    let valid = true;

    // only get the data for the selected provider
    const thirdPartyData = data.filter(item => item.provider === thirdPartyProviderName);

    thirdPartyData.forEach((item) => {
      const startDate = new moment(item.startDate);
      const endDate = new moment(item.endDate);

      // check other items for overlapping dates
      const otherData = thirdPartyData.filter(otherItem => otherItem !== item);

      if (otherData.length <= 0) {
        // no other items to check for collisions
        if (startDate.isSameOrAfter(endDate)) {
          valid = false;
        }
      }

      otherData.forEach((otherItem) => {
        if (item.startDate === otherItem.startDate
          || item.endDate === otherItem.endDate
          || item.startDate === otherItem.endDate
          || item.endDate === otherItem.startDate
          || startDate.isBetween(otherItem.startDate, otherItem.endDate)
          || endDate.isBetween(otherItem.startDate, otherItem.endDate)
          || startDate.isSameOrAfter(endDate)
        ) {
          valid = false;
          item.hasError = !valid;
        }
      });
    });

    return valid;
  };

  render() {
    const {
      className,
      thirdPartyData,
    } = this.props;

    const {
      canViewThirdPartyData,
      canManageThirdPartyData,
      thirdPartyProviders,
      thirdPartyProviderName,
      showAddId,
      showCollisionModal,
    } = this.state;

    // user needs to be able to view third party data
    if (!canViewThirdPartyData) {
      return null;
    }

    // only get the data for the selected provider
    const useThirdPartyData = get(thirdPartyData, 'data', []).filter(item => item.provider === thirdPartyProviderName);

    return (
      <div className={classNames('ThirdPartyData', className)}>
        <div className="content-blocks">
          <div className="content-block content-third-party">

            <div className="data-row location-data">
              <Field
                rootClassName="location-input location-provider"
                type="select"
                label="Third Party Provider"
                name="name"
                value={thirdPartyProviderName}
                options={thirdPartyProviders.map((provider) => {
                  return {
                    label: provider.name,
                    value: provider.key,
                  };
                })}
                disabled={!canViewThirdPartyData}
                onChange={this.onChangeThirdPartyProviderName}
              />
            </div>

            <div className="third-party-items">
              {useThirdPartyData.length > 0 || showAddId ? (
                <ThirdPartyDataRow
                  header
                />
              ) : null}

              {useThirdPartyData.map((item, index) => {
                return (
                  <ThirdPartyDataRow
                    key={`row-${item.externalId}-${index}`}
                    canManage={canManageThirdPartyData}
                    thirdPartyProviderName={thirdPartyProviderName}
                    externalId={item.externalId}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    error={item.hasError}
                    onCancel={this.onCancelEditThirdPartyDataRow}
                    onRemove={this.onRemoveThirdPartyDataRow}
                    onSave={this.onSaveThirdPartyDataRow}
                  />
                );
              })}

              {showAddId ? (
                <ThirdPartyDataRow
                  edit
                  isNew
                  canManage={canManageThirdPartyData}
                  thirdPartyProviderName={thirdPartyProviderName}
                  onRemove={this.onCancelAddThirdPartyDataRow}
                  onSave={this.onAddThirdPartyDataRow}
                />
              ) : null}
            </div>

            {canManageThirdPartyData && !showAddId ? (
              <div className="data-row add-row">
                <VibeButtonNew
                  variant="text"
                  text="ADD ID"
                  color={color.primary}
                  icon={viAdd}
                  onClick={this.onClickAddId}
                />
              </div>
            ) : null}
          </div>
        </div>

        <VibeModal
          show={showCollisionModal}
          type="error"
          text="Third party data dates cannot overlap another item from the same provider."
          onClose={this.onCloseCollisionModal}
        />
      </div>
    );
  }
}

ThirdPartyData.propTypes = {
  className: PropTypes.string,
  thirdPartyData: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onChangeThirdPartyProvider: PropTypes.func,
  onUpdate: PropTypes.func,
};

ThirdPartyData.defaultProps = {
  className: '',
  thirdPartyData: {},
  onChangeThirdPartyProvider: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(ThirdPartyData);
