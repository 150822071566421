import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  Field2 as Field,
  VibeTooltip,
  VibeIcon,
  viCheck,
  viAlert,
  viTrash,
  color,
} from 'vibeguide';
import './TargetLocation.scss';

function TargetLocation({
  className,
  companyId,
  locationId,
  // affectedAdPrograms,
  earliestEffectiveDate,
  name,
  warnings,
  onRemove,
  onUpdate,
}) {
  const [effectiveDate, setEffectiveDate] = useState(earliestEffectiveDate);

  /**
   * When the effective date is changed
   */
  const onChangeEffectiveDate = (date) => {
    onUpdate({
      companyId,
      locationId,
      effectiveDate: date,
    });

    setEffectiveDate(date);
  };

  /**
   * Remove the location from the copy
   */
  const onRemoveLocation = () => {
    onRemove({
      companyId,
      locationId,
    });
  };

  return (
    <div className={classNames('TargetLocation', className)}>
      <VibeTooltip
        placement="top"
        title={warnings.length > 0 ? (
          <div>
            {warnings.map((warning, index) => (
              <div
                key={`warning-${index}`}
                style={{
                  margin: 4,
                }}
              >
                {warning}
              </div>
            ))}
          </div>
        ) : ''}
      >
        <div className={classNames('content', { warning: warnings.length > 0 })}>
          <VibeIcon
            icon={warnings.length > 0
              ? viAlert
              : viCheck}
            color={warnings.length > 0
              ? color.orange
              : color.success}
            size={16}
            style={{
              marginRight: 8,
            }}
          />

          <div className="title">
            {name || 'Unknown'}
          </div>

          <Field
            type="datepicker"
            placeholder="YYYY-MM-DD"
            value={effectiveDate}
            dateProps={{
              format: 'YYYY-MM-DD',
              minDate: moment(earliestEffectiveDate).toDate(),
              tooltip: !earliestEffectiveDate
                ? 'Due to this location not having an earliest effective date, one cannot be set.'
                : '',
            }}
            disabled={!earliestEffectiveDate}
            onChange={onChangeEffectiveDate}
            required
          />

          <VibeIcon
            icon={viTrash}
            color={color.obsidian}
            hoverColor={color.error}
            size={16}
            style={{
              marginLeft: 8,
            }}
            tooltip="Do not copy this location"
            tooltipProps={{
              placement: 'right',
            }}
            onClick={onRemoveLocation}
          />
        </div>
      </VibeTooltip>
    </div>
  );
}

TargetLocation.propTypes = {
  className: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  // affectedAdPrograms: PropTypes.arrayOf(PropTypes.shape({
  //   _id: PropTypes.string,
  //   startDate: PropTypes.string,
  //   endDate: PropTypes.string,
  // })),
  earliestEffectiveDate: PropTypes.string,
  name: PropTypes.string,
  warnings: PropTypes.arrayOf(PropTypes.string),
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
};

TargetLocation.defaultProps = {
  className: '',
  // affectedAdPrograms: [],
  earliestEffectiveDate: '',
  name: '',
  warnings: [],
  onRemove: () => {},
  onUpdate: () => {},
};

export default TargetLocation;
