import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isNumber,
  toNumber,
} from 'lodash';
import {
  ColorUtil,
  Field2 as Field,
  VibeIcon,
  viLock,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function SegmentCalculator({
  className,
  style,
  spotSelectionStrategy,
  cpm,
  budget,
  impressions,
  spotsPerHour,
  locked,
  disableInput,
  onUpdate,
  segmentType,
}) {
  const [disableFields, setDisableFields] = useState({
    cpm: false,
    budget: false,
    impressions: false,
  });

  const prevSpotSelectionStrategy = useRef(spotSelectionStrategy);

  const allCalculatorFieldsSet = cpm > 0
    && budget > 0
    && impressions > 0
    && (segmentType === 'standard' || segmentType === 'added-value');

  /**
   * Get the name of the disabled field (if one is disabled)
   * Used to tell the user which field will be computed automatically when "Calculate" is clicked
   */
  const getDisabledFieldName = () => {
    if (disableFields.cpm && disableFields.budget && segmentType === 'added-value') {
      return 'CPM and Budget';
    }

    if (disableFields.cpm) {
      return 'CPM';
    }

    if (disableFields.budget) {
      return 'Budget';
    }

    if (disableFields.impressions) {
      return 'Target Impressions';
    }

    return null;
  };

  const disabledFieldName = getDisabledFieldName();

  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      [name]: value,
    });
  };

  const onChangeCurrency = ({
    name,
    value,
  }) => {
    onUpdate({
      [name]: value,
    });
  };

  /**
   * When the impressions field is changed
   */
  const onBlurImpressions = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    onUpdate({
      impressions: toNumber(value.replace(/\D/g, '')),
    });
  };

  /**
   * Get the value of the impression field
   */
  const getImpressionValue = () => {
    if (isNumber(impressions) && impressions > 0) {
      // format the impressions
      return impressions.toLocaleString();
    }

    if (isNumber(impressions) && impressions <= 0) {
      // do not show a value for 0 impressions
      return '';
    }

    // show whatever is set as the impression value
    return impressions;
  };

  /**
   * Unlock the calculator
   */
  const onClickUnlock = () => {
    onUpdate({
      lockCalculator: false,
    });
  };

  /**
   * Determine whether a field needs to be disabled
   * When 2 of the 3 fields (CPM, Budget, Impressions are filled), disable the last to be calculated by the API
   */
  useEffect(() => {
    let unsetFieldCount = 0;
    if (cpm <= 0) {
      unsetFieldCount += 1;
    }

    if (budget <= 0) {
      unsetFieldCount += 1;
    }

    if (impressions <= 0) {
      unsetFieldCount += 1;
    }

    if (unsetFieldCount === 1) {
      // only one field is left unset, disable it so it can be calculated by the API
      if (cpm <= 0) {
        setDisableFields({
          cpm: true,
          budget: false,
          impressions: false,
        });
      } else if (budget <= 0) {
        setDisableFields({
          cpm: false,
          budget: true,
          impressions: false,
        });
      } else if (impressions <= 0) {
        setDisableFields({
          cpm: false,
          budget: false,
          impressions: true,
        });
      }
    } else if (segmentType === 'added-value') {
      setDisableFields({
        cpm: true,
        budget: true,
        impressions: false,
      });
    } else {
      // more than one field is still unset, ensure all fields are enabled
      setDisableFields({
        cpm: false,
        budget: false,
        impressions: false,
      });
    }
  }, [cpm, budget, impressions, segmentType]);

  useEffect(() => {
    if (prevSpotSelectionStrategy.current !== spotSelectionStrategy) {
      if (spotSelectionStrategy === 'spots-per-hour') {
        onUpdate({
          cpm: '',
          impressions: '',
        });
      } else {
        onUpdate({
          spotsPerHour: '',
        });
      }
      prevSpotSelectionStrategy.current = spotSelectionStrategy;
    }
  }, [spotSelectionStrategy]);

  const spotsPeriodCheck = (event) => {
    if (event.target.name === 'spotsPerHour') {
      if (event.key === '.') {
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', spotsPeriodCheck);

    return () => {
      document.removeEventListener('keypress', spotsPeriodCheck);
    };
  }, []);

  return (
    <div
      className={classNames('SegmentCalculator', className)}
      style={style}
    >
      <div
        className="details-title"
        style={{
          marginTop: 16,
          marginBottom: 8,
        }}
      >
        Calculator
      </div>

      <div
        className="calculator-container"
        style={{
          position: 'relative',
          padding: 16,
          border: `1px solid ${color.whiteSmoke}`,
        }}
      >
        {spotSelectionStrategy !== 'spots-per-hour' ? (
          <Grid spacing={2} container>
            {segmentType === 'standard' && (
              <Grid xs={6} item>
                <Field
                  type="dollar"
                  label="CPM"
                  name="cpm"
                  value={cpm}
                  disabled={disableInput || locked || disableFields.cpm}
                  tabIndex={29}
                  required={!disableFields.cpm}
                  onChange={onChangeCurrency}
                />
              </Grid>
            )}

            {segmentType === 'standard' && (
              <Grid xs={6} item>
                <Field
                  type="dollar"
                  label="Budget"
                  name="budget"
                  value={budget}
                  disabled={disableInput || locked || disableFields.budget}
                  tabIndex={30}
                  required={!disableFields.budget}
                  onChange={onChangeCurrency}
                />
              </Grid>
            )}

            <Grid xs={6} item>
              <Field
                type="text"
                label="Target Impressions"
                name="impressions"
                placeholder="Impressions"
                value={getImpressionValue()}
                disabled={disableInput || locked || disableFields.impressions}
                tabIndex={31}
                required={!disableFields.impressions}
                onChange={onChange}
                onBlur={onBlurImpressions}
              />
            </Grid>

            {!locked && disabledFieldName && segmentType === 'standard' && (
              <Grid xs={6} item>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div
                    style={{
                      marginBottom: 4,
                      fontWeight: 'bold',
                    }}
                  >
                    {disabledFieldName}
                  </div>

                  will be calculated automatically on save
                </div>
              </Grid>
            )}

            {!locked && allCalculatorFieldsSet && segmentType === 'standard' && (
              <Grid xs={6} item>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    color: color.error,
                  }}
                >
                  You must provide exactly two of the following values: Budget, CPM, Impressions
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid spacing={2} container>
            <Grid xs={6} item>
              <Field
                type="number"
                label="Spots Per Hour"
                name="spotsPerHour"
                value={spotsPerHour}
                tabIndex={32}
                onChange={onChange}
                required
              />
            </Grid>
            {segmentType === 'standard' && (
              <Grid xs={6} item>
                <Field
                  type="dollar"
                  label="Budget"
                  name="budget"
                  value={budget}
                  disabled={disableInput || locked || disableFields.budget}
                  tabIndex={33}
                  required={!disableFields.budget}
                  onChange={onChangeCurrency}
                />
              </Grid>
            )}

            {segmentType === 'standard' && (
              <Grid xs={6} item>
                <Field
                  type="dollar"
                  label="CPM"
                  name="cpm"
                  value={cpm}
                  tabIndex={34}
                  onChange={onChangeCurrency}
                  disabled
                />
              </Grid>
            )}

            <Grid xs={6} item>
              <Field
                type="text"
                label="Target Impressions"
                name="impressions"
                placeholder="Impressions"
                value={getImpressionValue()}
                tabIndex={35}
                onChange={onChange}
                onBlur={onBlurImpressions}
                disabled
              />
            </Grid>

          </Grid>
        )}

        {locked && (
          <div
            className="locked"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: ColorUtil.transparent(color.obsidian, 32),
            }}
          >
            <VibeIcon
              icon={viLock}
              type="button"
              buttonProps={{
                size: 32,
                color: color.white,
                borderColor: color.obsidian,
              }}
              tooltip="Unlock Calculator"
              color={color.obsidian}
              size={20}
              onClick={onClickUnlock}
            />
          </div>
        )}
      </div>
    </div>
  );
}

SegmentCalculator.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  spotSelectionStrategy: PropTypes.string,
  cpm: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  budget: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  impressions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  spotsPerHour: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /** lock the segment calculator to allow saving if all 3 fields are populated */
  locked: PropTypes.bool,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
  segmentType: PropTypes.string,
};

SegmentCalculator.defaultProps = {
  className: '',
  spotSelectionStrategy: '',
  style: {},
  cpm: 0,
  budget: 0,
  impressions: 0,
  spotsPerHour: 0,
  locked: false,
  disableInput: false,
  onUpdate: () => {},
  segmentType: 'standard',
};

export default SegmentCalculator;
