import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  toInteger,
} from 'lodash';
import {
  ColorUtil,
  GlobalActions,
  Field2 as Field,
  VibeIcon,
  viDragIndicator,
  viClose,
  color,
} from 'vibeguide';

let currentY = 0;

function LoopTemplateItem({
  className,
  style,
  item,
  disableInput,
  dragging,
  onUpdate,
  onRemove,
  onChangeOrder,
  setDrag,
  resetDrag,
}) {
  const [dropAt, setDropAt] = useState(null);
  const itemRef = useRef(null);

  const onDragStart = (e) => {
    const {
      dataTransfer,
    } = e;

    dataTransfer.setData('data', JSON.stringify(item));

    setDrag({
      dragging: true,
      dragType: 'Loop Template',
    });
  };

  const onDragEnd = () => {
    resetDrag();
    setDropAt(null);
  };

  /**
   * Dragging over the droppable element
   */
  const onDragOver = (e) => {
    e.preventDefault();

    const {
      clientY,
    } = e;

    if (currentY === clientY) {
      return;
    }

    const itemRect = itemRef.current.getBoundingClientRect();
    const {
      top,
      height,
    } = itemRect;

    // get the halfway mark for the cell
    const halfHeight = height / 2;
    const halfway = top + halfHeight;
    const isTopHalf = clientY <= halfway;

    if (isTopHalf && dropAt !== 'above') {
      setDropAt('above');
    } else if (!isTopHalf && dropAt !== 'below') {
      setDropAt('below');
    }

    currentY = clientY;
  };

  /**
   * When the cursor is no longer dragging over the element
   */
  const onDragLeave = () => {
    setDropAt(null);
  };

  /**
   * Drop an item
   */
  const onDrop = (e) => {
    const {
      dataTransfer,
    } = e;

    const dropData = JSON.parse(dataTransfer.getData('data'));

    if (item._id === dropData._id) {
      // do not allow dropping in the same position
      console.warn('Cannot drop an item in the same spot');
      return;
    }

    setDropAt(null);

    onChangeOrder({
      dropItem: dropData,
      onItem: item,
      at: dropAt,
    });
  };

  /**
   * When an item is changed
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    onUpdate({
      ...item,
      [name]: value,
    });
  };

  /**
   * User changes the number input box
   */
  const onChangeNumber = ({
    target: {
      name,
      value,
    },
  }) => {
    onUpdate({
      ...item,
      [name]: toInteger(value),
    });
  };

  /**
   * Remove an item
   */
  const onClickRemoveItem = () => {
    onRemove(item._id);
  };

  return (
    <div
      className={classNames('LoopTemplateItem', className)}
      style={{
        ...style,
        position: 'relative',
      }}
    >
      <div
        ref={itemRef}
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          margin: '4px 0',
        }}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        // onDragOver={onDragOver}
        // onDragLeave={onDragLeave}
        draggable
      >
        <VibeIcon
          icon={viDragIndicator}
          color={color.manatee}
          hoverColor={color.obsidian}
          size={24}
        />

        <div
          style={{
            flexGrow: 1,
          }}
        >
          <Field
            type="text"
            name="name"
            placeholder="Name"
            value={item.name}
            onChange={onChange}
          />
        </div>

        <Field
          rootStyle={{
            marginLeft: 8,
          }}
          type="select"
          name="itemType"
          value={item.itemType}
          options={[
            {
              label: '',
              value: '',
            },
            {
              label: 'Ad',
              value: 'ad',
            },
            {
              label: 'Static',
              value: 'static',
            },
          ]}
          disabled={disableInput}
          onChange={onChange}
        />

        <Field
          rootStyle={{
            width: 60,
            marginLeft: 8,
          }}
          type="number"
          name="durationSeconds"
          value={item.durationSeconds}
          numberProps={{
            min: 0,
          }}
          disabled={disableInput}
          onChange={onChangeNumber}
        />

        <VibeIcon
          style={{
            marginLeft: 8,
          }}
          icon={viClose}
          color={color.error}
          hoverColor={ColorUtil.transparent(color.error, 50)}
          tooltip="Remove"
          tooltipProps={{
            placement: 'top',
          }}
          onClick={onClickRemoveItem}
        />

        {dragging && (
          <div
            className="drag-overlay"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 2,
            }}
            onDragOver={onDragOver}
            // onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          />
        )}
      </div>

      {dropAt === 'above' && (
        <div
          style={{
            position: 'absolute',
            top: -3,
            left: -8,
            right: -8,
            height: 2,
            backgroundColor: color.primary,
          }}
        />
      )}

      {dropAt === 'below' && (
        <div
          style={{
            position: 'absolute',
            bottom: -3,
            left: -8,
            right: -8,
            height: 2,
            backgroundColor: color.primary,
          }}
        />
      )}
    </div>
  );
}

LoopTemplateItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  item: PropTypes.object,
  disableInput: PropTypes.bool,
  onUpdate: PropTypes.func,
  onRemove: PropTypes.func,
  onChangeOrder: PropTypes.func,
};

LoopTemplateItem.defaultProps = {
  className: '',
  style: {},
  item: {},
  disableInput: false,
  onUpdate: () => {},
  onRemove: () => {},
  onChangeOrder: () => {},
};

function mapStateToProps(state) {
  return {
    dragging: state.global.drag.dragging,
  };
}

const mapDispatchToProps = {
  setDrag: GlobalActions.setDrag,
  resetDrag: GlobalActions.resetDrag,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoopTemplateItem);
