import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  isEmpty,
} from 'lodash';
import {
  API,
  MimeTypeHelper,
  Field2 as Field,
  PlayControlPlayer,
  ProgressBar,
  VibeButton,
  VibeIcon,
  viClose,
  color,
} from 'vibeguide';
import './UploadCut.scss';

class UploadCut extends PureComponent {
  constructor(props) {
    super(props);

    const {
      messageType,
    } = props;

    this.state = {
      cutType: messageType === 'ext-ad'
        ? 'final'
        : 'rough',
      file: null,
      fileData: null,
      progress: 0,
    };
  }

  onDrop = (fileObj) => {
    const file = get(fileObj, '[0]', {
      file: null,
    });

    if (file && file.error) {
      console.warn('File has an error', fileObj);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const {
        target: {
          result,
        },
      } = e;

      // Save file data to state to show as a preview
      this.setState({
        file: file.file,
        fileData: result,
      });
    };

    reader.onabort = () => console.error('file reading was aborted');
    reader.onerror = () => console.error('file reading has failed');

    reader.readAsDataURL(file.file);
  };

  onSave = async () => {
    const {
      messageId,
      onClose,
    } = this.props;

    const {
      cutType,
      file,
    } = this.state;

    const uploadResponse = cutType === 'rough'
      ? await API.MessageRequest.uploadRough(messageId, file, (progress) => {
        this.setState({
          progress,
        });
      })
      : await API.MessageRequest.uploadFinal(messageId, file, (progress) => {
        this.setState({
          progress,
        });
      });

    const uploadId = get(uploadResponse, '[0].documentId', null);

    if (uploadId) {
      onClose();
    }
  };

  setCutRough = () => {
    this.setState({
      cutType: 'rough',
    });
  };

  setCutFinal = () => {
    this.setState({
      cutType: 'final',
    });
  };

  render() {
    const {
      user,
      voiceTalentId,
      messageType,
      onClose,
    } = this.props;

    const {
      cutType,
      file,
      fileData,
      progress,
    } = this.state;

    const isVoiceTalent = user.voiceTalent && user._id === voiceTalentId;
    let placeholderHtml;

    if (!isEmpty(file)) {
      placeholderHtml = (
        <div className="upload-main">
          <PlayControlPlayer
            src={fileData}
            size={16}
          />

          <div className="upload-name">
            {file.name}
          </div>
        </div>
      );
    } else {
      placeholderHtml = (
        <div className="upload-main">
          Upload a new audio file
        </div>
      );
    }

    return (
      <div className="UploadCut">
        <div className="header">
          <div className="title">
            Please select the cut type
          </div>

          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        </div>

        <div className="content">
          <Field
            type="radio"
            label="Rough Cut"
            radioProps={{
              selected: cutType === 'rough',
            }}
            disabled={messageType === 'ext-ad'}
            onClick={this.setCutRough}
          />

          <Field
            type="radio"
            label="Final Cut"
            radioProps={{
              selected: cutType === 'final',
            }}
            disabled={isVoiceTalent}
            onClick={this.setCutFinal}
          />
        </div>

        <div className="footer">
          <VibeButton
            text="Upload"
            btnColor="green"
            textColor="white"
            disabled={isEmpty(file)}
            onClick={this.onSave}
          />

          <div className="upload-container">
            <Field
              type="dropzone"
              dropzoneProps={{
                type: 'document',
                style: {
                  height: 32,
                  minHeight: 'auto',
                },
                placeholder: placeholderHtml,
                allowUpload: true,
                mimeTypes: MimeTypeHelper.music,
              }}
              onDrop={this.onDrop}
            />

            <ProgressBar
              className="progress-bar"
              progress={progress}
              color={color.success}
              height={4}
            />
          </div>
        </div>
      </div>
    );
  }
}

UploadCut.propTypes = {
  messageId: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  voiceTalentId: PropTypes.string,
  onClose: PropTypes.func,
};

UploadCut.defaultProps = {
  voiceTalentId: null,
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(UploadCut);
