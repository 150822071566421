import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  sortDays,
} from '../../utils/TimeUtil';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import DaySelectorDay from './DaySelectorDay';
import color from '../../sass/color.scss';
import './DaySelector.scss';

/**
 * Format the day string
 */
function formatDay(dayFormat, dayStr) {
  if (dayFormat === 'dd') {
    switch (dayStr) {
      case 'Sun':
      case 'Sunday':
        return 'SU';
      case 'Mon':
      case 'Monday':
        return 'MO';
      case 'Tue':
      case 'Tuesday':
        return 'TU';
      case 'Wed':
      case 'Wednesday':
        return 'WE';
      case 'Thu':
      case 'Thursday':
        return 'TH';
      case 'Fri':
      case 'Friday':
        return 'FR';
      case 'Sat':
      case 'Saturday':
        return 'SA';
      default:
        return dayStr;
    }
  } else if (dayFormat === 'dddd') {
    switch (dayStr) {
      case 'SU':
      case 'Sun':
        return 'Sunday';
      case 'MO':
      case 'Mon':
        return 'Monday';
      case 'TU':
      case 'Tue':
        return 'Tuesday';
      case 'WE':
      case 'Wed':
        return 'Wednesday';
      case 'TH':
      case 'Thu':
        return 'Thursday';
      case 'FR':
      case 'Fri':
        return 'Friday';
      case 'SA':
      case 'Sat':
        return 'Saturday';
      default:
        return dayStr;
    }
  }

  // defaults to 3 letter day codes
  return dayStr;
}

function DaySelector({
  className,
  style,
  selectedColor,
  dayFormat,
  disabled,
  selectedDays: propSelectedDays,
  toggleAll,
  onChange,
}) {
  const formatSelectedDays = propSelectedDays.map(day => formatDay(dayFormat, day));
  const [selectedDays, setSelectedDays] = useState(formatSelectedDays);

  // selected days have changed
  useEffect(() => {
    setSelectedDays(propSelectedDays.map(day => formatDay(dayFormat, day)));
  }, [propSelectedDays]);

  const selectDay = (dayStr) => {
    if (disabled) {
      return;
    }

    // format the day based on the dayFormat
    const day = formatDay(dayFormat, dayStr);
    const selected = selectedDays.includes(day);
    const days = selected
      ? selectedDays.filter(selectedDay => selectedDay !== day)
      : [...selectedDays, day];

    const sortedDays = sortDays(days);

    setSelectedDays(sortedDays);
    onChange(sortedDays);
  };

  /**
   * Change days to a specific selection
   */
  const changeDays = (daysArr) => {
    if (disabled) {
      return;
    }

    const sortedDays = sortDays(daysArr);

    // save the days to an array using the specified day format
    const formatDays = sortedDays.map(day => formatDay(dayFormat, day));

    setSelectedDays(formatDays);
    onChange(formatDays);
  };

  const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
  ];

  return (
    <div
      className={classNames('DaySelector', className)}
      style={style}
    >
      {days.map((day) => (
        <DaySelectorDay
          key={day}
          day={day}
          selected={selectedDays.includes(formatDay(dayFormat, day))}
          selectedColor={selectedColor}
          disabled={disabled}
          onClick={() => selectDay(day)}
        />
      ))}

      {toggleAll && !disabled && (
        <div
          className="toggle-all"
          style={{
            cursor: !disabled
              ? 'pointer'
              : 'default',
          }}
        >
          {selectedDays.length <= 0 && (
            <VibeTooltip
              placement="top"
              title="Select all days"
            >
              <CheckBoxOutlineBlankIcon
                style={{
                  color: selectedColor,
                }}
                // select all days
                onClick={() => changeDays(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])}
              />
            </VibeTooltip>
          )}

          {selectedDays.length > 0 && selectedDays.length < 7 && (
            <VibeTooltip
              placement="top"
              title="Some days selected"
            >
              <IndeterminateCheckBoxIcon
                style={{
                  color: selectedColor,
                }}
                // select all days
                onClick={() => changeDays(['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'])}
              />
            </VibeTooltip>
          )}

          {selectedDays.length >= 7 && (
            <VibeTooltip
              placement="top"
              title="Reset day selection"
            >
              <CheckBoxIcon
                style={{
                  color: selectedColor,
                }}
                // reset selected days
                onClick={() => changeDays([])}
              />
            </VibeTooltip>
          )}
        </div>
      )}
    </div>
  );
}

DaySelector.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  selectedColor: PropTypes.string,
  dayFormat: PropTypes.oneOf([
    'dd',
    'ddd',
    'dddd',
  ]),
  disabled: PropTypes.bool,
  selectedDays: PropTypes.arrayOf(PropTypes.string),
  /** Allow toggling of all days */
  toggleAll: PropTypes.bool,
  onChange: PropTypes.func,
};

DaySelector.defaultProps = {
  className: '',
  style: {},
  selectedColor: color.primary,
  dayFormat: 'ddd',
  disabled: false,
  selectedDays: [],
  toggleAll: false,
  onChange: () => {},
};

export default DaySelector;
