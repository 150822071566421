import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  Field2 as Field,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeButtonNew,
  VibeIcon,
  viClose,
  viNowPlaying,
  viArrowRight,
  viArchive,
  viRestore,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import TransferLocations from './TransferLocations';

function DeviceManagementSidebar({
  className,
  style,
  deviceId,
  locationId,
  onClickOption,
  onClose,
  user,
  queueToast,
  setPanel,
}) {
  const [device, setDevice] = useState({
    identifiers: {},
  });

  const history = useNavigate();
  const qs = NavigationHelper.getParams();

  /**
   * Get the device data
   */
  const getDevice = async () => {
    const deviceResponse = await API.DeviceManagement.getById(deviceId);
    setDevice(deviceResponse);
  };

  /**
   * When the transfer panel is closed
   */
  const onCloseTransfer = (options = {}) => {
    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // remove ?type=transfer from the URL (if it exists)
    history(NavigationHelper.updateParams({
      type: null,
    }));

    if (options.closeMainSidebar) {
      onClose();
    }
  };

  /**
   * When the user clicks on make live
   */
  const onClickMakeLive = () => {
    onClickOption({
      option: 'make live',
      device,
    });
  };

  /**
   * When the user clicks on make live
   */
  const onClickDecommission = () => {
    onClickOption({
      option: 'decommission',
      device,
    });
  };

  /**
   * When the user clicks on make live
   */
  const onClickReset = () => {
    onClickOption({
      option: 'reset',
      device,
    });
  };

  /**
   * When the user clicks on transfer
   */
  const onClickTransfer = () => {
    setPanel({
      extraPanel: {
        width: window.innerWidth,
        show: true,
        title: 'Transfer Device to a Different Location',
        children: (
          <TransferLocations
            deviceId={deviceId}
            onClose={onCloseTransfer}
          />
        ),
      },
      onCloseSecond: onCloseTransfer,
    });
  };

  /**
   * When a field changes
   */
  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    setDevice({
      ...device,
      [name]: value,
    });
  };

  /**
   * When a field identifier changes
   */
  const onChangeIdentifier = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    setDevice({
      ...device,
      identifiers: {
        ...device.identifiers,
        [name]: value,
      },
    });
  };

  // /**
  //  * When a field validation runs
  //  */
  // const onValidate = ({
  //   name,
  //   valid,
  // }) => {
  //   if (valid && errorFields.includes(name)) {
  //     // Remove the error from the field
  //     setErrorFields(errorFields.filter(fieldName => fieldName !== name));
  //   } else if (!valid && !errorFields.includes(name)) {
  //     // Add an error to this field
  //     setErrorFields([...errorFields, name]);
  //   }
  // };

  /**
   * Save the Device
   */
  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const data = {
      locationId,
      deviceType: device.deviceType,
      identifiers: device.identifiers,
    };

    if (device._id) {
      data._id = device._id;
    }

    try {
      const response = device._id
        ? await API.DeviceManagement.update(data)
        : await API.DeviceManagement.create(data);

      const responseId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = device._id
        ? responseType === 'DEVICE.UPDATED'
        : responseType === 'DEVICE.CREATED';

      if (success) {
        // Successfully saved the baseline
        const saveMessage = device._id
          ? 'Device Updated!'
          : 'Device Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      // Refresh the details and redirect to their edit screen
      if (success && !device._id) {
        const redirectUrl = NavigationHelper.updateParams({
          deviceId: responseId,
          type: null,
        });

        history(redirectUrl);
      }

      document.dispatchEvent(new Event('onSaveDevice'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveDeviceError'));
    }
  };

  useEffect(() => {
    if (deviceId) {
      getDevice();
    }
  }, [deviceId]);

  /**
   * Open the sidebar to the transfer panel
   */
  useEffect(() => {
    if (qs.type === 'transfer') {
      onClickTransfer();
    }
  }, [qs.type]);

  return (
    <SidePanelContainer
      className={classNames('DeviceManagementSidebar', className)}
      style={style}
    >
      <SidePanelHeader
        className="sidepanel-header"
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="title">
          {deviceId
            ? 'Edit Device'
            : 'New Device'}
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <Field
              type="select"
              label="Device Type"
              name="deviceType"
              placeholder="Device Type"
              value={device.deviceType}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Android',
                  value: 'android',
                },
                {
                  label: 'MAndroid',
                  value: 'mandroid',
                },
                {
                  label: 'Optra',
                  value: 'optra',
                },
              ]}
              tabIndex={1}
              onChange={onChange}
              required
            />
          </Grid>

          <Grid xs={12} item>
            <Field
              type="text"
              label="Player ID"
              name="playerId"
              placeholder="Player ID"
              value={get(device, 'identifiers.playerId', '')}
              tabIndex={2}
              onChange={onChangeIdentifier}
            />
          </Grid>

          <Grid xs={12} item>
            <Field
              type="text"
              label="Serial Number"
              name="serialNumber"
              placeholder="Serial Number"
              value={get(device, 'identifiers.serialNumber', '')}
              tabIndex={3}
              onChange={onChangeIdentifier}
            />
          </Grid>

          <Grid xs={12} item>
            <Field
              type="text"
              label="Mac Address"
              name="macAddress"
              placeholder="0F:0F:0F:0F:0F:0F"
              value={get(device, 'identifiers.macAddress', '')}
              tabIndex={4}
              validation={{
                check: true,
                checkEmpty: false,
                regex: /^(?:[0-9A-F]{2}[:-]){5}(?:[0-9A-F]{2})$/i,
                errorText: 'Invalid MAC Address',
                events: ['onBlur'],
              }}
              // onValidate={onValidate}
              onChange={onChangeIdentifier}
            />
          </Grid>

          <Grid xs={12} item>
            <Field
              type="text"
              label="Phone Number"
              name="phoneNumber"
              value={get(device, 'identifiers.phoneNumber', '')}
              tabIndex={5}
              onChange={onChangeIdentifier}
            />
          </Grid>
        </Grid>
      </SidePanelContent>

      <SidePanelFooter>
        <VibeButtonNew
          text="Save Changes"
          color={color.primary}
          onClick={onSave}
        />

        <VibeIcon
          icon={viNowPlaying}
          type="button"
          buttonProps={{
            size: 32,
            borderColor: color.primary,
            style: {
              marginLeft: 8,
            },
          }}
          tooltip="Make Live"
          color={color.primary}
          size={20}
          disabled={(!user.can('admin.system_admin')
          || !user.can('location.manage_device_config'))
          || device.activationStatus === 'live'}
          onClick={onClickMakeLive}
        />

        <VibeIcon
          icon={viArrowRight}
          type="button"
          buttonProps={{
            size: 32,
            borderColor: color.primary,
            style: {
              marginLeft: 8,
            },
          }}
          tooltip="Transfer"
          color={color.primary}
          size={20}
          disabled={!user.can('admin.system_admin')
          || !user.can('location.manage_device_config')}
          onClick={onClickTransfer}
        />

        <VibeIcon
          icon={viRestore}
          type="button"
          buttonProps={{
            size: 32,
            borderColor: color.primary,
            style: {
              marginLeft: 8,
            },
          }}
          tooltip="Reset"
          color={color.primary}
          size={20}
          disabled={!user.can('admin.system_admin')
          || !user.can('location.manage_device_config')}
          onClick={onClickReset}
        />

        <VibeIcon
          icon={viArchive}
          type="button"
          buttonProps={{
            size: 32,
            borderColor: color.error,
            style: {
              marginLeft: 8,
            },
          }}
          tooltip="Decommission"
          color={color.error}
          size={20}
          disabled={(!user.can('admin.system_admin')
          || !user.can('location.manage_device_config'))
          || device.activationStatus === 'decommissioned'}
          onClick={onClickDecommission}
        />
      </SidePanelFooter>
    </SidePanelContainer>
  );
}

DeviceManagementSidebar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  deviceId: PropTypes.string,
  locationId: PropTypes.string.isRequired,
  onClickOption: PropTypes.func,
  onClose: PropTypes.func,
};

DeviceManagementSidebar.defaultProps = {
  className: '',
  style: {},
  deviceId: '',
  onClickOption: () => {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManagementSidebar);
