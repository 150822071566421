import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  sortBy,
} from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PageLayout from '../PageLayout/PageLayout';
import VibeSplash from '../VibeSplash/VibeSplash';
import SwapButton from '../SwapButton/SwapButton';
import PlaylistCard from '../PlaylistCard/PlaylistCard';
import {
  playlists as searchByPlaylists,
} from '../../helpers/SearchBy';
import {
  playlists as sortByPlaylists,
} from '../../helpers/SortBy';
import color from '../../sass/color.scss';
import './PlaylistsAssigned.scss';

class PlaylistsAssigned extends PureComponent {
  constructor(props) {
    super(props);

    const {
      selected,
    } = this.props;

    this._scrollRef = null;

    this.state = {
      playlists: selected,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      selected,
    } = this.props;

    const {
      selected: prevSelected,
    } = prevProps;

    // Update selected locations by grouping them by company
    if (JSON.stringify(selected) !== JSON.stringify(prevSelected)) {
      this.setState({
        playlists: sortBy(selected, 'name'),
      });
    }
  }

  onFilter = (data) => {
    const {
      collection,
    } = data;

    this.setState({
      playlists: collection,
    });
  };

  /**
   * When the unassign all button is clicked
   */
  onDeselectAll = () => {
    const {
      onDeselect,
    } = this.props;

    const {
      playlists,
    } = this.state;

    playlists.forEach((playlist) => {
      onDeselect(playlist._id);
    });
  };

  /**
   * When the Swap Button is Clicked
   */
  onSwap = (playlist) => {
    const {
      onDeselect,
    } = this.props;

    onDeselect(playlist._id);
  };

  render() {
    const {
      selected: originalSelected,
      customToolbar,
      allowChanges,
    } = this.props;

    const {
      playlists,
    } = this.state;

    const hasNoPlaylists = playlists.length <= 0;

    return (
      <div className="PlaylistsAssigned">
        <div className="title-container">
          <div className="title">
            Selected Playlists
          </div>

          {customToolbar}
        </div>

        <PageLayout
          className="assigned-layout"
          sortOptions={sortByPlaylists}
          searchOptions={searchByPlaylists}
          scrollRef={this._scrollRef}
          placeholder="Search..."
          collection={originalSelected}
          onFilter={this.onFilter}
          disableView
        />

        <div className="playlists-content-root">
          {hasNoPlaylists ? (
            <VibeSplash
              title="No Playlists Selected"
              titleStyle={{
                color: color.primary,
                textTransform: 'uppercase',
              }}
              subtitle="Select playlists from the left panel."
            />
          ) : (
            <PerfectScrollbar
              containerRef={(ref) => { this._scrollRef = ref; }}
            >
              <div className="playlists-content">
                <div className="assigned-toolbar">
                  {allowChanges ? (
                    <SwapButton
                      className="unassign-btn"
                      text="Unassign All"
                      color={color.success}
                      arrowPlacement="left"
                      arrowPosition="left"
                      onClick={this.onDeselectAll}
                    />
                  ) : null}
                </div>

                <div className="playlists-list">

                  {playlists.map((playlist) => {
                    return (
                      <div
                        key={playlist._id}
                        className="playlist-container"
                      >
                        <PlaylistCard
                          key={playlist._id}
                          playlist={playlist}
                          height={310}
                          swap={allowChanges}
                          swapDirection="left"
                          onSwap={this.onSwap}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </PerfectScrollbar>
          )}
        </div>
      </div>
    );
  }
}

PlaylistsAssigned.propTypes = {
  /** Selected playlists */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Custom toolbar */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Allow changes to be made */
  allowChanges: PropTypes.bool,
  /** When a location is deselected */
  onDeselect: PropTypes.func,
};

PlaylistsAssigned.defaultProps = {
  selected: [],
  customToolbar: null,
  allowChanges: false,
  onDeselect: () => {},
};

export default PlaylistsAssigned;
