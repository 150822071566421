import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  clone,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeIcon,
  VibeButton,
  viFlag,
  Field,
  viAlert,
  viClose,
  color,
} from 'vibeguide';
import './ManageAlerts.scss';

class ManageAlerts extends PureComponent {
  componentDidMount() {
    this.onOpen();
  }

  componentDidUpdate(prevProps) {
    const {
      locationId,
    } = this.props;

    const {
      locationId: prevLocationId,
    } = prevProps;

    if (locationId && locationId !== prevLocationId) {
      this.onOpen();
    }
  }

  /**
   * When the flag modal is opened
   */
  onOpen = async () => {
    const {
      locationId,
      onSetAlertData,
    } = this.props;

    const alertData = locationId ? await API.Location.Alerts.Config.get(locationId) : null;

    if (alertData) {
      onSetAlertData(alertData);

      return;
    }

    console.error('No alert data found');
  };

  onChangeVolume = (value) => {
    const {
      activeAlerts,
      onSetAlertData,
    } = this.props;

    const updatedAlerts = clone(activeAlerts);
    updatedAlerts.volume = value;

    onSetAlertData(updatedAlerts);
  };

  onChangeAudio = (value) => {
    const {
      activeAlerts,
      onSetAlertData,
    } = this.props;

    const updatedAlerts = clone(activeAlerts);
    updatedAlerts.audio = value;

    onSetAlertData(updatedAlerts);
  };

  onChangeBattery = (value) => {
    const {
      activeAlerts,
      onSetAlertData,
    } = this.props;

    const updatedAlerts = clone(activeAlerts);
    updatedAlerts.battery = value;

    onSetAlertData(updatedAlerts);
  };

  onChangeMemory = (value) => {
    const {
      activeAlerts,
      onSetAlertData,
    } = this.props;

    const updatedAlerts = clone(activeAlerts);
    updatedAlerts.memory = value;

    onSetAlertData(updatedAlerts);
  };

  onChangeNetwork = (value) => {
    const {
      activeAlerts,
      onSetAlertData,
    } = this.props;

    const updatedAlerts = clone(activeAlerts);
    updatedAlerts.network = value;

    onSetAlertData(updatedAlerts);
  };

  onChangeOffline = (value) => {
    const {
      activeAlerts,
      onSetAlertData,
    } = this.props;

    const updatedAlerts = clone(activeAlerts);
    updatedAlerts.offline = value;

    onSetAlertData(updatedAlerts);
  };

  onSaveAlertConfig = () => {
    const {
      onSaveAlertConfig,
    } = this.props;

    onSaveAlertConfig();
  };

  /**
   * Toggle the flag section
   */
  onToggleFlag = () => {
    const {
      onToggleFlag,
    } = this.props;

    onToggleFlag();
  };

  render() {
    const {
      activeAlerts: {
        name,
        volume,
        audio,
        battery,
        memory,
        network,
        offline,
      },
      onClose,
    } = this.props;

    return (
      <SidePanelContainer className="ManageAlerts">
        <SidePanelHeader
          icons={(
            <div>
              <VibeIcon
                id="flag"
                icon={viFlag}
                color={color.manatee}
                hoverColor={color.obsidian}
                tooltip="Flag"
                size={24}
                onClick={this.onToggleFlag}
              />

              <VibeIcon
                id="alert"
                icon={viAlert}
                color={color.primary}
                hoverColor={color.obsidian}
                tooltip="Manage Alerts"
                size={24}
                onClick={() => {}}
              />

              <VibeIcon
                id="close-sidebar"
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
            </div>
          )}
        >
          <div className="flex-horizontal">
            <div className="title">
              Manage Alerts
            </div>
          </div>
        </SidePanelHeader>

        <SidePanelContent>
          <div className="section-title">
            Active Alerts for {name}:
          </div>
          <Field
            type="toggle"
            className="config-toggle"
            name="volume"
            toggleProps={{
              label: 'Volume',
            }}
            value={volume ? 'on' : 'off'}
            onChange={this.onChangeVolume}
          />

          <Field
            type="toggle"
            className="config-toggle"
            toggleProps={{
              label: 'Audio',
            }}
            value={audio ? 'on' : 'off'}
            onChange={this.onChangeAudio}
          />

          <Field
            type="toggle"
            className="config-toggle"
            toggleProps={{
              label: 'Battery',
            }}
            value={battery ? 'on' : 'off'}
            onChange={this.onChangeBattery}
          />

          <Field
            type="toggle"
            className="config-toggle"
            toggleProps={{
              label: 'Memory',
            }}
            value={memory ? 'on' : 'off'}
            onChange={this.onChangeMemory}
          />

          <Field
            type="toggle"
            className="config-toggle"
            toggleProps={{
              label: 'Network',
            }}
            value={network ? 'on' : 'off'}
            onChange={this.onChangeNetwork}
          />

          <Field
            type="toggle"
            className="config-toggle"
            toggleProps={{
              label: 'Offline',
            }}
            value={offline ? 'on' : 'off'}
            onChange={this.onChangeOffline}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <div className="input-container modal-footer ">
            <div className="toolbar-btns">
              <VibeButton
                className="toolbar-btn save-btn"
                text="Save Changes"
                btnColor="purple"
                textColor="white"
                onClick={this.onSaveAlertConfig}
              />
            </div>
          </div>
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

ManageAlerts.propTypes = {
  locationId: PropTypes.string,
  activeAlerts: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onClose: PropTypes.func,
  onSetAlertData: PropTypes.func,
  onToggleFlag: PropTypes.func,
  onSaveAlertConfig: PropTypes.func,
};

ManageAlerts.defaultProps = {
  locationId: '',
  activeAlerts: {},
  onClose: () => {},
  onSetAlertData: () => {},
  onToggleFlag: () => {},
  onSaveAlertConfig: () => {},
};

export default ManageAlerts;
