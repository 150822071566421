import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  get,
  clone,
  reduce,
  toInteger,
} from 'lodash';
import {
  API,
  TimeUtil,
  NavigationHelper,
  Field2 as Field,
  DaySelector,
  VibeTooltip,
  VibeButtonNew,
  VibeIcon,
  viTrash,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import {
  calculateHourlySpotsForSpotPool,
} from '../../../AdPrograms/hourlySpotCalculator';
import LayoutTemplatePreview from '../../../../../Companies/Sidebar/CompanyLayoutTemplates/LayoutTemplatePreview';
import './SpotPoolItem.scss';

function newDoCopy() {
  return {
    selectedDays: [],
    dayDD: '',
  };
}

let doCopy = newDoCopy();

function SpotPoolItem({
  location,
  day,
  spotPool: propSpotPool,
  canView,
  canEdit,
  onCopy,
  onChange,
  onRemove,
}) {
  const [spotPool, setSpotPool] = useState(propSpotPool);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedDays, setSelectedDays] = useState([TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd')]);
  const [invalidStartTime, setInvalidStartTime] = useState(false);
  const [invalidStopTime, setInvalidStopTime] = useState(false);
  const [allocationExceedsPossibleSpots, setAllocationExceedsPossibleSpots] = useState(false);
  const [layoutTemplate, setLayoutTemplate] = useState({});
  const [loopTemplateName, setLoopTemplateName] = useState('');
  const itemRef = useRef(null);

  const qs = NavigationHelper.getParams() || {};

  const {
    companyId,
    mediaFormat,
  } = location;

  const {
    // allowedHourlySpots,
    distribution,
    startTime,
    endTime,
    frequency,
    groupSize,
    integrationType,
    maxHourlySpots,
    // _uniqueId,
    layoutTemplateId,
    loopTemplateId,
    targetZone,
  } = spotPool;

  // Get the total number of allocations
  const allocated = reduce(distribution, (sum, n) => {
    return sum + n;
  }, 0);

  // Get the total number of available allocations remaining
  const available = maxHourlySpots - allocated;

  // Is the allocation invalid?
  const allocationError = available < 0;

  const itemStart = TimeUtil.convertToTwelveHour({
    time: startTime,
  });

  const itemStop = TimeUtil.convertToTwelveHour({
    time: endTime,
  });

  /**
   * When the item is clicked and the user can edit
   */
  const onClickItem = () => {
    if (!canView) {
      console.warn('user cannot view the item');
      return;
    }

    setShowEdit(true);
  };

  /**
   * When the edit popover is closed
   */
  const onClosePopover = () => {
    setSpotPool(propSpotPool);
    setShowEdit(false);
    setAllocationExceedsPossibleSpots(false);
  };

  /**
   * When the start time is changed
   */
  const onChangeStartTime = (data) => {
    // time field has an error
    if (data.hasError) {
      setInvalidStartTime(true);
      return;
    }

    setSpotPool({
      ...spotPool,
      startTime: data.time24,
    });
    setInvalidStartTime(false);
  };

  /**
   * When the stop time is changed
   */
  const onChangeStopTime = (data) => {
    // time field has an error
    if (data.hasError) {
      setInvalidStopTime(true);
      return;
    }

    let newStopTime = data.time24;

    const time = data.time24.split(':');
    const hour = parseInt(time[0], 10);

    if (hour < 0 || hour >= 24) {
      newStopTime = '24:00';
    }

    setSpotPool({
      ...spotPool,
      endTime: newStopTime,
    });
    setInvalidStopTime(false);
  };

  /**
   * When a value is changed
   */
  const onChangeValue = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    try {
      let updatedGroupSize = spotPool.groupSize;

      if (name === 'groupSize') {
        updatedGroupSize = parseInt(value, 10);
      } else if (name === 'integrationType' && value === 'interrupt') {
        updatedGroupSize = 1;
      }

      const newSpotPool = calculateHourlySpotsForSpotPool({
        ...spotPool,
        [name]: value,
        groupSize: updatedGroupSize,
      }, location.mediaFormat);

      setSpotPool(newSpotPool);
      setAllocationExceedsPossibleSpots(false);
    } catch (err) {
      setSpotPool({
        ...spotPool,
        [name]: value,
      });
      setAllocationExceedsPossibleSpots(true);
    }
  };

  /**
   * When a distribution value is changed
   */
  const onChangeDistributionValue = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    try {
      const newSpotPool = calculateHourlySpotsForSpotPool({
        ...spotPool,
        distribution: {
          ...spotPool.distribution,
          [name]: toInteger(value),
        },
      }, location.mediaFormat);

      setSpotPool(newSpotPool);
      setAllocationExceedsPossibleSpots(false);
    } catch (err) {
      setSpotPool({
        ...spotPool,
        distribution: {
          ...spotPool.distribution,
          [name]: toInteger(value),
        },
      });
      setAllocationExceedsPossibleSpots(true);
    }
  };

  /**
   * When the days of the week are changed
   */
  const onChangeDays = (days) => {
    const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');

    if (!days.includes(dayDD)) {
      // do not let the day the spot pool is in to be deselected
      days.push(dayDD);
    }

    setSelectedDays(days);
  };

  /**
   * Change the layout template
   */
  const onChangeTemplate = ({
    name,
    value,
    data,
  }) => {
    if (name === 'layoutTemplateName') {
      setLayoutTemplate({
        ...layoutTemplate,
        name: value,
      });

      if (data && data._id) {
        setSpotPool({
          ...spotPool,
          layoutTemplateId: data._id,
          // reset the target zone when the layout template changes
          targetZone: '',
        });
      } else if (layoutTemplateId) {
        // remove the existing layout template ID
        setSpotPool({
          ...spotPool,
          layoutTemplateId: '',
          // reset the target zone when the layout template changes
          targetZone: '',
        });
      }
    } else if (name === 'loopTemplateName') {
      setLoopTemplateName(value);

      if (data && data._id) {
        setSpotPool({
          ...spotPool,
          loopTemplateId: data._id,
          maxHourlySpots: data.maxHourlyAdSpots,
        });
      } else if (layoutTemplateId) {
        // remove the existing loop template ID
        setSpotPool({
          ...spotPool,
          loopTemplateId: '',
          maxHourlySpots: 0,
        });
      }
    }
  };

  /**
   * When the target zone is changed
   */
  const onChangeTargetZone = (targetZone) => {
    setSpotPool({
      ...spotPool,
      targetZone,
    });
  };

  /**
   * Remove the spot pool item
   */
  const onRemoveSpotPool = () => {
    onRemove({
      day,
      spotPool,
    });
  };

  /**
   * Save the edited value
   */
  const onSave = () => {
    let newSpotPool = clone(spotPool);

    newSpotPool = {
      ...newSpotPool,
      distribution: {
        ...newSpotPool.distribution,
        'adult-beverage': toInteger(newSpotPool.distribution['adult-beverage']),
        endemic: toInteger(newSpotPool.distribution.endemic),
        general: toInteger(newSpotPool.distribution.general),
        'non-endemic': toInteger(newSpotPool.distribution['non-endemic']),
        'self-promotion': toInteger(newSpotPool.distribution['self-promotion']),
      },
      allowedHourlySpots: toInteger(newSpotPool.allowedHourlySpots),
      frequency: toInteger(newSpotPool.frequency),
      groupSize: toInteger(newSpotPool.groupSize),
      maxHourlySpots: toInteger(newSpotPool.maxHourlySpots),
    };

    if (selectedDays.length > 1) {
      // copy the spot pool to other days of the week
      const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');

      doCopy.dayDD = dayDD;
      doCopy.selectedDays = selectedDays.filter(day => day !== dayDD);
    }

    onChange({
      day,
      spotPool: newSpotPool,
    });

    setShowEdit(false);
  };

  /**
   * Check to see if the save button should be disabled
   */
  const allowSave = () => {
    if (invalidStartTime || invalidStopTime) {
      return false;
    }

    if (!startTime
      || !endTime
      || (mediaFormat === 'audio' && (!frequency || !groupSize || !integrationType))
      || (mediaFormat === 'visual' && (!layoutTemplateId || !loopTemplateId || !targetZone))
      || allocationError
      || allocationExceedsPossibleSpots
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    // use the URL query params to automatically open the edit view of a spot pool
    if (!showEdit && qs.day && qs.spotPoolStart) {
      const dayDD = TimeUtil.getDayFromIndex(TimeUtil.getDayOfWeekIndex(day), 'dd');

      if (qs.day.toLowerCase() === dayDD.toLowerCase()
        && qs.spotPoolStart === spotPool.startTime
      ) {
        // spot pool matches day and start time, show the edit view automatically
        setShowEdit(true);
      }
    }
  }, [qs.day, qs.spotPoolStart]);

  /**
   * Whenever the spot pool is updated in the props
   */
  useEffect(() => {
    if (propSpotPool !== spotPool) {
      setSpotPool(propSpotPool);

      if (doCopy.selectedDays.length > 0) {
        onCopy({
          days: doCopy.selectedDays,
          spotPool: propSpotPool,
        });

        // reset the selected days of the week
        setSelectedDays([doCopy.dayDD]);
        doCopy = newDoCopy();
      }
    }
  }, [propSpotPool]);

  /**
   * Whenever the spot pool edit modal is shown or hidden
   * Get the layout/loop template to populate the dropdown
   */
  useEffect(async () => {
    if (showEdit && mediaFormat === 'visual' && layoutTemplateId) {
      const layoutTemplate = await API.Company.LayoutTemplate.getById({
        _id: layoutTemplateId,
        companyId,
      });

      setLayoutTemplate(layoutTemplate);
    }

    if (showEdit && mediaFormat === 'visual' && loopTemplateId) {
      const loopTemplate = await API.Company.LoopTemplate.getById({
        _id: loopTemplateId,
        companyId,
      });

      setLoopTemplateName(loopTemplate.name);
    }
  }, [showEdit, layoutTemplateId]);

  return (
    <div
      className="SpotPoolItem"
      style={{
        width: '100%',
        height: '100%',
        border: `1px solid ${color.primary}4D`,
        borderRadius: 4,
        boxSizing: 'border-box',
        backgroundColor: `${color.primary}40`,
      }}
    >
      <VibeTooltip
        title={(
          <div>
            {itemStart} - {itemStop}

            <div
              style={{
                marginTop: 8,
              }}
            >
              {canEdit
                ? 'Click to Edit...'
                : 'Click to View...'}
            </div>
          </div>
        )}
        placement="left"
      >
        <div
          ref={itemRef}
          className="value"
          onClick={onClickItem}
        />
      </VibeTooltip>

      <Popover
        anchorEl={itemRef.current}
        open={showEdit}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        classes={{
          root: 'ItemPopperRoot',
          paper: 'ItemPopper SpotPoolItemPopper',
        }}
      >
        <div className="item-details-container">
          <div className="item-details">
            <Grid spacing={2} container>
              <Grid xs={12} item>
                <Field
                  type="custom"
                  label="Copy to Days"
                  labelProps={{
                    tooltip: 'Copy this Spot Pool to other days of the week',
                    tooltipPlacement: 'right',
                  }}
                >
                  <DaySelector
                    style={{
                      width: 250,
                    }}
                    dayFormat="dd"
                    disabled={!canEdit}
                    selectedDays={selectedDays}
                    toggleAll
                    onChange={onChangeDays}
                  />
                </Field>
              </Grid>

              <Grid xs={6} item>
                <Field
                  type="time"
                  label="Start Time"
                  placeholder="9:00am"
                  value={startTime}
                  tabIndex={1}
                  timeProps={{
                    // prevent overlapping items in the schedule
                    min: '00:00',
                    max: endTime,
                  }}
                  disabled={!canEdit}
                  onChange={onChangeStartTime}
                  required
                />
              </Grid>

              <Grid xs={6} item>
                <Field
                  type="time"
                  label="Stop Time"
                  placeholder="9:00am"
                  value={endTime}
                  tabIndex={2}
                  timeProps={{
                    min: startTime,
                    // prevent overlapping items in the schedule
                    max: '24:00',
                    endOfDay: true,
                  }}
                  disabled={!canEdit}
                  onChange={onChangeStopTime}
                  required
                />
              </Grid>
            </Grid>

            {mediaFormat === 'audio' && (
              <div
                style={{
                  margin: '16px 0',
                }}
              >
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <Field
                      type="select"
                      label="Integration"
                      labelProps={{
                        tooltip: 'How would you like these ads to integrate with the music?',
                        tooltipPlacement: 'right',
                      }}
                      name="integrationType"
                      value={integrationType}
                      options={[
                        {
                          label: '',
                          value: '',
                        },
                        {
                          label: 'Rotation',
                          value: 'rotate',
                        },
                        {
                          label: 'Interruption',
                          value: 'interrupt',
                        },
                      ]}
                      disabled={!canEdit}
                      tabIndex={3}
                      onChange={onChangeValue}
                      required
                    />
                  </Grid>

                  <Grid xs={6} item>
                    <Field
                      type="number"
                      label={`Frequency - ${integrationType === 'rotate' ? 'Songs' : 'Minutes'}`}
                      labelProps={{
                        tooltip: 'How often would you like to hear the ads?',
                        tooltipPlacement: 'right',
                      }}
                      placeholder="Frequency..."
                      name="frequency"
                      value={frequency}
                      numberProps={{
                        min: 1,
                      }}
                      tabIndex={4}
                      disabled={!canEdit}
                      onChange={onChangeValue}
                      required
                    />
                  </Grid>

                  <Grid xs={6} item>
                    <Field
                      type="number"
                      label="Count"
                      labelProps={{
                        tooltip: 'How many ads would you like to hear in a row?',
                        tooltipPlacement: 'right',
                      }}
                      placeholder="Count..."
                      name="groupSize"
                      value={integrationType === 'rotate'
                        ? groupSize
                        : 1}
                      numberProps={{
                        min: 1,
                      }}
                      tabIndex={5}
                      disabled={!canEdit || integrationType !== 'rotate'}
                      onChange={onChangeValue}
                      required={integrationType === 'rotate'}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            {mediaFormat === 'visual' && (
              <div
                style={{
                  margin: '16px 0',
                }}
              >
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <Field
                      type="dropdown"
                      label="Layout Template"
                      name="layoutTemplateName"
                      value={layoutTemplate.name}
                      tabIndex={3}
                      dropdownProps={{
                        type: 'layout template',
                        attr: 'name',
                        companyId,
                        fetch: API.Company.LayoutTemplate.list,
                      }}
                      disabled={!canEdit}
                      onChange={onChangeTemplate}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <Field
                      type="dropdown"
                      label="Loop Template"
                      name="loopTemplateName"
                      value={loopTemplateName}
                      tabIndex={4}
                      dropdownProps={{
                        type: 'loop template',
                        attr: 'name',
                        companyId,
                        fetch: API.Company.LoopTemplate.list,
                      }}
                      disabled={!canEdit}
                      onChange={onChangeTemplate}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <Field
                      type="custom"
                      label="Target Zone"
                    >
                      <LayoutTemplatePreview
                        style={{
                          maxHeight: 250,
                        }}
                        scalePercent={10}
                        width={get(layoutTemplate, 'size[0]', 0)}
                        height={get(layoutTemplate, 'size[1]', 0)}
                        zones={get(layoutTemplate, 'zones', [])}
                        targetZone={spotPool.targetZone}
                        selectable={canEdit}
                        onClickZone={onChangeTargetZone}
                      />
                    </Field>
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid spacing={2} container>
              <Grid xs={12} item>
                <VibeTooltip
                  title="Distribute available ad slots into their appropriate networks."
                  placement="right"
                >
                  <span className="title">
                    Network Allocation
                  </span>
                </VibeTooltip>

                {allocationExceedsPossibleSpots && (
                  <div
                    style={{
                      marginTop: 8,
                      color: color.error,
                      fontSize: 12,
                    }}
                  >
                    Allocation Excceds Possible Slots
                  </div>
                )}
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="custom"
                  label="Max Allowed"
                >
                  {maxHourlySpots}
                </Field>
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="custom"
                  label="Allocated"
                  error={allocationError}
                >
                  <div
                    style={{
                      color: !allocationError
                        ? color.obsidian
                        : color.error,
                    }}
                  >
                    {allocated}
                  </div>
                </Field>
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="custom"
                  label="Available"
                  error={allocationError}
                >
                  <div
                    style={{
                      color: !allocationError
                        ? color.obsidian
                        : color.error,
                    }}
                  >
                    {available}
                  </div>
                </Field>
              </Grid>

              <Grid xs={12} item>
                <VibeTooltip
                  title="These networks apply to all SSP&apos;s"
                  placement="right"
                >
                  <span className="title">
                    Networks - All SSP&apos;s
                  </span>
                </VibeTooltip>
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="number"
                  label="General"
                  placeholder="?"
                  name="general"
                  value={distribution.general}
                  numberProps={{
                    min: 0,
                  }}
                  tabIndex={6}
                  disabled={!canEdit}
                  onChange={onChangeDistributionValue}
                />
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="number"
                  label="Self-Promo"
                  placeholder="?"
                  name="self-promotion"
                  value={distribution['self-promotion']}
                  numberProps={{
                    min: 0,
                  }}
                  tabIndex={7}
                  disabled={!canEdit}
                  onChange={onChangeDistributionValue}
                />
              </Grid>

              <Grid xs={4} item />

              <Grid xs={12} item>
                <VibeTooltip
                  title="These networks only apply to Vistar clients"
                  placement="right"
                >
                  <span className="title">
                    Networks - Vistar
                  </span>
                </VibeTooltip>
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="number"
                  label="Endemic"
                  placeholder="?"
                  name="endemic"
                  value={distribution.endemic}
                  numberProps={{
                    min: 0,
                  }}
                  tabIndex={8}
                  disabled={!canEdit}
                  onChange={onChangeDistributionValue}
                />
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="number"
                  label="Non-Endemic"
                  placeholder="?"
                  name="non-endemic"
                  value={distribution['non-endemic']}
                  numberProps={{
                    min: 0,
                  }}
                  tabIndex={9}
                  disabled={!canEdit}
                  onChange={onChangeDistributionValue}
                />
              </Grid>

              <Grid xs={4} item>
                <Field
                  type="number"
                  label="Adult Beverage"
                  placeholder="?"
                  name="adult-beverage"
                  value={distribution['adult-beverage']}
                  numberProps={{
                    min: 0,
                  }}
                  tabIndex={10}
                  disabled={!canEdit}
                  onChange={onChangeDistributionValue}
                />
              </Grid>
            </Grid>

            {canEdit && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  marginTop: 8,
                  textAlign: 'center',
                }}
              >
                <VibeButtonNew
                  text="Save"
                  color={color.primary}
                  disabled={!allowSave()}
                  onClick={onSave}
                />

                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                  }}
                >
                  <VibeIcon
                    icon={viTrash}
                    type="button"
                    buttonProps={{
                      size: 32,
                      borderColor: color.error,
                    }}
                    tooltip="Remove"
                    color={color.error}
                    size={20}
                    onClick={onRemoveSpotPool}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}

SpotPoolItem.propTypes = {
  day: PropTypes.string.isRequired,
  spotPool: PropTypes.shape({
    allowedHourlySpots: PropTypes.number.isRequired,
    distribution: PropTypes.shape({
      'adult-beverage': PropTypes.number.isRequired,
      endemic: PropTypes.number.isRequired,
      general: PropTypes.number.isRequired,
      'non-endemic': PropTypes.number.isRequired,
      'self-promotion': PropTypes.number.isRequired,
    }).isRequired,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    frequency: PropTypes.number,
    groupSize: PropTypes.number,
    integrationType: PropTypes.string,
    maxHourlySpots: PropTypes.number.isRequired,
    // _uniqueId: PropTypes.string.isRequired,
    // attributes for a visual mediaFormat
    layoutTemplateId: PropTypes.string,
    loopTemplateId: PropTypes.string,
    targetZone: PropTypes.string,
  }).isRequired,
  /** Allow the user to view the item */
  canView: PropTypes.bool,
  /** Allow the user to edit the item */
  canEdit: PropTypes.bool,
  /** When the item is being copied to other days of the week */
  onCopy: PropTypes.func,
  /** When the item is changed */
  onChange: PropTypes.func,
  /** When the item is removed */
  onRemove: PropTypes.func,
};

SpotPoolItem.defaultProps = {
  canView: false,
  canEdit: false,
  onCopy: () => {},
  onChange: () => {},
  onRemove: () => {},
};

function mapStateToProps(state) {
  return {
    location: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(SpotPoolItem);
