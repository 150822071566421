import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  TableLocations,
  VibeModal,
  color,
} from 'vibeguide';

function TransferLocations({
  className,
  style,
  deviceId,
  user,
  onClose,
}) {
  const [location, setLocation] = useState({});

  /**
   * When a location is clicked to be added, initiate the transfer
   */
  const onSelectLocation = (data) => {
    setLocation(data);
  };

  /**
   * Cancel transferring the device
   */
  const onCancel = () => {
    setLocation({});
  };

  /**
   * Confirm transferring the device
   */
  const onConfirm = async () => {
    await API.DeviceManagement.transfer({
      _id: deviceId,
      targetLocationId: location._id,
    });

    setLocation({});
    onClose({
      closeMainSidebar: true,
    });
  };

  return (
    <div
      className={classNames('TransferLocations', className)}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
    >
      <TableLocations
        columns={[
          'Select',
          'Name',
          'Company',
          'Address 1',
          'Address 2',
          'City',
          'State/Province',
          'Postal Code',
          'DMA',
          'Ad Network',
          'Ad Programs',
          'Tags',
          '...',
        ]}
        fetch={API.User.getLocations}
        fetchProps={{
          filters: {
            _id: user._id,
          },
        }}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Locations',
          urlPaging: false,
          urlFilters: false,
        }}
        menuItems={[
          { name: 'Archive', userCan: 'location.delete' },
        ]}
        available
        onSelectLocation={onSelectLocation}
      />

      <VibeModal
        type="confirm"
        show={location._id !== undefined}
        confirmProps={{
          text: 'Transfer',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        title="Transfer Device"
        text={(
          <div>
            Are you sure you want to transfer this device to...

            <div
              style={{
                marginTop: 8,
                fontWeight: 900,
              }}
            >
              {location.name}
            </div>
          </div>
        )}
        onConfirm={onConfirm}
        onClose={onCancel}
      />
    </div>
  );
}

TransferLocations.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  deviceId: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

TransferLocations.defaultProps = {
  className: '',
  style: {},
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(TransferLocations);
