import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import API from '../../../api';
import Tag from '../../Tags/Tag';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import VibeButton from '../../VibeButton/VibeButton';
import color from '../../../sass/color.scss';
import './DeviceAttributeDelete.scss';

class DeviceAttributeDelete extends PureComponent {
  /**
   * When items are removed
   */
  onRemove = async () => {
    const {
      locationId,
      selectedAttributes,
      onClose,
    } = this.props;

    const removeFromLocationResponse = await API.Location.DeviceAttributes.update({
      _id: locationId,
      deviceAttributes: selectedAttributes.map((attribute) => {
        return {
          _id: attribute._id,
          action: 'remove',
        };
      }),
    });

    if (get(removeFromLocationResponse, '[0].type') === 'LOCATION.DEVICE_ATTRIBUTES_UPDATED') {
      onClose({
        reset: true,
      });
    } else {
      console.error('Error removing attributes from location', removeFromLocationResponse);
    }
  };

  render() {
    const {
      className,
      selectedIds,
      selectedAttributes,
      onClose,
    } = this.props;

    if (selectedIds.length <= 0) {
      return (
        <div className="DeviceAttributeDelete">
          <div className="empty">
            Select Device Attributes
          </div>
        </div>
      );
    }

    return (
      <div className={classNames('DeviceAttributeDelete', className)}>
        <div className="attribute-section">
          <div className="tag-selected-count">
            <VibeCheckbox
              color={color.success}
              size={16}
              checked
            />

            <div className="text">
              {selectedAttributes.length} selected
            </div>
          </div>
        </div>

        <div className="attribute-section section-content">
          <div className="attribute-item-container">
            {selectedAttributes.map((attribute) => {
              return (
                <Tag
                  key={attribute._id}
                  style={{
                    marginBottom: 8,
                  }}
                  tag={{
                    name: `${attribute.name}:${attribute.value}`,
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className="attribute-section section-footer">
          <VibeButton
            className="btn-save"
            text="Remove from this location"
            btnColor="red"
            textColor="white"
            onClick={this.onRemove}
          />

          <VibeButton
            text="Cancel"
            btnColor="transparent"
            textColor="manatee"
            onClick={onClose}
          />
        </div>
      </div>
    );
  }
}

DeviceAttributeDelete.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Location ID for the device attributes if location view (not admin) */
  locationId: PropTypes.string,
  /** Selected Device Attributes */
  selectedAttributes: PropTypes.arrayOf(PropTypes.object),
  /** Selected attribute IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** When the panel is closed */
  onClose: PropTypes.func,
};

DeviceAttributeDelete.defaultProps = {
  className: '',
  locationId: null,
  selectedAttributes: [],
  selectedIds: [],
  onClose: () => {},
};

export default DeviceAttributeDelete;
