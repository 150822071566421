import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import PlayControl from '../PlayControl/PlayControl';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import {
  getDayOfWeekIndex,
} from '../../utils/TimeUtil';
import {
  compare,
} from '../../helpers/Location';
import color from '../../sass/color.scss';
import './MessageListItem.scss';

class MessageListItem extends PureComponent {
  constructor(props) {
    super(props);

    const {
      companyId,
      locationId,
      startDate,
      stopDate,
      locations = [],
      messageBlockLocations = [],
    } = props;

    let willPlay = false;
    let startDateFormatted = null;
    let stopDateFormatted = null;

    if ((companyId && companyId !== '*') || (locationId && locationId !== '*')) {
      locations.forEach(loc => {
        if (
          loc.locationId === locationId
          || loc.companyId === '*'
          || (loc.locationId === '*' && (loc.companyId === companyId || companyId === '*'))
        ) {
          willPlay = true;
        }
      });
    } else if (companyId === '*' || locationId === '*') {
      // used on the message blocks page in the location assignments
      willPlay = true;
    } else {
      const compareResult = compare(messageBlockLocations, locations);

      if (compareResult.parent.missing.length <= 0) {
        willPlay = true;
      }
    }

    if (startDate || stopDate) {
      const startAt = startDate
        ? new moment(startDate).startOf('day')
        : null;
      const stopAt = stopDate
        ? new moment(stopDate).endOf('day')
        : null;

      if (startAt) {
        startDateFormatted = startAt.format('MM/DD/YYYY');
      }

      if (stopAt) {
        stopDateFormatted = stopAt.format('MM/DD/YYYY');
      }
    }

    this.state = {
      startDateFormatted,
      stopDateFormatted,
      willPlay,
    };
  }

  /**
   * Render the Tooltip when the message hasn't started or is expired
   */
  renderTooltip = () => {
    const {
      name,
      started,
      expired,
      daysOfWeek,
    } = this.props;

    const {
      willPlay,
      startDateFormatted,
      stopDateFormatted,
    } = this.state;

    const messages = [(
      <div key="message-name">
        {name}
      </div>
    )];

    if (!started) {
      messages.push(
        <div key="message-play-date">
          will begin playing on {startDateFormatted}.
        </div>,
      );
    }

    if (expired) {
      messages.push(
        <div key="message-expired">
          Expired on {stopDateFormatted}. Will be
          <br />automatically removed from all
          <br />message blocks by tomorrow.
        </div>,
      );
    }

    if (!willPlay) {
      messages.push(
        <div key="message-not-playable">
          Not playable at this location
        </div>,
      );
    }

    if (daysOfWeek.length > 0 && daysOfWeek.length < 7) {
      messages.push(
        <div key="message-not-today">
          This message is only scheduled to play on<br />
          {daysOfWeek.join(', ')}
        </div>,
      );
    }

    return (
      <div
        style={{
          textAlign: 'center',
          lineHeight: 1.67,
        }}
      >
        {messages}
      </div>
    );
  };

  render() {
    const {
      className,
      index,
      id,
      name,
      url,
      durationSeconds,
      started,
      expired,
      daysOfWeek,
      day,
    } = this.props;

    const {
      willPlay,
    } = this.state;

    // message is scheduled for specific days of the week (if all days are selected treat as not scheduled)
    const scheduled = daysOfWeek.length > 0 && daysOfWeek.length < 7;

    // eslint-disable-next-line no-nested-ternary
    const playControlColor = expired
      // message is expired
      ? color.error
      : scheduled
        // message is scheduled
        ? color.primary
        // message is started and not scheduled
        : color.success;

    const dayIndex = getDayOfWeekIndex(day);
    const daysOfWeekIndexes = daysOfWeek.map(day => getDayOfWeekIndex(day));

    return (
      <div className={classNames('MessageListItem', className, {
        'not-started': !started,
        expired,
        scheduled,
        'not-today': scheduled && day && !daysOfWeekIndexes.includes(dayIndex),
        'not-playable': !willPlay,
      })}
      >
        <div className="number">
          <div className="num">{index + 1}</div>
          <div className="grabber-handle">||</div>
        </div>

        <PlayControl
          id={id}
          name={name}
          src={url}
          durationSeconds={durationSeconds}
          color={playControlColor}
          playText="Play Message"
          pauseText="Pause Message"
        />

        <VibeTooltip
          title={this.renderTooltip()}
          placement="top"
          enterDelay={500}
        >
          <div className="message-name">
            {name}
          </div>
        </VibeTooltip>
      </div>

    );
  }
}

MessageListItem.propTypes = {
  className: PropTypes.string,
  /** Index */
  index: PropTypes.number.isRequired,
  /** Message ID */
  id: PropTypes.string.isRequired,
  /** Message Name */
  name: PropTypes.string.isRequired,
  /** Message URL */
  url: PropTypes.string,
  /** Message Duration */
  durationSeconds: PropTypes.number,
  /** Message has Started */
  started: PropTypes.bool,
  /** Message has Expired */
  expired: PropTypes.bool,
  /** Message Start Date */
  startDate: PropTypes.string,
  /** Message End Date */
  stopDate: PropTypes.string,
  /** Message Locations */
  locations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  /** Company ID of Message Block */
  companyId: PropTypes.string,
  /** Location ID of Message Block */
  locationId: PropTypes.string,
  /** Days of the Week */
  daysOfWeek: PropTypes.arrayOf(PropTypes.string),
  /** Day of the week the message block is being shown */
  day: PropTypes.string,
};

MessageListItem.defaultProps = {
  className: '',
  url: null,
  durationSeconds: 0,
  started: true,
  expired: false,
  startDate: null,
  stopDate: null,
  locations: [],
  companyId: null,
  locationId: null,
  daysOfWeek: [],
  day: null,
};

export default MessageListItem;
