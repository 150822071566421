import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeCheckbox,
  VibeSplash,
  VibeButton,
  Field2 as Field,
  color,
} from 'vibeguide';
import AlertEmailSettings from './AlertEmailSettings';

class AlertVolumeConfig extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      configFetched: false,
      enableMaster: false,
      enableMessage: false,
      enableMusic: false,
      volumeMaster: 0,
      volumeMessage: 0,
      volumeMusic: 0,
      emailFlaggedLocations: false,
      recipients: [],
      suppressNotificationMinutes: 0,
    };
  }

  componentDidMount() {
    this.getConfig();
  }

  /**
   * When the email settings are changed
   */
  onUpdateEmailSettings = (data) => {
    this.setState(data);
  };

  onChangeSelect = ({
    name,
    checked,
  }) => {
    this.setState({
      [name]: checked,
    });
  };

  onChangePercentage = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    this.setState({
      [name]: parseInt(value, 10),
    });
  };

  getConfig = async () => {
    const config = await API.Location.Alerts.Volume.get();

    this.setState({
      configFetched: true,
      enableMaster: get(config, 'low_master.active', false),
      enableMessage: get(config, 'low_message.active', false),
      enableMusic: get(config, 'low_music.active', false),
      volumeMaster: get(config, 'low_master.threshold', '?'),
      volumeMessage: get(config, 'low_message.threshold', '?'),
      volumeMusic: get(config, 'low_music.threshold', '?'),
      emailFlaggedLocations: get(config, 'emailNotification.emailFlaggedLocations', false),
      recipients: get(config, 'emailNotification.recipients', []),
      suppressNotificationMinutes: get(config, 'suppressNotificationMinutes', 0),
    });
  };

  save = async () => {
    const {
      onToast,
    } = this.props;

    const {
      enableMaster,
      enableMessage,
      enableMusic,
      volumeMaster,
      volumeMessage,
      volumeMusic,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
    } = this.state;

    const data = {
      low_master: {
        active: enableMaster,
        threshold: volumeMaster,
      },
      low_message: {
        active: enableMessage,
        threshold: volumeMessage,
      },
      low_music: {
        active: enableMusic,
        threshold: volumeMusic,
      },
      emailNotification: {
        emailFlaggedLocations,
        recipients,
      },
      suppressNotificationMinutes,
    };

    onToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const saveResponse = await API.Location.Alerts.Volume.update(data);

    if (get(saveResponse, '[0].type') !== 'SYSTEMSETTING.UPDATED') {
      onToast({
        type: 'error',
        title: 'Error updating setting',
        timeout: 10,
        allowClose: true,
      });

      return;
    }

    onToast({
      type: 'success',
      title: 'Saved!',
      allowClose: true,
      delay: 500,
    });
  };

  render() {
    const {
      className,
    } = this.props;

    const {
      configFetched,
      enableMaster,
      enableMessage,
      enableMusic,
      volumeMaster,
      volumeMessage,
      volumeMusic,
      emailFlaggedLocations,
      recipients,
      suppressNotificationMinutes,
    } = this.state;

    if (!configFetched) {
      return (
        <VibeSplash
          type="loading"
          size={64}
          style={{
            color: color.primary,
          }}
        />
      );
    }

    return (
      <SidePanelContainer className={classNames('AlertSidebarConfig', 'AlertVolumeConfig', className)}>
        <SidePanelContent>
          <div className="section">
            <div className="section-title">
              Send alert when:
            </div>

            <div className="section-content">
              <div className="setting">
                <VibeCheckbox
                  className="setting-check"
                  name="enableMaster"
                  size={16}
                  color={color.success}
                  checked={enableMaster}
                  onChange={this.onChangeSelect}
                />

                <div className="text">
                  Master volume falls below:
                </div>

                <Field
                  className="setting-input"
                  type="number"
                  name="volumeMaster"
                  value={volumeMaster}
                  numberProps={{
                    min: 0,
                    max: 100,
                  }}
                  onChange={this.onChangePercentage}
                  disabled={!enableMaster}
                />
              </div>

              <div className="setting">
                <VibeCheckbox
                  className="setting-check"
                  name="enableMessage"
                  size={16}
                  color={color.success}
                  checked={enableMessage}
                  onChange={this.onChangeSelect}
                />

                <div className="text">
                  Message volume falls below:
                </div>

                <Field
                  className="setting-input"
                  type="number"
                  name="volumeMessage"
                  value={volumeMessage}
                  numberProps={{
                    min: 0,
                    max: 100,
                  }}
                  onChange={this.onChangePercentage}
                  disabled={!enableMessage}
                />
              </div>

              <div className="setting">
                <VibeCheckbox
                  className="setting-check"
                  name="enableMusic"
                  size={16}
                  color={color.success}
                  checked={enableMusic}
                  onChange={this.onChangeSelect}
                />

                <div className="text">
                  Music volume falls below:
                </div>

                <Field
                  className="setting-input"
                  type="number"
                  name="volumeMusic"
                  value={volumeMusic}
                  numberProps={{
                    min: 0,
                    max: 100,
                  }}
                  onChange={this.onChangePercentage}
                  disabled={!enableMusic}
                />
              </div>
            </div>
          </div>

          <AlertEmailSettings
            emailFlaggedLocations={emailFlaggedLocations}
            recipients={recipients}
            suppressNotificationMinutes={suppressNotificationMinutes}
            onUpdate={this.onUpdateEmailSettings}
          />
        </SidePanelContent>

        <SidePanelFooter>
          <VibeButton
            text="Save Changes"
            btnColor="green"
            textColor="white"
            onClick={this.save}
          />
        </SidePanelFooter>
      </SidePanelContainer>
    );
  }
}

AlertVolumeConfig.propTypes = {
  className: PropTypes.string,
  onToast: PropTypes.func,
};

AlertVolumeConfig.defaultProps = {
  className: '',
  onToast: () => {},
};

export default AlertVolumeConfig;
