import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viCalendarEventOutline from '../../../icons/viCalendarEventOutline';
import viCalendarEventDotted from '../../../icons/viCalendarEventDotted';
import viCalendarEventFilled from '../../../icons/viCalendarEventFilled';
import color from '../../../sass/color.scss';
import './MonthDay.scss';

class MonthDay extends PureComponent {
  constructor(props) {
    super(props);

    const {
      disabled,
      hasEvents,
      showDay,
    } = props;

    this.dragRef = React.createRef();

    const droppable = !disabled && !hasEvents && showDay;

    this.state = {
      dragging: false,
      dragOver: false,
      droppable,
    };
  }

  componentDidUpdate() {
    const {
      disabled,
      hasEvents,
      showDay,
    } = this.props;

    const {
      droppable: currDroppable,
    } = this.state;

    const droppable = !disabled && !hasEvents && showDay;

    if (droppable !== currDroppable) {
      this.setState({
        droppable,
      });
    }
  }

  onSelect = () => {
    const {
      date,
      showDay,
      disabled,
      selectDisabled,
      hasEvents,
      onSelect,
    } = this.props;

    if (!showDay || ((disabled && !selectDisabled) || (disabled && selectDisabled && !hasEvents))) {
      // Do not allow the day to be selected
      return;
    }

    onSelect(date);
  };

  onDragStart = (e) => {
    const {
      dataTransfer,
    } = e;

    const {
      date,
      disabled,
    } = this.props;

    if (disabled) {
      // Do not allow the date to be dragged
      return;
    }

    const {
      dragRef: {
        current: dragRef,
      },
    } = this;

    const dragRefRect = dragRef.getBoundingClientRect();
    const crt = dragRef.cloneNode(true);
    // Safari 3.0+ "[object HTMLElementConstructor]"
    // eslint-disable-next-line wrap-iife, func-names, dot-notation, no-undef, max-len
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    const dragImage = isSafari ? dragRef : crt;

    document.body.appendChild(crt);
    dataTransfer.setDragImage(dragImage, dragRefRect.width / 2, dragRefRect.height / 2);

    // Set drag data
    dataTransfer.setData('calendar:day', date.format());

    this.setState({
      dragging: true,
    });
  };

  onDragEnd = () => {
    this.setState({
      dragging: false,
    });
  };

  onDragOver = (e) => {
    // Prevent default operation to allow dropping
    e.preventDefault();
  };

  onDragEnter = () => {
    // const {
    //   date,
    // } = this.props;

    const {
      dragOver,
      droppable,
    } = this.state;

    if (dragOver || !droppable) {
      // Already dragging over this day
      return;
    }

    // console.log('Drag onto', date.format());

    this.setState({
      dragOver: true,
    });
  };

  onDragLeave = () => {
    this.setState({
      dragOver: false,
    });
  };

  onDrop = (e) => {
    const {
      dataTransfer,
    } = e;

    const {
      date,
      onDrop,
    } = this.props;

    const {
      dragOver,
      droppable,
    } = this.state;

    if (dragOver) {
      this.setState({
        dragOver: false,
      });
    }

    if (!droppable) {
      // Do not allow dropping on disabled dates
      return;
    }

    const dragDate = new moment(dataTransfer.getData('calendar:day'));

    onDrop({
      from: dragDate,
      to: date,
    });
  };

  render() {
    const {
      className,
      date,
      showDay,
      selected,
      highlight,
      disabled,
      hasEvents,
      dragEvents,
    } = this.props;

    const {
      dragging,
      dragOver,
    } = this.state;

    const dayNumber = showDay
      ? date.format('D')
      : '';

    return (
      <div
        className={classNames('MonthDay', className, {
          'show-day': showDay,
          selected: selected && showDay,
          'has-icon': hasEvents,
          disabled,
        })}
        draggable={!disabled && dragEvents && hasEvents}
        onClick={this.onSelect}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDragEnter={this.onDragEnter}
      >
        <div className="day-container">
          <div
            className={classNames('day-text', {
              highlight: highlight && showDay,
              'icon-filled': hasEvents,
              selected: selected && showDay,
              dragging,
            })}
          >
            {dayNumber}
          </div>
        </div>

        {dragOver ? (
          <div
            className="drag-overlay"
            onDragLeave={this.onDragLeave}
            onDragOver={this.onDragOver}
            onDrop={this.onDrop}
          />
        ) : null}

        {hasEvents && showDay ? (
          <div>
            {!dragging ? (
              <VibeIcon
                className="day-icon"
                icon={viCalendarEventFilled}
                color={selected
                  ? color.secondary
                  : color.primary}
                size={36}
              />
            ) : null}

            <div
              ref={this.dragRef}
              style={{
                position: 'fixed',
                top: -36,
                left: 0,
              }}
            >
              <VibeIcon
                icon={viCalendarEventFilled}
                color={color.primary}
                size={36}
              />
            </div>
          </div>
        ) : null}

        {selected && !hasEvents && showDay ? (
          <VibeIcon
            className="day-icon"
            icon={viCalendarEventOutline}
            color={color.primary}
            size={36}
          />
        ) : null}

        {dragOver ? (
          <VibeIcon
            className="day-icon"
            icon={viCalendarEventDotted}
            color={color.primary}
            size={36}
          />
        ) : null}
      </div>
    );
  }
}

MonthDay.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  /** Date */
  date: PropTypes.instanceOf(moment).isRequired,
  /** Whether or not to show the day number */
  showDay: PropTypes.bool,
  /** When the day is selected */
  selected: PropTypes.bool,
  /** Highlight the day number */
  highlight: PropTypes.bool,
  /** Disable the day from being selected */
  disabled: PropTypes.bool,
  /** Does the day have any events? */
  hasEvents: PropTypes.bool,
  /** Can events be dragged */
  dragEvents: PropTypes.bool,
  /** Allow the disabled dates to be selected (while still looking disabled) */
  selectDisabled: PropTypes.bool,
  /** When the day is selected */
  onSelect: PropTypes.func,
  /** When an item is dropped on a day */
  onDrop: PropTypes.func,
};

MonthDay.defaultProps = {
  className: '',
  showDay: false,
  selected: false,
  highlight: false,
  disabled: false,
  hasEvents: false,
  dragEvents: false,
  selectDisabled: false,
  onSelect: () => {},
  onDrop: () => {},
};

export default MonthDay;
