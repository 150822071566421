import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  trim,
  isEqual,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import {
  convertDuration,
} from '../../../utils/TimeUtil';
import API from '../../../api';
import VibeTable from '../VibeTable';
import VibeModal from '../../VibeModal/VibeModal';
import ContentSteps from '../CellTypes/ContentSteps';
import ContentLabel from '../CellTypes/ContentLabel';
import ContentTags from '../CellTypes/ContentTags';
import TimestampCell from '../CellTypes/TimestampCell';
import AudioCell from '../CellTypes/AudioCell';
import CellTooltip from '../CellTypes/CellTooltip';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viStore from '../../../icons/viStore';
import viTime from '../../../icons/viTime';
import viDollar from '../../../icons/viDollar';
import viAddCircle from '../../../icons/viAddCircle';
import viCloseCircle from '../../../icons/viCloseCircle';
import color from '../../../sass/color.scss';
import './TableMessages.scss';

const tableId = 'table:messages';
const permissionPrefix = 'message';

class TableMessages extends PureComponent {
  constructor(props) {
    super(props);

    let {
      columns: columnNames,
    } = props;

    const {
      user,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
      disableSort,
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Add/Remove',
      defaultWidth: 77,
      icon: viAddCircle,
      locked: true,
    },
    {
      name: 'Add/Remove Quantity',
      defaultWidth: 120,
      icon: viAddCircle,
      locked: true,
    },
    {
      name: 'Message Name',
      searchAttr: 'name',
      defaultWidth: 266,
      resizable: true,
      searchable: true,
      sortable: !disableSort,
      autoFocus: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyId',
      sortAttr: 'companyName',
      valueAttr: 'companyName',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      searchSelector: 'company',
      sortable: !disableSort,
    },
    {
      name: 'Partner',
      searchAttr: 'partnerId',
      sortAttr: 'partnerName',
      valueAttr: 'partnerName',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      searchAdvanced: false,
      searchSelector: 'partner',
      sortable: !disableSort,
    },
    {
      name: 'Advertiser',
      searchAttr: 'advertiserName',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      searchAdvanced: false,
      searchSelector: 'advertiser',
      sortable: false,
    },
    {
      name: 'Requester',
      searchAttr: 'createdByUserName',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      sortable: !disableSort,
    },
    {
      name: 'Step',
      searchAttr: 'status',
      defaultWidth: 160,
      resizable: true,
      searchable: true,
      sortable: !disableSort,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Unassigned',
          value: 'submitted',
        },
        {
          label: 'Needs Rough Cut',
          value: 'assigned',
        },
        {
          label: 'Needs Final Cut',
          value: 'rough-cut-uploaded',
        },
        {
          label: 'Denied',
          value: 'denied',
        },
      ],
    },
    {
      name: 'Start Date',
      searchAttr: 'startDate',
      defaultWidth: 175,
      resizable: true,
    },
    {
      name: 'End Date',
      searchAttr: 'endDate',
      defaultWidth: 175,
      resizable: true,
    },
    {
      name: 'Due Date',
      searchAttr: 'dueDate',
      defaultWidth: 175,
      resizable: true,
      sortable: !disableSort,
    },
    {
      name: 'Requested',
      searchAttr: 'createdDate',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      sortable: !disableSort,
      datepicker: true,
    },
    {
      name: 'Last Updated',
      searchAttr: 'modifiedDate',
      defaultWidth: 230,
      resizable: true,
      sortable: !disableSort,
    },
    {
      name: 'Type',
      searchAttr: 'messageType',
      defaultWidth: 120,
      resizable: true,
      searchable: true,
      sortable: !disableSort,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Regular',
          value: 'std',
        },
        {
          label: 'Ad',
          value: 'ad',
        },
        {
          label: 'External Ads',
          value: 'ext-ad',
        },
        {
          label: 'Air Check',
          value: 'air',
        },
        {
          label: 'Spec Spot',
          value: 'spec',
        },
        {
          label: 'Sounder',
          value: 'sounder',
          disabled: true,
        },
      ],
    },
    {
      name: 'Script',
      searchAttr: '$text',
      valueAttr: 'script',
      defaultWidth: 300,
      resizable: true,
      searchable: true,
    },
    {
      name: 'Script Type',
      searchAttr: 'readType',
      defaultWidth: 135,
      resizable: true,
      searchable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Script Help',
          value: 'interpret',
        },
        {
          label: 'Exact',
          value: 'exact',
        },
      ],
    },
    {
      name: 'Locations Assigned',
      defaultWidth: 100,
      icon: viStore,
      resizable: true,
    },
    {
      name: 'Duration',
      searchAttr: 'durationSeconds',
      defaultWidth: 95,
      icon: viTime,
      resizable: true,
    },
    {
      name: 'Assigned Voice Talent',
      searchAttr: 'voiceTalentId',
      sortAttr: 'voiceTalentUserName',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      sortable: !disableSort,
      dropdownItems: [],
    },
    {
      name: 'Tags',
      searchAttr: 'tags.name',
      valueAttr: 'tags',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      editable: true,
    }];

    if (!user.can('message.content_curator') && columnNames.includes('Assigned Voice Talent')) {
      // remove voice talent column if they are not a content curator
      columnNames = columnNames.filter(name => name !== 'Assigned Voice Talent');
    }

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
      // only fetch voice talents if the column exists and the user has access to view
      voiceTalentsFetched: !columnNames.includes('Assigned Voice Talent'),
    };

    // listen for when sidebar data changes
    document.addEventListener('onSaveMessage', this.onUpdateTableData);
    document.addEventListener('onUpdateTableAssignedMessages', this.onUpdateTableAssignedData);
  }

  componentDidMount() {
    const {
      columnNames,
    } = this.state;

    if (columnNames.includes('Assigned Voice Talent')) {
      // fetch voice talent list
      this.getVoiceTalentList();
    }

    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      filters,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
      filters: prevFilters,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    } else if (fetch && !isEqual(filters, prevFilters)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onSaveMessage', this.onUpdateTableData);
    document.removeEventListener('onUpdateTableAssignedMessages', this.onUpdateTableAssignedData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * When an event asks the table to update the assigned table
   */
  onUpdateTableAssignedData = () => {
    const {
      assigned,
    } = this.props;

    if (assigned) {
      this.onUpdate({
        refresh: true,
      });
    }
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'archive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to archive ${row.name}?`,
          confirmApproveText: 'Yes, Archive',
          confirmApproveColor: color.error,
        });

        break;
      }

      case 'unarchive': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to unarchive ${row.name}?`,
          confirmApproveText: 'Yes, Unarchive',
          confirmApproveColor: color.success,
        });

        break;
      }

      default:
        break;
    }
  };

  onConfirmModal = async () => {
    const {
      collection,
      messageRequest,
      onRemove,
    } = this.props;

    const {
      confirmAction,
      confirmRow: {
        _id,
      },
    } = this.state;

    if (!_id) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'archive': {
        const response = messageRequest
          ? await API.MessageRequest.deactivate(_id)
          : await API.Message.deactivate(_id);

        const successText = messageRequest
          ? 'MESSAGEREQUEST.DEACTIVATED'
          : 'MESSAGE.DEACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // if using a local collection, remove from the table
          if (collection.length > 0) {
            const items = collection.filter(item => item._id === _id);

            items.forEach((item) => {
              // remove the item from the table
              onRemove(item);
            });
          }

          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      case 'unarchive': {
        const response = messageRequest
          ? await API.MessageRequest.reactivate({
            _id,
          })
          : await API.Message.reactivate({
            _id,
          });

        const successText = messageRequest
          ? 'MESSAGEREQUEST.REACTIVATED'
          : 'MESSAGE.REACTIVATED';
        const success = get(response, '[0].type') === successText;

        if (success) {
          // tell listening components to update the counts and table data
          this.onUpdate({
            refresh: true,
          });
        }

        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * Bulk Edit Selected Rows
   */
  onBulkEditApply = async ({
    selected,
    data,
  }) => {
    // bulk add tags to a message
    const response = await API.Message.bulkAddTags({
      mergeMode: 'merge',
      messages: selected.map(item => item._id),
      tags: data.tags.map(tag => tag._id),
    });

    const success = response.filter(result => result.type.indexOf('MESSAGE.BULK_APPLY_TAGS') < 0).length <= 0;

    if (success) {
      // refresh table data
      this.onUpdate({
        refresh: true,
      });
    }
  };

  /**
   * When the Bulk Archive Completes
   */
  onBulkArchive = () => {
    // tell listening components to update the counts and table data
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Remove a Message from the table
   */
  onRemoveMessage = (e, _rowId) => {
    const {
      onRemove,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onRemove(row);
  };

  /**
   * Add a Message to the table
   */
  onAddMessage = (e, _rowId) => {
    const {
      onAdd,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    onAdd(row);
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Fetch the voice talent list for dropdown
   */
  getVoiceTalentList = async () => {
    const voiceTalentList = await API.User.listVoiceTalent();
    const voiceTalents = voiceTalentList.map((voiceTalent) => {
      return {
        key: voiceTalent._id,
        label: trim(`${voiceTalent.fname || ''} ${voiceTalent.lname || ''}`),
        value: voiceTalent._id,
      };
    });

    this.setState((state) => {
      const {
        columnsDef,
      } = state;

      const voiceTalentColumn = find(columnsDef, { name: 'Assigned Voice Talent' });
      voiceTalentColumn.dropdownItems = [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...voiceTalents,
      ];

      return {
        columnsDef,
        voiceTalentsFetched: true,
      };
    });
  };

  /**
   * Get label style for the message type cell
   */
  getLabelStyle = (type) => {
    switch (type) {
      case 'ad':
        return {
          text: 'Ad',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'air':
        return {
          text: 'Air Check',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'spec':
        return {
          text: 'Spec Spot',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'sounder':
        return {
          text: 'Sounder',
          textStyle: {
            background: color.royalBlue16,
            color: color.royalBlue,
          },
        };

      case 'ext':
        return {
          text: 'Uploaded',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'ext-ad':
        return {
          text: 'External Ads',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      case 'std':
      default:
        return {
          text: 'Regular',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Get the custom class for the column
   */
  getMessageClassName = ({
    started,
    expired,
  }) => {
    if (expired) {
      return 'message-expired';
    }

    if (!started) {
      return 'message-not-started';
    }

    return null;
  };

  /**
   * Fetch data for the table
   * Only request and return the response from the API or collection
   */
  fetchData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    const {
      rows,
      // totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    return rows;
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      collection,
      highlight,
      messageRequest,
    } = this.props;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');
    const customClassName = !messageRequest
      ? this.getMessageClassName({
        started: row.started,
        expired: row.expired,
      })
      : '';

    switch (column.name) {
      case 'Add/Remove': {
        const assigned = find(collection, { _rowId: row._rowId }) !== undefined;

        return (
          <VibeIcon
            name={row._rowId}
            icon={assigned
              ? viCloseCircle
              : viAddCircle}
            color={assigned
              ? color.error
              : color.success}
            hoverColor={assigned
              ? color.error75
              : color.success75}
            size={24}
            tooltip={assigned
              ? 'Remove Message'
              : 'Add Message'}
            tooltipProps={{
              placement: 'right',
            }}
            onClick={assigned
              ? this.onRemoveMessage
              : this.onAddMessage}
          />
        );
      }

      case 'Add/Remove Quantity': {
        const assignedCount = highlight.filter(item => item._id === row._id).length;

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <VibeIcon
              name={row._rowId}
              icon={viCloseCircle}
              color={color.error}
              hoverColor={color.error75}
              size={24}
              onClick={this.onRemoveMessage}
            />

            <div
              style={{
                width: 24,
                textAlign: 'center',
              }}
            >
              {assignedCount}
            </div>

            <VibeIcon
              name={row._rowId}
              icon={viAddCircle}
              color={color.success}
              hoverColor={color.success75}
              size={24}
              onClick={this.onAddMessage}
            />
          </div>
        );
      }

      case 'Message Name': {
        const audioUrl = row.url || row.finalCutUrl || row.roughCutUrl;
        const playControlColor = !messageRequest && row.expired
          ? color.error
          : color.success;

        return (
          <AudioCell
            className={classNames('cell-content', customClassName)}
            id={row._id}
            name={row.name}
            src={audioUrl}
            disabled={!audioUrl}
            color={playControlColor}
            durationSeconds={row.durationSeconds}
            playText="Play Message"
            pauseText="Pause Message"
            icon={row.messageType === 'ad'
              ? viDollar
              : null}
          />
        );
      }

      case 'Step':
        return (
          <ContentSteps
            status={value}
            changeRequested={row.changeRequested}
          />
        );

      case 'Start Date':
      case 'End Date':
      case 'Due Date':
        return (
          <TimestampCell
            className={customClassName}
            time={value}
            format="ddd, MMM DD, YYYY"
          />
        );

      case 'Requested':
      case 'Last Updated':
        return (
          <TimestampCell
            className={customClassName}
            time={value}
          />
        );

      case 'Type': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(row.messageType);

        return (
          <ContentLabel
            className={classNames('cell-content', customClassName)}
            text={text}
            textStyle={textStyle}
          />
        );
      }

      case 'Script Type': {
        const scriptType = value === 'interpret'
          ? 'Script Help'
          : 'Exact';

        return (
          <CellTooltip title={scriptType}>
            <div className={classNames('cell-content', customClassName)}>
              {scriptType}
            </div>
          </CellTooltip>
        );
      }

      case 'Duration': {
        const duration = convertDuration(value, 'm:ss');

        return (
          <CellTooltip title={duration}>
            <div className={classNames('cell-content', customClassName)}>
              {duration}
            </div>
          </CellTooltip>
        );
      }

      case 'Assigned Voice Talent': {
        const voiceTalent = trim(`${row.voiceTalentUserFname || ''} ${row.voiceTalentUserLname || ''}`);

        return (
          <CellTooltip title={voiceTalent}>
            <div className={classNames('cell-content', customClassName)}>
              {voiceTalent}
            </div>
          </CellTooltip>
        );
      }

      case 'Tags':
        return (
          <ContentTags
            className={classNames('cell-content', customClassName)}
            tags={row.tags}
            hideIcon
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className={classNames('cell-content', customClassName)}>
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      disableSort,
      messageRequest,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
      voiceTalentsFetched,
    } = this.state;

    if (!voiceTalentsFetched) {
      return null;
    }

    return (
      <div className={classNames('Table', 'TableMessages', className)}>
        <VibeTable
          {...this.props}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={!disableSort
            ? sortBy
            : {}}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          bulkArchive={messageRequest
            ? API.MessageRequest.deactivateBulk
            : API.Message.deactivateBulk}
          onSelectMenuItem={this.onSelectMenuItem}
          onBulkEditApply={this.onBulkEditApply}
          onBulkArchive={this.onBulkArchive}
          onReset={this.onReset}
          onFetch={this.fetchData}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableMessages.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  messageRequest: PropTypes.bool,
  /** Disable sorting columns (used for shuffled message blocks) */
  disableSort: PropTypes.bool,
  ...tablePropsType,
};

TableMessages.defaultProps = {
  messageRequest: false,
  disableSort: false,
  ...tablePropsDefault,
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default withRouter(connect(mapStateToProps)(TableMessages));
