import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  size,
  kebabCase,
  isUndefined,
} from 'lodash';
import withRouter from '../../wrappers/withRouter';
import {
  submenuPropsType,
  submenuPropsDefault,
} from '../../types/submenuPropsType';
import {
  clearParams,
} from '../../helpers/Navigation';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import VibeIcon from '../VibeIcon/VibeIcon';
import viRestore from '../../icons/viRestore';
import viDensityComfortable from '../../icons/viDensityComfortable';
import viDensityCompact from '../../icons/viDensityCompact';
import viDensityNormal from '../../icons/viDensityNormal';
import viCloseCircle from '../../icons/viCloseCircle';
import TablePaginator from './TablePaginator';
import BulkActions from './Toolbar/BulkActions';
import ApplyCancel from './Toolbar/ApplyCancel';
import color from '../../sass/color.scss';
import './Toolbar.scss';

class Toolbar extends PureComponent {
  onClickRestore = () => {
    const {
      onReset,
    } = this.props;

    onReset();
  };

  onChangeDensity = (e, density) => {
    const {
      onChangeDensity,
    } = this.props;

    onChangeDensity(density);
  };

  /**
   * When the user wants to clear all existing filters
   */
  onClickClearFilters = () => {
    const {
      paginatorProps: {
        urlFilters,
      },
      history,
      onClearFilters,
    } = this.props;

    if (urlFilters) {
      const path = clearParams();
      history(path);
    }

    onClearFilters();
  };

  /**
   * When cancel is clicked for a single item edit
   */
  onClickCancel = () => {
    const {
      onSingleItemCancel,
    } = this.props;

    onSingleItemCancel();
  };

  /**
   * When cancel is clicked for a single item edit
   */
  onClickApply = () => {
    const {
      onSingleItemApply,
    } = this.props;

    onSingleItemApply();
  };

  render() {
    const {
      style,
      params: {
        type,
      },
      submenu,
      submenuProps: {
        items,
      },
      paginator,
      paginatorProps,
      toggle,
      toggleProps,
      bulk,
      bulkProps,
      filters,
      density,
      page,
      selectedIds,
      isEditItem,
      customButtons,
      customHtmlLeft,
      customHtmlRight,
      pageSize,
      pageSizes,
      onBulkEdit,
      onBulkEditApply,
      onBulkAdd,
      onBulkBlock,
      onBulkRemove,
      onBulkArchive,
      onChangeToggle,
      onChangePage,
      onChangePageSize,
    } = this.props;

    let numFilters = size(filters);

    if (!isUndefined(filters.active)) {
      // Remove active from the total count since the table requires it
      numFilters -= 1;
    }

    const activeItem = submenu
      ? items.filter(item => type === kebabCase(item.name) && item.enabled)[0]
      : false;

    return (
      <div
        className="Toolbar"
        style={style}
      >
        <div className="left-container">
          {customHtmlLeft}

          {paginator ? (
            <div className="paginator-container">
              <TablePaginator
                className={paginatorProps.className}
                page={page}
                label={activeItem
                  ? activeItem.paginatorLabel || activeItem.name
                  : paginatorProps.label}
                totalItems={paginatorProps.totalItems || 0}
                pageSize={pageSize}
                pageSizes={pageSizes}
                onChangePageSize={onChangePageSize}
                urlPaging={paginatorProps.urlPaging}
                onChangePage={onChangePage}
              />
            </div>
          ) : null}

          {numFilters > 0 ? (
            <div className="filters">
              <div className="filter-content">
                <VibeIcon
                  className="filter-icon"
                  icon={viCloseCircle}
                  color={color.primary}
                  hoverColor={color.secondary}
                  size={16}
                  onClick={this.onClickClearFilters}
                />

                Clear {numFilters} {numFilters === 1 ? 'Filter' : 'Filters'}
              </div>
            </div>
          ) : null}
        </div>

        <div className="toolbar-container">
          {bulk && selectedIds.length > 0 && !isEditItem ? (
            <BulkActions
              selectedIds={selectedIds}
              edit={bulkProps.edit}
              editType={bulkProps.editType}
              add={bulkProps.add}
              block={bulkProps.block}
              remove={bulkProps.remove}
              archive={bulkProps.archive}
              disableEdit={bulkProps.disableEdit}
              disableAdd={bulkProps.disableAdd}
              disableBlock={bulkProps.disableBlock}
              disableRemove={bulkProps.disableRemove}
              // do not allow bulk archive when viewing archived data
              disableArchive={bulkProps.disableArchive || toggleProps.toggled}
              onBulkEdit={onBulkEdit}
              onBulkEditApply={onBulkEditApply}
              onBulkAdd={onBulkAdd}
              onBulkBlock={onBulkBlock}
              onBulkRemove={onBulkRemove}
              onBulkArchive={onBulkArchive}
            />
          ) : null}

          {isEditItem ? (
            <ApplyCancel
              onCancel={this.onClickCancel}
              onApply={this.onClickApply}
            />
          ) : null}

          {customButtons.length > 0 ? (
            <div className="custom-buttons">
              {customButtons}
            </div>
          ) : null}

          {toggle ? (
            <div className="toggle-main">
              {!toggleProps.tooltip ? (
                <div className="toggle-active">
                  <div className="switch-label">
                    <label htmlFor="layout-toggle" className="switch">
                      <p className="option-title">
                        {toggleProps.label}
                      </p>
                    </label>
                  </div>

                  <div>
                    <input
                      id="layout-toggle"
                      className="switch__input"
                      type="checkbox"
                      onChange={onChangeToggle}
                      checked={toggleProps.toggled}
                    />
                    <div
                      className="switch__checkbox"
                      onClick={onChangeToggle}
                    />
                  </div>
                </div>
              ) : (
                <VibeTooltip
                  title={toggleProps.label}
                  placement="top"
                >
                  <div className="toggle-active">
                    <input
                      id="layout-toggle"
                      className="switch__input"
                      type="checkbox"
                      onChange={onChangeToggle}
                      checked={toggleProps.toggled}
                    />
                    <div
                      className="switch__checkbox"
                      onClick={onChangeToggle}
                    />
                  </div>
                </VibeTooltip>
              )}
            </div>
          ) : null}

          <VibeIcon
            id="density-comfortable"
            className="density-icon"
            name="comfortable"
            icon={viDensityComfortable}
            color={density === 'comfortable'
              ? color.primary
              : color.whiteSmoke}
            hoverColor={color.primary}
            size={24}
            tooltip="Comfortable"
            tooltipProps={{
              placement: 'top',
            }}
            onClick={this.onChangeDensity}
          />

          <VibeIcon
            id="density-normal"
            className="density-icon"
            name="normal"
            icon={viDensityNormal}
            color={density === 'normal'
              ? color.primary
              : color.whiteSmoke}
            hoverColor={color.primary}
            size={24}
            tooltip="Normal"
            tooltipProps={{
              placement: 'top',
            }}
            onClick={this.onChangeDensity}
          />

          <VibeIcon
            id="density-compact"
            className="density-icon"
            name="compact"
            icon={viDensityCompact}
            color={density === 'compact'
              ? color.primary
              : color.whiteSmoke}
            hoverColor={color.primary}
            size={24}
            tooltip="Compact"
            tooltipProps={{
              placement: 'top',
            }}
            onClick={this.onChangeDensity}
          />

          <VibeIcon
            id="restore-default"
            className="density-icon"
            icon={viRestore}
            color={color.whiteSmoke}
            hoverColor={color.primary}
            size={24}
            tooltip="Restore Default Settings"
            tooltipProps={{
              placement: 'top',
            }}
            onClick={this.onClickRestore}
          />

          {customHtmlRight}
        </div>
      </div>
    );
  }
}

Toolbar.propTypes = {
  style: PropTypes.object,
  density: PropTypes.string,
  /** Show Paginator */
  paginator: PropTypes.bool.isRequired,
  /** Paginator Properties */
  paginatorProps: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    totalItems: PropTypes.number,
    urlPaging: PropTypes.bool,
  }).isRequired,
  /** Show Toggle */
  toggle: PropTypes.bool.isRequired,
  /** Toggle Properties */
  toggleProps: PropTypes.shape({
    toggled: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.bool.isRequired,
  }).isRequired,
  /** Show Bulk Actions */
  bulk: PropTypes.bool.isRequired,
  /** Bulk Properties */
  bulkProps: PropTypes.shape({
    edit: PropTypes.bool,
    add: PropTypes.bool,
    block: PropTypes.bool,
    remove: PropTypes.bool,
    archive: PropTypes.bool,
    disableEdit: PropTypes.bool,
    disableAdd: PropTypes.bool,
    disableBlock: PropTypes.bool,
    disableRemove: PropTypes.bool,
    disableArchive: PropTypes.bool,
  }),
  /** Show Submenu */
  submenu: PropTypes.bool,
  /** Submenu Properties */
  submenuProps: submenuPropsType,
  /** Selected IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Table Filters */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Current page */
  page: PropTypes.number,
  /** Is the table editing any items */
  isEditItem: PropTypes.bool,
  /** Custom Buttons for the toolbar */
  customButtons: PropTypes.arrayOf(PropTypes.element),
  /** Custom HTML for the left side */
  customHtmlLeft: PropTypes.element,
  /** Custom HTML for the right side */
  customHtmlRight: PropTypes.element,
  /** Items per page */
  pageSize: PropTypes.number,
  /** Items per page options */
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  /** When the density is changed */
  onChangeDensity: PropTypes.func,
  /** When items per page is changed */
  onChangePageSize: PropTypes.func,
  /** When the toggle is changed */
  onChangeToggle: PropTypes.func.isRequired,
  /** When bulk edit is toggled */
  onBulkEdit: PropTypes.func,
  /** When bulk add is toggled */
  onBulkAdd: PropTypes.func,
  /** When bulk block is toggled */
  onBulkBlock: PropTypes.func,
  /** When bulk edit changes are applied */
  onBulkEditApply: PropTypes.func,
  /** When bulk remove is toggled */
  onBulkRemove: PropTypes.func,
  /** When bulk archive is toggled */
  onBulkArchive: PropTypes.func,
  /** When a single item being edited is applied */
  onSingleItemApply: PropTypes.func,
  /** When a single item being edited is canceled */
  onSingleItemCancel: PropTypes.func,
  /** When the page is changed (not using URL paging) */
  onChangePage: PropTypes.func,
  /** When all the filters are cleared */
  onClearFilters: PropTypes.func,
  /** When the table is reset */
  onReset: PropTypes.func,
};

Toolbar.defaultProps = {
  style: {},
  density: 'normal',
  bulkProps: {
    edit: false,
    add: false,
    block: false,
    remove: false,
    archive: false,
    disableEdit: false,
    disableAdd: false,
    disableBlock: false,
    disableRemove: false,
    disableArchive: false,
  },
  submenu: false,
  submenuProps: submenuPropsDefault,
  selectedIds: [],
  filters: {},
  page: 1,
  isEditItem: false,
  customButtons: [],
  customHtmlLeft: null,
  customHtmlRight: null,
  pageSize: 50,
  pageSizes: [50],
  onChangeDensity: () => {},
  onChangePageSize: () => {},
  onBulkEdit: () => {},
  onBulkAdd: () => {},
  onBulkBlock: () => {},
  onBulkEditApply: () => {},
  onBulkRemove: () => {},
  onBulkArchive: () => {},
  onSingleItemApply: () => {},
  onSingleItemCancel: () => {},
  onChangePage: () => {},
  onClearFilters: () => {},
  onReset: () => {},
};

export default withRouter(Toolbar);
