import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import color from '../../../sass/color.scss';
import './DeviceAttributeListItem.scss';

class DeviceAttributeListItem extends PureComponent {
  onClick = () => {
    const {
      deviceAttribute,
      allowSelect,
      onClick,
      onSelect,
    } = this.props;

    if (allowSelect) {
      // Do not allow clicking the item if the checkbox is shown
      onSelect(deviceAttribute);
      return;
    }

    onClick(deviceAttribute);
  };

  render() {
    const {
      className,
      active,
      deviceAttribute,
      selected,
      allowSelect,
      allowSelectItem,
    } = this.props;

    return (
      <div className={classNames('DeviceAttributeListItem', className)}>
        <div
          className={classNames('attribute-info', { active, selectable: allowSelectItem })}
          onClick={this.onClick}
        >
          {allowSelect ? (
            <div>
              <VibeCheckbox
                size={16}
                color={color.success}
                checked={selected}
              />
            </div>
          ) : null}

          <div className="attribute-name">
            {deviceAttribute.name} : {deviceAttribute.value}
          </div>
        </div>
      </div>
    );
  }
}

DeviceAttributeListItem.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Device Attribute Data */
  deviceAttribute: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    active: PropTypes.bool,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  }).isRequired,
  /** Is the attribute active */
  active: PropTypes.bool,
  /** Is the attribute selected */
  selected: PropTypes.bool,
  /** Allow selection of attributes */
  allowSelect: PropTypes.bool,
  /** Allow selection of the single item */
  allowSelectItem: PropTypes.bool,
  /** When an attribute is selected */
  onSelect: PropTypes.func,
  /** When an attribute is clicked */
  onClick: PropTypes.func,
};

DeviceAttributeListItem.defaultProps = {
  className: '',
  active: false,
  selected: false,
  allowSelect: false,
  allowSelectItem: false,
  onSelect: () => {},
  onClick: () => {},
};

export default DeviceAttributeListItem;
