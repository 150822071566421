import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import VibeButton from '../VibeButton/VibeButton';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCheckCircle from '../../icons/viCheckCircle';
import viEdit from '../../icons/viEdit';
import defaultLocationImage from '../../assets/default_location.png';
import color from '../../sass/color.scss';
import './UploadDropzone.scss';

class UploadDropzone extends Component {
  constructor(props) {
    super(props);

    const {
      image,
    } = props;

    this.state = {
      image: image || defaultLocationImage,
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const {
      type,
      allowUpload,
      onDrop,
    } = this.props;

    if (!allowUpload) {
      return;
    }

    const files = [];

    acceptedFiles.forEach((file) => {
      files.push({
        isComplete: false,
        error: null,
        progress: 0,
        file,
      });
    });

    rejectedFiles.forEach((file) => {
      files.push({
        isComplete: false,
        error: 'Wrong file type.',
        progress: 0,
        file,
      });
    });

    if (type === 'image' && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        const fileAsDataURL = reader.result;
        // Save image data to state to show as a preview
        this.setState({
          image: fileAsDataURL,
        });
      };

      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');

      reader.readAsDataURL(file);
    }

    onDrop(files);
  };

  /**
   * When the user drags over the drop container
   * This is a fix to the drop event not firing on dialogs
   */
  onDragOver = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      type,
      style,
      dragOverStyle,
      acceptedMimeTypes,
      placeholder,
      allowUpload,
      fieldId,
      disabled,
    } = this.props;

    const {
      image,
    } = this.state;

    let placeholderHtml;

    if (placeholder) {
      placeholderHtml = placeholder;
    } else {
      placeholderHtml = type === 'image' ? (
        <div className="drag-container">
          <div className="image-container">
            <img
              className="image-placeholder"
              src={image}
              alt="Placeholder"
            />
          </div>

          {allowUpload ? (
            <div className="edit-icon-container">
              <VibeIcon
                icon={viEdit}
                color={color.white}
                size={16}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div>
          <p className="drag-info">
            Drag and drop song files here, or browse your computer
          </p>
          <div>
            <VibeButton
              text="Browse..."
              btnColor="purple"
              textColor="white"
            />
          </div>
        </div>
      );
    }

    const dragOverHtml = type === 'image' ? (
      <div>
        <VibeIcon
          icon={viCheckCircle}
          color={color.success}
          size={24}
        />
      </div>
    ) : (
      <p>
        Drop files here...
      </p>
    );

    return (
      <div
        className={classNames('UploadDropzone', { allow: allowUpload && !disabled })}
        id={fieldId}
      >
        <Dropzone
          accept={acceptedMimeTypes}
          // causes error when selecting a file stored in the cloud (or network storage)
          useFsAccessApi={false}
          onDrop={this.onDrop}
          onDragOver={this.onDragOver}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps()}
                className={classNames('dropzone', `dropzone-${type}`, { 'dropzone--isActive': isDragActive })}
                style={style}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />

                {placeholderHtml}

                {isDragActive && allowUpload ? (
                  <div
                    className="drag-over-container"
                    style={{
                      background: color.white,
                      ...dragOverStyle,
                    }}
                  >
                    {dragOverHtml}
                  </div>
                ) : null}
              </div>
            );
          }}
        </Dropzone>

        {disabled && (
          <div className="disabled" />
        )}
      </div>
    );
  }
}

UploadDropzone.propTypes = {
  acceptedMimeTypes: PropTypes.object,
  /** Placeholder Element */
  placeholder: PropTypes.element,
  /** Drag over container style */
  dragOverStyle: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  fieldId: PropTypes.string,
  image: PropTypes.string,
  /** Custom style */
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  /** Allow a file to be dropped */
  allowUpload: PropTypes.bool,
  onDrop: PropTypes.func,
};

UploadDropzone.defaultProps = {
  acceptedMimeTypes: {},
  fieldId: 'upload-dropzone',
  placeholder: null,
  image: null,
  dragOverStyle: {},
  style: {},
  disabled: false,
  type: null,
  allowUpload: false,
  onDrop: () => {},
};

export default UploadDropzone;
