import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import {
  color,
} from 'vibeguide';

const versionStyle = {
  padding: 4,
  color: color.primary,
  backgroundColor: color.primary16,
  border: `1px solid ${color.primary}`,
  borderRadius: 4,
  fontSize: 10,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

function AppVersions({
  webVersion,
  apiVersion,
}) {
  return (
    <div
      className="AppVersions"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '8px 8px 0 16px',
      }}
    >
      <div
        style={versionStyle}
      >
        API:
        {apiVersion || (
          <CircularProgress
            color="inherit"
            size={8}
            style={{
              marginLeft: 4,
            }}
          />
        )}
      </div>

      <div
        style={{
          ...versionStyle,
          marginLeft: 8,
        }}
      >
        Web: {webVersion}
      </div>
    </div>
  );
}

AppVersions.propTypes = {
  webVersion: PropTypes.string,
  apiVersion: PropTypes.string,
};

AppVersions.defaultProps = {
  webVersion: null,
  apiVersion: null,
};

export default AppVersions;
