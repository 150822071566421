import React from 'react';
import {
  isFinite,
} from 'lodash';
import {
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from '@mui/material';
import color from '../../sass/color.scss';

function Progress({
  value,
  valueMax,
  pacing,
  disabled,
}) {
  const getValuePercentage = () => {
    const percentage = valueMax > 0
      ? (value / valueMax) * 100
      : 0;

    // force showing 100% as a max
    return percentage <= 100
      ? percentage
      : 100;
  };

  const pacingPercentage = pacing * 100;
  // ensure the max value is not 0 (shows progress bar at 100%)
  const valuePercentage = getValuePercentage();

  const calculateThresholdColor = () => {
    switch (true) {
      // if percentage is less than 10% - RED
      case pacingPercentage < 10:
        return color.error;
      // if percentage is less than 90% but greater than 10% - YELLOW
      case pacingPercentage < 90:
        return color.warning;
      // if percentage is less than 100% but greaters than 90% - GREEN
      case pacingPercentage < 100:
        return color.success;
      default:
        return color.primary;
    }
  };

  const progressColor = calculateThresholdColor();
  const isValueNumber = isFinite(value);
  const useValue = isValueNumber
    ? value.toLocaleString()
    : value;
  const useValueMax = isFinite(valueMax)
    ? valueMax.toLocaleString()
    : valueMax;

  if (!isValueNumber) {
    return (
      <div className="Progress" />
    );
  }

  return (
    <div className="Progress">
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography sx={{ color: progressColor }}>
            {useValue}
            <span style={{ paddingLeft: 5, color: color.manatee }}>
              of {useValueMax}
            </span>
          </Typography>
        </Grid>
      </Grid>

      <LinearProgress
        variant="determinate"
        value={valuePercentage < 5 && valuePercentage > 0
          // display 5% by default if progress is greater than 0 but less than 5%
          ? 5
          // display the actual percentage
          : valuePercentage}
        disabled={disabled}
        sx={{
          height: 8,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            borderRadius: 5,
            backgroundColor: color.manatee16,
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: progressColor, // Set the color of the progress bar
          },
        }}
      />
    </div>
  );
}

export default Progress;
