import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import head from 'lodash/head';
import last from 'lodash/last';
import capitalize from 'lodash/capitalize';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  API,
  StorageUtil,
  GlobalActions,
  Notes,
  Tag,
  VibeAccordion,
  VibeAlert,
  VibeButtonNew,
  VibeIcon,
  VibeTooltip,
  viDownload,
  viEye,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import SubNavigation from '../../../Header/SubNavigation';
import SegmentSchedule from './SegmentSchedule';
import SegmentInformation from './SegmentInformation';
import InfoCards from '../OrderDashboard/InfoCards/InfoCards';
import AllocationCompanies from '../../Flights/Sidebar/Allocations/AllocationCompanies';
import OrderCreatives from './OrderCreatives';
import './OrderSegment.scss';

function OrderSegment({
  user,
  setPanel,
}) {
  const [data, setData] = useState({});
  const [accordionExpanded, setAccordionExpanded] = useState(StorageUtil.getLocal('orders:expanded', true));

  const {
    id: orderId,
    segmentId,
  } = useParams();

  const {
    order,
    segment,
  } = data;

  const updateTitle = (status) => {
    return `${capitalize(status)} Orders`;
  };

  /**
   * When the Segment Information Accordion is changed
   */
  const onChangeAccordion = (e, expanded) => {
    StorageUtil.setLocal('orders:expanded', expanded);
    setAccordionExpanded(expanded);
  };

  const getData = async () => {
    if (!segmentId || !orderId) {
      return;
    }

    const newData = {};

    // always get segment details
    newData.segment = await API.Order.Segment.get({
      _id: orderId,
      segmentId,
    });

    setData({
      ...data,
      ...newData,
    });
  };

  /**
   * Create a Note
   */
  const onCreateNote = async (note, onSuccess, onError) => {
    try {
      const response = await API.Order.Segment.addNote({
        _id: orderId,
        segmentId,
        note,
      });

      const success = get(response, '[0].type') === 'ORDERSEGMENT.NOTE_ADDED';

      if (success) {
        getData();
        onSuccess();
      } else {
        onError('Error Creating Note');
      }
    } catch (err) {
      // error adding the note
      onError(err.message);
    }
  };

  /**
   * Close the Main Sidebar
   * Allocation Companies is shown on the second panel, we need to remove the first panel as well
   */
  const onCloseSidebar = () => {
    setPanel({
      show: false,
      children: null,
    });
  };

  /**
   * When the user clicks location warnings icon
   */
  const onClickLocations = () => {
    setPanel({
      show: true,
      width: 1,
      extraPanel: {
        show: true,
        width: window.innerWidth,
        children: (
          <AllocationCompanies
            companies={get(data, 'segment.companies', [])}
            onClose={onCloseSidebar}
          />
        ),
      },
    });
  };

  /**
   * Export locations to CSV file
   */
  const onExport = async () => {
    const segment = await API.Order.Segment.getExpandedLocations({
      _id: orderId,
      segmentId,
    });

    const segmentLocationsIds = segment[0].locations.map((location) => {
      return location.locationId;
    });

    const locations = user.partnerId && user.accountType === 'partner' ? await API.PartnerUser.getLocations({
      _id: user._id,
      pageSize: -1,
      filters: {
        _id: segmentLocationsIds,
      },
    }, {
      headers: {
        Accept: 'text/csv',
      },
    }) : await API.User.getLocations({
      _id: user._id,
      pageSize: -1,
      filters: {
        _id: segmentLocationsIds,
      },
    }, {
      headers: {
        Accept: 'text/csv',
      },
    });

    const blob = new Blob([locations], { type: 'text/csv;charset=utf-8' });
    const timestamp = moment().format('YYYY-MM-DD_HH-mm-ss');
    saveAs(blob, `locations-${timestamp}.csv`);
  };

  // Refresh segment data if segmentId changes
  useEffect(() => {
    getData();
  }, [segmentId, orderId]);

  const breadcrumbs = (segment?.orderName && segment?.name) && [
    {
      title: 'Dashboard',
      linkUrl: '../../orders/dashboard',
    },
    {
      title: updateTitle(segment?.orderStatus),
      linkUrl: `../../orders/dashboard/${segment?.orderStatus}`,
    },
    {
      title: segment.orderName,
      linkUrl: '../details',
    },
    {
      title: segment.name,
      linkUrl: './',
    },
  ];

  if (!segment) {
    return null;
  }

  // Get segment day parts for the earliest start and latest end time
  const dayParts = get(segment, 'dayParts', []) || [];

  // Sort the day parts to make sure we get the correct start / stop time
  const dayPartSortStart = sortBy(dayParts, 'startTime');
  const dayPartSortEnd = sortBy(dayParts, 'endTime');

  // Get the earliest start time and the latest end time from the day parts
  const earliestStartTime = get(head(dayPartSortStart), 'startTime', 'Unknown');
  const latestEndTime = get(last(dayPartSortEnd), 'endTime', 'Unknown');

  const isViewOnly = segment.partnerAccessLevel === 'view-only'
    || segment.partnerAccessLevel === 'hidden';

  return (
    <div className="OrderSegment">
      <SubNavigation
        breadcrumbs={breadcrumbs}
      />

      <div className="segment-header">
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 12,
            }}
          >
            <div className="segment-title">
              Order Detail Summary
            </div>

            {isViewOnly && (
              <Tag
                tag={{
                  name: 'View-Only',
                  icon: viEye,
                }}
                style={{
                  background: color.primary16,
                  color: color.primary,
                  fontSize: 12,
                }}
              />
            )}
          </div>

          <div className="segment-stats">
            <div className="stat">
              <div>Companies</div>
              <div className="value">{segment?.locationSummary?.companies || 0}</div>
            </div>

            <div className="stat">
              <div>Banners</div>
              <div className="value">{segment?.locationSummary?.banners || 0}</div>
            </div>

            <VibeTooltip
              title="View Allocations"
              placement="right"
            >
              <div
                className="stat action"
                onClick={onClickLocations}
              >
                <div>Locations</div>

                {get(segment, 'warnings.length', 0) > 0 && (
                  <VibeAlert
                    style={{
                      cursor: 'pointer',
                      marginLeft: 4,
                      fontSize: 12,
                    }}
                    severity="warning"
                    alerts={segment.warnings || []}
                    size={24}
                    iconOnly
                  />
                )}

                <div className="value action">
                  {segment?.locationSummary?.locations || 0}
                </div>
              </div>
            </VibeTooltip>
            <VibeButtonNew
              key="btn-export"
              variant="outlined"
              text="Export"
              color={color.violetVibe}
              icon={(
                <VibeIcon
                  icon={viDownload}
                  color={color.violetVibe}
                  size={16}
                />
              )}
              tooltip="Export Locations to CSV"
              tooltipProps={{
                placement: 'top',
              }}
              onClick={onExport}
            />,
          </div>
        </div>

        <SegmentSchedule
          startDate={segment.startDate}
          endDate={segment.endDate}
          startTime={earliestStartTime}
          endTime={latestEndTime}
          days={segment.daysOfWeek || []}
        />
      </div>

      {segment.reportingAuthority === 'vibenomics' && (
        <InfoCards
          data={segment}
        />
      )}

      <VibeAccordion
        style={{
          margin: '2rem',
          marginTop: 0,
        }}
        title="Segment Information"
        expanded={accordionExpanded}
        onChange={onChangeAccordion}
      >
        <SegmentInformation
          segmentType={segment.segmentType}
          mediaFormat={segment.mediaFormat}
          spotType={segment.spotType}
          spotSelectionStrategy={segment.spotSelectionStrategy}
          frequencyCapped={segment.frequencyCapped}
          frequencyCap={segment.frequencyCap}
          allowBackToBackAdDelivery={segment.allowBackToBackAdDelivery}
          allocateRemainder={segment.allocateRemainder}
        />
      </VibeAccordion>

      {dayParts.length > 0 && !isViewOnly && (
        <OrderCreatives
          segment={segment}
          order={order}
          orderId={orderId}
          segmentId={segmentId}
          allowCreativeManagement={segment.adProvider === 'vibenomics'}
        />
      )}

      {!isViewOnly && (
        <div
          style={{
            margin: '2rem',
          }}
        >
          <Grid spacing={2} container>
            <Grid xs={6} item>
              <Notes
                notes={segment?.activity?.map((item) => {
                  return {
                    _id: item._id,
                    type: item.activityType,
                    userId: item.userId,
                    username: `${item.userFName} ${item.userLName}`,
                    date: item.date,
                    avatarUrl: item.userImageUrl,
                    note: item.note,
                  };
                })}
                allowCreate={user.can('order_segment.modify')}
                numVisibleMessages={5}
                onCreate={onCreateNote}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSegment);
