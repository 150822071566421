import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import API from '../../../api';
import PanelItemLink from '../PanelItems/PanelItemLink';
import DeviceAttributeListItem from './DeviceAttributeListItem';
import SearchInput from '../../SearchInput/SearchInput';
import viAdd from '../../../icons/viAdd';
import viStore from '../../../icons/viStore';
import viTrash from '../../../icons/viTrash';
import color from '../../../sass/color.scss';
import './DeviceAttributesList.scss';

class DeviceAttributesList extends PureComponent {
  constructor(props) {
    super(props);

    this.isAtBottom = false;

    // Timeout to run search
    this.searchTimeout = null;

    this.state = {
      searchValue: '',
      searchResults: [],
      pageNumber: 1,
      hasMoreItems: false,
    };
  }

  componentDidMount() {
    this.getDeviceAttributes();
  }

  componentDidUpdate(prevProps) {
    const {
      resetList,
    } = this.props;

    const {
      resetList: prevResetList,
    } = prevProps;

    if (resetList && !prevResetList) {
      this.setState({
        pageNumber: 1,
        hasMoreItems: false,
      }, this.getDeviceAttributes);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.searchTimeout);
  }

  onScroll = (e) => {
    const {
      target: {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
    } = e;

    const {
      hasMoreItems,
    } = this.state;

    // Pad the bottom to trigger the next page before hitting the bottom
    const padBottom = 100;
    const scrollBottom = scrollTop + clientHeight;

    const atBottom = scrollBottom >= scrollHeight - padBottom;

    if (atBottom && !this.isAtBottom) {
      // At the bottom
      this.isAtBottom = true;

      if (hasMoreItems) {
        this.getDeviceAttributes();
      }
    } else if (!atBottom && this.isAtBottom) {
      // Not at the bottom
      this.isAtBottom = false;
    }
  };

  /**
   * When search is changed
   */
  onSearchChanged = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState((state) => {
      return {
        searchValue: value,
        searchResults: value
          ? state.searchResults
          : [],
      };
    });

    clearTimeout(this.searchTimeout);

    if (value) {
      this.searchTimeout = setTimeout(this.search, 500);
    }
  };

  /**
   * Get Device Attributes
   */
  getDeviceAttributes = async () => {
    const {
      type,
      locationId,
      deviceAttributes,
      onChangeDeviceAttributes,
    } = this.props;

    const {
      pageNumber,
    } = this.state;

    const deviceAttributesResponse = type === 'admin'
      ? await API.DeviceAttribute.list({
        pageNumber,
        active: true,
      })
      : await API.Location.DeviceAttributes.list({
        _id: locationId,
        active: true,
      });

    const newDeviceAttributes = type === 'admin'
      ? get(deviceAttributesResponse, 'data', [])
      : get(deviceAttributesResponse, 'deviceAttributes', []);

    onChangeDeviceAttributes([
      ...deviceAttributes,
      ...newDeviceAttributes,
    ]);

    this.setState((state) => {
      return {
        pageNumber: state.pageNumber + 1,
        hasMoreItems: type === 'admin'
          ? deviceAttributesResponse.pageNumber !== deviceAttributesResponse.totalPages
          : false,
      };
    });
  };

  /**
   * Search Device Attributes
   */
  search = async () => {
    const {
      searchValue,
    } = this.state;

    const results = await API.DeviceAttribute.list({
      filters: {
        nameAndValue: searchValue,
        active: true,
      },
    });

    this.setState({
      searchResults: results.data,
    });
  };

  render() {
    const {
      className,
      type,
      deviceAttributes,
      activeAttribute,
      selectedIds,
      showAssignAttribute,
      showNewAttribute,
      showCopyToLocations,
      showDeleteAttributes,
      allowSearch,
      allowAssign,
      allowCreate,
      allowCopy,
      allowRemove,
      allowSelectItem,
      allowSelect,
      onSelect,
      onClick,
      onShowAssignAttribute,
      onShowNewAttribute,
      onCopyToLocations,
      onDeleteAttributes,
    } = this.props;

    const {
      searchValue,
      searchResults,
    } = this.state;

    return (
      <div
        className={classNames('DeviceAttributesList', className)}
        onScroll={this.onScroll}
      >
        <div className="attribute-links">
          {allowCreate && type === 'admin' ? (
            <PanelItemLink
              text="New Attribute"
              icon={viAdd}
              color={color.success}
              active={showNewAttribute && !activeAttribute._id}
              onClick={onShowNewAttribute}
            />
          ) : null}

          {allowAssign && type === 'location' ? (
            <PanelItemLink
              text="Add Attributes"
              icon={viAdd}
              color={color.success}
              active={showAssignAttribute}
              onClick={onShowAssignAttribute}
            />
          ) : null}

          {allowCopy ? (
            <PanelItemLink
              text="Copy to Locations"
              icon={viStore}
              color={color.primary}
              active={showCopyToLocations}
              onClick={onCopyToLocations}
            />
          ) : null}

          {allowRemove ? (
            <PanelItemLink
              text="Remove Attributes"
              icon={viTrash}
              color={color.error}
              active={showDeleteAttributes}
              onClick={onDeleteAttributes}
            />
          ) : null}
        </div>

        {allowSearch ? (
          <div className="search-attributes">
            <SearchInput
              value={searchValue}
              onChange={this.onSearchChanged}
            />
          </div>
        ) : null}

        {searchResults.length > 0 ? (
          <div className="search-results">
            {searchResults.map((attribute) => {
              return (
                <DeviceAttributeListItem
                  key={attribute._id}
                  deviceAttribute={attribute}
                  active={attribute._id === activeAttribute._id}
                  selected={selectedIds.includes(attribute._id)}
                  allowSelect={allowSelect}
                  allowSelectItem={allowSelectItem}
                  onSelect={onSelect}
                  onClick={onClick}
                />
              );
            })}
          </div>
        ) : (
          <div className="attributes">
            {deviceAttributes.map((attribute) => {
              return (
                <DeviceAttributeListItem
                  key={attribute._id}
                  deviceAttribute={attribute}
                  active={attribute._id === activeAttribute._id}
                  selected={selectedIds.includes(attribute._id)}
                  allowSelect={allowSelect}
                  allowSelectItem={allowSelectItem}
                  onSelect={onSelect}
                  onClick={onClick}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

DeviceAttributesList.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Show the attribute list panel for admin or location view */
  type: PropTypes.oneOf([
    'admin',
    'location',
  ]).isRequired,
  /** Location ID for the device attributes if location view (not admin) */
  locationId: PropTypes.string,
  /** Active Device Attribute */
  activeAttribute: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    active: PropTypes.bool,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  }),
  /** Device Attributes */
  deviceAttributes: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    active: PropTypes.bool,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  /** Selected attribute IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Reset the attributes */
  resetList: PropTypes.bool,
  /** Show assign attribute panel */
  showAssignAttribute: PropTypes.bool,
  /** Show new attribute panel */
  showNewAttribute: PropTypes.bool,
  /** Show copy to locations panel */
  showCopyToLocations: PropTypes.bool,
  /** Show delete attributes panel */
  showDeleteAttributes: PropTypes.bool,
  /** Allow Searching of items */
  allowSearch: PropTypes.bool,
  /** Allow attribute assignment to a location */
  allowAssign: PropTypes.bool,
  /** Allow attribute creation */
  allowCreate: PropTypes.bool,
  /** Allow attribute copy to locations */
  allowCopy: PropTypes.bool,
  /** Allow removal of attributes */
  allowRemove: PropTypes.bool,
  /** Allow selection of items */
  allowSelect: PropTypes.bool,
  /** Allow selecting of a single item */
  allowSelectItem: PropTypes.bool,
  /** Show assign attribute panel */
  onShowAssignAttribute: PropTypes.func,
  /** Show new attribute panel */
  onShowNewAttribute: PropTypes.func,
  /** Copy to locations selected */
  onCopyToLocations: PropTypes.func,
  /** Delete attributes selected */
  onDeleteAttributes: PropTypes.func,
  /** When an attribute is clicked */
  onClick: PropTypes.func,
  /** When an attribute is selected */
  onSelect: PropTypes.func,
  /** When device attributes are fetched */
  onChangeDeviceAttributes: PropTypes.func,
};

DeviceAttributesList.defaultProps = {
  className: '',
  locationId: null,
  activeAttribute: {},
  deviceAttributes: [],
  selectedIds: [],
  resetList: false,
  showAssignAttribute: false,
  showNewAttribute: false,
  showCopyToLocations: false,
  showDeleteAttributes: false,
  allowSearch: false,
  allowAssign: false,
  allowCreate: false,
  allowCopy: false,
  allowRemove: false,
  allowSelect: false,
  allowSelectItem: false,
  onShowAssignAttribute: () => {},
  onShowNewAttribute: () => {},
  onCopyToLocations: () => {},
  onDeleteAttributes: () => {},
  onClick: () => {},
  onSelect: () => {},
  onChangeDeviceAttributes: () => {},
};

export default DeviceAttributesList;
