import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeButtonNew,
  viAdd,
  color,
} from 'vibeguide';

function ProviderHeader({
  className,
  style,
  title,
  showNew,
  showRemove,
  onClickNew,
  onClickRemove,
}) {
  return (
    <div
      className={classNames('ProviderHeader', className)}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px 24px',
        borderTop: `1px solid ${color.whiteSmoke}`,
        borderBottom: `4px solid ${color.primary}`,
        ...style,
      }}
    >
      <div
        style={{
          flexGrow: 1,
          fontSize: 18,
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </div>

      <div>
        {showNew && (
          <VibeButtonNew
            text="New Config"
            style={{
              marginRight: 16,
            }}
            color={color.primary}
            icon={viAdd}
            iconProps={{
              style: {
                padding: 0,
              },
            }}
            onClick={onClickNew}
          />
        )}

        {showRemove && (
          <VibeButtonNew
            text="Remove Ad Provider"
            color={color.error}
            onClick={onClickRemove}
          />
        )}
      </div>
    </div>
  );
}

ProviderHeader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  showNew: PropTypes.bool,
  showRemove: PropTypes.bool,
  onClickNew: PropTypes.func,
  onClickRemove: PropTypes.func,
};

ProviderHeader.defaultProps = {
  className: '',
  style: {},
  showNew: false,
  showRemove: false,
  onClickNew: () => {},
  onClickRemove: () => {},
};

export default ProviderHeader;
