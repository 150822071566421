import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  API,
  ToastActions,
  Field2 as Field,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeButtonNew,
  VibeIcon,
  viArchive,
  viUnarchive,
  color,
} from 'vibeguide';

function CompanyBannerEdit({
  className,
  style,
  companyId,
  banner: propsBanner,
  user,
  queueToast,
}) {
  const [banner, setBanner] = useState(propsBanner);
  const [confirmArchive, setConfirmArchive] = useState(false);

  const showArchive = banner._id && user.can('company_banner.delete');
  const isArchived = banner._id && !banner.active;

  const disableSave = !banner.value;
  const disableInput = (banner._id && !user.can('company_banner.modify'))
    || (!banner._id && !user.can('company_banner.create'));

  /**
   * User clicks Archive
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * User clicks Unarchive
   */
  const onClickUnarchive = async () => {
    await API.Company.Banner.reactivate({
      _id: banner._id,
      companyId,
    });

    setBanner({
      ...banner,
      active: true,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompanyBanner'));
  };

  /**
   * User confirms archiving the banner
   */
  const onConfirmArchive = async () => {
    setConfirmArchive(false);
    await API.Company.Banner.deactivate({
      _id: banner._id,
      companyId,
    });

    setBanner({
      ...banner,
      active: false,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompanyBanner'));
  };

  /**
   * User cancels archiving the banner
   */
  const onCancelArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * User changes the input box
   */
  const onChange = ({
    target: {
      name,
      value,
    },
  }) => {
    setBanner({
      ...banner,
      [name]: value,
    });
  };

  /**
   * Save the banner
   */
  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const data = {
      companyId,
      value: banner.value,
    };

    if (banner._id) {
      data._id = banner._id;
    }

    try {
      const response = banner._id
        ? await API.Company.Banner.update(data)
        : await API.Company.Banner.create(data);

      // const bannerId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = banner._id
        ? responseType === 'COMPANYBANNER.UPDATED'
        : responseType === 'COMPANYBANNER.CREATED';

      if (success) {
        // Successfully saved the baseline
        const saveMessage = banner._id
          ? 'Banner Updated!'
          : 'Banner Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      document.dispatchEvent(new Event('onSaveCompanyBanner'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveCompanyBannerError'));
    }
  };

  useEffect(() => {
    setBanner(propsBanner);
  }, [propsBanner]);

  return (
    <SidePanelContainer
      className={classNames('CompanyBannerEdit', className)}
      style={style}
    >
      <SidePanelContent>
        <div>
          <Field
            type="text"
            label="Banner"
            placeholder="Banner"
            name="value"
            value={banner.value}
            tabIndex={1}
            disabled={disableInput}
            onChange={onChange}
            required
            autoFocus
          />
        </div>
      </SidePanelContent>

      <SidePanelFooter>
        <VibeButtonNew
          text="Save Changes"
          color={color.primary}
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {banner._id && (
          <div>
            {showArchive && !isArchived && (
              <VibeIcon
                icon={viArchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.error,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Archive"
                color={color.error}
                size={20}
                onClick={onClickArchive}
              />
            )}

            {showArchive && isArchived && (
              <VibeIcon
                icon={viUnarchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.success,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Unarchive"
                color={color.success}
                size={20}
                onClick={onClickUnarchive}
              />
            )}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${banner.value}?`}
        confirmProps={{
          text: 'Archive',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCancelArchive}
      />
    </SidePanelContainer>
  );
}

CompanyBannerEdit.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  banner: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }),
};

CompanyBannerEdit.defaultProps = {
  className: '',
  style: {},
  banner: {
    _id: '',
    value: '',
    active: false,
  },
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBannerEdit);
