import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import {
  capitalize,
  find,
} from 'lodash';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  CircularProgress,
} from '@mui/material';
import { list as adProviderList } from '../../helpers/AdProvider';
import VibeTooltip from '../VibeTooltip/VibeTooltip';
import LabelCell from './CellTypes/LabelCell';
import TimestampCell from './CellTypes/TimestampCell';
import Progress from '../Progress/Progress';
import color from '../../sass/color.scss';
import './VibeMiniTable.scss';

function VibeMiniTable({
  className,
  style,
  loading,
  hideColumnHeader,
  columnData,
  tableData,
  renderLink,
}) {
  // a function that will convert camelcase to titlecase
  const toTitleCase = (string) => {
    return string
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const getLabelStyle = (status) => {
    switch (status) {
      case 'live':
        return {
          text: 'LIVE',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'pending':
        return {
          text: 'PENDING',
          textStyle: {
            background: color.yellow16,
            color: color.yellow,
          },
        };

      case 'awaitingCreative':
        return {
          text: 'AWAITING CREATIVE',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'paused':
        return {
          text: 'PAUSED',
          textStyle: {
            background: color.error16,
            color: color.error,
          },
        };

      case 'completed':
        return {
          text: 'COMPLETED',
          textStyle: {
            background: color.primary16,
            color: color.primary,
          },
        };

      case 'cancelled':
        return {
          text: 'CANCELLED',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      default:
        return {
          text: capitalize(status),
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  const displayColumnHeader = (column, idx) => {
    switch (column) {
      // add column checks here to display unique header values
      case 'name':
      case 'status':
      case 'deliveredImpressions':
        // returns an empty table cell for these header values
        return <TableCell key={idx} />;
      default:
        return <TableCell key={idx}>{toTitleCase(column)}</TableCell>;
    }
  };

  const renderCell = (data, column, idx) => {
    switch (column) {
      case 'name':
        return (
          <TableCell
            key={idx}
            className="mini-table-cell"
            sx={{
              maxWidth: 150,
            }}
          >
            <Link
              to={renderLink(data)}
            >
              <div
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <VibeTooltip
                  title={data[column]}
                  placement="top"
                >
                  <span>
                    {data[column]}
                  </span>
                </VibeTooltip>
              </div>
            </Link>
          </TableCell>
        );

      case 'endDate':
      case 'startDate':
        return (
          <TableCell
            key={idx}
            className="mini-table-cell"
            style={{
              minWidth: 80,
            }}
          >
            <TimestampCell
              time={data[column]}
              format="ddd, MMM DD, YYYY"
            />
          </TableCell>
        );

      case 'status': {
        const { text, textStyle } = getLabelStyle(data[column]);

        return (
          <TableCell
            key={idx}
            className="mini-table-cell"
            style={{
              minWidth: 80,
            }}
          >
            <LabelCell
              style={{
                maxWidth: 100,
                fontSize: 12,
              }}
              value={text}
              background={textStyle.background}
              color={textStyle.color}
            />
          </TableCell>
        );
      }

      case 'deliveredImpressions':
        return (
          <TableCell
            key={idx}
            className="mini-table-cell"
            sx={{
              minWidth: 200,
            }}
          >
            {data.reportingAuthority === 'vibenomics' ? (
              <Progress
                value={data[column]}
                valueMax={data.targetImpressions}
                pacing={data.pacingPercent}
                disabled={data.status === 'pending' || data.status === 'cancelled'}
              />
            ) : (
              <div>
                Refer to {find(adProviderList, { value: data.reportingAuthority }).name} for delivery details
              </div>
            )}
          </TableCell>
        );

      default:
        return (
          <TableCell
            key={idx}
            className="mini-table-cell"
            sx={{
              maxWidth: 150,
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <VibeTooltip
                title={data[column]}
                placement="top"
              >
                {data[column]}
              </VibeTooltip>
            </div>
          </TableCell>
        );
    }
  };

  if (tableData.length <= 0) {
    return (
      <div
        className={classNames('VibeMiniTable', className)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 317,
          fontSize: 14,
          color: color.manatee,
          ...style,
        }}
      >
        {loading ? (
          <CircularProgress
            color="inherit"
            size={16}
          />
        ) : 'No data to display.'}
      </div>
    );
  }

  return (
    <TableContainer
      className={classNames('VibeMiniTable', className)}
      style={style}
    >
      <Table stickyHeader>
        {!hideColumnHeader && (
          <TableHead>
            <TableRow>
              {columnData.map((column, columnIdx) => displayColumnHeader(column, columnIdx))}
            </TableRow>
          </TableHead>
        )}

        <TableBody
          sx={{
            width: '100%',
          }}
        >
          {tableData.map((data, dataIdx) => (
            <TableRow
              key={dataIdx}
              sx={{
                height: 65,
              }}
            >
              {columnData.map((column, columnIdx) => renderCell(data, column, columnIdx))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

VibeMiniTable.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  /** Loading the data, show a spinning icon */
  loading: PropTypes.bool,
  hideColumnHeader: PropTypes.bool,
  columnData: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.object),
  /** When rendering the link, send back to the parent component to get the URL for the link */
  renderLink: PropTypes.func,
};

VibeMiniTable.defaultProps = {
  className: '',
  style: {},
  loading: false,
  hideColumnHeader: false,
  columnData: [],
  tableData: [],
  renderLink: () => {},
};

export default VibeMiniTable;
