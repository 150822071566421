import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  trim,
} from 'lodash';
import {
  API,
  Field2 as Field,
  UserAvatar,
  VibeToggle,
  color,
} from 'vibeguide';
import {
  Grid,
} from '@mui/material';
import './SongPRO.scss';

class SongPRO extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fetched: false,
      proDefinitions: [],
    };
  }

  componentDidMount() {
    this.getProList();
  }

  /**
   * When a PRO item is toggled
   */
  onToggle = (name) => {
    const {
      selection,
      onUpdate,
    } = this.props;

    onUpdate({
      proSelection: {
        ...selection,
        [name]: !selection[name],
      },
    });
  };

  getProList = async () => {
    const {
      onUpdate,
    } = this.props;

    const proDefinitions = await API.Music.getProList();

    this.setState({
      fetched: true,
      proDefinitions,
    });

    onUpdate({
      proDefinitions,
    });
  };

  render() {
    const {
      className,
      selection,
      confirmedByFirstName,
      confirmedByLastName,
      confirmedByAvatar,
      confirmedDate,
    } = this.props;

    const {
      fetched,
      proDefinitions,
    } = this.state;

    // only render when the PRO definitions are fetched
    if (!fetched) {
      return null;
    }

    const confirmedByName = trim(`${confirmedByFirstName} ${confirmedByLastName}`);
    const confirmedDateFormat = confirmedDate
      ? new moment(confirmedDate).format('ddd, MMM DD, YYYY, h:mma')
      : '';

    return (
      <div className={classNames('SongPRO', className)}>
        <Grid
          className="row extra-space"
          spacing={2}
          container
        >
          <Grid
            className="details-title"
            xs={6}
            item
          >
            PRO
          </Grid>
        </Grid>

        <Grid
          className="row"
          spacing={1}
          container
        >
          <Grid
            xs={12}
            item
          >
            <Field
              type="custom"
              label="PRO List"
            />
          </Grid>

          {proDefinitions.map((definition) => {
            return (
              <Grid
                key={`definition-${definition.name}`}
                xs={6}
                item
              >
                <VibeToggle
                  label={definition.name}
                  labelStyle={{
                    color: color.obsidian,
                    fontSize: 12,
                  }}
                  checked={selection[definition.name]}
                  color={color.success}
                  outlined
                  onChange={this.onToggle}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid
          className="row"
          spacing={2}
          container
        >
          <Grid
            xs={6}
            item
          >
            <Field
              type="custom"
              label="PRO Confirmed By"
            >
              {confirmedByName ? (
                <UserAvatar
                  avatar={confirmedByAvatar}
                  name={confirmedByName}
                />
              ) : null}
            </Field>
          </Grid>

          <Grid
            xs={6}
            item
          >
            <Field
              type="custom"
              label="PRO Confirmed Date"
            >
              <div className="confirmed-date">
                {confirmedDateFormat}
              </div>
            </Field>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SongPRO.propTypes = {
  className: PropTypes.string,
  selection: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  confirmedByFirstName: PropTypes.string,
  confirmedByLastName: PropTypes.string,
  confirmedByAvatar: PropTypes.string,
  confirmedDate: PropTypes.string,
  onUpdate: PropTypes.func,
};

SongPRO.defaultProps = {
  className: '',
  selection: {},
  confirmedByFirstName: '',
  confirmedByLastName: '',
  confirmedByAvatar: '',
  confirmedDate: '',
  onUpdate: () => {},
};

export default SongPRO;
