import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import {
  get,
  find,
} from 'lodash';
import {
  API,
  GlobalActions,
  ToastActions,
  NavigationHelper,
  FlightModel,
  FlightSummaryModel,
  SegmentModel,
  FlightStatus,
  Submenu,
  VibeModal,
  VibeTooltip,
  VibeAlert,
  viCopy,
  viCreate,
  viArchive,
  viUnarchive,
  viSave,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import SubNavigation from '../../Header/SubNavigation';
import SegmentSidebar from './Sidebar/SegmentSidebar';
import FlightInformation from './FlightInformation';
import AdInformation from './AdInformation';
import './FlightDetails.scss';
import FlightSegmentOverview from './FlightSegmentOverview';

/**
 * Reset the confirmation modal dialog
 */
function resetConfirmModal() {
  return {
    show: false,
    title: '',
    description: '',
    confirmText: '',
    confirmColor: '',
    cancelText: '',
    onConfirm: () => {},
    onCancel: () => {},
  };
}

function FlightDetails({
  className,
  user,
  socket,
  setPanel,
  queueToast,
}) {
  const [flight, setFlight] = useState({
    data: new FlightModel(),
    summary: new FlightSummaryModel(),
    segments: [],
  });
  const [advertiserName, setAdvertiserName] = useState('');

  const [confirmModal, setConfirmModal] = useState(resetConfirmModal());

  const history = useNavigate();
  const qs = NavigationHelper.getParams() || {};

  const {
    id,
    type,
  } = useParams();

  /**
   * Update Flight Data
   */
  const updateFlightData = (data) => {
    setFlight({
      ...flight,
      data: new FlightModel({
        ...flight.data,
        ...data,
      }),
    });
  };

  /**
   * Get Flight Segments
   * Include flight summary segments if a new summary was fetched
   * Otherwise use the existing flight summary data
   */
  const getSegments = async (summarySegments) => {
    // if user cannot view segments skip
    if (!user.can('flight_segment.view')) {
      return [];
    }

    const params = NavigationHelper.getParams();
    const active = get(params, 'active') !== 'false';

    // get all segments so we can merge with flight summary data
    const response = await API.Flight.Segment.list(
      {
        flightId: id,
        filters: {
          active,
        },
        pageSize: -1,
      },
    );

    if (!summarySegments) {
      // no summary segments passed in, use existing summary
      summarySegments = get(flight, 'summary.segments', []);
    }

    // merge segment data from the flight summary with segments from the API
    const segmentItems = get(response, 'data', []).map((segment) => {
      // find the associated segment in the summary data
      const summarySegment = find(summarySegments, { _id: segment._id }) || {};

      // merge the two together
      return {
        ...new SegmentModel(segment),
        ...summarySegment,
      };
    });

    return segmentItems;
  };

  /**
   * Get Flight Data
   */
  const getFlightData = async () => {
    const flightResponse = await API.Flight.getById(id);
    return new FlightModel(flightResponse);
  };

  /**
   * Get Flight Summary
   */
  const getFlightSummary = async () => {
    const flightSummaryResponse = await API.Flight.summary(id);
    return new FlightSummaryModel(flightSummaryResponse);
  };

  /**
   * Get Flight Details
   */
  const getFlight = async () => {
    const flightData = await getFlightData();
    const flightSummaryData = await getFlightSummary();
    const flightSegments = await getSegments(flightSummaryData.segments);

    setFlight({
      ...flight,
      data: flightData,
      summary: flightSummaryData,
      segments: flightSegments,
    });

    if (flightData.advertiserId) {
      // only set the advertiser name if an ID is also set
      setAdvertiserName(flightData.advertiserName);
    }
  };

  const onChange = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    updateFlightData({
      [name]: value,
    });
  };

  const onChangeStartDate = (startDate) => {
    updateFlightData({
      startDate,
    });
  };

  const onChangeEndDate = (endDate) => {
    updateFlightData({
      endDate,
    });
  };

  const onChangeDropdown = ({
    name,
    value,
    // data,
  }) => {
    updateFlightData({
      [name]: value,
    });
  };

  const onChangeAdvertiser = (data) => {
    const {
      id,
      value,
    } = data;

    updateFlightData({
      advertiserId: id,
      advertiserName: value,
    });

    setAdvertiserName(value);
  };

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      segmentId: null,
      flightId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  /**
   * Toggle the archive button
   */
  const onToggleArchive = async () => {
    const flightSegments = await getSegments();

    setFlight({
      ...flight,
      segments: flightSegments,
    });
  };

  /**
   * Cancel the confirmation modal
   */
  const onCancelConfirmModal = () => {
    setConfirmModal(resetConfirmModal());
  };

  /**
   * Confirm Inventory
   */
  const onConfirmInventory = async () => {
    onCancelConfirmModal();

    await API.Flight.confirm(id);
  };

  // TODO: Remove this function
  /**
   * Release (Unconfirm) Inventory
   */
  // const onReleaseInventory = async () => {
  //   onCancelConfirmModal();
  //   await API.Flight.unconfirm(id);
  // };

  /**
   * Archive Flight
   */
  const onArchiveFlight = async () => {
    onCancelConfirmModal();

    const archiveResponse = await API.Flight.deactivate(id);
    const success = get(archiveResponse, '[0].type') === 'FLIGHT.DEACTIVATED';

    // redirect to flights page after archiving a flight
    if (success) {
      history('/ad-ops/flights');
    }
  };

  /**
   * Unarchive Flight
   */
  const onUnarchiveFlight = async () => {
    onCancelConfirmModal();

    const response = await API.Flight.reactivate({
      _id: id,
    });
    const success = get(response, '[0].type') === 'FLIGHT.REACTIVATED';

    // redirect to flights page after unarchiving a flight
    if (success) {
      history('/ad-ops/flights');
    }
  };

  /**
   * Copy Flight
   */
  const onCopyFlight = async () => {
    onCancelConfirmModal();

    const response = await API.Flight.copy(id);
    const success = get(response, '[0].type') === 'FLIGHT.CREATED';

    // redirect to the copied flight
    if (success) {
      const copyFlightId = get(response, '[0].documentId');
      history(`/ad-ops/flight/${copyFlightId}`);
    }
  };

  /**
   * User clicks Archive Flight
   */
  const onClickArchive = () => {
    setConfirmModal({
      show: true,
      title: 'Archive Flight',
      description: `Are you sure you want to archive ${get(flight, 'data.name', 'Unknown')}?`,
      confirmText: 'Archive',
      confirmColor: color.error,
      cancelText: 'Cancel',
      onConfirm: onArchiveFlight,
      onCancel: onCancelConfirmModal,
    });
  };

  /**
   * User clicks Unarchive Flight
   */
  const onClickUnarchive = () => {
    setConfirmModal({
      show: true,
      title: 'Unarchive Flight',
      description: `Are you sure you want to unarchive ${get(flight, 'data.name', 'Unknown')}?`,
      confirmText: 'Unarchive',
      confirmColor: color.error,
      cancelText: 'Cancel',
      onConfirm: onUnarchiveFlight,
      onCancel: onCancelConfirmModal,
    });
  };

  /**
   * User clicks Copy Flight
   */
  const onClickCopy = () => {
    setConfirmModal({
      show: true,
      title: 'Copy Flight',
      description: `Are you sure you want to copy ${get(flight, 'data.name', 'Unknown')}?`,
      confirmText: 'Copy',
      confirmColor: color.primary,
      cancelText: 'Cancel',
      onConfirm: onCopyFlight,
      onCancel: onCancelConfirmModal,
    });
  };

  /**
   * User clicks Confirm Inventory
   */
  const onClickConfirmInventory = () => {
    setConfirmModal({
      show: true,
      title: 'Confirm Inventory',
      description: (
        <div>
          Confirm the flight and associated segments?<br />
          This process may take several minutes.
        </div>
      ),
      confirmText: 'Confirm',
      confirmColor: color.success,
      cancelText: 'Cancel',
      onConfirm: onConfirmInventory,
      onCancel: onCancelConfirmModal,
    });
  };

  // TODO: Remove this function
  /**
   * User clicks Release Inventory
   */
  // const onClickReleaseInventory = () => {
  //   setConfirmModal({
  //     show: true,
  //     title: 'Release Inventory',
  //     description: (
  //       <div>
  //         Unconfirm the flight and associated segments?<br />
  //         This process may take several minutes.
  //       </div>
  //     ),
  //     confirmText: 'Unconfirm',
  //     confirmColor: color.error,
  //     cancelText: 'Cancel',
  //     onConfirm: onReleaseInventory,
  //     onCancel: onCancelConfirmModal,
  //   });
  // };

  /**
   * Listen for socket events
   */
  const onApiEvent = async (e) => {
    switch (e.type) {
      case 'SOCKET.SUBSCRIBE_FAILED': {
        const topic = get(e, 'data.topic');
        const flightId = get(e, 'data.id');
        const error = get(e, 'data.error');
        // only re-try subscribing to a flight if the subscribed flight
        // doesn't match the current fetched flight
        // this is to prevent multiple requests to the API for the same flight
        if (error.includes('Already subscribed') && flightId === id) {
          break;
        }

        if (
          (topic === 'flight' && flightId === id)
          && (flightId !== flight.data._id)
        ) {
          // retry subscribing to the flight after a few seconds
          setTimeout(() => {
            socket.emit('subscribe', {
              topic: 'flight',
              id,
            });
          }, 3000);
        }
        break;
      }

      // When a flight is calculating
      case 'FLIGHT.COMPUTING': {
        const flightData = await getFlightData();
        const flightSegments = await getSegments();

        setFlight({
          ...flight,
          data: flightData,
          // do not request a new summary until after the flight is computed
          summary: flight.summary,
          segments: flightSegments,
        });
        break;
      }

      case 'FLIGHT.CONFIRM_QUEUED':
      case 'FLIGHT.CONFIRM_STARTED':
      case 'FLIGHT.UNCONFIRM_QUEUED':
      case 'FLIGHT.UNCONFIRM_STARTED': {
        const flightData = await getFlightData();
        const flightSegments = await getSegments();

        setFlight({
          ...flight,
          data: flightData,
          summary: new FlightSummaryModel(),
          segments: flightSegments,
        });
        break;
      }

      case 'FLIGHT.COMPUTED':
      case 'FLIGHT.CONFIRM_FAILED':
      case 'FLIGHT.UNCONFIRMED':
      case 'FLIGHT.UNCONFIRM_FAILED':
      case 'FLIGHT.RECOVERED': {
        getFlight();
        break;
      }

      case 'FLIGHT.CONFIRMED': {
        history(`../order/${id}/details`);
        break;
      }

      default:
        // console.warn('Event', e);
        break;
    }
  };

  /**
 * When the socket reconnects
 */
  const onSocketReconnect = () => {
    if (flight.data._id) {
      socket.emit('subscribe', {
        topic: 'flight',
        id: flight.data._id,
      });

      getFlight();
    }
  };

  /**
   * User clicks Save Flight
   */
  const onSave = async () => {
    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const flightResponse = await API.Flight.update(flight.data);
      const success = get(flightResponse, '[0].type') === 'FLIGHT.UPDATED';

      if (success) {
        queueToast({
          type: 'success',
          title: 'Saved!',
          allowClose: true,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSaveFlight'));
      } else {
        console.error('Error saving flight to API', flightResponse);

        queueToast({
          type: 'error',
          title: 'Error Saving Flight',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving flight', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Flight',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  const sidebar = (type, segmentId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      children: (
        <SegmentSidebar
          type="flight"
          segmentId={segmentId}
          routeId={id}
          isNew={type === 'new'}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.segmentId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.segmentId);
    }
  }, [qs.type, qs.segmentId]);

  /**
   * When connected to the socket, listen for flight events
   * Resubscribe to the socket events when the flight data is fetched
   */
  useEffect(() => {
    socket.on('connect', onSocketReconnect);

    if (socket.connected) {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.on('VAPI_EVENT', onApiEvent);
    }

    // when component unmounts
    return () => {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.off('connect', onSocketReconnect);
    };
  }, [socket.connected, flight.data._id, id]);

  /**
   * When connected to the socket, listen for flight events
   * Initially subscribe to the flight from the page ID
   * Resubscribe if the flight ID changes (when a flight is copied)
   */
  useEffect(() => {
    if (socket.connected) {
      socket.off('VAPI_EVENT', onApiEvent);
      socket.on('VAPI_EVENT', onApiEvent);

      socket.emit('subscribe', {
        topic: 'flight',
        id,
      });
    }

    // when component unmounts
    return () => {
      socket.off('VAPI_EVENT', onApiEvent);

      socket.emit('unsubscribe', {
        topic: 'flight',
        id,
      });
    };
  }, [socket.connected, id]);

  /**
   * When the flight ID changes fetch the new flight data
   */
  useEffect(() => {
    if (id) {
      getFlight();
    }
  }, [id]);

  const flightData = flight.data;
  const flightSummary = flight.summary;
  const flightSegments = flight.segments;

  // only render when the flight is fetched
  if (!flightData._id) {
    return null;
  }

  const confirmed = flightData.status === 'confirmed';
  const unconfirmed = flightData.status === 'unconfirmed';
  // disable buttons when flight is not in a confirmed or unconfirmed state
  const disableButtons = !confirmed && !unconfirmed;
  const disableCreateOrder = disableButtons || flightSegments.length === 0;
  const disableSave = !flightData.name
    || !flightData.advertiserId
    || !flightData.adProvider
    || !flightData.deliverySystem
    || !flightData.startDate
    || !flightData.endDate;

  const flightStatusButtons = [
    {
      text: 'Save',
      userCan: [
        'flight.modify',
      ],
      color: color.primary,
      icon: viSave,
      disabled: disableSave || disableButtons,
      onClick: onSave,
    },
    {
      text: 'Create Order',
      userCan: [
        'flight.confirm',
      ],
      color: color.primary,
      icon: viCreate,
      disabled: disableCreateOrder,
      tooltip: 'This flight must have segments',
      onClick: onClickConfirmInventory,
    },
  ];

  // TODO: remove this code
  // const flightStatusButtons = confirmed
  //   ? [
  //     {
  //       text: 'Release Inventory',
  //       userCan: [
  //         'flight.unconfirm',
  //       ],
  //       color: color.primary,
  //       disabled: disableButtons,
  //       onClick: onClickReleaseInventory,
  //     },
  //   ]
  //   : [
  //     {
  //       text: 'Create Order',
  //       userCan: [
  //         'flight.confirm',
  //       ],
  //       color: color.primary,
  //       icon: viCreate,
  //       disabled: disableButtons,
  //       onClick: onClickConfirmInventory,
  //     },
  //     {
  //       text: 'Save',
  //       userCan: [
  //         'flight.modify',
  //       ],
  //       color: color.primary,
  //       icon: viSave,
  //       disabled: disableSave || disableButtons,
  //       onClick: onSave,
  //     },
  //   ];

  // flight buttons to show
  const flightButtons = flightData.active
    ? [
      {
        text: 'Copy',
        userCan: [
          'flight.create',
        ],
        icon: viCopy,
        color: color.primary,
        disabled: disableButtons,
        onClick: onClickCopy,
      },
      ...flightStatusButtons,
    ]
    : [];

  if (flightData.active) {
    // show archive flight button only when unconfirmed
    flightButtons.push({
      text: 'Archive',
      userCan: [
        'flight.delete',
      ],
      color: color.error,
      icon: viArchive,
      disabled: disableButtons,
      onClick: onClickArchive,
    });
  } else {
    // show unarchive flight button
    flightButtons.push({
      text: 'Unarchive',
      userCan: [
        'flight.delete',
      ],
      color: color.error,
      icon: viUnarchive,
      onClick: onClickUnarchive,
    });
  }

  /**
   * Get the page content
   */
  const getPageContent = () => {
    switch (type) {
      case 'segments':
        return (
          <FlightSegmentOverview
            flightData={flightData}
            flightSummary={flightSummary}
            flightSegments={flightSegments}
            onToggleArchive={onToggleArchive}
          />
        );

      case 'overview':
      default:
        return (
          <>
            <Grid spacing={3} sx={{ ml: 2 }} container>
              <Grid xs={12} lg={6} item>
                <FlightInformation
                  flightData={flightData}
                  title="Flight Information"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Grid spacing={3} sx={{ ml: 2 }} container>
              <Grid xs={12} lg={6} item>
                <AdInformation
                  flightData={flightData}
                  advertiserName={advertiserName}
                  title="Ad Information"
                  user={user}
                  onChange={onChange}
                  onChangeDropdown={onChangeDropdown}
                  onChangeAdvertiser={onChangeAdvertiser}
                  onChangeStartDate={onChangeStartDate}
                  onChangeEndDate={onChangeEndDate}
                />
              </Grid>
            </Grid>
          </>
        );
    }
  };

  return (
    <div className={classNames('FlightDetails', className)}>
      <SubNavigation
        menuName="Flight Menu"
        isDropMenu
        title={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {get(flightSummary, 'warnings.length', 0) > 0 && (
              <VibeAlert
                style={{
                  marginRight: 8,
                  fontSize: 12,
                }}
                severity="warning"
                alerts={flightSummary.warnings || []}
                size={24}
                iconOnly
              />
            )}

            <VibeTooltip title={flightData.name}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {flightData.name}
              </div>
            </VibeTooltip>

            <FlightStatus
              status={flightData.status}
              style={{
                marginLeft: 8,
              }}
            />
          </div>
        )}
        style={{
          borderBottom: `1px solid ${color.whiteSmoke}`,
        }}
        buttons={flightButtons}
        disabled={flightData.flightStage === 'order'}
      />

      <Submenu
        rootUrl={`/ad-ops/flight/${flightData._id}`}
        matchParameter="tab"
        items={[
          {
            name: 'Overview',
            show: true,
            enabled: user.can('flight.view'),
          },
          {
            name: 'Segments',
            show: true,
            enabled: user.can('flight_segment.view'),
          },
        ]}
      />

      <div
        style={{
          height: 'calc(100% - 68px)',
          paddingBottom: 0,
          margin: '24px 0 0 0',
        }}
      >
        {getPageContent()}
      </div>

      <VibeModal
        type="confirm"
        show={confirmModal.show}
        confirmProps={{
          text: confirmModal.confirmText,
          color: confirmModal.confirmColor,
        }}
        cancelProps={{
          text: confirmModal.cancelText,
          color: color.manatee,
        }}
        title={confirmModal.title}
        text={confirmModal.description}
        onConfirm={confirmModal.onConfirm}
        onClose={confirmModal.onCancel}
      />
    </div>
  );
}

FlightDetails.propTypes = {
  className: PropTypes.string,
};

FlightDetails.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    socket: state.socket.connection,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightDetails);
