import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import color from '../../sass/color.scss';

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: `1px solid ${color.whiteSmoke}`,
          boxShadow: 'none',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: color.secondary,
          fontWeight: 'bold',
        },
        content: {
          margin: '16px 0',
        },
        expandIconWrapper: {
          color: color.secondary,
        },
      },
    },
  },
});

function VibeAccordion({
  className,
  style,
  title,
  expanded,
  children,
  onChange,
}) {
  return (
    <div
      className={classNames('VibeAccordion', className)}
      style={style}
    >
      <ThemeProvider theme={theme}>
        <Accordion
          expanded={expanded}
          onChange={onChange}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            {title}
          </AccordionSummary>

          <AccordionDetails
            sx={{
              paddingTop: 0,
            }}
          >
            <div
              style={{
                borderTop: `1px solid ${color.gray200}`,
                marginBottom: 16,
              }}
            />

            {children}
          </AccordionDetails>
        </Accordion>
      </ThemeProvider>
    </div>
  );
}

VibeAccordion.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onChange: PropTypes.func,
};

VibeAccordion.defaultProps = {
  className: '',
  style: {},
  expanded: false,
  onChange: () => {},
};

export default VibeAccordion;
