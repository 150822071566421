import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PanelItemLink from '../PanelItems/PanelItemLink';
import Tag from '../../Tags/Tag';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import viSwap from '../../../icons/viSwap';
import viAdd from '../../../icons/viAdd';
import viClose from '../../../icons/viClose';
import color from '../../../sass/color.scss';
import './DeviceAttributeCopyToLocations.scss';

class DeviceAttributeCopyToLocations extends PureComponent {
  onClickReplace = () => {
    const {
      onShowLocations,
    } = this.props;

    onShowLocations({
      mergeMode: 'replace',
    });
  };

  onClickAddTo = () => {
    const {
      onShowLocations,
    } = this.props;

    onShowLocations({
      mergeMode: 'merge',
    });
  };

  render() {
    const {
      className,
      selectedIds,
      deviceAttributes,
      mergeMode,
      showLocations,
      onClose,
    } = this.props;

    const selectedAttributes = deviceAttributes.filter(attribute => selectedIds.includes(attribute._id));

    if (selectedIds.length <= 0) {
      return (
        <div className="DeviceAttributeCopyToLocations">
          <div className="empty">
            Select Device Attributes
          </div>
        </div>
      );
    }

    return (
      <div className={classNames('DeviceAttributeCopyToLocations', className)}>
        <div className="attribute-section">
          <div className="tag-selected-count">
            <VibeCheckbox
              color={color.success}
              size={16}
              checked
            />

            <div className="text">
              {selectedAttributes.length} selected
            </div>
          </div>
        </div>

        <div className="attribute-section attribute-list">
          {selectedAttributes.map((attribute) => {
            return (
              <Tag
                key={attribute._id}
                style={{
                  marginBottom: 8,
                }}
                tag={{
                  name: `${attribute.name}:${attribute.value}`,
                }}
              />
            );
          })}
        </div>

        <div className="attribute-links">
          <PanelItemLink
            text="Replace..."
            icon={viSwap}
            color={color.primary}
            active={mergeMode === 'replace' && showLocations}
            onClick={this.onClickReplace}
          />

          <PanelItemLink
            text="Add To..."
            icon={viAdd}
            color={color.primary}
            active={mergeMode === 'merge' && showLocations}
            onClick={this.onClickAddTo}
          />

          <PanelItemLink
            text="Cancel"
            icon={viClose}
            color={color.manatee}
            onClick={onClose}
          />
        </div>
      </div>
    );
  }
}

DeviceAttributeCopyToLocations.propTypes = {
  /** Class */
  className: PropTypes.string,
  /** Device Attributes */
  deviceAttributes: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    active: PropTypes.bool,
    createdDate: PropTypes.string,
    modifiedDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  /** Selected attribute IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Location merge mode */
  mergeMode: PropTypes.oneOf([
    'replace',
    'merge',
  ]),
  /** Show location selector */
  showLocations: PropTypes.bool,
  /** When to toggle the locations panel */
  onShowLocations: PropTypes.func,
  /** When the panel is closed */
  onClose: PropTypes.func,
};

DeviceAttributeCopyToLocations.defaultProps = {
  className: '',
  deviceAttributes: [],
  selectedIds: [],
  mergeMode: 'replace',
  showLocations: false,
  onShowLocations: () => {},
  onClose: () => {},
};

export default DeviceAttributeCopyToLocations;
