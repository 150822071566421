import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SwapButton from '../SwapButton/SwapButton';
import VibeSplash from '../VibeSplash/VibeSplash';
import Paginator from '../Paginator/Paginator';
import PageLayout from '../PageLayout/PageLayout';
import PlaylistCard from '../PlaylistCard/PlaylistCard';
import {
  playlists as searchByPlaylists,
} from '../../helpers/SearchBy';
import {
  playlists as sortByPlaylists,
} from '../../helpers/SortBy';
import color from '../../sass/color.scss';
import './PlaylistsUnassigned.scss';

class PlaylistsUnassigned extends PureComponent {
  constructor(props) {
    super(props);

    this._scrollRef = null;

    this.state = {
      playlists: [],
      totalItems: 0,
    };
  }

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    this.setState({
      playlists: collection,
      totalItems,
    });
  };

  /**
   * Assign all loaded playlists
   */
  onClickAssignAll = () => {
    const {
      selected,
      onSelect,
      // songId,
    } = this.props;

    const {
      playlists,
    } = this.state;

    // Get the playlists that are not selected/unselected
    const unselected = playlists.filter((playlist) => {
      const selectedItem = find(selected, {
        _id: playlist._id,
      }) !== undefined;

      // const alreadyAssigned = playlist.songs.find((song) => {
      //   return song.songId === songId;
      // }) !== undefined;

      if (!selectedItem /* && !alreadyAssigned */) {
        return playlist;
      }

      return false;
    });

    // Select the playlists
    unselected.forEach((playlist) => {
      onSelect(playlist);
    });
  };

  /**
   * When the Swap Button is Clicked
   */
  onSwap = (playlist) => {
    const {
      onSelect,
    } = this.props;

    onSelect(playlist);
  };

  render() {
    const {
      selected,
      allowChanges,
      allowPaginator,
      fetch,
      filters,
      // songId,
    } = this.props;

    const {
      playlists,
      totalItems,
    } = this.state;

    const unselected = playlists.filter((playlist) => {
      const selectedItem = find(selected, {
        _id: playlist._id,
      }) !== undefined;

      if (!selectedItem) {
        return playlist;
      }

      return false;
    });

    const alreadyAssigned = [];

    // const alreadyAssigned = playlists.filter((playlist) => {
    //   const alreadyAssigned = playlist.songs.find((song) => {
    //     return song.songId === songId;
    //   }) !== undefined;

    //   if (alreadyAssigned) {
    //     return playlist;
    //   }

    //   return false;
    // });

    return (
      <div className="PlaylistsUnassigned">
        <div className="title-container">
          <div className="title">
            Available Playlists
          </div>
        </div>

        <PageLayout
          sortOptions={sortByPlaylists}
          searchOptions={searchByPlaylists}
          scrollRef={this._scrollRef}
          placeholder="Search..."
          filter={fetch}
          filterSearch={filters}
          onFilter={this.onFilter}
          disableView
        />

        <div className="playlists-content-root">
          <PerfectScrollbar
            containerRef={(ref) => { this._scrollRef = ref; }}
          >
            {unselected.length <= 0 ? (
              <VibeSplash
                title="No Playlists Found"
                titleStyle={{
                  color: color.primary,
                  textTransform: 'uppercase',
                }}
                subtitle="All Playlists are currently assigned."
              />
            ) : (
              <div className="playlists-content">
                {allowPaginator && unselected.length > 0 ? (
                  <div className="paginator-container">
                    <Paginator
                      className="playlists-paginator"
                      totalLoaded={unselected.length}
                      totalItems={totalItems}
                      label="Playlists"
                    />

                    <div>
                      {(allowChanges && unselected.length - alreadyAssigned.length) > 0
                        ? (
                          <SwapButton
                            className="assign-btn"
                            text={unselected.length > 1
                              ? `Select These ${unselected.length - alreadyAssigned.length} Playlists`
                              : 'Select This Playlist'}
                            arrowPlacement="right"
                            arrowPosition="right"
                            onClick={this.onClickAssignAll}
                          />
                        ) : null}
                    </div>
                  </div>
                ) : null}

                <div className="playlists-list">
                  {unselected.map((playlist) => {
                    return (
                      <div
                        key={playlist._id}
                        className="playlist-container"
                      >
                        <PlaylistCard
                          key={playlist._id}
                          playlist={playlist}
                          height={310}
                          swap={allowChanges}
                          swapDirection="right"
                          onSwap={this.onSwap}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

PlaylistsUnassigned.propTypes = {
  /** Song Id */
  // songId: PropTypes.string,
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected playlists */
  selected: PropTypes.arrayOf(PropTypes.object),
  /** Allow changes to be made */
  allowChanges: PropTypes.bool,
  /** Allow the Paginator to be displayed */
  allowPaginator: PropTypes.bool,
  /** Fetch playlists endpoint */
  fetch: PropTypes.func,
  /** When a playlist is selected */
  onSelect: PropTypes.func,
};

PlaylistsUnassigned.defaultProps = {
  // songId: '',
  filters: null,
  selected: [],
  allowChanges: false,
  allowPaginator: false,
  fetch: () => {},
  onSelect: () => {},
};

export default PlaylistsUnassigned;
