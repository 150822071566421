import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  VibeCardNew,
  VibeTooltip,
  VibeIcon,
  viUser,
  viCheckCircle,
  viCloseCircle,
  color,
} from 'vibeguide';

function ApproverCard({
  className,
  style,
  approver,
  showWaitingStatus,
  showIcon,
  selectable,
  onSelect,
}) {
  const approverName = `${approver.fname} ${approver.lname}`.trim();

  const onSelectApprover = () => {
    onSelect({
      ...approver,
      selected: !approver.selected,
    });
  };

  /**
   * Determine whether or not the Approver Status is shown
   */
  const showStatus = () => {
    // do not show status when there is none, or if we are deliberately hiding the waiting status
    if (!approver.status
      || (approver.status === 'waiting' && !showWaitingStatus)
    ) {
      return false;
    }

    return true;
  };

  /**
   * Get the status text
   */
  const getStatusText = () => {
    if (approver.approvalType === 'override-client'
      || approver.approvalType === 'override-global'
      || approver.approvalType === 'override-partner') {
      return `Override: ${approver.status}`;
    }

    return approver.status;
  };

  /**
   * Get the status color
   */
  const getStatusColor = () => {
    switch (approver.status) {
      case 'approved':
        return color.success;

      case 'denied':
        return color.error;

      case 'waiting':
      default:
        return color.manatee;
    }
  };

  /**
   * Get the status icon
   */
  const getStatusIcon = () => {
    switch (approver.status) {
      case 'approved':
        return (
          <VibeIcon
            style={{
              marginLeft: 4,
            }}
            icon={viCheckCircle}
            color={color.success}
            size={16}
          />
        );

      case 'denied':
        return (
          <VibeIcon
            style={{
              marginLeft: 4,
            }}
            icon={viCloseCircle}
            color={color.error}
            size={16}
          />
        );

      default:
        return null;
    }
  };

  /**
   * Get the Approver Title (or title override)
   */
  const getApprovalTitle = () => {
    if (approver.approvalType === 'override-client'
      || approver.approvalType === 'override-global'
      || approver.approvalType === 'override-partner') {
      return 'Global Approver';
    }

    return approver.title;
  };

  return (
    <VibeCardNew
      className={classNames('ApproverCard', className)}
      style={style}
      selectable={selectable}
      selected={approver.selected}
      onSelect={onSelectApprover}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            flexGrow: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
            }}
          >
            {showIcon && (
              <VibeIcon
                style={{
                  marginRight: 8,
                }}
                icon={viUser}
                color={color.primary}
                size={16}
              />
            )}

            {approverName}
          </div>

          <div
            style={{
              marginLeft: showIcon
                ? 24
                : 0,
              color: color.manatee,
            }}
          >
            {(approver.title
              || (approver.approvalType === 'override-client'
                || approver.approvalType === 'override-global'
                || approver.approvalType === 'override-partner'))
              && (
                <div>
                  {getApprovalTitle()}
                </div>
              )}

            {approver.email && (
              <div>
                {approver.email}
              </div>
            )}
          </div>
        </div>

        {showStatus() && (
          <VibeTooltip
            title={approver.reviewedAt
              ? moment(approver.reviewedAt).format('LLLL')
              : ''}
            placement="left"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: getStatusColor(),
                textTransform: 'capitalize',
              }}
            >
              {getStatusText()}
              {getStatusIcon()}
            </div>
          </VibeTooltip>
        )}
      </div>
    </VibeCardNew>
  );
}

ApproverCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  approver: PropTypes.object,
  showWaitingStatus: PropTypes.bool,
  showIcon: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
};

ApproverCard.defaultProps = {
  className: '',
  style: {},
  approver: {},
  showWaitingStatus: false,
  showIcon: false,
  selectable: false,
  onSelect: () => {},
};

export default ApproverCard;
