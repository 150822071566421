import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  API,
  Field,
  VibeIcon,
  viCalendarEventOutline,
  viAdd,
  viClose,
  color,
} from 'vibeguide';
import './AddScheduledItem.scss';

class AddScheduledItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      baselineId: '',
      baselineName: '',
    };
  }

  /**
   * When the baseline input is changed
   */
  onChange = (data) => {
    const {
      baselineId,
      baselineName,
    } = data;

    this.setState({
      baselineId,
      baselineName,
    });
  };

  /**
   * Add the scheduled baseline
   */
  schedule = async () => {
    const {
      day,
      currentLocation: {
        companyId,
        _id: locationId,
        timeZone,
      },
      onSchedule,
    } = this.props;

    const {
      baselineId,
    } = this.state;

    const locations = [{
      companyId,
      locationId,
    }];

    const data = {
      action: 'add',
      baselines: [{
        _id: baselineId,
        startDate: day.format('YYYY-MM-DD'),
        originTimeZone: timeZone,
      }],
      locations,
    };

    const scheduleResponse = await API.Location.updateSchedule(data);
    const success = get(scheduleResponse, '[0].type') === 'LOCATION.SCHEDULE_UPDATED';

    if (!success) {
      console.error('Error scheduling baseline', scheduleResponse);
      return;
    }

    onSchedule();
  };

  render() {
    const {
      currentLocation: {
        _id: locationId,
      },
      day,
      onCancel,
    } = this.props;

    const {
      baselineId,
      baselineName,
    } = this.state;

    return (
      <div className="AddScheduledItem">
        <div className="scheduled-content">
          <div className="baseline-day">
            <VibeIcon
              className="day-icon"
              icon={viCalendarEventOutline}
              color={color.secondary}
              size={40}
            />

            <div className="day-number">
              {day.format('D')}
            </div>
          </div>

          <div className="baseline-name">
            <Field
              type="baseline"
              value={baselineName}
              baselineProps={{
                baselineId,
                baselineName,
                locationId,
              }}
              dropdownPosition="top"
              autoFocus
              onChange={this.onChange}
            />
          </div>

          <div className="baseline-actions">
            <VibeIcon
              className="action-icon"
              icon={viAdd}
              color={baselineId
                ? color.primary
                : color.manatee}
              hoverColor={baselineId
                ? color.primary50
                : color.manatee}
              size={24}
              tooltip={baselineId
                ? 'Add'
                : null}
              disabled={!baselineId}
              onClick={this.schedule}
            />

            <VibeIcon
              className="action-icon"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.manatee50}
              size={24}
              tooltip="Cancel"
              onClick={onCancel}
            />
          </div>
        </div>
      </div>
    );
  }
}

AddScheduledItem.propTypes = {
  day: PropTypes.instanceOf(moment).isRequired,
  /** When the baseline(s) complete scheduling */
  onSchedule: PropTypes.func,
  /** When the user cancels the add action */
  onCancel: PropTypes.func,
};

AddScheduledItem.defaultProps = {
  onSchedule: () => {},
  onCancel: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(AddScheduledItem);
