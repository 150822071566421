import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  isArray,
} from 'lodash';
import VibeModal from '../VibeModal/VibeModal';
import VibeIcon from '../VibeIcon/VibeIcon';
import viAlert from '../../icons/viAlert';
import viError from '../../icons/viError';
import viInformation from '../../icons/viInformation';
import viCheckCircleOutline from '../../icons/viCheckCircleOutline';
import color from '../../sass/color.scss';
import './VibeAlert.scss';

/**
 * Get options based on the alert severity
 */
function getSeverityOptions(severity) {
  switch (severity) {
    case 'error':
      return {
        icon: viError,
        color: color.error,
      };

    case 'warning':
      return {
        icon: viAlert,
        color: color.orange,
      };

    case 'success':
      return {
        icon: viCheckCircleOutline,
        color: color.success,
      };

    case 'info':
    default:
      return {
        icon: viInformation,
        color: color.primary,
      };
  }
}

/**
 * Get the container style based on the alert variant
 */
function getStyle(variant, severityOptions) {
  switch (variant) {
    case 'filled':
      return {
        padding: 8,
        border: `1px solid ${severityOptions.color}`,
        borderRadius: 4,
        backgroundColor: severityOptions.color,
        color: color.white,
      };

    case 'outlined':
      return {
        padding: 8,
        border: `1px solid ${severityOptions.color}`,
        borderRadius: 4,
        color: severityOptions.color,
      };

    case 'standard':
    default:
      return {};
  }
}

function VibeAlert({
  className,
  style,
  variant,
  severity,
  alerts,
  size,
  iconOnly,
  onClick,
}) {
  const [showAllAlerts, setShowAllAlerts] = useState(false);
  const severityOptions = getSeverityOptions(severity);
  const useStyle = {
    ...getStyle(variant, severityOptions),
    ...style,
  };

  const alertItems = isArray(alerts)
    // alerts are already in an array
    ? alerts
    // only one alert text, put into an array
    : [alerts];

  // condense alerts to X or less with a show more link
  const numAlertsToShow = 3;
  const hasOverflowAlerts = alertItems.length > numAlertsToShow;
  // show all alerts in the tooltip or condense to the first X
  const showAlertItems = alertItems.slice(0, 3);

  /**
   * When the user clicks the alert container
   * Prevent the click action from any component the VibeAlert is wrapped inside
   */
  const onClickAlertContainer = (e) => {
    e.preventDefault();
    onClick(e);
  };

  /**
   * Show all alerts
  */
  const onClickShowAllAlerts = () => {
    // stop the app from clicking whatever component the alert is wrapped in
    setShowAllAlerts(true);
  };

  /**
   * Close the modal showing all alerts
   */
  const onCloseModal = () => {
    setShowAllAlerts(false);
  };

  return (
    <div
      className={classNames('VibeAlert', className)}
      style={useStyle}
      onClick={onClickAlertContainer}
    >
      <div className="alert-container">
        <VibeIcon
          icon={severityOptions.icon}
          color={variant === 'filled'
            ? color.white
            : severityOptions.color}
          size={size}
          tooltip={(
            <div className="VibeAlertItems">
              {showAlertItems.map((alert, index) => {
                return (
                  <div
                    key={`alert-${index}`}
                    className="alert"
                  >
                    {alert}
                  </div>
                );
              })}

              {hasOverflowAlerts && (
                <div
                  style={{
                    cursor: 'pointer',
                    margin: '8px 0',
                    fontWeight: 'bold',
                    fontSize: 12,
                    textAlign: 'center',
                    userSelect: 'none',
                  }}
                  onClick={onClickShowAllAlerts}
                >
                  Show All ({alertItems.length})
                </div>
              )}
            </div>
          )}
        />

        {!iconOnly && (
          <div className="alerts">
            {showAlertItems.map((alert, index) => {
              return (
                <div
                  key={`alert-${index}`}
                  className="alert"
                >
                  {alert}
                </div>
              );
            })}

            {hasOverflowAlerts && (
              <div
                style={{
                  cursor: 'pointer',
                  margin: '8px 0',
                  fontWeight: 'bold',
                  fontSize: 12,
                  textAlign: 'center',
                  userSelect: 'none',
                }}
                onClick={onClickShowAllAlerts}
              >
                Show All ({alertItems.length})
              </div>
            )}
          </div>
        )}
      </div>

      <VibeModal
        show={showAllAlerts}
        type="custom"
        title="Warnings"
        text={(
          <div>
            {alertItems.map((alert, index) => {
              return (
                <div
                  key={`alert-${index}`}
                  className="alert"
                >
                  {alert}
                </div>
              );
            })}
          </div>
        )}
        onClose={onCloseModal}
      />
    </div>
  );
}

VibeAlert.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  variant: PropTypes.oneOf([
    'standard',
    'filled',
    'outlined',
  ]),
  severity: PropTypes.oneOf([
    'error',
    'warning',
    'info',
    'success',
  ]).isRequired,
  alerts: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  size: PropTypes.number,
  iconOnly: PropTypes.bool,
  onClick: PropTypes.func,
};

VibeAlert.defaultProps = {
  className: '',
  style: {},
  variant: 'standard',
  alerts: '',
  size: 24,
  iconOnly: false,
  onClick: () => {},
};

export default VibeAlert;
