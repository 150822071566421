import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  find,
} from 'lodash';
import {
  API,
  PageLayout,
  Users,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeButton,
  VibeIcon,
  SearchByHelper,
  viMusicVoice,
  viChevronLeft,
  color,
} from 'vibeguide';
import './AssignVoiceTalent.scss';

class AssignVoiceTalent extends PureComponent {
  constructor(props) {
    super(props);

    const {
      voiceTalentId,
      voiceTalentName,
    } = this.props;

    this.state = {
      filteredVoiceTalent: [],
      voiceTalentList: [],
      voiceTalentId,
      voiceTalentName,
    };
  }

  componentDidMount() {
    this.getVoiceTalent();
  }

  onFilter = (data) => {
    const {
      collection,
    } = data;

    this.setState({
      filteredVoiceTalent: collection,
    });
  };

  onSelectUser = (userId) => {
    const {
      filteredVoiceTalent,
    } = this.state;

    const voiceTalent = find(filteredVoiceTalent, { _id: userId });

    this.setState({
      voiceTalentId: voiceTalent._id,
      voiceTalentName: `${voiceTalent.fname} ${voiceTalent.lname}`,
    });
  };

  getVoiceTalent = async () => {
    const voiceTalentList = await API.User.listVoiceTalent();

    this.setState({
      voiceTalentList,
    });
  };

  /**
   * Save Voice Talent Selection
   */
  save = async () => {
    const {
      messageId,
      onClose,
    } = this.props;

    const {
      voiceTalentId,
      voiceTalentList,
    } = this.state;

    if (!voiceTalentId) {
      // No voice talent selected
      return;
    }

    await API.MessageRequest.assignVoiceTalent({
      _id: messageId,
      assignTo: voiceTalentId,
    });

    // find the new assigned voice talent to update the message
    const voiceTalent = find(voiceTalentList, { _id: voiceTalentId });
    onClose(voiceTalent);
  };

  render() {
    const {
      onClose,
    } = this.props;

    const {
      filteredVoiceTalent,
      voiceTalentList,
      voiceTalentId,
      voiceTalentName,
    } = this.state;

    return (
      <div className="AssignVoiceTalent">
        <SidePanelContainer>
          <SidePanelHeader
            className="panel-header"
          >
            <div className="panel-header-container">
              <VibeIcon
                icon={viChevronLeft}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onClose}
              />
              <PageLayout
                className="page-layout"
                searchOptions={SearchByHelper.users}
                collection={voiceTalentList}
                onFilter={this.onFilter}
                disableView
                disableSort
              />
            </div>
          </SidePanelHeader>

          <SidePanelContent>
            <div className="voice-talent-list">
              <Users
                users={filteredVoiceTalent}
                selectedIds={[voiceTalentId]}
                onSelect={this.onSelectUser}
                onDeselect={this.onDeselectUser}
                allowClick
                slim
              />
            </div>
          </SidePanelContent>

          {voiceTalentId ? (
            <SidePanelFooter className="panel-footer">
              <div className="voice-talent-container">
                <div className="selected-voice-talent">
                  <VibeIcon
                    icon={viMusicVoice}
                    color={color.success}
                    size={16}
                  />

                  <div className="selected-name">
                    {voiceTalentName} Selected
                  </div>
                </div>

                <div className="toolbar">
                  <VibeButton
                    className="assign-btn"
                    text="Assign"
                    btnColor="green"
                    textColor="white"
                    onClick={this.save}
                  />

                  <VibeButton
                    text="Cancel"
                    btnColor="transparent"
                    textColor="manatee"
                    onClick={onClose}
                  />
                </div>
              </div>
            </SidePanelFooter>
          ) : null}
        </SidePanelContainer>
      </div>
    );
  }
}

AssignVoiceTalent.propTypes = {
  messageId: PropTypes.string.isRequired,
  voiceTalentId: PropTypes.string,
  voiceTalentName: PropTypes.string,
  onClose: PropTypes.func,
};

AssignVoiceTalent.defaultProps = {
  voiceTalentId: null,
  voiceTalentName: null,
  onClose: () => {},
};

export default AssignVoiceTalent;
