import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import VibeIcon from '../VibeIcon/VibeIcon';
import viRadioOn from '../../icons/viRadioOn';
import viRadioOff from '../../icons/viRadioOff';
import color from '../../sass/color.scss';
import './RadioField.scss';

function RadioField({
  className,
  style,
  icon,
  label,
  value,
  description,
  selected,
  disabled,
  readOnly,
  onClick,
}) {
  const onClickField = () => {
    onClick(value ?? label);
  };

  return (
    <div
      className={classNames('RadioField', className, { selected, disabled, 'read-only': readOnly })}
      style={style}
      onClick={disabled || readOnly
        ? null
        : onClickField}
    >
      {!readOnly && (
        <VibeIcon
          style={{
            marginRight: 16,
          }}
          icon={selected
            ? viRadioOn
            : viRadioOff}
          color={selected
            ? color.primary
            : color.manatee}
          size={24}
        />
      )}

      <div className="radio-info">
        <div className="radio-title">
          {icon && (
            <VibeIcon
              icon={icon}
              className="radio-icon"
              color={color.gray500}
              hoverColor={color.obsidian}
              size={16}
            />
          )}

          {label}
        </div>

        {description && (
          <div className="description">
            {description}
          </div>
        )}
      </div>
    </div>
  );
}

RadioField.propTypes = {
  /** Custom class */
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  description: PropTypes.string,
  /** Is the field selected */
  selected: PropTypes.bool.isRequired,
  /** Is the field disabled */
  disabled: PropTypes.bool,
  icon: PropTypes.func,
  /** Is the field read only (no radio icon) */
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
};

RadioField.defaultProps = {
  className: '',
  style: {},
  value: null,
  description: null,
  disabled: false,
  icon: null,
  readOnly: false,
  onClick: () => {},
};

export default RadioField;
