import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MessageSelection,
  GlobalActions,
  LocationHelper,
  VibeIcon,
  VibeButton,
  viClose,
  viAdd,
  color,
} from 'vibeguide';
import './MessageBlockMessages.scss';

class MessageBlockMessages extends PureComponent {
  constructor(props) {
    super(props);

    const {
      messages,
    } = props;

    this.state = {
      assignedMessages: messages,
    };
  }

  /**
   * When the messages are changed
   */
  onChange = (messages) => {
    const {
      onUpdate,
    } = this.props;

    this.setState({
      assignedMessages: messages,
    });

    onUpdate({
      messages,
    });
  };

  /**
   * When the assign Messages button is clicked
   */
  onToggleAddMessages = () => {
    const {
      setPanel,
      onCloseAvailable,
      locations,
      allLocations,
      companyId,
      user,
    } = this.props;

    const {
      assignedMessages,
    } = this.state;

    // Build location query for search filtering
    const locationData = user.getLocations({
      allLocations,
      companyId,
      locations,
    });

    // Group the location spec
    const group = LocationHelper.group(locationData);

    const filters = {
      active: true,
      locations: {
        matchType: 'intersect',
        locations: group,
      },
    };

    setPanel({
      thirdPanel: {
        width: window.innerWidth,
        show: true,
        children: (
          <MessageSelection
            filters={filters}
            selected={assignedMessages}
            customToolbar={(
              <VibeIcon
                className="close"
                icon={viClose}
                color={color.white}
                hoverColor={color.white}
                size={24}
                onClick={onCloseAvailable}
              />
            )}
            assigning
            allowChanges
            onChange={this.onChange}
          />
        ),
      },
    });
  };

  render() {
    const {
      sequence,
      allowChanges,
      onCloseAssigned,
    } = this.props;

    const {
      assignedMessages,
    } = this.state;

    return (
      <div className="MessageBlockMessages">
        <MessageSelection
          selected={assignedMessages}
          allowCustomOrder={sequence !== 'shuffled' && allowChanges}
          customToolbar={(
            <div className="assigned-toolbar">
              {allowChanges ? (
                <VibeButton
                  className="btn-toolbar"
                  text="Assign Messages"
                  btnColor="green"
                  variant="outlined"
                  icon={(
                    <VibeIcon
                      icon={viAdd}
                      color={color.success}
                      size={16}
                    />
                  )}
                  textColor="green"
                  onClick={this.onToggleAddMessages}
                />
              ) : null}

              <VibeIcon
                className="close"
                icon={viClose}
                color={color.manatee}
                hoverColor={color.obsidian}
                size={24}
                onClick={onCloseAssigned}
              />
            </div>
          )}
          allowChanges={allowChanges}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

MessageBlockMessages.propTypes = {
  companyId: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    companyId: PropTypes.string,
    locationId: PropTypes.string,
  })),
  allLocations: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.shape),
  /** Message block message sequence */
  sequence: PropTypes.oneOf([
    'fixed',
    'fixed-no-repeat',
    'shuffled',
  ]),
  /** Allow changes to the message selection */
  allowChanges: PropTypes.bool,
  /** When the assigned messages panel is closed */
  onCloseAssigned: PropTypes.func,
  /** When the available messages panel is closed */
  onCloseAvailable: PropTypes.func,
  onUpdate: PropTypes.func,
};

MessageBlockMessages.defaultProps = {
  companyId: '',
  locations: [],
  allLocations: false,
  messages: [],
  sequence: 'fixed',
  allowChanges: false,
  onCloseAssigned: () => {},
  onCloseAvailable: () => {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBlockMessages);
