import React from 'react';
import PropTypes from 'prop-types';
import LabelCell from '../VibeTable/CellTypes/LabelCell';
import color from '../../sass/color.scss';

/**
 * Get the style for the missing creatives tag
 */
function getStatusStyle(status) {
  switch (status) {
    case 'Missing':
      return {
        backgroundColor: color.error16,
        color: color.error,
      };

    case 'Attached':
      return {
        backgroundColor: color.success16,
        color: color.success,
      };

    case 'N/A':
      return {
        backgroundColor: color.orange16,
        color: color.orange,
      };

    default:
      return {
        backgroundColor: color.orange16,
        color: color.orange,
      };
  }
}

function MissingCreatives({
  status,
}) {
  const statusStyle = getStatusStyle(status);

  return (
    <LabelCell
      value={status}
      background={statusStyle.backgroundColor}
      color={statusStyle.color}
      style={{
        textTransform: 'uppercase',
      }}
      inline
    />
  );
}

MissingCreatives.propTypes = {
  status: PropTypes.string.isRequired,
};

export default MissingCreatives;
