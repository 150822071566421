import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SidePanelSection from '../SidePanelSection';
import VibeTooltip from '../../VibeTooltip/VibeTooltip';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viArrowRight from '../../../icons/viArrowRight';
import viError from '../../../icons/viError';
import color from '../../../sass/color.scss';
import './Assignments.scss';

function getStyle(count) {
  const style = {
    backgroundColor: color.manatee16,
    color: color.manatee,
  };

  // ACAF locations
  const isACAF = count === 'All Current and Future';
  // Custom count element
  const isCustom = typeof count === 'object';

  if (isACAF) {
    style.backgroundColor = color.success16;
    style.color = color.success;
  } else if (isCustom) {
    style.backgroundColor = 'transparent';
  }

  return style;
}

function Assignments({
  className,
  style,
  title,
  items,
  required,
}) {
  return (
    <SidePanelSection
      className={classNames('Assignments', className)}
      style={style}
      title={title}
    >
      <div className={`assignments-container ${required && 'assignments-required'}`}>
        {items.map((item) => {
          const {
            label,
            count,
            tooltip,
            required,
            disabled,
            warning,
            onClick,
            onClickWarning,
          } = item;

          // Custom count element
          const custom = typeof count === 'object';
          // get the formatting for the count/label/element
          const style = getStyle(count);

          return (
            <div
              key={`assignment-${label}`}
              className={classNames('assignment', { disabled })}
              onClick={onClick}
            >
              <div className={classNames('assignment-name', { required })}>
                {warning && (
                  <div
                    className="on-click-warning"
                    onClick={onClickWarning}
                  >
                    <VibeIcon
                      icon={viError}
                      color={color.error}
                      size={16}
                      tooltip={warning}
                      style={{
                        marginRight: 4,
                      }}
                    />
                  </div>
                )}

                {label}
              </div>

              {count !== undefined && (
                <VibeTooltip
                  title={tooltip || ''}
                >
                  <div
                    className={classNames('assignment-count', { custom })}
                    style={style}
                  >
                    <div className="count">
                      {count}
                    </div>
                  </div>
                </VibeTooltip>
              )}

              <div className="assignment-extra">
                <VibeIcon
                  icon={viArrowRight}
                  color={color.obsidian}
                  size={16}
                />
              </div>
            </div>
          );
        })}
      </div>
    </SidePanelSection>
  );
}

Assignments.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  /** Items */
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    count: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.element,
    ]),
    tooltip: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    warning: PropTypes.string,
    onClick: PropTypes.func,
  })).isRequired,
};

Assignments.defaultProps = {
  className: '',
  style: {},
  title: 'Assignments',
};

export default Assignments;
