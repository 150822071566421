import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
} from 'lodash';
import {
  ColorUtil,
  VibeTooltip,
  color,
} from 'vibeguide';

function LayoutTemplatePreview({
  className,
  style,
  scalePercent,
  width,
  height,
  zones,
  targetZone,
  selectable,
  onClickZone,
}) {
  // convert percent 20 to 0.2
  const scaleTo = scalePercent / 100;

  /**
   * When the user hovers over a specific zone in the preview
   */
  const onMouseEnterZone = (e) => {
    e.target.style.backgroundColor = ColorUtil.transparent(color.primary, 25);
  };

  /**
   * When the user stops hovering over a specific zone in the preview
   */
  const onMouseLeaveZone = (e) => {
    const zoneName = get(e, 'target.dataset.zone');

    if (zoneName !== targetZone) {
      // change the background color back if not the target zone (spot pool item)
      e.target.style.backgroundColor = 'transparent';
    }
  };

  /**
   * When a zone is clicked
   */
  const onClick = (e) => {
    // do not allow click if not selectable
    if (!selectable) {
      return;
    }

    const zoneName = get(e, 'target.dataset.zone');
    onClickZone(zoneName);
  };

  return (
    <div
      className={classNames('LayoutTemplatePreview', className)}
      style={{
        overflow: 'auto',
        ...style,
      }}
    >
      <div
        className="preview-main"
        style={{
          position: 'relative',
          width: width * scaleTo,
          height: height * scaleTo,
          margin: '0 auto',
          border: `1px solid ${color.obsidian}`,
        }}
      >
        {zones.map((zone) => (
          <VibeTooltip
            key={`zone-${zone._id}-${zone.name}`}
            title={zone.name}
            placement="top"
          >
            <div
              className="zone"
              style={{
                cursor: selectable
                  ? 'pointer'
                  : 'default',
                position: 'absolute',
                top: get(zone, 'position[1]', 0) * scaleTo,
                left: get(zone, 'position[0]', 0) * scaleTo,
                width: get(zone, 'size[0]', 0) * scaleTo,
                height: get(zone, 'size[1]', 0) * scaleTo,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: targetZone === zone.name
                  ? ColorUtil.transparent(color.primary, 25)
                  : 'transparent',
                boxSizing: 'border-box',
                border: targetZone === zone.name
                  ? `1px solid ${ColorUtil.transparent(color.primary, 30)}`
                  : `1px solid ${color.whiteSmoke}`,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                userSelect: 'none',
              }}
              data-zone={zone.name}
              onMouseEnter={onMouseEnterZone}
              onMouseLeave={onMouseLeaveZone}
              onClick={onClick}
            >
              {zone.name}
            </div>
          </VibeTooltip>
        ))}
      </div>
    </div>
  );
}

LayoutTemplatePreview.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  scalePercent: PropTypes.number,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  zones: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.arrayOf(PropTypes.number),
    position: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])),
  })),
  // show which zone is selected (spot pool item modal)
  targetZone: PropTypes.string,
  // allow select of the zones
  selectable: PropTypes.bool,
  // when a zone is clicked
  onClickZone: PropTypes.func,
};

LayoutTemplatePreview.defaultProps = {
  className: '',
  style: {},
  scalePercent: 20,
  width: 0,
  height: 0,
  zones: [],
  targetZone: '',
  selectable: false,
  onClickZone: () => {},
};

export default LayoutTemplatePreview;
