import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import {
  VibeButtonNew,
  VibeIcon,
  viTableChart,
  color,
} from 'vibeguide';

function ReportCard({
  className,
  style,
  name,
}) {
  return (
    <div
      className={classNames('ReportCard', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        width: 360,
        padding: 32,
        backgroundColor: color.white,
        border: `1px solid ${color.whiteSmoke}`,
        borderRadius: 4,
        ...style,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 56,
          height: 56,
          backgroundColor: color.primary,
          borderRadius: '100%',
        }}
      >
        <VibeIcon
          icon={viTableChart}
          color={color.white}
          size={28}
        />
      </div>

      <div
        style={{
          flexGrow: 1,
          fontsize: 24,
          lineHeight: '32px',
          fontWeight: 600,
        }}
      >
        {name}
      </div>

      <div>
        <VibeButtonNew
          style={{
            backgroundColor: color.primary16,
            border: 'none',
          }}
          variant="outlined"
          text="View Report"
          color={color.primary}
          link={`/reporting/report/${kebabCase(name)}`}
        />
      </div>
    </div>
  );
}

ReportCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string.isRequired,
};

ReportCard.defaultProps = {
  className: '',
  style: {},
};

export default ReportCard;
