import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  useNavigate,
} from 'react-router-dom';
import {
  get,
} from 'lodash';
import {
  API,
  NavigationHelper,
  GlobalActions,
  ToastActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeButtonNew,
  VibeTooltip,
  VibeIcon,
  viClose,
  viArchive,
  viUnarchive,
  color,
} from 'vibeguide';
import Information from './Information';
import './DeliverySystemDetails.scss';

function DeliverySystemDetails({
  className,
  style,
  deliverySystem,
  user,
  setPanel,
  queueToast,
  onUpdate,
}) {
  const [confirmArchive, setConfirmArchive] = useState(false);
  const history = useNavigate();

  const onClose = () => {
    setPanel({
      show: false,
    });
  };

  /**
   * Archive the item
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * When the archive modal is closed
   */
  const onCloseArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * Archive the item
   */
  const onClickUnarchive = async () => {
    await API.Flight.DeliverySystem.reactivate({
      _id: deliverySystem._id,
    });

    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveDeliverySystem'));
  };

  /**
   * When the archive modal is confirmed
   */
  const onConfirmArchive = async () => {
    await API.Flight.DeliverySystem.deactivate(deliverySystem._id);

    onCloseArchive();
    onClose();

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveDeliverySystem'));
  };

  const onSave = async () => {
    const data = {
      value: deliverySystem.value,
    };

    if (deliverySystem._id) {
      // editing an object
      data._id = deliverySystem._id;
    }

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    try {
      const response = deliverySystem._id
        ? await API.Flight.DeliverySystem.update(data)
        : await API.Flight.DeliverySystem.create(data);

      const deliverySystemId = get(response, '[0].documentId', null);
      const responseType = get(response, '[0].type');

      const success = data._id
        ? responseType === 'FLIGHTDELIVERYSYSTEM.UPDATED'
        : responseType === 'FLIGHTDELIVERYSYSTEM.CREATED';

      if (success) {
        // Successfully saved the resolution
        const saveMessage = data._id
          ? 'Delivery System Updated!'
          : 'Delivery System Created!';

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
        });

        // tell listening components the object was saved
        document.dispatchEvent(new Event('onSaveDeliverySystem'));

        // creating a new object, redirect to the proper URL
        if (!deliverySystem._id) {
          const redirectUrl = NavigationHelper.updateParams({
            deliverySystemId,
            type: null,
          });

          history(redirectUrl);
        }
      } else {
        console.error('Error saving delivery system to API', response);

        queueToast({
          type: 'error',
          title: 'Error Saving Delivery System',
          timeout: 10,
          allowClose: true,
        });
      }
    } catch (err) {
      console.error('Error saving delivery system', err);

      queueToast({
        type: 'error',
        title: 'Error Saving Delivery System',
        timeout: 10,
        allowClose: true,
      });
    }
  };

  const disableSave = !deliverySystem.value;

  const disableInput = (deliverySystem._id && !user.can('flight_delivery_system.modify'))
    || (!deliverySystem._id && !user.can('flight_delivery_system.create'));

  const showArchive = user.can('flight_delivery_system.delete');
  const isArchived = !deliverySystem.active;

  return (
    <SidePanelContainer
      className={classNames('DeliverySystemDetails', className)}
      style={style}
    >
      <SidePanelHeader
        icons={(
          <VibeIcon
            className="close"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={24}
            onClick={onClose}
          />
        )}
      >
        <div className="title">
          <VibeTooltip title={deliverySystem.value || 'New Delivery System'}>
            <span>
              {deliverySystem._id
                ? deliverySystem.value
                : 'New Delivery System'}
            </span>
          </VibeTooltip>
        </div>
      </SidePanelHeader>

      <SidePanelContent>
        <Information
          value={deliverySystem.value}
          disableInput={disableInput}
          onUpdate={onUpdate}
        />
      </SidePanelContent>

      <SidePanelFooter className="panel-footer">
        <VibeButtonNew
          text="Save Delivery System"
          style={{
            marginRight: 12,
          }}
          color={color.primary}
          // loadingEvent="onSaveDeliverySystem"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {deliverySystem._id && (
          <div className="toolbar-buttons">
            {showArchive && !isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viArchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.error,
                  }}
                  tooltip="Archive"
                  color={color.error}
                  size={20}
                  onClick={onClickArchive}
                />
              </div>
            ) : null}

            {showArchive && isArchived ? (
              <div className="toolbar-button">
                <VibeIcon
                  icon={viUnarchive}
                  type="button"
                  buttonProps={{
                    size: 32,
                    borderColor: color.success,
                  }}
                  tooltip="Unarchive"
                  color={color.success}
                  size={20}
                  onClick={onClickUnarchive}
                />
              </div>
            ) : null}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text="Would you like to archive this delivery system?"
        confirmProps={{
          text: 'Archive',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCloseArchive}
      />
    </SidePanelContainer>
  );
}

DeliverySystemDetails.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  deliverySystem: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  onUpdate: PropTypes.func,
};

DeliverySystemDetails.defaultProps = {
  className: '',
  style: {},
  deliverySystem: {},
  onUpdate: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySystemDetails);
