import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  TimeUtil,
  Field2 as Field,
  VibeButtonNew,
  VibeIcon,
  viMusic,
  viTime,
  viClose,
  color,
} from 'vibeguide';
import {
  Popover,
} from '@mui/material';
import ArchivedBanner from './ArchivedBanner';
import defaultPlaylistImage from '../../../../../assets/default_playlist.png';
import './Playlist.scss';

class Playlist extends PureComponent {
  constructor(props) {
    super(props);

    this.itemRef = React.createRef();
    this.startTime = null;
    this.stopTime = null;

    this.state = {
      showDetails: false,
      invalidStartTime: false,
      invalidStopTime: false,
    };
  }

  onRemove = () => {
    const {
      id,
    } = this.props;

    document.dispatchEvent(new CustomEvent('onRemoveItem', {
      detail: {
        type: 'Playlist',
        _uniqueId: id,
      },
    }));
  };

  onChangeStartTime = (data) => {
    // time field has an error
    if (data.hasError) {
      this.setState({
        invalidStartTime: true,
      });

      return;
    }

    this.startTime = data.time24;

    // no error, allow saving if the stop time is also valid
    this.setState({
      invalidStartTime: false,
    });
  };

  onChangeStopTime = (data) => {
    // time field has an error
    if (data.hasError) {
      this.setState({
        invalidStopTime: true,
      });

      return;
    }

    let newStopTime = data.time24;

    const time = data.time24.split(':');
    const hour = parseInt(time[0], 10);

    if (hour < 0 || hour >= 24) {
      newStopTime = '24:00';
    }

    this.stopTime = newStopTime;

    // no error, allow saving if the start time is also valid
    this.setState({
      invalidStopTime: false,
    });
  };

  onClosePopover = () => {
    this.setState({
      showDetails: false,
    });

    this.startTime = null;
    this.stopTime = null;
  };

  onSave = () => {
    const {
      id,
    } = this.props;

    document.dispatchEvent(new CustomEvent('onChangeItemDuration', {
      detail: {
        type: 'Playlist',
        startTime: this.startTime,
        stopTime: this.stopTime,
        _uniqueId: id,
      },
    }));

    this.onClosePopover();
  };

  toggleActive = () => {
    this.setState((state) => {
      return {
        showDetails: !state.showDetails,
      };
    });
  };

  render() {
    const {
      playlist,
      startTime,
      stopTime,
      minStartTime,
      maxStopTime,
      isHover,
      archived,
      readOnly,
    } = this.props;

    const {
      showDetails,
      invalidStartTime,
      invalidStopTime,
    } = this.state;

    const {
      itemRef: {
        current: itemRef,
      },
    } = this;

    const itemStart = TimeUtil.convertToTwelveHour({
      time: startTime,
    });

    const itemStop = TimeUtil.convertToTwelveHour({
      time: stopTime,
    });

    const duration = TimeUtil.convertDuration(playlist.durationSeconds, '0h 0m');

    if (isHover) {
      return (
        <div className="Playlist is-hover">
          {archived ? (
            <ArchivedBanner />
          ) : null}

          <div className="item-container">
            <div className="music-span">
              {itemStart} - {itemStop}
            </div>

            <div className="music-info">
              <div className="music-image-container">
                {playlist.imageUrl ? (
                  <img src={playlist.imageUrl} className="music-image" alt="Music" />
                ) : (
                  <img className="music-image" src={defaultPlaylistImage} alt="Music" />
                )}
              </div>

              <div>
                <div className="music-title">
                  {playlist.name}
                </div>

                <div className="music-category">
                  {playlist.category}
                </div>

                <div className="music-details">
                  <span className="music-data">
                    <VibeIcon
                      className="music-icon"
                      icon={viMusic}
                      color={color.manatee}
                      size={12}
                    />

                    <span>
                      {playlist.musicItemsCount}
                    </span>
                  </span>

                  <span className="music-data">
                    <VibeIcon
                      className="detail-icon"
                      icon={viTime}
                      color={color.manatee}
                      size={12}
                    />

                    <span>
                      {duration}
                    </span>
                  </span>
                </div>

                <div className="music-description">
                  {playlist.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const itemRefRect = itemRef
      ? itemRef.getBoundingClientRect()
      : { top: 0, left: 0 };

    const top = itemRefRect.top - 20;
    const left = itemRefRect.left + (itemRefRect.width / 2);

    return (
      <div className={classNames('Playlist', { active: showDetails, archived })}>
        {!readOnly && (
          <VibeIcon
            className="music-remove"
            icon={viClose}
            color={color.manatee}
            hoverColor={color.obsidian}
            size={16}
            onClick={this.onRemove}
          />
        )}

        <div
          className="item-container"
          ref={this.itemRef}
          onClick={!readOnly
            ? this.toggleActive
            : null}
        >
          <div className="playlist-span">
            {itemStart} - {itemStop}
          </div>

          <div className="music-info">
            <div className="music-image-container">
              {playlist.imageUrl ? (
                <img src={playlist.imageUrl} className="music-image" alt="Music" />
              ) : (
                <img className="music-image" src={defaultPlaylistImage} alt="Music" />
              )}
            </div>

            <div>
              <div className="music-title">
                {playlist.name}
              </div>

              <div className="music-category">
                {playlist.category}
              </div>
            </div>
          </div>

          <div className="music-details">
            <span className="music-data">
              <VibeIcon
                className="music-icon"
                icon={viMusic}
                color={color.manatee}
                size={12}
              />
              <span>
                {playlist.musicItemsCount}
              </span>
            </span>
            <span className="music-data">
              <VibeIcon
                className="detail-icon"
                icon={viTime}
                color={color.manatee}
                size={12}
              />
              <span>
                {duration}
              </span>
            </span>
          </div>

          <div className="music-description">
            {playlist.description}
          </div>
        </div>

        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{
            top,
            left,
          }}
          open={showDetails}
          onClose={this.onClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          classes={{
            root: 'ItemPopperRoot',
            paper: 'ItemPopper',
          }}
        >
          <div className="item-details-container">
            <div className="item-details">
              <div className="details-toolbar">
                <VibeIcon
                  icon={viClose}
                  color={color.manatee}
                  hoverColor={color.obsidian}
                  onClick={!readOnly
                    ? this.toggleActive
                    : null}
                />
              </div>

              <div className="time-fields">
                <div className="start-time">
                  <Field
                    type="time"
                    label="Start Time"
                    placeholder="9:00am"
                    value={startTime}
                    tabIndex={1}
                    timeProps={{
                      // prevent overlapping items in the schedule
                      min: minStartTime,
                      max: stopTime,
                    }}
                    onChange={this.onChangeStartTime}
                  />
                </div>
                <div className="stop-time">
                  <Field
                    type="time"
                    label="Stop Time"
                    placeholder="9:00am"
                    value={stopTime}
                    tabIndex={2}
                    timeProps={{
                      min: startTime,
                      // prevent overlapping items in the schedule
                      max: maxStopTime,
                      endOfDay: true,
                    }}
                    onChange={this.onChangeStopTime}
                  />
                </div>
              </div>

              <div
                style={{
                  marginTop: 8,
                  textAlign: 'center',
                }}
              >
                <VibeButtonNew
                  text="Save"
                  color={color.primary}
                  disabled={invalidStartTime || invalidStopTime}
                  onClick={this.onSave}
                />
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

Playlist.propTypes = {
  /** Unique ID in the TimeSlot */
  id: PropTypes.string,
  playlist: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    active: PropTypes.bool,
    category: PropTypes.string,
    durationSeconds: PropTypes.number,
    name: PropTypes.string,
  }),
  startTime: PropTypes.string,
  stopTime: PropTypes.string,
  /** Prevent starting before */
  minStartTime: PropTypes.string,
  /** Prevent stopping after */
  maxStopTime: PropTypes.string,
  archived: PropTypes.bool,
  /** When the item is inside a hover popover */
  isHover: PropTypes.bool,
  /** All data in the schedule is read-only */
  readOnly: PropTypes.bool,
};

Playlist.defaultProps = {
  id: null,
  playlist: {
    active: true,
    category: '',
    durationSeconds: 0,
    name: '',
  },
  startTime: '',
  stopTime: '',
  minStartTime: '',
  maxStopTime: '',
  archived: false,
  isHover: false,
  readOnly: false,
};

export default Playlist;
