import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
} from 'lodash';
import {
  AdProviderHelper,
  VibeModal,
  color,
} from 'vibeguide';
import ProviderHeader from '../ProviderHeader';

function ProviderGoodvibes({
  className,
  style,
  providerKey,
  onRemoveProvider,
}) {
  const [confirmRemoveProvider, setConfirmRemoveProvider] = useState(false);

  /**
   * When a user clicks remove on an item, show confirmation modal
   */
  const onClickRemoveProvider = () => {
    setConfirmRemoveProvider(true);
  };

  /**
   * When the user confirms removing the provider
   */
  const onConfirmRemoveProvider = () => {
    setConfirmRemoveProvider(false);
    onRemoveProvider(providerKey);
  };

  /**
   * When the user cancels removing the provider
   */
  const onCancelRemoveProvider = () => {
    setConfirmRemoveProvider(false);
  };

  // get the name of the ad provider
  const adProviderName = get(find(AdProviderHelper.list, { value: providerKey }), 'name', 'Unknown');

  return (
    <div
      className={classNames('ProviderGoodvibes', className)}
      style={style}
    >
      <ProviderHeader
        title={adProviderName}
        showRemove
        onClickRemove={onClickRemoveProvider}
      />

      <div
        style={{
          padding: 24,
        }}
      >
        No configuration required
      </div>

      <VibeModal
        show={confirmRemoveProvider}
        type="confirm"
        title="Removing Ad Provider"
        text={(
          <div>
            You are about to remove the
            <span
              style={{
                padding: '0 4px',
                color: color.error,
                fontWeight: 'bold',
              }}
            >
              {adProviderName}
            </span>
            Ad Provider and all configurations. Do you want to proceed?
          </div>
        )}
        confirmProps={{
          text: 'Remove Ad Provider',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmRemoveProvider}
        onClose={onCancelRemoveProvider}
      />
    </div>
  );
}

ProviderGoodvibes.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  providerKey: PropTypes.string.isRequired,
  onRemoveProvider: PropTypes.func,
};

ProviderGoodvibes.defaultProps = {
  className: '',
  style: {},
  onRemoveProvider: () => {},
};

export default ProviderGoodvibes;
