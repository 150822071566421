import React from 'react';
import PropTypes from 'prop-types';
import FullObjectSelection from '../FullObjectSelection/FullObjectSelection';
import CompaniesUnassigned from './CompaniesUnassigned';
import CompaniesAssigned from './CompaniesAssigned';

function CompanySelection({
  filters,
  selected,
  partnerId,
  allowCustomOrder,
  allowChanges,
  allowACAF,
  customToolbar,
  assigning,
  onChange,
}) {
  if (assigning) {
    return (
      <FullObjectSelection className="CompanySelection">
        <CompaniesUnassigned
          selected={selected}
          partnerId={partnerId}
          customToolbar={customToolbar}
          filters={filters}
          onChange={onChange}
        />
      </FullObjectSelection>
    );
  }

  return (
    <FullObjectSelection className="CompanySelection">
      <CompaniesAssigned
        selected={selected}
        allowCustomOrder={allowCustomOrder}
        allowChanges={allowChanges}
        allowACAF={allowACAF}
        customToolbar={customToolbar}
        onChange={onChange}
      />
    </FullObjectSelection>
  );
}

CompanySelection.propTypes = {
  /** Filters for the fetch method */
  filters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  /** Selected objects */
  selected: PropTypes.arrayOf(PropTypes.object),
  partnerId: PropTypes.string,
  /** Allow custom order of objects in the assigned table */
  allowCustomOrder: PropTypes.bool,
  /** Allow changes to the object selection */
  allowChanges: PropTypes.bool,
  /** Allow All Current and Future */
  allowACAF: PropTypes.bool,
  /** Custom toolbar for assigned locations */
  customToolbar: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Selector is assigning objects */
  assigning: PropTypes.bool,
  /** When the objects are changed */
  onChange: PropTypes.func,
};

CompanySelection.defaultProps = {
  filters: null,
  selected: [],
  partnerId: '',
  allowCustomOrder: false,
  allowChanges: false,
  allowACAF: false,
  assigning: false,
  customToolbar: null,
  onChange: () => {},
};

export default CompanySelection;
