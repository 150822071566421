import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeIcon,
  viRadioOn,
  viRadioOff,
  color,
} from 'vibeguide';
import './Behavior.scss';

class Behavior extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      frequencyError: '',
    };
  }

  onChangeIntegration = (integration) => {
    const {
      groupSize,
      onUpdate,
    } = this.props;

    onUpdate({
      listType: integration,
      groupSize: integration === 'duration'
        // reset consecutive messages to 1 for duration message blocks
        ? 1
        // keep consecutive messages the same
        : groupSize,
    });
  };

  onChangeFrequency = (e) => {
    const {
      onUpdate,
      integration,
    } = this.props;

    const {
      target: {
        value,
      },
    } = e;

    const num = parseInt(value, 10);

    if (integration === 'duration') {
      if (value === '' || (num > 0 && num < 1440)) {
        this.setState({
          frequencyError: '',
        });
        onUpdate({
          frequency: num || value,
        });
      } else if (num > 1439) {
        this.setState({
          frequencyError: 'Frequency value must be less than 1440 minutes.',
        });
      }
    }

    if (integration === 'rotate') {
      if (value === '' || num > 0) {
        this.setState({
          frequencyError: '',
        });
        onUpdate({
          frequency: num || value,
        });
      }
    }
  };

  onBlurFrequency = (e) => {
    const {
      onUpdate,
    } = this.props;

    const {
      target: {
        value,
      },
    } = e;

    const num = parseInt(value, 10);

    if (value === '' || num < 1) {
      onUpdate({
        frequency: 4,
      });
    }
  };

  onChangeConsecutiveMessages = (e) => {
    const {
      onUpdate,
    } = this.props;

    const {
      target: {
        value,
      },
    } = e;

    const num = parseInt(value, 10);

    if (value === '' || num > 0) {
      onUpdate({
        groupSize: num || value,
      });
    }
  };

  onBlurConsecutiveMessages = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    const num = parseInt(value, 10);

    const {
      onUpdate,
    } = this.props;

    if (value === '' || num < 1) {
      onUpdate({
        groupSize: 1,
      });
    }
  };

  onChangeStartType = (startType) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      startType,
    });
  };

  onChangeMessageSequence = (messageSequence) => {
    const {
      onUpdate,
    } = this.props;

    onUpdate({
      sequence: messageSequence,
    });
  };

  render() {
    const {
      integration,
      frequency,
      groupSize,
      startType,
      messageSequence,
      disableInput,
    } = this.props;

    const {
      frequencyError,
    } = this.state;

    return (
      <div className="Behavior">
        <div className="block-container left-blocks">
          <div className="block">
            <div className="section-header">
              <div className="section-title">
                Vibe Integration
              </div>
              <div className="section-subtitle">
                How would you like these messages to integrate with your music?
              </div>
            </div>

            <div className="integration-settings">
              <div
                className={classNames('integration-setting', { selected: integration === 'rotate' })}
                onClick={disableInput ? null : () => this.onChangeIntegration('rotate')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={integration === 'rotate'
                    ? viRadioOn
                    : viRadioOff}
                  color={integration === 'rotate'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    Rotation
                  </div>
                  <div className="integration-subtitle">
                    Play messages at specific intervals of songs.
                  </div>
                </div>
              </div>

              <div
                className={classNames('integration-setting', { selected: integration === 'duration' })}
                onClick={disableInput ? null : () => this.onChangeIntegration('duration')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={integration === 'duration'
                    ? viRadioOn
                    : viRadioOff}
                  color={integration === 'duration'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    Duration
                  </div>
                  <div className="integration-subtitle">
                    Play messages at specific intervals of time.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block">
            <div className="section-header">
              <div className="section-title">
                Message Frequency
              </div>
              <div className="section-subtitle">
                How often would you like to hear the messages?
              </div>
            </div>

            <div className="integration-settings">
              <div className="integration-frequency">
                Every
                <input
                  type="number"
                  className="song-input"
                  value={frequency}
                  onChange={this.onChangeFrequency}
                  disabled={disableInput}
                  onBlur={this.onBlurFrequency}
                />
                {integration === 'rotate' ? 'songs.' : 'minutes.'}
              </div>
              <div className="frequency-error">
                {frequencyError}
              </div>
            </div>
          </div>

          <div className="block">
            <div className="section-header">
              <div className="section-title">
                Consecutive Messages
              </div>
              <div className="section-subtitle">
                How many messages would you like to hear in a row?
              </div>
            </div>

            <div className="integration-settings">
              <div className="integration-consecutive">
                <input
                  type="number"
                  className="consecutive-input"
                  value={groupSize}
                  disabled={disableInput || integration === 'duration'}
                  onChange={this.onChangeConsecutiveMessages}
                  onBlur={this.onBlurConsecutiveMessages}
                />

                <div className="consecutive-text">
                  {groupSize === 1
                    ? 'message'
                    : 'messages'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="block-container right-blocks">
          <div className="block">
            <div className="section-header">
              <div className="section-title">
                Start Type
              </div>
              <div className="section-subtitle">
                When the message block begins, would you like the first message
                to play before or after the next song in the queue?
              </div>
            </div>

            <div className="integration-settings">
              <div
                className={classNames('integration-setting', 'center', { selected: startType === 'before' })}
                onClick={disableInput ? null : () => this.onChangeStartType('before')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={startType === 'before'
                    ? viRadioOn
                    : viRadioOff}
                  color={startType === 'before'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    Before
                  </div>
                  <div className="integration-subtitle">
                    Messages will begin before the music.
                  </div>
                </div>
              </div>

              <div
                className={classNames('integration-setting', 'center', { selected: startType === 'after' })}
                onClick={disableInput ? null : () => this.onChangeStartType('after')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={startType === 'after'
                    ? viRadioOn
                    : viRadioOff}
                  color={startType === 'after'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    After
                  </div>
                  <div className="integration-subtitle">
                    Messages will begin after the music.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="block">
            <div className="section-header">
              <div className="section-title">
                Message Sequence
              </div>
              <div className="section-subtitle">
                In which order would you like to hear the messages?
              </div>
            </div>

            <div className="integration-settings">
              <div
                className={classNames('integration-setting short', { selected: messageSequence === 'fixed' })}
                onClick={disableInput ? null : () => this.onChangeMessageSequence('fixed')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={messageSequence === 'fixed'
                    ? viRadioOn
                    : viRadioOff}
                  color={messageSequence === 'fixed'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    Fixed
                  </div>
                  <div className="integration-subtitle">
                    Play messages in order on a loop.
                  </div>
                </div>
              </div>

              <div
                className={classNames('integration-setting short', { selected: messageSequence === 'fixed-no-repeat' })}
                onClick={disableInput ? null : () => this.onChangeMessageSequence('fixed-no-repeat')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={messageSequence === 'fixed-no-repeat'
                    ? viRadioOn
                    : viRadioOff}
                  color={messageSequence === 'fixed-no-repeat'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    Fixed (No Repeat)
                  </div>
                  <div className="integration-subtitle">
                    Play messages in order only once.
                  </div>
                </div>
              </div>

              <div
                className={classNames('integration-setting', 'short', { selected: messageSequence === 'shuffled' })}
                onClick={disableInput ? null : () => this.onChangeMessageSequence('shuffled')}
              >
                <VibeIcon
                  className="icon-selected"
                  icon={messageSequence === 'shuffled'
                    ? viRadioOn
                    : viRadioOff}
                  color={messageSequence === 'shuffled'
                    ? color.primary
                    : color.manatee}
                  size={24}
                />

                <div className="integration-info">
                  <div className="integration-title">
                    Shuffled
                  </div>
                  <div className="integration-subtitle">
                    Shuffle after every message has played.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Behavior.propTypes = {
  integration: PropTypes.string,
  frequency: PropTypes.number,
  groupSize: PropTypes.number,
  startType: PropTypes.string,
  messageSequence: PropTypes.string,
  onUpdate: PropTypes.func,
  disableInput: PropTypes.bool,
};

Behavior.defaultProps = {
  integration: 'rotate',
  frequency: 4,
  groupSize: 1,
  startType: 'before',
  messageSequence: 'fixed',
  disableInput: false,
  onUpdate: () => {},
};

export default Behavior;
