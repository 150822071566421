import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {
  get,
  isEqual,
  find,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
} from '../../../helpers/Navigation';
import VibeTable from '../VibeTable';
import VibeButton from '../../VibeButton/VibeButton';
import VibeIcon from '../../VibeIcon/VibeIcon';
import CellTooltip from '../CellTypes/CellTooltip';
import viTime from '../../../icons/viTime';
import color from '../../../sass/color.scss';
import viEdit from '../../../icons/viEdit';
import viClose from '../../../icons/viClose';
import viCheck from '../../../icons/viCheck';
import Field from '../../Field/Field2';
import VibeButtonNew from '../../VibeButton/VibeButtonNew';
import AudioCell from '../CellTypes/AudioCell';
import VibeModal from '../../VibeModal/VibeModal';
import { formatByteSize } from '../../../helpers/FormatByteSize';

const tableId = 'table:creatives';
const permissionPrefix = 'flight_segment';

class TableCreatives extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Day Part',
      searchAttr: 'dayPart',
      defaultWidth: 230,
      /**
       * prevent resizing when creative management is disabled
       * this allows the Creative Management Unavailable column to expand to fill unused space
       * while keeping the day part column the correct size
       */
      resizable: !columnNames.includes('Creative Management Unavailable'),
      editable: true,
    },
    {
      name: 'Message Name',
      searchAttr: 'name',
      defaultWidth: 345,
      resizable: true,
    },
    {
      name: 'Weight',
      searchAttr: 'weight',
      defaultWidth: 100,
      resizable: true,
      editable: true,
    },
    {
      name: 'File Type',
      searchAttr: 'fileType',
      defaultWidth: 150,
      resizable: true,
    },
    {
      name: 'File Size',
      searchAttr: 'fileSize',
      defaultWidth: 150,
      resizable: true,
    },
    {
      name: 'Graphic Size',
      searchAttr: 'graphicSize',
      defaultWidth: 100,
      resizable: true,
    },
    {
      name: 'Duration',
      searchAttr: 'durationSeconds',
      icon: viTime,
      defaultWidth: 100,
      resizable: true,
    },
    {
      name: 'Creative Management Unavailable',
      label: '',
      defaultWidth: 500,
      resizable: true,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      rowsEdit: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      isEditMode: false,
      errorIds: [],
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
    };

    // listen for data changes
    document.addEventListener('onUpdateTableCreatives', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
      onSuccess,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }

    if (onSuccess) {
      this.setState({
        isEditMode: false,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onUpdateTableCreatives', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  onSelectMenuItem = (itemName, _rowId) => {
    const {
      onSelectMenuItem,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });

    if (!row) {
      console.error('Row not found matching ID', _rowId);
      return;
    }

    switch (itemName) {
      case 'delete': {
        this.setState({
          confirm: true,
          confirmAction: itemName,
          confirmRow: row,
          confirmText: `Are you sure you want to delete ${row.name}?`,
          confirmApproveText: 'Yes, Delete',
          confirmApproveColor: color.error,
        });

        break;
      }

      default:
        break;
    }

    onSelectMenuItem({
      name: itemName,
      row,
    });
  };

  onConfirmModal = async () => {
    const {
      isEditMode,
      confirmAction,
      confirmRow: {
        _rowId,
      },
      rows,
    } = this.state;

    if (!_rowId) {
      console.error('onConfirmModal no row ID');
      return;
    }

    switch (confirmAction) {
      case 'delete': {
        // if using a local collection, remove from the table
        if (rows.length > 0) {
          const filteredRows = rows.filter(item => item._rowId !== _rowId);

          // If table is in edit mode -- just remove the row locally
          if (isEditMode) {
            this.setState({
              rows: filteredRows,
            });
          } else {
            this.setState({
              rows: filteredRows,
              isEditMode: true,
            });
          }
        }
        break;
      }

      default:
        break;
    }

    this.resetConfirmModal();
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
      });
    }
  };

  /**
   * Toggle the table edit mode
   */
  onClickEditMode = () => {
    this.setState({
      isEditMode: true,
    });
  };

  /**
   * Cancel edit mode changes
   */
  onClickEditModeCancel = () => {
    const {
      onCancelEdit,
    } = this.props;

    this.setState({
      isEditMode: false,
    });

    onCancelEdit();
  };

  /**
   * Adds new media
   */
  onAddMediaClick = (row) => {
    const {
      onAddMedia,
    } = this.props;

    onAddMedia(row);
  };

  /**
   * Save edit mode changes
   */
  onClickEditModeSave = () => {
    const {
      onSaveTable,
    } = this.props;

    const {
      rows,
    } = this.state;

    onSaveTable(rows);
  };

  /**
   * When a field is changed during edit mode
   */
  onChangeEditField = (e) => {
    const {
      target: {
        name,
        value,
        dataset: {
          id: _rowId,
        },
      },
    } = e;

    this.setState((state) => {
      // current row data
      const currRow = find(state.rows, { _rowId }) || {};

      const edits = {
        rows: state.rows.map(row => {
          if (row._rowId === currRow._rowId) {
            return {
              ...row,
              [name]: value,
            };
          }
          return row;
        }),
      };

      return edits;
    });
  };

  /**
   * Fetch data for the table
   * Only request and return the response from the API or collection
   */
  fetchData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    const {
      rows,
      // totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    return rows;
  };

  /**
   * Reset the confirm modal data
   */
  resetConfirmModal = () => {
    this.setState({
      confirm: false,
      confirmRow: {},
      confirmAction: '',
      confirmText: '',
      confirmApproveText: '',
      confirmApproveColor: '',
    });
  };

  renderCell = ({
    column,
    row,
  }) => {
    const {
      collection,
    } = this.props;

    const {
      isEditMode,
      rows,
    } = this.state;

    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Day Part': {
        const firstDayPart = collection.filter(c => c.dayPart === row.dayPart)[0];
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 8,
            }}
          >
            {firstDayPart?._rowId === row._rowId && (
              <>
                <div>{value}</div>
                {column.editable && isEditMode && (
                  <VibeButtonNew
                    text="Add Media"
                    color={color.primary}
                    onClick={() => {
                      this.onAddMediaClick(row);
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      }

      case 'Message Name': {
        if (value) {
          return (
            <AudioCell
              className="cell-content"
              id={row._rowId}
              name={row.name}
              src={row.url}
              disabled={!row.url}
              color={color.success}
              durationSeconds={row.durationSeconds}
              playText="Play Media"
              pauseText="Pause Media"
              edit={column.editable && isEditMode}
            />
          );
        }

        return null;
      }

      case 'Weight': {
        if (column.editable && isEditMode) {
          const totalWeight = rows.reduce((accumulator, rowData) => {
            if (rowData.dayPart === row.dayPart) {
              return accumulator + (+rowData.weight);
            }
            return accumulator;
          }, 0);

          return (
            <Field
              type="number"
              dataId={row._rowId}
              name={column.searchAttr}
              placeholder="Add Weight..."
              value={value}
              tabIndex={200}
              onChange={this.onChangeEditField}
              numberProps={{
                min: 0,
                max: 1,
                step: 0.1,
              }}
              error={totalWeight !== 1}
              validation={{
                check: true,
                checkEmpty: true,
                events: [
                  'onBlur',
                ],
              }}
              required
            />
          );
        }

        if (value) {
          return (
            <div>{value * 100}%</div>
          );
        }

        return null;
      }

      case 'File Size': {
        if (value) {
          return formatByteSize(value);
        }

        return null;
      }

      case 'Duration': {
        if (value) {
          const duration = moment.duration(value, 'seconds');
          return (
            <div>{moment.utc(duration.asMilliseconds()).format('mm:ss')}</div>
          );
        }

        return null;
      }

      case 'Creative Management Unavailable':
        return (
          <div
            style={{
              color: color.primary,
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Creative Management Not Available for Non-Vibenomics Orders
          </div>
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      rowLink,
      toolbarProps,
      editMode,
      errorIds: propErrorIds,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      rowsEdit,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      confirm,
      confirmText,
      confirmApproveText,
      confirmApproveColor,
      sortBy,
      filters,
      isEditMode,
      errorIds,
    } = this.state;

    const customButtons = [];

    // merge row data with edit row data
    const newRows = isEditMode
      // only merge when in edit mode
      ? rows.map((row) => {
        const rowEdit = find(rowsEdit, { _rowId: row._rowId }) || {};

        return {
          ...row,
          ...rowEdit,
        };
      })
      // not in edit mode, use existing row data
      : rows;

    if (editMode && !isEditMode) {
      customButtons.push(
        <VibeButton
          key="btn-edit-mode"
          variant="outlined"
          text="Edit Mode"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viEdit}
              color={color.primary}
              size={16}
            />
          )}
          tooltip="Edit all cells in the table"
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onClickEditMode}
        />,
      );
    }

    if (isEditMode) {
      customButtons.push(
        <VibeButton
          key="btn-edit-mode-cancel"
          variant="outlined"
          text="Cancel"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viClose}
              color={color.primary}
              size={16}
            />
          )}
          onClick={this.onClickEditModeCancel}
        />,
      );

      customButtons.push(
        <VibeButton
          key="btn-edit-mode-save"
          variant="outlined"
          text="Save Changes"
          btnColor="green"
          icon={(
            <VibeIcon
              icon={viCheck}
              color={color.success}
              size={16}
            />
          )}
          onClick={this.onClickEditModeSave}
        />,
      );
    }

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TableCreatives', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          errorIds={[
            ...propErrorIds,
            ...errorIds,
          ]}
          rowLink={!isEditMode
            ? rowLink
            : null}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={newRows}
          loading={loading}
          permissionPrefix={permissionPrefix}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onSelectMenuItem={this.onSelectMenuItem}
          onReset={this.onReset}
          onFetch={this.fetchData}
          onUpdate={this.onUpdate}
        />

        <VibeModal
          show={confirm}
          type="confirm"
          confirmProps={{
            text: confirmApproveText,
            color: confirmApproveColor,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          text={confirmText}
          onConfirm={this.onConfirmModal}
          onClose={this.resetConfirmModal}
        />
      </div>
    );
  }
}

TableCreatives.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableCreatives.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableCreatives);
