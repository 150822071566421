import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  VibeModal,
  VibeIcon,
  viRadioOn,
  viRadioOff,
  color,
} from 'vibeguide';
import MoveItemLocations from './MoveItemLocations';
import './MoveItem.scss';

class MoveItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      thisLocation: true,
      showLocations: false,
      locations: [],
    };
  }

  /**
   * When the location selection is closed
   */
  onCloseLocations = () => {
    this.setState({
      showLocations: false,
      locations: [],
    });
  };

  /**
   * When the location selection is saved
   */
  onSaveLocations = (locations) => {
    this.setState({
      locations,
      showLocations: false,
    });
  };

  /**
   * When the move action is confirmed
   */
  onMove = () => {
    const {
      onMove,
    } = this.props;

    const {
      locations,
    } = this.state;

    onMove(locations);
  };

  setOptionThisLocation = () => {
    this.setState({
      thisLocation: true,
      locations: [],
    });
  };

  setOptionCustomLocations = () => {
    this.setState({
      thisLocation: false,
      showLocations: true,
      locations: [],
    });
  };

  render() {
    const {
      onCancel,
    } = this.props;

    const {
      thisLocation,
      showLocations,
    } = this.state;

    return (
      <div className="MoveItem">
        <VibeModal
          type="custom"
          width={310}
          height={200}
          text={(
            <div className="move-modal">
              <div className="title">
                Move baseline schedule for...
              </div>

              <div className="options">
                <div
                  className={classNames('option', { active: thisLocation })}
                  onClick={this.setOptionThisLocation}
                >
                  <VibeIcon
                    icon={thisLocation
                      ? viRadioOn
                      : viRadioOff}
                    color={thisLocation
                      ? color.primary
                      : color.manatee}
                    size={24}
                  />

                  <div className="text">
                    This Location
                  </div>
                </div>

                <div
                  className={classNames('option', { active: !thisLocation })}
                  onClick={this.setOptionCustomLocations}
                >
                  <VibeIcon
                    icon={!thisLocation
                      ? viRadioOn
                      : viRadioOff}
                    color={!thisLocation
                      ? color.primary
                      : color.manatee}
                    size={24}
                  />

                  <div className="text">
                    Select Locations
                  </div>
                </div>
              </div>

              <div className="toolbar">
                <div
                  className="toolbar-item item-cancel"
                  onClick={onCancel}
                >
                  Cancel
                </div>

                <div
                  className="toolbar-item item-confirm"
                  onClick={this.onMove}
                >
                  Confirm Move
                </div>
              </div>
            </div>
          )}
          show
        />

        {showLocations ? (
          <MoveItemLocations
            onClose={this.onCloseLocations}
            onSave={this.onSaveLocations}
          />
        ) : null}
      </div>
    );
  }
}

MoveItem.propTypes = {
  /** When the user confirms the move action */
  onMove: PropTypes.func,
  /** When the user cancels the move action */
  onCancel: PropTypes.func,
};

MoveItem.defaultProps = {
  onMove: () => {},
  onCancel: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
    currentLocation: state.locations.currentLocation,
  };
}

export default connect(mapStateToProps)(MoveItem);
