import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  map,
  orderBy,
} from 'lodash';
import {
  messageBlocks as searchByMessageBlocks,
} from '../../../../helpers/SearchBy';
import {
  messageBlocks as sortByMessageBlocks,
} from '../../../../helpers/SortBy';
import {
  updateParams,
} from '../../../../helpers/Navigation';
import Cards from '../../../Cards/Cards';
import EmptyState from '../../../EmptyState/EmptyState';
import Draggable from '../../../Draggable/Draggable';
import MessageBlockCard from '../../../MessageBlockCard/MessageBlockCard';
import PageLayout from '../../../PageLayout/PageLayout';
import Paginator from '../../../Paginator/Paginator';
import VibeIcon from '../../../VibeIcon/VibeIcon';
import viDensityNormal from '../../../../icons/viDensityNormal';
import viViewCard from '../../../../icons/viViewCard';
import color from '../../../../sass/color.scss';
import messagesImage from '../../../../assets/messages.png';
import './MessageBlockCards.scss';

class MessageBlockCards extends PureComponent {
  constructor(props) {
    super(props);

    this.scrollRef = React.createRef();

    this.state = {
      messageBlocks: [],
      totalItems: 0,
    };
  }

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    collection.forEach((messageBlock) => {
      messageBlock.messages = orderBy(messageBlock.messages, 'sequence');
    });

    this.setState({
      messageBlocks: collection,
      totalItems,
    });
  };

  renderMessageList = () => {
    const {
      draggable,
    } = this.props;

    const {
      messageBlocks,
    } = this.state;

    const cardHtml = draggable
      ? map(messageBlocks, (message) => (
        <Draggable
          key={message._id}
          dragType="Message Block"
          data={message}
          closeOnDrag
          allowDrag
        >
          <MessageBlockCard
            message={message}
          />
        </Draggable>
      ))
      : map(messageBlocks, (message) => (
        <MessageBlockCard
          key={message._id}
          message={message}
          link={updateParams({
            messageBlockId: message._id,
          })}
          allowClick
        />
      ));

    return (
      <Cards>
        {cardHtml}
      </Cards>
    );
  };

  render() {
    const {
      user,
      fetch,
      fetchFilters,
      cardProps,
      customButtons,
      toggle,
      onViewTable,
    } = this.props;

    const {
      messageBlocks,
      totalItems,
    } = this.state;

    const {
      scrollRef: {
        current: scrollRef,
      },
    } = this;

    return (
      <div className="MessageBlockCards">
        <PageLayout
          searchOptions={searchByMessageBlocks}
          sortOptions={sortByMessageBlocks}
          disableSort={sortByMessageBlocks.length <= 0}
          scrollRef={scrollRef}
          toggleLabel="Show Archived Message Blocks"
          filter={fetch}
          filterProps={{
            // do not automatically load message block cards if you are an admin user
            requireFilter: user.sysAdmin && cardProps.requireFilter,
            fetchFilters,
            filters: cardProps.filters,
          }}
          customComponent={(
            <div className="toggle-view-container">
              {customButtons.length > 0 ? (
                <div
                  style={{
                    marginRight: 16,
                  }}
                >
                  {customButtons}
                </div>
              ) : null}

              <VibeIcon
                className="toggle-view list-view"
                id="toggle-show-list"
                icon={viDensityNormal}
                color={color.whiteSmoke}
                hoverColor={color.secondary}
                size={24}
                tooltip="List View"
                tooltipProps={{
                  placement: 'bottom',
                }}
                onClick={onViewTable}
              />

              <VibeIcon
                className="toggle-view card-view"
                id="toggle-show-cards"
                icon={viViewCard}
                color={color.primary}
                hoverColor={color.secondary}
                size={24}
                tooltip="Card View"
                tooltipProps={{
                  placement: 'bottom',
                }}
              />
            </div>
          )}
          onFilter={this.onFilter}
          disableView
          showToggle={toggle}
        />

        <div
          ref={this.scrollRef}
          className="message-block-list"
        >
          {totalItems > 0 ? (
            <div>
              <Paginator
                className="message-block-paginator"
                totalLoaded={messageBlocks.length}
                totalItems={totalItems}
                label="Message Blocks"
              />

              <div className="message-blocks">
                {this.renderMessageList()}
              </div>
            </div>
          ) : (
            <EmptyState
              image={messagesImage}
              title={cardProps.requireFilter
                ? 'Enter a Search Term!'
                : 'No Results Found'}
              description={cardProps.requireFilter
                ? 'To list relevant message blocks, please enter a search term in the field above'
                : 'No message blocks found. Try clearing your filters'}
            />
          )}
        </div>
      </div>
    );
  }
}

MessageBlockCards.propTypes = {
  fetch: PropTypes.func.isRequired,
  /** Filters to pass to the root fetch function */
  fetchFilters: PropTypes.oneOfType([
    PropTypes.object,
  ]),
  cardProps: PropTypes.shape({
    // require a filter before requesting API data
    requireFilter: PropTypes.bool,
    // filters to send when using card view
    filters: PropTypes.oneOfType([
      PropTypes.object,
    ]),
  }),
  customButtons: PropTypes.arrayOf(PropTypes.element),
  /** Cards are draggable */
  draggable: PropTypes.bool,
  /** Show archive toggle */
  toggle: PropTypes.bool,
  onViewTable: PropTypes.func,
};

MessageBlockCards.defaultProps = {
  fetchFilters: {},
  cardProps: {
    requireFilter: false,
    filters: {},
  },
  customButtons: [],
  draggable: false,
  toggle: false,
  onViewTable: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(MessageBlockCards);
