import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  TimeUtil,
  Field2 as Field,
  VibeTooltip,
  VibeButtonNew,
  color,
} from 'vibeguide';
import Popover from '@mui/material/Popover';
import './SpotValueItem.scss';

function SpotValueItem({
  day,
  startTime,
  stopTime,
  value: propValue,
  canView,
  canEdit,
  onChange,
}) {
  const [value, setValue] = useState(propValue);
  const [showEdit, setShowEdit] = useState(false);
  const itemRef = useRef(null);

  const itemStart = TimeUtil.convertToTwelveHour({
    time: startTime,
    // short: true,
  });

  const itemStop = TimeUtil.convertToTwelveHour({
    time: stopTime,
    // short: true,
  });

  const itemRefRect = itemRef && itemRef.current
    ? itemRef.current.getBoundingClientRect()
    : { top: 0, left: 0 };

  /**
   * When the item is clicked and the user can edit
   */
  const onClickItem = () => {
    if (!canView) {
      console.warn('user cannot view the item');
      return;
    }

    setShowEdit(true);
  };

  /**
   * When the edit popover is closed
   */
  const onClosePopover = () => {
    setShowEdit(false);
    setValue(propValue);
  };

  /**
   * When the value is changed
   */
  const onChangeValue = (e) => {
    const {
      target: {
        value: fieldValue,
      },
    } = e;

    setValue(fieldValue);
  };

  /**
   * Save the edited value
   */
  const onSave = () => {
    onChange({
      day,
      startTime,
      stopTime,
      value,
    });

    setShowEdit(false);
  };

  /**
   * When the value is changed on the parent component
   */
  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return (
    <div
      className="SpotValueItem"
      style={{
        width: '100%',
        height: '100%',
        border: `1px solid ${color.primary}4D`,
        borderRadius: 4,
        boxSizing: 'border-box',
      }}
    >
      <VibeTooltip
        title={(
          <div>
            {itemStart} - {itemStop}

            <div
              style={{
                marginTop: 8,
              }}
            >
              {canEdit
                ? 'Click to Edit...'
                : 'Click to View...'}
            </div>
          </div>
        )}
        placement="right"
      >
        <div
          ref={itemRef}
          className="value"
          style={{
            opacity: value === '0'
              ? 0.4
              : 1,
          }}
          onClick={onClickItem}
        >
          {value}
        </div>
      </VibeTooltip>

      <Popover
        anchorReference="anchorPosition"
        anchorPosition={{
          top: itemRefRect.top - 10,
          left: itemRefRect.left + (itemRefRect.width / 2),
        }}
        open={showEdit}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{
          root: 'ItemPopperRoot',
          paper: 'ItemPopper',
        }}
      >
        <div className="item-details-container">
          <div className="item-details">
            <div className="time-fields">
              <div className="start-time">
                <Field
                  type="time"
                  label="Start Time"
                  placeholder="9:00am"
                  value={startTime}
                  tabIndex={1}
                  timeProps={{
                    // prevent overlapping items in the schedule
                    min: '00:00',
                    max: stopTime,
                  }}
                  disabled
                />
              </div>
              <div className="stop-time">
                <Field
                  type="time"
                  label="Stop Time"
                  placeholder="9:00am"
                  value={stopTime}
                  tabIndex={2}
                  timeProps={{
                    min: startTime,
                    // prevent overlapping items in the schedule
                    max: '24:00',
                    endOfDay: true,
                  }}
                  disabled
                />
              </div>
            </div>

            <div
              style={{
                marginTop: 8,
              }}
            >
              <Field
                type="number"
                label="Value"
                placeholder="Value..."
                value={value}
                tabIndex={3}
                numberProps={{
                  min: 0,
                }}
                disabled={!canEdit}
                autoFocus
                onChange={onChangeValue}
              />
            </div>

            {canEdit && (
              <div
                style={{
                  marginTop: 8,
                  textAlign: 'center',
                }}
              >
                <VibeButtonNew
                  text="Save"
                  color={color.primary}
                  disabled={!value}
                  onClick={onSave}
                />
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
}

SpotValueItem.propTypes = {
  day: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  stopTime: PropTypes.string.isRequired,
  value: PropTypes.string,
  /** Allow the user to edit the item */
  canView: PropTypes.bool,
  /** Allow the user to edit the item */
  canEdit: PropTypes.bool,
  /** When the item start/stop time or value is changed */
  onChange: PropTypes.func,
};

SpotValueItem.defaultProps = {
  value: '',
  canView: false,
  canEdit: false,
  onChange: () => {},
};

export default SpotValueItem;
