import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  toInteger,
  uniqueId,
} from 'lodash';
import {
  API,
  ColorUtil,
  ToastActions,
  Field2 as Field,
  SidePanelContainer,
  SidePanelContent,
  SidePanelFooter,
  VibeModal,
  VibeButtonNew,
  VibeIcon,
  viArchive,
  viUnarchive,
  viEdit,
  viCheck,
  viAdd,
  color,
} from 'vibeguide';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LayoutTemplatePreview from './LayoutTemplatePreview';

function CompanyLayoutTemplateEdit({
  className,
  style,
  templateId,
  companyId,
  user,
  queueToast,
}) {
  const [template, setTemplate] = useState({});
  const [screenResolution, setScreenResolution] = useState('');
  const [confirmArchive, setConfirmArchive] = useState(false);

  const sizeWidth = get(template, 'size[0]', '') || '';
  const sizeHeight = get(template, 'size[1]', '') || '';
  const zones = get(template, 'zones', []);

  const showArchive = template._id && user.can('visual_layout_template.delete');
  const isArchived = template._id && !template.active;

  const disableSave = !template.name
    // ensure no zones are in edit mode
    || zones.filter(zone => zone.edit === true).length > 0
    || get(template, 'size[0]', 0) <= 0
    || get(template, 'size[1]', 0) <= 0;
  const disableInput = (template._id && !user.can('visual_layout_template.modify'))
    || (!template._id && !user.can('visual_layout_template.create'));

  /**
   * Get the template
   */
  const getTemplate = async () => {
    const response = await API.Company.LayoutTemplate.getById({
      _id: templateId,
      companyId,
    });

    // add a unique ID for each zone
    response.zones.forEach((zone) => {
      if (!zone._id) {
        zone._id = uniqueId('zone-');
      }
    });

    setTemplate(response);
  };

  /**
   * User clicks Archive
   */
  const onClickArchive = () => {
    setConfirmArchive(true);
  };

  /**
   * User clicks Unarchive
   */
  const onClickUnarchive = async () => {
    await API.Company.LayoutTemplate.reactivate({
      _id: template._id,
      companyId,
    });

    setTemplate({
      ...template,
      active: true,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveVisualLayoutTemplate'));
  };

  /**
   * User confirms archiving the object
   */
  const onConfirmArchive = async () => {
    setConfirmArchive(false);
    await API.Company.LayoutTemplate.deactivate({
      _id: template._id,
      companyId,
    });

    setTemplate({
      ...template,
      active: false,
    });

    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveVisualLayoutTemplate'));
  };

  /**
   * User cancels archiving the banner
   */
  const onCancelArchive = () => {
    setConfirmArchive(false);
  };

  /**
   * User changes the input box
   */
  const onChange = ({
    target: {
      name,
      value,
    },
  }) => {
    setTemplate({
      ...template,
      [name]: value,
    });
  };

  /**
   * When either of the size fields are changed
   */
  const onChangeSize = (e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    // convert the value to a number
    const newSize = toInteger(value);

    if (name === 'sizeWidth') {
      // update the width
      setTemplate({
        ...template,
        size: [
          newSize,
          sizeHeight,
        ],
      });
    } else if (name === 'sizeHeight') {
      // update the height
      setTemplate({
        ...template,
        size: [
          sizeWidth,
          newSize,
        ],
      });
    }
  };

  /**
   * Change a screen resolution
   */
  const onChangeScreenResolution = ({
    // name,
    value,
    data,
  }) => {
    setScreenResolution(value);

    if (data && get(data, 'size').length === 2) {
      // size is included (change the layout template size)
      setTemplate({
        ...template,
        size: [
          get(data, 'size[0]', 0),
          get(data, 'size[1]', 0),
        ],
      });
    }
  };

  /**
   * Change a zone
   */
  const onChangeZone = (zoneId, e) => {
    const {
      target: {
        name,
        value,
      },
    } = e;

    if (name === 'sizeWidth') {
      // convert the value to a number
      const newSize = toInteger(value);

      setTemplate({
        ...template,
        zones: zones.map((zone) => {
          if (zone._id === zoneId) {
            return {
              ...zone,
              size: [
                newSize,
                zone.size[1],
              ],
            };
          }

          return zone;
        }),
      });
    } else if (name === 'sizeHeight') {
      // convert the value to a number
      const newSize = toInteger(value);

      setTemplate({
        ...template,
        zones: zones.map((zone) => {
          if (zone._id === zoneId) {
            return {
              ...zone,
              size: [
                zone.size[0],
                newSize,
              ],
            };
          }

          return zone;
        }),
      });
    } else if (name === 'positionX') {
      // convert the value to a number
      const newPosition = toInteger(value);

      setTemplate({
        ...template,
        zones: zones.map((zone) => {
          if (zone._id === zoneId) {
            return {
              ...zone,
              position: [
                newPosition,
                zone.position[1],
              ],
            };
          }

          return zone;
        }),
      });
    } else if (name === 'positionY') {
      // convert the value to a number
      const newPosition = toInteger(value);

      setTemplate({
        ...template,
        zones: zones.map((zone) => {
          if (zone._id === zoneId) {
            return {
              ...zone,
              position: [
                zone.position[0],
                newPosition,
              ],
            };
          }

          return zone;
        }),
      });
    } else {
      // change the field
      setTemplate({
        ...template,
        zones: zones.map((zone) => {
          if (zone._id === zoneId) {
            return {
              ...zone,
              [name]: value,
            };
          }

          return zone;
        }),
      });
    }
  };

  /**
   * Put a zone in edit mode
   */
  const onClickEditZone = (zone) => {
    setTemplate({
      ...template,
      zones: zones.map((zoneItem) => {
        if (zoneItem.name === zone.name) {
          return {
            ...zoneItem,
            edit: true,
          };
        }

        return {
          ...zoneItem,
          edit: false,
        };
      }),
    });
  };

  /**
   * Save a Zone
   */
  const onClickSaveZone = () => {
    setTemplate({
      ...template,
      zones: zones.map((zoneItem) => {
        return {
          ...zoneItem,
          edit: false,
        };
      }),
    });
  };

  /**
   * Remove a Zone
   */
  const onClickRemoveZone = (zone) => {
    setTemplate({
      ...template,
      zones: zones.filter(z => z._id !== zone._id),
    });
  };

  /**
   * Add a new zone
   */
  const onClickNewZone = () => {
    setTemplate({
      ...template,
      zones: [
        ...zones.map((zone) => {
          return {
            ...zone,
            edit: false,
          };
        }),
        {
          _id: uniqueId('zone-'),
          name: '',
          position: [0, 0],
          size: [0, 0],
          // put the new zone in edit mode
          edit: true,
        },
      ],
    });
  };

  /**
   * Save the template
   */
  const onSave = async () => {
    document.dispatchEvent(new Event('onSaveVisualLayoutTemplateStart'));

    queueToast({
      type: 'info',
      title: 'Saving...',
      allowClose: true,
    });

    const data = {
      companyId,
      name: template.name,
      size: template.size,
      zones: zones.map((zone) => {
        return {
          name: zone.name,
          position: zone.position,
          size: zone.size,
        };
      }),
    };

    if (template._id) {
      data._id = template._id;
    }

    try {
      const response = template._id
        ? await API.Company.LayoutTemplate.update(data)
        : await API.Company.LayoutTemplate.create(data);

      const responseType = get(response, '[0].type');

      const success = template._id
        ? responseType === 'VISUALLAYOUTTEMPLATE.UPDATED'
        : responseType === 'VISUALLAYOUTTEMPLATE.CREATED';

      if (success) {
        // Successfully saved the object
        const saveMessage = template._id
          ? 'Template Updated!'
          : 'Template Created!';

        setTemplate({
          ...template,
          _id: get(response, '[0].documentId'),
        });

        queueToast({
          type: 'success',
          title: saveMessage,
          allowClose: true,
          delay: 500,
        });
      }

      document.dispatchEvent(new Event('onSaveVisualLayoutTemplate'));
    } catch (err) {
      document.dispatchEvent(new Event('onSaveVisualLayoutTemplateError'));
    }
  };

  useEffect(() => {
    if (templateId) {
      getTemplate();
    }
  }, [templateId]);

  return (
    <SidePanelContainer
      className={classNames('CompanyLayoutTemplateEdit', className)}
      style={style}
    >
      <SidePanelContent>
        <Grid spacing={2} container>
          <Grid xs={12} item>
            <Field
              type="text"
              label="Name"
              placeholder="Name"
              name="name"
              value={template.name}
              tabIndex={20}
              disabled={disableInput}
              onChange={onChange}
              required
              autoFocus
            />
          </Grid>

          {user.can('screen_resolution.view') && (
            <Grid xs={12} item>
              <Field
                type="dropdown"
                label="Screen Resolution"
                name="screenResolution"
                value={screenResolution}
                tabIndex={21}
                dropdownProps={{
                  type: 'screen resolution',
                  attr: 'name',
                  fetch: API.ScreenResolution.list,
                }}
                disabled={disableInput}
                onChange={onChangeScreenResolution}
              />
            </Grid>
          )}

          <Grid xs={6} item>
            <Field
              type="number"
              label="Width (in pixels)"
              name="sizeWidth"
              value={sizeWidth}
              numberProps={{
                min: 0,
              }}
              tabIndex={22}
              disabled={disableInput}
              onChange={onChangeSize}
              required
            />
          </Grid>

          <Grid xs={6} item>
            <Field
              type="number"
              label="Height (in pixels)"
              name="sizeHeight"
              value={sizeHeight}
              numberProps={{
                min: 0,
              }}
              tabIndex={23}
              disabled={disableInput}
              onChange={onChangeSize}
              required
            />
          </Grid>

          <Grid xs={12} item>
            <div
              style={{
                marginBottom: 8,
                fontSize: 14,
                fontWeight: 900,
              }}
            >
              Zones
            </div>

            <TableContainer
              style={{
                border: `1px solid ${color.whiteSmoke}`,
              }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell align="right">
                      Width
                    </TableCell>
                    <TableCell align="right">
                      Height
                    </TableCell>
                    <TableCell align="right">
                      X
                    </TableCell>
                    <TableCell align="right">
                      Y
                    </TableCell>
                    <TableCell align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {zones.map((zone) => (
                    <TableRow
                      key={zone._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" style={{ position: 'relative' }}>
                        {zone.edit ? (
                          <Field
                            style={{
                              height: 24,
                            }}
                            type="text"
                            name="name"
                            value={zone.name}
                            tabIndex={24}
                            numberProps={{
                              min: 0,
                            }}
                            onChange={(e) => onChangeZone(zone._id, e)}
                          />
                        ) : zone.name}
                      </TableCell>
                      <TableCell align="right">
                        {zone.edit ? (
                          <Field
                            style={{
                              height: 24,
                            }}
                            type="number"
                            name="sizeWidth"
                            value={zone.size[0] || ''}
                            tabIndex={25}
                            numberProps={{
                              min: 0,
                            }}
                            onChange={(e) => onChangeZone(zone._id, e)}
                          />
                        ) : zone.size[0]}
                      </TableCell>
                      <TableCell align="right">
                        {zone.edit ? (
                          <Field
                            style={{
                              height: 24,
                            }}
                            type="number"
                            name="sizeHeight"
                            value={zone.size[1] || ''}
                            tabIndex={26}
                            numberProps={{
                              min: 0,
                            }}
                            onChange={(e) => onChangeZone(zone._id, e)}
                          />
                        ) : zone.size[1]}
                      </TableCell>
                      <TableCell align="right">
                        {zone.edit ? (
                          <Field
                            style={{
                              height: 24,
                            }}
                            type="number"
                            name="positionX"
                            value={zone.position[0] || ''}
                            tabIndex={27}
                            numberProps={{
                              min: 0,
                            }}
                            onChange={(e) => onChangeZone(zone._id, e)}
                          />
                        ) : zone.position[0]}
                      </TableCell>
                      <TableCell align="right">
                        {zone.edit ? (
                          <Field
                            style={{
                              height: 24,
                            }}
                            type="number"
                            name="positionY"
                            value={zone.position[1] || ''}
                            tabIndex={28}
                            numberProps={{
                              min: 0,
                            }}
                            onChange={(e) => onChangeZone(zone._id, e)}
                          />
                        ) : zone.position[1]}
                      </TableCell>
                      <TableCell align="right">
                        <VibeIcon
                          icon={zone.edit
                            ? viCheck
                            : viEdit}
                          color={zone.edit
                            ? color.success
                            : color.primary}
                          hoverColor={zone.edit
                            ? ColorUtil.transparent(color.success, 50)
                            : ColorUtil.transparent(color.primary, 50)}
                          size={16}
                          tooltip={zone.edit
                            ? 'Save'
                            : 'Edit'}
                          tooltipProps={{
                            placement: 'top',
                          }}
                          style={{
                            marginTop: 4,
                            marginRight: 4,
                          }}
                          onClick={() => {
                            if (zone.edit) {
                              onClickSaveZone();
                            } else {
                              onClickEditZone(zone);
                            }
                          }}
                        />

                        <VibeIcon
                          icon={viArchive}
                          color={color.error}
                          hoverColor={ColorUtil.transparent(color.error, 50)}
                          size={16}
                          tooltip="Remove"
                          tooltipProps={{
                            placement: 'top',
                          }}
                          // disabled={zone.edit}
                          onClick={() => onClickRemoveZone(zone)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 8,
              }}
            >
              <VibeButtonNew
                text="New Zone"
                icon={viAdd}
                color={color.primary}
                disabled={disableInput}
                onClick={onClickNewZone}
              />
            </div>
          </Grid>

          <Grid xs={12} item>
            <div
              style={{
                marginBottom: 8,
                fontSize: 14,
                fontWeight: 900,
              }}
            >
              Preview
            </div>

            <LayoutTemplatePreview
              style={{
                maxHeight: 250,
              }}
              scalePercent={20}
              width={sizeWidth}
              height={sizeHeight}
              zones={zones}
            />
          </Grid>
        </Grid>
      </SidePanelContent>

      <SidePanelFooter>
        <VibeButtonNew
          text="Save Changes"
          color={color.primary}
          loadingEvent="onSaveVisualLayoutTemplate"
          disabled={disableSave || disableInput}
          onClick={onSave}
        />

        {template._id && (
          <div>
            {showArchive && !isArchived && (
              <VibeIcon
                icon={viArchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.error,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Archive"
                color={color.error}
                size={20}
                onClick={onClickArchive}
              />
            )}

            {showArchive && isArchived && (
              <VibeIcon
                icon={viUnarchive}
                type="button"
                buttonProps={{
                  size: 32,
                  borderColor: color.success,
                  style: {
                    marginLeft: 8,
                  },
                }}
                tooltip="Unarchive"
                color={color.success}
                size={20}
                onClick={onClickUnarchive}
              />
            )}
          </div>
        )}
      </SidePanelFooter>

      <VibeModal
        show={confirmArchive}
        type="confirm"
        title="Archive"
        text={`Are you sure you want to archive ${template.name}?`}
        confirmProps={{
          text: 'Archive',
          color: color.error,
        }}
        cancelProps={{
          text: 'Cancel',
          color: color.manatee,
        }}
        onConfirm={onConfirmArchive}
        onClose={onCancelArchive}
      />
    </SidePanelContainer>
  );
}

CompanyLayoutTemplateEdit.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  templateId: PropTypes.string,
  companyId: PropTypes.string.isRequired,
};

CompanyLayoutTemplateEdit.defaultProps = {
  className: '',
  style: {},
  templateId: '',
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  queueToast: ToastActions.queueToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyLayoutTemplateEdit);
