import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import classNames from 'classnames';
import {
  get,
  find,
  clone,
  orderBy,
  uniqueId,
  uniqBy,
} from 'lodash';
import { CircularProgress } from '@mui/material';
import API from '../../api';
import PageLayout from '../PageLayout/PageLayout';
import {
  tags as searchByTags,
} from '../../helpers/SearchBy';
import {
  tags as sortByTags,
} from '../../helpers/SortBy';
import VibeButton from '../VibeButton/VibeButton';
import VibeModal from '../VibeModal/VibeModal';
import VibeIcon from '../VibeIcon/VibeIcon';
import viUndo from '../../icons/viUndo';
import viRedo from '../../icons/viRedo';
import viAdd from '../../icons/viAdd';
import viCloseCircle from '../../icons/viCloseCircle';
import Tag from './Tag';
import color from '../../sass/color.scss';
import './ManageTags.scss';

/**
 * Utilize an async/await inside a forEach
 */
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

class ManageTags extends PureComponent {
  constructor(props) {
    super(props);

    const {
      user,
    } = props;

    this._scrollRef = null;
    // Array of tags to add on save
    this.addTags = [];
    // Array of tags to remove on save
    this.removeTags = [];
    // this.removeArchiveTags = [];
    // Array of tags to edit on save
    this.editTags = [];
    // Queue of actions while managing tags
    this.queue = [];
    // Undo index
    this.undoIndex = -1;
    // Selected sort option
    this.sortOption = get(sortByTags, '[0]', {});
    // Pending tab if confirmation dialog is shown
    this.pendingTab = null;

    const canManageTags = user.can('tag.view_admin') || user.can('tag.view');
    const canViewAdminTags = user.can('tag.view_admin');
    const canViewClientTags = user.can('tag.view');
    const canCreateAdminTags = user.can('tag.create_admin');
    const canCreateClientTags = user.can('tag.create');
    const canRemoveAdminTags = user.can('tag.delete_admin');
    const canRemoveClientTags = user.can('tag.delete');
    const canModifyAdminTags = user.can('tag.modify_admin');
    const canModifyClientTags = user.can('tag.modify');

    this.state = {
      // List of tags
      tags: [],
      // Can the user manage the tags at all
      canManageTags,
      // Can the user view admin tags
      // canViewAdminTags,
      // Can the user view client tags
      // canViewClientTags,
      // Can the user view both admin/client tags
      canViewAdminClientTags: canViewAdminTags && canViewClientTags,
      // Can the user create admin tags
      canCreateAdminTags,
      // Can the user create client tags
      canCreateClientTags,
      // Can the user remove admin tags
      canRemoveAdminTags,
      // Can the user remove client tags
      canRemoveClientTags,
      // Can the user modify admin tags
      canModifyAdminTags,
      // Can the user modify client tags
      canModifyClientTags,
      // Value for the tag being added
      addTagValue: '',
      // Active tab to load tags for
      activeTab: canViewAdminTags
        ? 'admin'
        : 'client',
      // Dialog to confirm clearing of unsaved changes
      showConfirmClearUnsaved: false,
      // Is the user viewing archived tags
      isArchiveView: false,
      loading: false,
      tagExistsError: false,
      newTagExistsError: false,
      archivedTagExistsError: false,
      tagErrorText: '',
    };
  }

  /**
   * When the apply button is clicked
   */
  onApply = async () => {
    try {
      await this.save();
    } catch (err) {
      this.setState({
        loading: false,
      });
    }
  };

  /**
   * When the save and close button is clicked
   */
  onSaveAndClose = async () => {
    const {
      onSave,
    } = this.props;

    await this.save();

    onSave();
  };

  /**
   * Save the individual tags that were added
   */
  onSaveAddTags = async () => {
    const {
      user,
      companyId,
    } = this.props;

    const {
      activeTab,
    } = this.state;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const results = [];
    const successResults = [];

    // determine if the user can view tags for this company
    const useCompanyId = user.hasAccessToCompany(companyId)
      ? companyId
      : user.companyId;

    const saveCompanyId = activeTab === 'admin'
      ? adminCompanyId
      : useCompanyId;

    await asyncForEach(this.addTags, async (tag) => {
      if (tag.active) {
        const result = await API.Tag.create({
          name: tag.name,
          type: tag.type,
          companyId: saveCompanyId,
        });

        results.push(...result);
      } else {
        // Tag was archived
        const result = await API.Tag.reactivate({
          _id: tag._id,
        });
        results.push(...result);
      }
    });

    results.forEach((result) => {
      const tagId = get(result, 'documentId', null);
      const tagName = get(result, 'data.name', null);

      if (result.type === 'TAG.CREATED') {
        // Tag was added
        successResults.push(result);

        // Remove tag from tags array and queue
        this.addTags = this.addTags.filter(t => t.name !== tagName);
        this.queue = this.queue.filter(q => {
          if (q.tag.name !== tagName) {
            return q;
          }

          return null;
        });

        // Reset the undo index
        this.undoIndex = -1;

        // Update the tag status and ID for created tag
        this.setState((state) => {
          return {
            tags: state.tags.map((tag) => {
              if (tag.name === tagName) {
                return {
                  ...tag,
                  _id: tagId,
                  status: 'unedited',
                };
              }

              return tag;
            }),
          };
        });
      } else if (result.type === 'TAG.REACTIVATED') {
        // Tag was reactivated
        successResults.push(result);

        // Remove tag from tags array and queue
        this.addTags = this.addTags.filter(t => t._id !== tagId);
        this.queue = this.queue.filter(q => {
          if (q.tag._id !== tagId) {
            return q;
          }

          return null;
        });

        // Reset the undo index
        this.undoIndex = -1;

        // Remove the tag from the list once reactivated
        this.setState((state) => {
          return {
            tags: state.tags.filter(t => t._id !== tagId),
          };
        });
      } else {
        // Something went wrong
        console.error('Error creating/reactivating tag', result);
      }
    });

    return successResults;
  };

  /**
   * Save the individual tags that were removed
   */
  onSaveRemoveTags = async () => {
    const results = [];
    const successResults = [];

    await asyncForEach(this.removeTags, async (tagId) => {
      const result = await API.Tag.deactivate(tagId);
      results.push(...result);
    });

    results.forEach((result) => {
      if (result.type === 'TAG.DEACTIVATED') {
        // Tag was removed
        successResults.push(result);

        const tagId = get(result, 'documentId', null);

        // Remove tag from tags array and queue
        this.removeTags = this.removeTags.filter(tId => tId !== tagId);
        this.queue = this.queue.filter(q => {
          if (q.tag._id !== tagId) {
            return q;
          }

          return null;
        });

        // Reset the undo index
        this.undoIndex = -1;

        // Remove the tag
        this.setState((state) => {
          return {
            tags: state.tags.filter(tag => tag._id !== tagId),
          };
        });
      } else {
        console.error('Error removing tag', result);
      }
    });

    return successResults;
  };

  /**
   * Save the individual tags that were edited
   */
  onSaveEditTags = async () => {
    const {
      user,
      companyId,
    } = this.props;

    const {
      activeTab,
    } = this.state;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    const results = [];
    const successResults = [];

    // determine if the user can view tags for this company
    const useCompanyId = user.hasAccessToCompany(companyId)
      ? companyId
      : user.companyId;

    const saveCompanyId = activeTab === 'admin'
      ? adminCompanyId
      : useCompanyId;

    await asyncForEach(this.editTags, async (tag) => {
      const result = await API.Tag.update({
        _id: tag._id,
        name: tag.name,
        type: tag.type,
        companyId: saveCompanyId,
      });

      results.push(...result);
    });

    results.forEach((result) => {
      if (result.type === 'TAG.UPDATED') {
        // Tag was edited
        successResults.push(result);

        const tagId = get(result, 'documentId', null);

        // Remove tag from tags array and queue
        this.editTags = this.editTags.filter(t => t._id !== tagId);
        this.queue = this.queue.filter(q => {
          if (q.tag._id !== tagId) {
            return q;
          }

          return null;
        });

        // Reset the undo index
        this.undoIndex = -1;

        // Update the tag status for updated tag
        this.setState((state) => {
          return {
            tags: state.tags.map((tag) => {
              if (tag._id === tagId) {
                return {
                  ...tag,
                  status: 'unedited',
                };
              }

              return tag;
            }),
          };
        });
      } else {
        console.error('Error updating tag', result);
      }
    });

    return successResults;
  };

  /**
   * Switch the active tab between admin and client
   */
  onChangeActiveTab = (e) => {
    const {
      target: {
        dataset: {
          tab: activeTab,
        },
      },
    } = e;

    if (
      this.addTags.length > 0
      || this.removeTags.length > 0
      || this.editTags.length > 0
    ) {
      // There are unsaved actions
      this.pendingTab = activeTab;

      this.setState({
        showConfirmClearUnsaved: true,
      });
    } else {
      // All changes have been saved
      // Clear the queue, and tags to save/remove
      this.resetQueue();

      this.setState({
        activeTab,
      });
    }
  };

  /**
   * Confirm changing the active tab (clear unsaved changes)
   */
  onConfirmChangeActiveTab = () => {
    this.resetQueue();

    this.setState({
      activeTab: this.pendingTab,
      showConfirmClearUnsaved: false,
    });

    this.pendingTab = null;
  };

  /**
   * Confirm changing the active tab (clear unsaved changes)
   */
  onCancelChangeActiveTab = () => {
    this.setState({
      showConfirmClearUnsaved: false,
    });

    this.pendingTab = null;
  };

  /**
   * When the tag being added input is changed
   */
  onChangeAddTag = (e) => {
    const {
      target: {
        value,
      },
    } = e;

    this.setState({
      addTagValue: value,
    });
  };

  /**
   * When the tag being added presses a key
   */
  onKeyDownAddTag = (e) => {
    if (e.key === 'Enter') {
      this.onAddTag();
    }
  };

  /**
   * When the tag input receives focus
   */
  onFocusTagInput = () => {
    this.setState({
      tagExistsError: false,
      newTagExistsError: false,
      archivedTagExistsError: false,
    });
    this.setState((state) => {
      return {
        tags: state.tags.map((tag) => {
          if (tag.status === 'found') {
            return {
              ...tag,
              status: 'unedited',
            };
          }
          return tag;
        }),
      };
    });
  };

  onFilter = (data) => {
    const {
      collection,
      sortOption,
    } = data;

    const {
      tags,
      isArchiveView,
    } = this.state;

    const collectionTags = collection.map((item) => {
      const tag = find(tags, { _id: item._id });

      if (tag) {
        // Apply old tag information (such as status)
        return {
          ...item,
          ...tag,
        };
      }

      return item;
    });

    // Merge the collection tags and the added tags
    const allTags = [...collectionTags, ...this.addTags];

    // Sort the tags
    this.sortOption = sortOption;
    let sortedTags = this.sortTags(allTags);

    if (this.removeTags.length > 0) {
      sortedTags.forEach((tag) => {
        return this.removeTags.forEach((tag1) => {
          if (tag._id === tag1) {
            tag.status = 'remove';
          }
        });
      });
    }

    if (this.editTags.length > 0) {
      const includeEditTags = sortedTags.map((tag) => {
        return this.editTags.map((tag1) => {
          if (tag._id === tag1._id) {
            return tag1;
          }
          return tag;
        })[0];
      });

      sortedTags = includeEditTags;
    }

    // filter out duplicate unarchive items when toggling
    const sortedTags1 = uniqBy(sortedTags, '_id');

    let sortedTagsFinal = [];
    if (isArchiveView) {
      sortedTagsFinal = sortedTags1.map((tag) => {
        if (tag.status === 'remove' && !tag.active) {
          return {
            ...tag,
            status: 'unarchive',
          };
        }
        return tag;
      });
    } else {
      sortedTagsFinal = sortedTags1;
    }

    this.setState({
      tags: sortedTagsFinal,
    });
  };

  /**
   * When the toggle is changed
   */
  onChangeToggle = (isToggleOn) => {
    // Reset the queue and items to update because the whole view is changing
    // this.resetQueue();

    this.setState({
      addTagValue: '',
      isArchiveView: isToggleOn,
      tagExistsError: false,
      newTagExistsError: false,
      archivedTagExistsError: false,
    });
  };

  /**
   * When the search input is changed
   */
  onChangeSearch = (query) => {
    // Reset the queue and items to update because the whole view is changing
    this.resetQueue();

    console.warn('onChangeSearch', query);
  };

  /**
   * When a tag is added
   */
  onAddTag = async (tag) => {
    const {
      activeTab,
      addTagValue = '',
    } = this.state;

    const {
      companyId,
      user,
    } = this.props;

    if (tag && tag._id) {
      // Add (unarchive the tag)
      tag.statusPrev = tag.status;
      tag.status = 'remove';
      this.addToQueue('add', tag);
      this.addTag(tag, {
        status: 'unarchive',
      });
      return;
    }

    if (!addTagValue.trim()) {
      console.warn('Cannot add an empty tag');
      return;
    }

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    // determine if the user can view tags for this company
    const useCompanyId = user.hasAccessToCompany(companyId)
      ? companyId
      : user.companyId;

    // Use admin company when loading admin tags
    const filterCompanyId = activeTab === 'admin'
      ? adminCompanyId
      : useCompanyId;

    // Check locally if tag exists
    const latestTags = await API.Tag.list({
      pageNumber: 1,
      pageSize: -1,
      filters: {
        active: true,
        type: activeTab,
        companyId: filterCompanyId,
      },
      sort: { name: 'asc' },
    });

    const latestArchivedTags = await API.Tag.list({
      pageNumber: 1,
      pageSize: -1,
      filters: {
        active: false,
        type: activeTab,
        companyId: filterCompanyId,
      },
      sort: { name: 'asc' },
    });

    const tagExists = latestTags.data.some(tag => tag.name.toLowerCase() === addTagValue.toLowerCase());
    const archivedTagExists = latestArchivedTags.data.some(tag => tag.name.toLowerCase() === addTagValue.toLowerCase());
    const newTagExists = this.addTags.some(tag => tag.name.toLowerCase() === addTagValue.toLowerCase());

    if (tagExists || newTagExists || archivedTagExists) {
      if (tagExists) {
        this.setState({
          tagExistsError: tagExists,
          tagErrorText: addTagValue,
        });
      }

      if (newTagExists) {
        this.setState({
          newTagExistsError: newTagExists,
          tagErrorText: addTagValue,
        });
      }

      if (archivedTagExists) {
        this.setState({
          archivedTagExistsError: archivedTagExists,
          tagErrorText: addTagValue,
        });
      }
      this.setState((state) => {
        return {
          tags: state.tags.map((tag) => {
            if (tag.name.toLowerCase() === addTagValue.toLowerCase()) {
              if (tag.status === 'add') {
                return {
                  ...tag,
                  status: 'add',
                };
              }
              if (tag._id !== undefined) {
                return {
                  ...tag,
                  status: 'found',
                };
              }
            }
            return tag;
          }),
        };
      });
    } else {
      this.setState({
        tagExistsError: false,
        newTagExistsError: false,
        archivedTagExistsError: false,
      });
      const newTag = {
        _id: uniqueId('new-tag-'),
        name: addTagValue,
        type: activeTab === 'admin'
          ? 'admin'
          : 'client',
        status: 'add',
        active: true,
      };

      this.addToQueue('add', newTag);
      this.addTag(newTag);
    }
  };

  /**
   * When the remove icon is clicked on a tag
   */
  onRemoveTag = (tag) => {
    // Add tag removal to queue
    tag.statusPrev = tag.status;
    tag.status = 'remove';
    this.addToQueue('remove', tag);
    this.removeTag(tag);
  };

  /**
   * When the restore (add) icon is clicked on a tag
   */
  onRestoreTag = (tag) => {
    // Add tag removal to queue
    tag.statusPrev = tag.status;
    tag.status = 'restore';
    this.addToQueue('restore', tag);
    this.restoreTag(tag);
  };

  /**
   * When the tag is edited
   */
  onEditTag = (tag) => {
    // Add tag edit to queue
    tag.statusPrev = tag.status;
    tag.status = 'edit';
    this.addToQueue('edit', tag);
    this.editTag(tag);
  };

  /**
   * Save all changes
   */
  save = async () => {
    this.setState({
      loading: true,
    });

    const addTagsResult = await this.onSaveAddTags();
    const editTagsResult = await this.onSaveEditTags();
    const removeTagsResult = await this.onSaveRemoveTags();

    this.setState({
      loading: false,
    });

    // Merge all results
    const results = [
      ...addTagsResult,
      ...editTagsResult,
      ...removeTagsResult,
    ];

    console.warn('Save Tags Results', results);
  };

  /**
   * Reset the queue
   */
  resetQueue = () => {
    this.undoIndex = -1;
    this.queue = [];
    this.addTags = [];
    this.removeTags = [];
    this.editTags = [];
    // this.unarchiveTags = [];
  };

  /**
   * Add a tag
   */
  addTag = (tag, tagData = {}) => {
    // Add tag to add tags array
    this.addTags.push(clone(tag));

    this.setState((state) => {
      // Does the tag already exist
      const tagExists = find(state.tags, { _id: tag._id }) !== undefined;

      if (tagExists) {
        return {
          tags: state.tags.map((t) => {
            if (t._id === tag._id) {
              return {
                ...tag,
                ...tagData,
              };
            }

            return t;
          }),
        };
      }

      // Merge the tags
      const tags = [...state.tags, {
        ...tag,
        ...tagData,
      }];

      // Sort the tags
      const sortedTags = this.sortTags(tags);

      return {
        // Reset add tag input
        addTagValue: '',
        tags: sortedTags,
      };
    });
  };

  /**
   * Remove a tag
   */
  removeTag = (tag, tagData = {}) => {
    // Check if the tag is in the added tags array
    const isAddedTag = find(this.addTags, { _id: tag._id }) !== undefined;

    if (isAddedTag && tag._id.indexOf('new-tag-') >= 0) {
      // Tag is added but hasn't been saved
      this.removeAddedTag(tag);
      return;
    }

    if (isAddedTag) {
      // Tag was added as an unarchive action. Remove from add tags array
      this.addTags = this.addTags.filter(t => t._id !== tag._id);

      this.setState((state) => {
        return {
          tags: state.tags.map((t) => {
            if (t._id === tag._id) {
              return {
                ...t,
                ...tag,
                status: 'unedited',
              };
            }

            return t;
          }),
        };
      });

      return;
    }

    // Tag exists on the server
    // Add tag to remove tags array
    this.removeTags.push(tag._id);

    this.setState((state) => {
      return {
        tags: state.tags.map((t) => {
          if (t._id === tag._id) {
            return {
              ...t,
              ...tag,
              ...tagData,
            };
          }

          return t;
        }),
      };
    });
  };

  /**
   * Remove a tag that was added but not saved
   */
  removeAddedTag = (tag) => {
    // Remove tag from the add tags array
    this.addTags = this.addTags.filter(t => t._id !== tag._id);

    this.setState((state) => {
      return {
        tags: state.tags.filter(t => t._id !== tag._id),
      };
    });
  };

  /**
   * Restore a tag
   */
  restoreTag = (tag, tagData = {}) => {
    // Remove tag from remove tags array
    this.removeTags = this.removeTags.filter(tagId => tagId !== tag._id);

    this.setState((state) => {
      const tagToRestore = find(state.tags, { _id: tag._id });

      // Tag doesn't exist in the loaded tags. Re-add it.
      if (!tagToRestore) {
        // Add tag to add tags array
        this.addTags.push(tag);

        // Merge the tags
        const tags = [...state.tags, {
          ...tag,
          ...tagData,
        }];

        // Sort the tags
        const sortedTags = this.sortTags(tags);

        // Re-add the tag that was removed
        return {
          tags: sortedTags,
        };
      }

      return {
        tags: state.tags.map((t) => {
          if (t._id === tag._id) {
            return {
              ...t,
              ...tag,
              ...tagData,
            };
          }

          return t;
        }),
      };
    });
  };

  /**
   * Edit a tag
   */
  editTag = (tag, tagData = {}) => {
    // Check if the tag is in the add tags queue
    const addTag = find(this.addTags, { _id: tag._id });

    if (addTag) {
      // Update the tag being added
      addTag.name = tag.name;
    } else {
      // Tag is not being added, add to edit tags array
      this.editTags.push(clone(tag));
    }

    this.setState((state) => {
      return {
        tags: state.tags.map((t) => {
          if (t._id === tag._id) {
            return {
              ...t,
              ...tag,
              ...tagData,
            };
          }

          return t;
        }),
      };
    });
  };

  /**
   * Remove an edited tag
   */
  removeEditedTag = (tag, tagData = {}) => {
    // Remove tag from the add tags array
    this.editTags = this.editTags.filter(t => t._id !== tag._id);

    this.setState((state) => {
      return {
        tags: state.tags.map((t) => {
          if (t._id === tag._id) {
            return {
              ...t,
              ...tag,
              ...tagData,
            };
          }

          return t;
        }),
      };
    });
  };

  /**
   * Sort tags by the sort option
   */
  sortTags = (tags) => {
    const sortedTags = orderBy(tags, [tag => tag.name.toLowerCase()], [this.sortOption.attr]);

    if (this.sortOption.direction === 'desc') {
      return sortedTags.reverse();
    }

    return sortedTags;
  };

  onClickRemoveInput = () => {
    this.setState({
      addTagValue: '',
      tagExistsError: false,
      newTagExistsError: false,
      archivedTagExistsError: false,
    });
  };

  /**
   * Add to the queue for undo
   */
  addToQueue = (action, tag) => {
    this.queue.push({
      action,
      tag,
    });

    // Update the undo index to the last item in the queue
    this.undoIndex = this.queue.length - 1;
  };

  /**
   * Undo an action
   */
  undo = () => {
    if (this.undoIndex < 0) {
      console.warn('Nothing to undo');
      return;
    }

    // Get the queue item to undo
    const queueItem = clone(this.queue[this.undoIndex]);

    if (!queueItem) {
      console.warn('No queue item to undo');
      return;
    }

    switch (queueItem.action) {
      // Remove a tag that was added
      case 'add':
        if (queueItem.tag.active) {
          // Active tag
          this.removeAddedTag(queueItem.tag);
        } else {
          // Archived tag
          // Remove from add tags array
          this.addTags = this.addTags.filter(t => t._id !== queueItem.tag._id);

          this.restoreTag(queueItem.tag, {
            status: queueItem.tag.statusPrev,
          });
        }
        break;

      // Restore a tag that was removed
      case 'remove':
        this.restoreTag(queueItem.tag, {
          status: queueItem.tag.statusPrev,
        });
        break;

      // Remove a tag that was restored
      case 'restore':
        this.removeTag(queueItem.tag, {
          status: queueItem.tag.statusPrev,
        });
        break;

      // Remove an edit to a tag name
      case 'edit':
        this.removeEditedTag(queueItem.tag, {
          name: queueItem.tag.namePrev,
          status: queueItem.tag.statusPrev,
        });
        break;

      default:
        console.warn('Unknown queue action', queueItem.action);
        break;
    }

    if (this.undoIndex >= 0) {
      // Next index to undo
      this.undoIndex -= 1;
    }
  };

  /**
   * Redo an action
   */
  redo = () => {
    const redoIndex = this.undoIndex + 1;

    // Get the queue item to undo
    const queueItem = clone(this.queue[redoIndex]);

    if (!queueItem) {
      console.warn('No queue item to redo');
      return;
    }

    switch (queueItem.action) {
      // Add a tag
      case 'add':
        if (queueItem.tag.active) {
          // Active tag
          this.addTag(queueItem.tag);
        } else {
          // Archived tag
          this.addTag(queueItem.tag, {
            status: 'add',
          });
        }
        break;

      // Remove a tag
      case 'remove':
        this.removeTag(queueItem.tag);
        break;

      // Restore a tag
      case 'restore':
        this.restoreTag(queueItem.tag);
        break;

      // Edit a tag
      case 'edit':
        this.editTag(queueItem.tag);
        break;

      default:
        console.warn('Unknown queue action', queueItem.action);
        break;
    }

    if (redoIndex <= this.queue.length - 1) {
      // Next index to redo
      this.undoIndex += 1;
    }
  };

  render() {
    const {
      user,
      companyId,
      onlyAdminTags,
      onCancel,
    } = this.props;

    const {
      tags,
      canManageTags,
      canViewAdminClientTags,
      canCreateAdminTags,
      canCreateClientTags,
      canRemoveAdminTags,
      canRemoveClientTags,
      canModifyAdminTags,
      canModifyClientTags,
      activeTab,
      addTagValue,
      showConfirmClearUnsaved,
      isArchiveView,
      loading,
      tagExistsError,
      newTagExistsError,
      archivedTagExistsError,
      tagErrorText,
    } = this.state;

    const {
      ADMIN_COMPANY_ID: adminCompanyId,
    } = process.env;

    // determine if the user can view tags for this company
    const useCompanyId = companyId && user.hasAccessToCompany(companyId)
      ? companyId
      : user.companyId;

    // Use admin company when loading admin tags
    const filterCompanyId = activeTab === 'admin'
      ? adminCompanyId
      : useCompanyId;

    const filters = {
      type: activeTab,
      companyId: filterCompanyId,
    };

    return (
      <div className="ManageTags">
        <div className="manage-header">
          <div className="header-info">
            <div className="header-title">
              <div>
                Manage Tags
              </div>

              <div className="undo-redo">
                <VibeIcon
                  tooltip="Undo"
                  icon={viUndo}
                  color={color.manatee}
                  hoverColor={color.manatee50}
                  size={20}
                  disabled={this.undoIndex < 0}
                  onClick={this.undo}
                />

                <VibeIcon
                  tooltip="Redo"
                  style={{
                    marginRight: 16,
                  }}
                  icon={viRedo}
                  color={color.manatee}
                  hoverColor={color.manatee50}
                  size={20}
                  disabled={this.undoIndex >= this.queue.length - 1}
                  onClick={this.redo}
                />
              </div>
            </div>

            <div className="header-subtitle">
              Double-click to edit a tags name.
            </div>
          </div>

          <div className="header-buttons">
            <VibeButton
              className="btn-action btn-cancel"
              text="Cancel"
              btnColor="transparent"
              textColor="green"
              onClick={onCancel}
            />

            <VibeButton
              className="btn-action btn-save"
              text="Save"
              btnColor="green"
              textColor="white"
              disabled={!canManageTags}
              onClick={this.onApply}
            />

            <VibeButton
              className="btn-action btn-save"
              text="Save &amp; Close"
              btnColor="green"
              textColor="white"
              disabled={!canManageTags}
              onClick={this.onSaveAndClose}
            />
          </div>
        </div>

        {canViewAdminClientTags && (
          <div className="manage-tabs">
            <div
              className={classNames('tab', 'tab-admin', { active: activeTab === 'admin' })}
              data-tab="admin"
              onClick={this.onChangeActiveTab}
            >
              Admin

              <div className="active-bar" />
            </div>

            {!onlyAdminTags && (
              <div
                className={classNames('tab', 'tab-client', { active: activeTab === 'client' })}
                data-tab="client"
                onClick={this.onChangeActiveTab}
              >
                Client

                <div className="active-bar" />
              </div>
            )}
          </div>
        )}

        <div className="manage-toolbar">
          <PageLayout
            searchOptions={searchByTags}
            sortOptions={sortByTags}
            disableSort={sortByTags.length <= 0}
            toggleLabel="Show Archived Tags"
            pageSize={-1}
            scrollRef={this._scrollRef}
            filter={API.Tag.list}
            filterSearch={filters}
            onFilter={this.onFilter}
            onChangeToggle={this.onChangeToggle}
            onChangeSearch={this.onChangeSearch}
            disableView
            showToggle
          />
        </div>

        <div className="manage-tag-list-container">
          {loading && (
            <div
              style={{
                position: 'absolute',
                top: 90,
                left: 0,
                width: '100%',
                height: 'calc(100% - 90px)',
                backdropFilter: 'blur(6px)',
                textAlign: 'center',
                color: color.primary,
                zIndex: 101,
              }}
            >
              <CircularProgress
                className="tag-loader"
                color="inherit"
                size={75}
              />
            </div>
          )}
          <PerfectScrollbar containerRef={(ref) => { this._scrollRef = ref; }}>
            <div>
              {activeTab === 'admin' && canCreateAdminTags ? (
                <div className="add-tags-container">
                  <label
                    className="label-add-tag"
                    htmlFor="add-tag"
                  >
                    Add Tag
                  </label>

                  <div className="add-tag-input-container">
                    <input
                      id="add-tag"
                      className="input-add-tag"
                      type="text"
                      value={addTagValue}
                      disabled={isArchiveView}
                      onChange={this.onChangeAddTag}
                      onKeyDown={this.onKeyDownAddTag}
                      onFocus={this.onFocusTagInput}
                    />

                    <div className="icon-delete-input">
                      <VibeIcon
                        className="tag-icon remove-tag-icon"
                        icon={viCloseCircle}
                        color={color.manatee}
                        hoverColor={color.obsidian}
                        size={20}
                        onClick={this.onClickRemoveInput}
                      />
                    </div>

                    <div className="icon-add-tag">
                      <VibeIcon
                        icon={viAdd}
                        color={color.manatee}
                        size={24}
                        onClick={this.onAddTag}
                      />
                    </div>
                  </div>
                  {(tagExistsError)
                    && (
                      <div className="add-tag-error">A tag named <span>{tagErrorText} </span>
                        already exists.
                      </div>
                    )}
                  {(newTagExistsError)
                    && (
                      <div className="add-tag-error">A tag named <span>{tagErrorText} </span>
                        already exists.
                      </div>
                    )}
                  {(archivedTagExistsError)
                    && (
                      <div className="add-tag-error">A tag named <span>{tagErrorText} </span>
                        already exists in the archive.
                      </div>
                    )}
                </div>
              ) : null}

              {activeTab === 'client' && canCreateClientTags ? (
                <div className="add-tags-container">
                  <label
                    className="label-add-tag"
                    htmlFor="add-tag"
                  >
                    Add Tag
                  </label>

                  <div className="add-tag-input-container">
                    <input
                      id="add-tag"
                      className="input-add-tag"
                      type="text"
                      value={addTagValue}
                      disabled={isArchiveView}
                      onChange={this.onChangeAddTag}
                      onKeyDown={this.onKeyDownAddTag}
                    />

                    <div className="icon-delete-input">
                      <VibeIcon
                        className="tag-icon remove-tag-icon"
                        icon={viCloseCircle}
                        color={color.manatee}
                        hoverColor={color.obsidian}
                        size={20}
                        onClick={this.onClickRemoveInput}
                      />
                    </div>

                    <div className="icon-add-tag">
                      <VibeIcon
                        icon={viAdd}
                        color={color.manatee}
                        size={24}
                        onClick={this.onAddTag}
                      />
                    </div>
                  </div>
                  {(tagExistsError)
                    && (
                      <div className="add-tag-error">A tag named <span>{tagErrorText} </span>
                        already exists.
                      </div>
                    )}
                  {(newTagExistsError)
                    && (
                      <div className="add-tag-error">A tag named <span>{tagErrorText} </span>
                        already exists.
                      </div>
                    )}
                  {(archivedTagExistsError)
                    && (
                      <div className="add-tag-error">A tag named <span>{tagErrorText} </span>
                        already exists in the archive.
                      </div>
                    )}
                </div>
              ) : null}

              <div className="manage-tag-list">
                {tags.map((tag) => {
                  if (isArchiveView) {
                    if (!tag.active && tag.status !== 'remove') {
                      return (
                        <Tag
                          key={tag._id}
                          tag={tag}
                          allowEdit={
                            (tag.type === 'admin' && canModifyAdminTags)
                            || (tag.type === 'client' && canModifyClientTags)
                          }
                          allowRemove={
                            (tag.type === 'admin' && canRemoveAdminTags)
                            || (tag.type === 'client' && canRemoveClientTags)
                          }
                          manage
                          onRemove={this.onRemoveTag}
                          onAdd={this.onAddTag}
                          onRestore={this.onRestoreTag}
                          onEdit={this.onEditTag}
                        />
                      );
                    }
                  } else if (tag.active) {
                    return (
                      <Tag
                        key={tag._id}
                        tag={tag}
                        allowEdit={
                          (tag.type === 'admin' && canModifyAdminTags)
                          || (tag.type === 'client' && canModifyClientTags)
                        }
                        allowRemove={
                          (tag.type === 'admin' && canRemoveAdminTags)
                          || (tag.type === 'client' && canRemoveClientTags)
                        }
                        manage
                        onRemove={this.onRemoveTag}
                        onAdd={this.onAddTag}
                        onRestore={this.onRestoreTag}
                        onEdit={this.onEditTag}
                      />
                    );
                  }
                  return <div key={tag._id} />;
                })}

                {tags.length <= 0 ? (
                  <div className="empty-tags">
                    No tags found.

                    {activeTab === 'admin' && canCreateAdminTags ? (
                      <div className="empty-add-tags">
                        Add tags using the input above!
                      </div>
                    ) : null}

                    {activeTab === 'client' && canCreateClientTags ? (
                      <div className="empty-add-tags">
                        Add tags using the input above!
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </PerfectScrollbar>
        </div>

        <VibeModal
          show={showConfirmClearUnsaved}
          type="confirm"
          text={(
            <div>
              You currently have unsaved changes that will be lost
              <br />
              if you switch tabs. Do you want to switch anyway?
            </div>
          )}
          confirmProps={{
            text: 'Switch Tabs',
            color: color.error,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          onConfirm={this.onConfirmChangeActiveTab}
          onClose={this.onCancelChangeActiveTab}
        />
      </div>
    );
  }
}

ManageTags.propTypes = {
  /** Company ID to load tags to manage */
  companyId: PropTypes.string,
  /** Only Show Admin Tags */
  onlyAdminTags: PropTypes.bool,
  /** When cancel button is clicked */
  onCancel: PropTypes.func,
  /** When save button is clicked */
  onSave: PropTypes.func,
};

ManageTags.defaultProps = {
  companyId: '',
  onlyAdminTags: false,
  onCancel: () => {},
  onSave: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(ManageTags);
