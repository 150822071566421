import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  API,
  GlobalActions,
  SidePanelContainer,
  SidePanelHeader,
  SidePanelContent,
  TableCompanyBanners,
  VibeButtonNew,
  VibeIcon,
  viAdd,
  viClose,
  color,
} from 'vibeguide';
import CompanyBannerEdit from './CompanyBannerEdit';

function CompanyBanners({
  companyId,
  user,
  onClose,
  setPanel,
}) {
  /**
   * Close the panel
   */
  const onCloseBanner = () => {
    onClose();

    setPanel({
      extraPanel: {
        show: false,
      },
    });

    // Reset the children after it's been hidden
    setTimeout(() => {
      setPanel({
        extraPanel: {
          children: null,
        },
      });
    }, 900);
  };

  /**
   * Show the new/edit banner
   */
  const onOpenBanner = (banner = {}) => {
    setPanel({
      thirdPanel: {
        title: banner._id
          ? 'Update Banner'
          : 'New Banner',
        width: 400,
        show: true,
        children: (
          <CompanyBannerEdit
            companyId={companyId}
            banner={banner}
          />
        ),
      },
    });
  };

  /**
   * When user clicks the New Banner button
   */
  const onClickNewBanner = () => {
    onOpenBanner({
      _id: '',
      value: '',
      active: true,
    });
  };

  useEffect(() => {
    // tell listening components the object was saved
    document.dispatchEvent(new Event('onSaveCompanyBanner'));
  }, [companyId]);

  return (
    <SidePanelContainer className="CompanyBanners">
      <SidePanelHeader
        icons={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <VibeButtonNew
              text="New Banner"
              style={{
                marginRight: 16,
              }}
              color={color.primary}
              icon={viAdd}
              iconProps={{
                style: {
                  padding: 0,
                },
              }}
              disabled={!user.can('company_banner.create')}
              onClick={onClickNewBanner}
            />

            <VibeIcon
              className="close"
              icon={viClose}
              color={color.manatee}
              hoverColor={color.obsidian}
              size={24}
              onClick={onCloseBanner}
            />
          </div>
        )}
      >
        <div className="title">
          Banners
        </div>
      </SidePanelHeader>

      <SidePanelContent
        style={{
          display: 'flex',
          padding: 0,
        }}
      >
        <TableCompanyBanners
          columns={[
            'Banner',
            '...',
          ]}
          fetch={API.Company.Banner.list}
          fetchProps={{
            filters: {
              companyId,
            },
          }}
          defaultSortBy={{
            label: 'Banner',
            attr: 'value',
            direction: 'asc',
          }}
          paginator
          paginatorProps={{
            label: 'Banners',
            urlPaging: false,
            urlFilters: false,
          }}
          toggle
          toggleProps={{
            label: 'Show Archived Banners',
            tooltip: true,
          }}
          menuItems={[
            { name: 'Archive', userCan: 'company_banner.delete' },
          ]}
          companyId={companyId}
          onClickRow={onOpenBanner}
        />
      </SidePanelContent>
    </SidePanelContainer>
  );
}

CompanyBanners.propTypes = {
  companyId: PropTypes.string,
  onClose: PropTypes.func,
};

CompanyBanners.defaultProps = {
  companyId: '',
  onClose: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBanners);
