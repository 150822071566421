import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VibeModal from '../../VibeModal/VibeModal';
import VibeCheckbox from '../../VibeCheckbox/VibeCheckbox';
import VibeIcon from '../../VibeIcon/VibeIcon';
import viEdit from '../../../icons/viEdit';
import viAdd from '../../../icons/viAdd';
import viBlock from '../../../icons/viBlock';
import viClose from '../../../icons/viClose';
import viTrash from '../../../icons/viTrash';
import ApplyCancel from './ApplyCancel';
import color from '../../../sass/color.scss';
import './BulkActions.scss';

class BulkActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBulkEdit: false,
      showConfirm: false,
    };
  }

  /**
   * When bulk edit is selected
   */
  onClickEdit = () => {
    const {
      disableEdit,
      onBulkEdit,
    } = this.props;

    if (disableEdit) {
      return;
    }

    this.setState({
      isBulkEdit: true,
    });

    onBulkEdit(true);
  };

  /**
   * When bulk add is selected
   */
  onClickAdd = () => {
    const {
      disableAdd,
      onBulkAdd,
    } = this.props;

    if (disableAdd) {
      return;
    }

    onBulkAdd(true);
  };

  /**
   * When bulk block is selected
   */
  onClickBlock = () => {
    const {
      disableBlock,
      onBulkBlock,
    } = this.props;

    if (disableBlock) {
      return;
    }

    onBulkBlock(true);
  };

  /**
   * When bulk remove is selected
   */
  onClickRemove = () => {
    const {
      disableRemove,
      selectedIds,
      onBulkRemove,
    } = this.props;

    if (disableRemove) {
      return;
    }

    onBulkRemove(true, selectedIds);
    console.warn('Bulk Remove IDs', selectedIds);
  };

  /**
   * When bulk archive is selected, show a confirmation modal
   */
  onClickArchive = () => {
    this.setState({
      showConfirm: true,
    });
  };

  /**
   * When bulk archive is cancelled
   */
  onCancelArchive = () => {
    this.setState({
      showConfirm: false,
    });
  };

  /**
   * When bulk archive is confirmed
   */
  onConfirmArchive = () => {
    const {
      disableArchive,
      onBulkArchive,
    } = this.props;

    if (disableArchive) {
      return;
    }

    this.setState({
      showConfirm: false,
    });

    onBulkArchive();
  };

  /**
   * When a bulk action is canceled
   */
  onClickCancel = () => {
    const {
      onBulkEdit,
    } = this.props;

    this.setState({
      isBulkEdit: false,
    });

    onBulkEdit(false);
  };

  /**
   * When the bulk action edit is applied
   */
  onClickApplyEdits = () => {
    const {
      onBulkEditApply,
    } = this.props;

    this.setState({
      isBulkEdit: false,
    });

    onBulkEditApply();
  };

  render() {
    const {
      selectedIds = [],
      edit,
      editType,
      add,
      block,
      remove,
      archive,
      disableEdit,
      disableAdd,
      disableBlock,
      disableRemove,
      disableArchive,
    } = this.props;

    const {
      isBulkEdit,
      showConfirm,
    } = this.state;

    const selectedCount = selectedIds.length;
    let content;

    if (isBulkEdit && editType !== 'sidebar') {
      // Show the bulk edit toolbar
      content = (
        <ApplyCancel
          onCancel={this.onClickCancel}
          onApply={this.onClickApplyEdits}
        />
      );
    } else {
      // Show the bulk actions
      const showActions = edit
        || add
        || block
        || remove
        || archive;

      content = (
        <div className="content bulk-actions">
          <div className="selected-container">
            <VibeCheckbox
              style={{
                width: 16,
                height: 16,
              }}
              checked
            />
            <div>
              {selectedCount} Selected
            </div>
          </div>

          <div
            className="actions-container"
            style={{
              display:
                showActions
                  ? 'block'
                  : 'none',
            }}
          >
            {edit ? (
              <VibeIcon
                className="action-icon"
                icon={viEdit}
                color={color.success}
                hoverColor={color.success75}
                size={16}
                tooltip="Bulk Edit"
                tooltipProps={{
                  placement: 'top',
                }}
                disabled={disableEdit}
                onClick={this.onClickEdit}
              />
            ) : null}

            {add ? (
              <VibeIcon
                className="action-icon"
                icon={viAdd}
                color={color.success}
                hoverColor={color.success75}
                size={16}
                tooltip="Bulk Add"
                tooltipProps={{
                  placement: 'top',
                }}
                disabled={disableAdd}
                onClick={this.onClickAdd}
              />
            ) : null}

            {block ? (
              <VibeIcon
                className="action-icon"
                icon={viBlock}
                color={color.success}
                hoverColor={color.success75}
                size={16}
                tooltip="Bulk Block"
                tooltipProps={{
                  placement: 'top',
                }}
                disabled={disableBlock}
                onClick={this.onClickBlock}
              />
            ) : null}

            {remove ? (
              <VibeIcon
                className="action-icon"
                icon={viClose}
                color={color.success}
                hoverColor={color.success75}
                size={16}
                tooltip="Bulk Remove"
                tooltipProps={{
                  placement: 'top',
                }}
                disabled={disableRemove}
                onClick={this.onClickRemove}
              />
            ) : null}

            {archive ? (
              <VibeIcon
                className="action-icon"
                icon={viTrash}
                color={color.success}
                hoverColor={color.success75}
                size={16}
                tooltip="Bulk Archive"
                tooltipProps={{
                  placement: 'top',
                }}
                disabled={disableArchive}
                onClick={this.onClickArchive}
              />
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className="BulkActions">
        {content}

        <VibeModal
          className="bulk-modal"
          show={showConfirm}
          type="confirm"
          confirmProps={{
            text: 'Archive',
            color: color.error,
          }}
          cancelProps={{
            text: 'Cancel',
            color: color.manatee,
          }}
          title="Bulk Archive"
          text={`Are you sure you want to archive ${selectedIds.length} items?`}
          onConfirm={this.onConfirmArchive}
          onClose={this.onCancelArchive}
        />
      </div>
    );
  }
}

BulkActions.propTypes = {
  /** Selected IDs */
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  /** Allow bulk edit */
  edit: PropTypes.bool,
  /** Bulk edit type */
  editType: PropTypes.string,
  /** Allow bulk add  */
  add: PropTypes.bool,
  /** Allow bulk block */
  block: PropTypes.bool,
  /** Allow bulk remove */
  remove: PropTypes.bool,
  /** Allow bulk archive */
  archive: PropTypes.bool,
  /** Disable bulk edit icon */
  disableEdit: PropTypes.bool,
  /** Disable bulk add icon */
  disableAdd: PropTypes.bool,
  /** Disable bulk block icon */
  disableBlock: PropTypes.bool,
  /** Disable bulk remove icon */
  disableRemove: PropTypes.bool,
  /** Disable bulk archive icon */
  disableArchive: PropTypes.bool,
  /** When bulk edit is toggled */
  onBulkEdit: PropTypes.func,
  /** When bulk edit changes are applied */
  onBulkEditApply: PropTypes.func,
  /** When bulk block is toggled */
  onBulkBlock: PropTypes.func,
  /** When bulk add is toggled */
  onBulkAdd: PropTypes.func,
  /** When bulk remove is toggled */
  onBulkRemove: PropTypes.func,
  /** When bulk archive is toggled */
  onBulkArchive: PropTypes.func,
};

BulkActions.defaultProps = {
  selectedIds: [],
  edit: false,
  editType: 'table',
  add: false,
  block: false,
  remove: false,
  archive: false,
  disableEdit: false,
  disableAdd: false,
  disableBlock: false,
  disableRemove: false,
  disableArchive: false,
  onBulkEdit: () => {},
  onBulkEditApply: () => {},
  onBulkBlock: () => {},
  onBulkAdd: () => {},
  onBulkRemove: () => {},
  onBulkArchive: () => {},
};

export default BulkActions;
