import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  API,
  GlobalActions,
  NavigationHelper,
  TableTargetGroups,
  viAdd,
  color,
  withRouter,
} from 'vibeguide';
import SubNavigation from '../../Header/SubNavigation';
import TargetGroupSidebar from './Sidebar/TargetGroupSidebar';

function TargetGroupContainer({
  className,
  setPanel,
  history,
}) {
  const qs = NavigationHelper.getParams() || {};

  /**
   * When the sidebar is closed
   */
  const onCloseSidebar = () => {
    const url = NavigationHelper.updateParams({
      targetGroupId: null,
      type: null,
    }, {
      keepPage: true,
    });

    history(url);
  };

  const sidebar = (type, targetGroupId = null) => {
    setPanel({
      show: true,
      backdrop: type === 'new',
      width: 400,
      children: (
        <TargetGroupSidebar
          targetGroupId={targetGroupId}
        />
      ),
      onClose: onCloseSidebar,
    });
  };

  useEffect(() => {
    const isNew = qs.type === 'new';
    const isView = qs.targetGroupId && qs.type !== 'new';

    if (isNew || isView) {
      sidebar(qs.type, qs.targetGroupId);
    }
  }, [qs.type, qs.targetGroupId]);

  return (
    <div
      className={classNames('TargetGroupContainer', className)}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <SubNavigation
        title="Target Groups"
        buttons={[
          {
            text: 'New Target Group',
            icon: viAdd,
            userCan: [
              'target_group.create',
            ],
            color: color.primary,
            url: NavigationHelper.updateParams({
              type: 'new',
            }),
          },
        ]}
      />

      <TableTargetGroups
        columns={[
          'Name',
          'Media Format',
          'Number of Locations',
          '...',
        ]}
        fetch={API.TargetGroup.list}
        defaultSortBy={{
          label: 'Name',
          attr: 'name',
          direction: 'asc',
        }}
        paginator
        paginatorProps={{
          label: 'Target Groups',
          urlPaging: true,
          urlFilters: true,
        }}
        toggle
        toggleProps={{
          label: 'Show Archived Target Groups',
          tooltip: true,
        }}
        rowLink={{
          targetGroupId: '_id',
        }}
        menuItems={[
          { name: 'Archive', userCan: 'target_group.delete' },
        ]}
      />
    </div>
  );
}

TargetGroupContainer.propTypes = {
  className: PropTypes.string,
};

TargetGroupContainer.defaultProps = {
  className: '',
};

const mapDispatchToProps = {
  setPanel: GlobalActions.setPanel,
};

export default withRouter(connect(null, mapDispatchToProps)(TargetGroupContainer));
