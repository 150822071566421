import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  color,
  Field2 as Field,
} from 'vibeguide';
import Grid from '@mui/material/Grid';

function FlightInformation({
  className,
  style,
  title,
  flightData,
  onChange,
}) {
  return (
    <div
      className={classNames('ProfileContent', className)}
      style={{
        position: 'relative',
        marginBottom: 16,
        padding: 16,
        backgroundColor: color.white,
        border: `1px solid ${color.whiteSmoke}`,
        fontSize: 12,
        userSelect: 'none',
        ...style,
      }}
    >
      {title && (
        <div
          className={classNames('ProfileContentTitle', className)}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 16,
            ...style,
          }}
        >
          <div
            style={{
              flexGrow: 1,
              fontSize: 14,
              fontWeight: 'bold',
              color: color.primary,
            }}
          >
            {title}
          </div>
        </div>
      )}
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <Field
            type="text"
            label="Flight Name"
            name="name"
            placeholder="Name"
            value={flightData.name}
            disabled={false}
            onChange={onChange}
            tabIndex={1}
            required
          />
        </Grid>

        <Grid xs={12} item>
          <Field
            type="text"
            label="Opportunity Name"
            name="opportunityName"
            placeholder="Opportunity Name"
            value={flightData.opportunityName}
            disabled={false}
            onChange={onChange}
            tabIndex={2}
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="text"
            label="Opportunity Salesforce ID"
            name="opportunityId"
            placeholder="Opportunity Salesforce ID"
            value={flightData.opportunityId}
            disabled={false}
            onChange={onChange}
            tabIndex={3}
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="text"
            label="Deal ID"
            name="dealId"
            placeholder="Deal ID"
            value={flightData.dealId}
            disabled={false}
            onChange={onChange}
            tabIndex={4}
          />
        </Grid>

        <Grid xs={6} item>
          <Field
            type="text"
            label="Order Reference ID"
            name="extReferenceId"
            placeholder="Order Reference ID"
            value={flightData.extReferenceId}
            disabled={false}
            onChange={onChange}
            tabIndex={5}
          />
        </Grid>
      </Grid>
    </div>
  );
}

FlightInformation.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
};

FlightInformation.defaultProps = {
  className: '',
  style: {},
  title: '',
};

export default FlightInformation;
