import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Grid } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import {
  playlists as searchByPlaylists,
} from '../../helpers/SearchBy';
import {
  playlists as sortByPlaylists,
} from '../../helpers/SortBy';
import PlaylistCard from '../PlaylistCard/PlaylistCard';
import Paginator from '../Paginator/Paginator';
import PageLayout from '../PageLayout/PageLayout';
import VibeButton from '../VibeButton/VibeButton';
import VibeIcon from '../VibeIcon/VibeIcon';
import viCheckCircleOutline from '../../icons/viCheckCircleOutline';
import color from '../../sass/color.scss';
import './Playlists.scss';

class Playlists extends Component {
  constructor(props) {
    super(props);

    const {
      selectAll,
    } = props;

    this.state = {
      playlists: [],
      totalItems: 0,
      selectAll,
    };

    this._scrollRef = null;
  }

  componentDidUpdate(prevProps) {
    const {
      selectAll,
    } = this.props;

    const {
      selectAll: prevSelectAll,
    } = prevProps;

    if (selectAll !== prevSelectAll) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectAll,
      });
    }
  }

  onFilter = (data) => {
    const {
      collection,
      totalItems,
    } = data;

    this.setState({
      playlists: collection,
      totalItems,
    });
  };

  toggleSelected = (playlistId, selected) => {
    const {
      onSelect,
      onDeselect,
    } = this.props;

    const {
      playlists,
    } = this.state;

    if (selected) {
      // Selected a Playlist
      const playlist = find(playlists, { _id: playlistId });

      if (playlist) {
        onSelect(playlist);
      } else {
        console.warn('Playlist not found...', playlistId);
      }
    } else {
      // Deselected a Playlist
      const playlist = find(playlists, { _id: playlistId });

      if (playlist) {
        onDeselect(playlist);
      } else {
        console.warn('Playlist not found', playlistId);
      }
    }
  };

  toggleSelectAll = () => {
    const {
      selectedPlaylists,
      onSelectMultiple,
      onDeselectMultiple,
    } = this.props;

    const {
      playlists,
    } = this.state;

    // Select All Locations
    const selected = playlists.filter(playlist => {
      return find(selectedPlaylists, { _id: playlist._id });
    });

    const isAllSelected = playlists.length === selected.length;

    if (isAllSelected) {
      // All are selected, deselect
      onDeselectMultiple(playlists);
    } else {
      // All are not selected, select
      onSelectMultiple(playlists);
    }

    console.warn('Select All Toggled');
  };

  render() {
    const {
      selectedPlaylists,
      filterPlaylists,
      showPaginator,
      allowClick,
    } = this.props;

    const {
      playlists,
      totalItems,
      selectAll,
    } = this.state;

    const playlistCards = [];

    playlists.forEach((playlist, index) => {
      const isSelected = selectAll
        || find(selectedPlaylists, { _id: playlist._id }) !== undefined;

      playlistCards.push(
        <Grid item xs={12} sm={6} md={3} key={index}>
          <PlaylistCard
            playlist={playlist}
            toggleSelected={this.toggleSelected}
            selected={isSelected}
            onClick={this.selectPlaylist}
            allowClick={allowClick}
          />
        </Grid>,
      );
    });

    // Check all selected on playlists
    const selected = playlists.filter(playlist => {
      return find(selectedPlaylists, { _id: playlist._id });
    });

    // Check all selected
    const isAllSelected = playlists.length === selected.length;

    return (
      <div className="Playlists">
        <Grid container>
          <Grid item xs={12}>
            <PageLayout
              className="playlist-page-container"
              sortOptions={sortByPlaylists}
              searchOptions={searchByPlaylists}
              scrollRef={this._scrollRef}
              filter={filterPlaylists}
              onFilter={this.onFilter}
              disableView
            />
          </Grid>
        </Grid>

        <div className="playlist-content">
          <PerfectScrollbar containerRef={(ref) => { this._scrollRef = ref; }}>
            <Grid
              className="playlist-container"
              container
            >
              <Grid
                xs={12}
                item
              >
                <div className="playlist-toolbar">
                  {showPaginator ? (
                    <Paginator
                      className="playlist-paginator"
                      totalLoaded={playlists.length}
                      totalItems={totalItems}
                      label="Playlists"
                    />
                  ) : null}

                  <div className="toolbar-right">
                    <div className="toolbar-right-items">
                      {selectAll || selectedPlaylists.length > 0 ? (
                        <div className="selected-count-container">
                          <div>
                            {selectAll ? 'All' : selectedPlaylists.length} Selected
                          </div>

                          <VibeIcon
                            icon={viCheckCircleOutline}
                            color={color.success}
                            size={24}
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        </div>
                      ) : null}

                      <div className="select-container">
                        <VibeButton
                          text={isAllSelected ? 'Deselect All' : 'Select All'}
                          btnColor="green"
                          textColor="white"
                          icon={isAllSelected ? (
                            <Close className="select-icon" />
                          ) : (
                            <Check className="select-icon" />
                          )}
                          iconPlacement="left"
                          disabled={selectAll}
                          onClick={this.toggleSelectAll}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Grid
                  className=""
                  spacing={3}
                  container
                >
                  {playlistCards}
                </Grid>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

Playlists.propTypes = {
  /** Selected playlists */
  selectedPlaylists: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string,
  })),
  /** Are all playlists selected */
  selectAll: PropTypes.bool,
  allowClick: PropTypes.bool,
  showPaginator: PropTypes.bool,
  /** Filter playlists function */
  filterPlaylists: PropTypes.func,
  /** When a playlist is selected */
  onSelect: PropTypes.func,
  /** When multiple playlists are selected */
  onSelectMultiple: PropTypes.func,
  /** When multiple playlists are deselected */
  onDeselectMultiple: PropTypes.func,
  /** When a playlist is deselected */
  onDeselect: PropTypes.func,
};

Playlists.defaultProps = {
  selectedPlaylists: [],
  selectAll: false,
  allowClick: false,
  showPaginator: false,
  filterPlaylists: () => {},
  onSelect: () => {},
  onSelectMultiple: () => {},
  onDeselect: () => {},
  onDeselectMultiple: () => {},
};

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

export default connect(mapStateToProps)(Playlists);
