import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  get,
  find,
  isEqual,
  capitalize,
  toString,
  size,
} from 'lodash';
import withRouter from '../../../wrappers/withRouter';
import {
  tablePropsType,
  tablePropsDefault,
} from '../../../types/tablePropsType';
import {
  getSettings,
  getFiltersFromUrl,
  getData,
} from '../../../helpers/Table';
import {
  getParam,
  updateParams,
} from '../../../helpers/Navigation';
import {
  list as mediaFormatList,
  getIcon as getMediaFormatIcon,
  getTooltip as getMediaFormatTooltip,
} from '../../../helpers/MediaFormat';
import VibeTable from '../VibeTable';
import VibeButton from '../../VibeButton/VibeButton';
import VibeIcon from '../../VibeIcon/VibeIcon';
import CellTooltip from '../CellTypes/CellTooltip';
import LabelCell from '../CellTypes/LabelCell';
import ContentTags from '../CellTypes/ContentTags';
import ContentPercent from '../CellTypes/ContentPercent';
import IconCell from '../CellTypes/IconCell';
import Tag from '../../Tags/Tag';
import TimestampCell from '../CellTypes/TimestampCell';
import viFlag from '../../../icons/viFlag';
import viFlagSlash from '../../../icons/viFlagSlash';
import viDownload from '../../../icons/viDownload';
import viError from '../../../icons/viError';
import viSpeaker from '../../../icons/viSpeaker';
import countries from '../../../utils/Countries';
import color from '../../../sass/color.scss';

const tableId = 'table:dashboard:support';

class TableSupportDashboard extends PureComponent {
  constructor(props) {
    super(props);

    const {
      columns: columnNames,
      defaultSortBy,
      pageSize,
      paginatorProps: {
        urlPaging,
        urlFilters,
      },
    } = props;

    const columnsDef = [{
      name: '.',
      defaultWidth: 40,
    },
    {
      name: '...',
      defaultWidth: 72,
    },
    {
      name: 'Device Type',
      searchAttr: 'deviceType',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'MAndroid',
          value: 'mandroid',
        },
        {
          label: 'Optra',
          value: 'optra',
        },
      ],
    },
    {
      name: 'Provisioning',
      searchAttr: 'deviceActivationStatus',
      defaultWidth: 165,
      searchable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Live',
          value: 'live',
        },
        {
          label: 'Standby',
          value: 'standby',
        },
        {
          label: 'Setup',
          value: 'setup',
        },
        {
          label: 'Decommissioned',
          value: 'decommissioned',
        },
      ],
    },
    {
      name: 'Location Online',
      searchAttr: 'playerOnline',
      defaultWidth: 200,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Online',
          value: 'true',
        },
        {
          label: 'Offline',
          value: 'false',
        },
      ],
    },
    {
      name: 'Device Online',
      searchAttr: 'deviceOnline',
      defaultWidth: 200,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Online',
          value: 'true',
        },
        {
          label: 'Offline',
          value: 'false',
        },
      ],
    },
    {
      name: 'Flags',
      searchAttr: 'flagEnabled',
      icon: viFlag,
      defaultWidth: 95,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Flagged',
          value: 'true',
        },
        {
          label: 'Not Flagged',
          value: 'false',
        },
      ],
    },
    {
      name: 'Ad Network',
      searchAttr: 'adNetworkEnabled',
      defaultWidth: 170,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Ad Programs',
      searchAttr: 'adProgramEnabled',
      defaultWidth: 170,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Content Network Owner',
      searchAttr: 'contentNetworkOwner',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Content Delivery System',
      searchAttr: 'contentDeliverySystem',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Ad Delivery System',
      searchAttr: 'adDeliverySystem',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Ad Network Owner',
      searchAttr: 'adNetworkOwner',
      defaultWidth: 260,
      sortable: true,
      searchable: true,
      resizable: true,
    },
    {
      name: 'Media Format',
      searchAttr: 'mediaFormat',
      icon: viSpeaker,
      defaultWidth: 95,
      resizable: false,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        ...mediaFormatList(),
      ],
    },
    {
      name: 'Last Check In',
      searchAttr: 'lastCheckInTime',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      datepicker: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Location',
      searchAttr: 'name',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
      autoFocus: true,
    },
    {
      name: 'Company',
      searchAttr: 'companyName',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Prov.',
      searchAttr: 'onboardStatus',
      defaultWidth: 120,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Setup',
          value: 'setup',
        },
        {
          label: 'Shipping',
          value: 'shipping',
        },
        {
          label: 'Complete',
          value: 'complete',
        },
      ],
    },
    {
      name: 'Mast. Vol',
      searchAttr: 'masterVolume',
      defaultWidth: 150,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Msg. Vol',
      searchAttr: 'messageVolume',
      defaultWidth: 150,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Mus. Vol',
      searchAttr: 'musicVolume',
      defaultWidth: 150,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Audio',
      searchAttr: 'audioConnected',
      defaultWidth: 110,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Battery',
      searchAttr: 'batteryLevel',
      defaultWidth: 150,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Charge',
      searchAttr: 'chargingStatus',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Avail Memory',
      searchAttr: 'memoryPercentage',
      defaultWidth: 150,
      resizable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Player ID',
      searchAttr: 'playerId',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Serial Number',
      searchAttr: 'serialNumber',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'IMEI',
      searchAttr: 'imei',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Cell',
      searchAttr: 'cellular',
      defaultWidth: 135,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Connected',
          value: 'connected',
        },
        {
          label: 'Disconnected',
          value: 'disconnected',
        },
      ],
    },
    {
      name: 'Version',
      searchAttr: 'vibenomicsPlayerVersion',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      locked: true,
    },
    {
      name: 'Model',
      searchAttr: 'model',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Phone Number',
      searchAttr: 'phoneNumber',
      defaultWidth: 212,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'IP Address',
      searchAttr: 'ipAddress',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'MAC Address',
      searchAttr: 'macAddress',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Wifi SSID',
      searchAttr: 'wifiSSID',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Network Type',
      searchAttr: 'networkType',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Connection Type',
      searchAttr: 'connectionType',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
      dropdownItems: [
        {
          label: '',
          value: '',
          placeholder: 'Search...',
        },
        {
          label: 'Ethernet',
          value: 'ethernet',
        },
        {
          label: 'WIFI',
          value: 'wifi',
        },
        {
          label: 'Cellular',
          value: 'cellular',
        },
        {
          label: 'Offline',
          value: 'offline',
        },
      ],
    },
    {
      name: 'Last Start Time',
      searchAttr: 'lastStartTime',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      datepicker: true,
      sortable: true,
    },
    {
      name: 'Last Connected',
      searchAttr: 'lastConnectTime',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      // removed datepicker due to Alerts dashboard allowing a date range
      // datepicker: true,
      sortable: true,
    },
    {
      name: 'Last Disconnected',
      searchAttr: 'lastDisconnectTime',
      defaultWidth: 230,
      resizable: true,
      searchable: true,
      // removed datepicker due to Alerts dashboard allowing a date range
      // datepicker: true,
      sortable: true,
    },
    {
      name: 'Address 1',
      searchAttr: 'address.line1',
      sortAttr: 'address1',
      valueAttr: 'address1',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Address 2',
      searchAttr: 'address.line2',
      sortAttr: 'address2',
      valueAttr: 'address2',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'Country',
      searchAttr: 'address.country',
      sortAttr: 'country',
      valueAttr: 'country',
      defaultWidth: 200,
      resizable: true,
      searchable: true,
      sortable: true,
    },
    {
      name: 'City',
      searchAttr: 'address.city',
      sortAttr: 'city',
      valueAttr: 'city',
      defaultWidth: 190,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'State/Province',
      searchAttr: 'address.state',
      sortAttr: 'state',
      valueAttr: 'state',
      defaultWidth: 150,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Postal Code',
      searchAttr: 'address.postalCode',
      sortAttr: 'postalCode',
      valueAttr: 'postalCode',
      defaultWidth: 135,
      resizable: true,
      searchable: true,
      searchAdvanced: true,
      sortable: true,
    },
    {
      name: 'Latitude',
      searchAttr: 'lat',
      defaultWidth: 190,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Longitude',
      searchAttr: 'long',
      defaultWidth: 190,
      resizable: true,
      sortable: true,
    },
    {
      name: 'Tags',
      searchAttr: 'tags.name',
      valueAttr: 'tags',
      defaultWidth: 275,
      resizable: true,
      searchable: true,
    }];

    const settings = getSettings({
      tableId,
      columnsDef,
      columnNames,
    });

    this.state = {
      columnsDef,
      columnNames,
      rows: [],
      loading: true,
      totalItems: 0,
      active: !urlFilters || (urlFilters && getParam('active') !== 'false'),
      pageNumber: urlPaging
        ? parseInt(getParam('page') || 1, 10)
        : 1,
      pageSize: get(settings, 'pageSize', pageSize),
      sortBy: {
        label: get(settings, 'sortBy.label', defaultSortBy.label),
        attr: get(settings, 'sortBy.attr', defaultSortBy.attr),
        direction: get(settings, 'sortBy.direction', defaultSortBy.direction),
      },
      filters: urlFilters
        ? getFiltersFromUrl({ columns: columnsDef })
        : {},
    };

    // listen for when sidebar data changes
    document.addEventListener('onUpdateTableSupportDashboard', this.onUpdateTableData);
    document.addEventListener('onSaveLocation', this.onUpdateTableData);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const {
      fetch,
      collection,
    } = this.props;

    const {
      collection: prevCollection,
    } = prevProps;

    if (!fetch && !isEqual(collection, prevCollection)) {
      this.onUpdate({
        refresh: true,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('onUpdateTableSupportDashboard', this.onUpdateTableData);
    document.removeEventListener('onSaveLocation', this.onUpdateTableData);
  }

  /**
   * When an event asks the table to update the table data
   */
  onUpdateTableData = () => {
    this.onUpdate({
      refresh: true,
    });
  };

  /**
   * Reset to Default Settings
   */
  onReset = () => {
    const {
      defaultSortBy,
      pageSize,
    } = this.props;

    this.onUpdate({
      refresh: true,
      data: {
        sortBy: defaultSortBy,
        pageSize: pageSize || 50,
      },
    });
  };

  onUpdate = ({
    data,
    refresh = false,
  }) => {
    if (refresh) {
      // refresh the table data
      this.setState(data, this.getData);
    } else {
      this.setState(data);
    }
  };

  /**
   * When the Flag icon is clicked
   */
  onClickFlag = (e, _rowId) => {
    e.preventDefault();
    e.stopPropagation();

    const {
      history,
    } = this.props;

    const {
      rows,
    } = this.state;

    const row = find(rows, { _rowId });
    const {
      _id: locationId,
    } = row;

    const url = updateParams({
      locationId,
      type: 'flags',
    });

    history(url);
  };

  onExport = () => {
    this.getData({
      export: true,
    });
  };

  getData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    if (get(props, 'fetchProps.requireFilter') && size(get(state, 'filters', {})) <= 0) {
      // require a filter before fetching any data from the API
      const rows = [];
      const totalItems = 0;

      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        totalItems,
        filters: {},
        requireFilter: get(props, 'fetchProps.requireFilter', false),
      });

      console.log('a search filter must be provided before API results are returned');
      return;
    }

    if (!state.loading && !config.export) {
      this.setState({
        loading: true,
      });
    }

    const {
      rows,
      filters,
      totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    if (rows) {
      this.setState({
        loading: false,
        rows,
        totalItems,
      });

      props.onFetchComplete({
        rows,
        filters,
        totalItems,
        requireFilter: get(props, 'fetchProps.requireFilter', false),
      });
    }
  };

  /**
   * Fetch data for the table
   * Only request and return the response from the API or collection
   */
  fetchData = async (config = {}) => {
    const {
      props,
      state,
    } = this;

    const {
      rows,
      // totalItems,
    } = await getData({
      props,
      state,
      config,
    });

    return rows;
  };

  /**
   * Get label style for the message type cell
   */
  getLabelStyle = (status) => {
    switch (status) {
      case 'live':
        return {
          text: 'Live',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'standby':
        return {
          text: 'Standby',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'setup':
        return {
          text: 'Setup',
          textStyle: {
            background: color.orange16,
            color: color.orange,
          },
        };

      case 'decommissioned':
        return {
          text: 'Decommissioned',
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };

      default:
        return {
          text: capitalize(status),
          textStyle: {
            background: color.manatee16,
            color: color.manatee,
          },
        };
    }
  };

  /**
   * Get the Provisioning Status Label Colors
   */
  getProvisioningStatusColors = (status = '') => {
    switch (status.toLowerCase()) {
      case 'complete':
        return {
          text: 'Complete',
          textStyle: {
            background: color.success16,
            color: color.success,
          },
        };

      case 'shipping':
        return {
          text: 'Shipping',
          textStyle: {
            background: color.carrot16,
            color: color.carrot,
          },
        };

      case 'setup':
        return {
          text: 'Setup',
          textStyle: {
            background: color.lightGray,
            color: color.manatee,
          },
        };

      default:
        return {
          text: status,
          textStyle: {
            background: 'transparent',
            color: color.manatee,
          },
        };
    }
  };

  renderCell = ({
    column,
    row,
  }) => {
    // get the attribute with data for the cell
    const attr = column.valueAttr || column.searchAttr;
    const value = get(row, attr, '');

    switch (column.name) {
      case 'Location': {
        const testLocation = get(row, 'testLocation');
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
              {testLocation && (
                <Tag
                  tag={{
                    name: 'Test',
                  }}
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    marginLeft: 8,
                    fontSize: 10,
                    textTransform: 'uppercase',
                    backgroundColor: color.primary16,
                    color: color.primary,
                  }}
                />
              )}
            </div>
          </CellTooltip>
        );
      }

      case 'Device Type':
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {capitalize(value)}
            </div>
          </CellTooltip>
        );

      case 'Provisioning': {
        const {
          text,
          textStyle,
        } = this.getLabelStyle(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Location Online':
        return (
          <LabelCell
            value={value
              ? 'Online'
              : 'Offline'}
            background={value
              ? color.success16
              : color.error16}
            color={value
              ? color.success
              : color.error}
          />
        );

      case 'Device Online':
        return (
          <LabelCell
            value={value
              ? 'Online'
              : 'Offline'}
            background={value
              ? color.success16
              : color.error16}
            color={value
              ? color.success
              : color.error}
          />
        );

      case 'Flags':
        return (
          <IconCell
            rowId={row._rowId}
            icon={value
              ? viFlag
              : viFlagSlash}
            color={value
              ? color.error
              : color.manatee}
            hoverColor={value
              ? color.error75
              : color.secondary}
            onClick={this.onClickFlag}
          />
        );

      case 'Ad Network':
        return (
          <LabelCell
            value={value
              ? 'Enabled'
              : 'Disabled'}
            background={value
              ? color.success16
              : color.error16}
            color={value
              ? color.success
              : color.error}
          />
        );

      case 'Ad Programs':
        return (
          <LabelCell
            value={value
              ? 'Enabled'
              : 'Disabled'}
            background={value
              ? color.success16
              : color.error16}
            color={value
              ? color.success
              : color.error}
          />
        );

      case 'Media Format':
        return (
          <IconCell
            rowId={row._rowId}
            icon={getMediaFormatIcon(value)}
            color={color.manatee}
            tooltip={getMediaFormatTooltip(value)}
            tooltipProps={{
              placement: 'right',
            }}
          />
        );

      case 'Last Check In':
      case 'Last Start Time':
      case 'Last Connected':
      case 'Last Disconnected':
        return (
          <TimestampCell
            time={value}
          />
        );

      case 'Prov.': {
        const {
          text,
          textStyle,
        } = this.getProvisioningStatusColors(value);

        return (
          <LabelCell
            value={text}
            background={textStyle.background}
            color={textStyle.color}
          />
        );
      }

      case 'Mast. Vol':
      case 'Msg. Vol':
      case 'Mus. Vol':
      case 'Battery':
        return (
          <ContentPercent
            percentage={value}
            ranges={[
              {
                range: '0',
                color: color.error,
                border: true,
                icon: viError,
              },
              {
                range: '1-25',
                color: color.error,
              },
              {
                range: '26-50',
                color: color.primary,
              },
              {
                range: '51-100',
                color: color.success,
              },
            ]}
          />
        );

      case 'Avail Memory':
        return (
          <ContentPercent
            percentage={Math.round(value * 100)}
            ranges={[
              {
                range: '0',
                color: color.error,
                border: true,
                icon: viError,
              },
              {
                range: '1-25',
                color: color.error,
              },
              {
                range: '26-60',
                color: color.primary,
              },
              {
                range: '61-100',
                color: color.success,
              },
            ]}
          />
        );

      case 'Audio': {
        const text = value
          ? 'True'
          : 'False';

        return (
          <CellTooltip title={text}>
            <div className="cell-content">
              {text}
            </div>
          </CellTooltip>
        );
      }

      case 'Charge':
      case 'IMEI':
      case 'Cell': {
        // replace "unknown" with an empty cell
        const valueStr = toString(value);
        const text = valueStr === 'unknown'
          ? ''
          : valueStr;

        return (
          <CellTooltip title={text}>
            <div
              className="cell-content"
              style={{
                textTransform: 'capitalize',
              }}
            >
              {text}
            </div>
          </CellTooltip>
        );
      }

      case 'Version':
      case 'Model':
      case 'IP Address':
      case 'MAC Address':
      case 'Wifi SSID': {
        // replace "unknown" with an empty cell
        const valueStr = toString(value);
        const text = valueStr === 'unknown'
          ? ''
          : valueStr;

        return (
          <CellTooltip title={text}>
            <div className="cell-content">
              {text}
            </div>
          </CellTooltip>
        );
      }

      case 'Country': {
        const srcSetRoot = 'https://flagcdn.com/w40';
        const srcRoot = 'https://flagcdn.com/w20';

        return (
          <div>
            {row.country && (
              <div>
                <img
                  loading="lazy"
                  width="20"
                  style={{
                    marginRight: 10,
                  }}
                  srcSet={`${srcSetRoot}/${row.country?.toLowerCase()}.png 2x`}
                  src={`${srcRoot}/${row.country?.toLowerCase()}.png`}
                  alt=""
                />
                {countries.find(country => country.code === row.country)?.label}
              </div>
            )}
          </div>
        );
      }

      case 'Tags':
        return (
          <ContentTags
            className="cell-content"
            tags={row.tags}
            hideIcon
          />
        );

      default:
        return (
          <CellTooltip title={value}>
            <div className="cell-content">
              {value}
            </div>
          </CellTooltip>
        );
    }
  };

  render() {
    const {
      className,
      toolbarProps,
      csv,
    } = this.props;

    const {
      columnsDef,
      columnNames,
      rows,
      loading,
      totalItems,
      active,
      pageNumber,
      pageSize,
      sortBy,
      filters,
    } = this.state;

    const customButtons = csv
      ? [(
        <VibeButton
          key="btn-export"
          variant="outlined"
          text="Export"
          btnColor="purple"
          icon={(
            <VibeIcon
              icon={viDownload}
              color={color.primary}
              size={16}
            />
          )}
          tooltip={`Export ${totalItems} Locations to CSV`}
          tooltipProps={{
            placement: 'top',
          }}
          onClick={this.onExport}
        />
      )]
      : [];

    // add export button
    const newToolbarProps = {
      ...toolbarProps,
      customButtons: [
        ...toolbarProps.customButtons,
        ...customButtons,
      ],
    };

    return (
      <div className={classNames('Table', 'TableSupportDashboard', className)}>
        <VibeTable
          {...this.props}
          toolbarProps={newToolbarProps}
          tableId={tableId}
          columnsDef={columnsDef}
          columnNames={columnNames}
          rows={rows}
          loading={loading}
          sortBy={sortBy}
          filters={filters}
          totalItems={totalItems}
          active={active}
          pageNumber={pageNumber}
          pageSize={pageSize}
          renderCell={this.renderCell}
          onReset={this.onReset}
          onUpdate={this.onUpdate}
          onFetch={this.fetchData}
        />
      </div>
    );
  }
}

TableSupportDashboard.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ...tablePropsType,
};

TableSupportDashboard.defaultProps = {
  ...tablePropsDefault,
};

export default withRouter(TableSupportDashboard);
