import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Link,
} from 'react-router-dom';
import withRouter from '../../wrappers/withRouter';
import VibeIcon from '../VibeIcon/VibeIcon';
import color from '../../sass/color.scss';
import './NavigationSideItem.scss';

function NavigationSideItem({
  className,
  id,
  text,
  icon,
  url,
  matchUrl,
  show,
  location: {
    pathname = '',
  },
}) {
  if (!show) {
    return null;
  }

  const active = pathname.indexOf(matchUrl || url) >= 0;

  return (
    <Link
      id={id}
      className={classNames('NavigationSideItem', className, { active })}
      to={url}
    >
      {icon ? (
        <VibeIcon
          icon={icon}
          color={active
            ? color.yellow
            : color.navigationText}
          size={24}
        />
      ) : null}

      <div className="text">
        {text}
      </div>

      <div className="active-arrow" />
    </Link>
  );
}

NavigationSideItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
  url: PropTypes.string.isRequired,
  matchUrl: PropTypes.string,
  show: PropTypes.bool,
};

NavigationSideItem.defaultProps = {
  className: '',
  id: '',
  icon: null,
  matchUrl: null,
  show: false,
};

export default withRouter(NavigationSideItem);
